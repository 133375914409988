import ls from "local-storage";
import { observable, toJS } from "mobx";
import { formatLocationHours, formatMenuHours, getLocationNameById } from "../services/utils";
import _ from "lodash";
import moment from "moment";

const store = observable({
  categories: observable.map(),
  categorySearch: observable.box(""),
  currentLocation: observable.map(),
  content: observable.map(),
  customTextOptions: observable.map(),
  error: observable.map(),
  formattedReports: observable.map(),
  fees: observable.map(),
  fulfillments: observable.map(),
  fulfillmentSearch: observable.box(""),
  isAdmin: observable.box(false),
  isSyncing: observable.box(null),
  items: observable.map(),
  itemSearch: observable.box(""),
  loading: observable.box(false),
  loadingUsers: observable.box(false),
  loadingCounter: observable.box(0),
  userLoadingCounter: observable.box(0),
  loadingMessage: observable.map(),
  locations: observable.map(),
  loggedIn: observable.box(false),
  menus: observable.map(),
  menuSearch: observable.box(""),
  modifierLists: observable.map(),
  modifierListSearch: observable.box(""),
  modifiers: observable.map(),
  modifierSearch: observable.box(""),
  notifications: observable.map(),
  groupedNotifications: observable.map(),
  scheduledNotifications: observable.map(),
  sentNotifications: observable.map(),
  pausedFulfillments: observable.map(),
  posConnectionStatus: observable.box(true),
  promoCodeList: observable.map(),
  currentPromoCodes: observable.map(),
  expiredPromoCodes: observable.map(),
  currentSales: observable.map(),
  expiredSales: observable.map(),
  punchCardSettings: observable.map(),
  referrals: observable.map(),
  scheduledBroadcasts: observable.map(),
  selectedHours: observable.map(),
  sentBroadcasts: observable.map(),
  showErrorDialog: observable.box(false),
  showSecondaryButton: observable.box(false),
  showSyncErrorDialog: observable.box(false),
  showTenantList: observable.box(false),
  squareLoyalty: observable.map(),
  success: observable.map(),
  sync_task_id: observable.box(null),
  sync_status: observable.box(null),
  sync_counter: observable.box(0),
  sync_error_message: observable.map(),
  taxes: observable.map(),
  tenant: observable.map(),
  tenantId: observable.map(),
  tenantRequired: observable.box(false),
  tenantSearch: observable.box(""),
  tenants: observable.map(),
  activeTheme: observable.map(),
  themes: observable.map(),
  token: observable.map(),
  user: observable.map(),
  users: observable.map(),
  userSearch: observable.box(""),
  websocketShouldReopen: observable.box(true),
  punch_cards: observable.map(),
  current_punch_card: observable.map(),
  punch_list: observable.map(),
  used_punch_cards: observable.map(),
  availableRewards: observable.map(null),

  updateCategories(categories) {
    this.categories = categories;
  },

  updateCategory(category) {
    let index = _.findIndex(this.categories, { 'id': category.id });
    this.categories.splice(index, 1, category);
  },

  addToCategories(categories) {
    this.categories.push(categories);
    this.categories = _.flattenDeep(this.categories);
  },

  updateContent(content) {
    this.content = content;
    let customTextOptions = [];
    for (let property in content.defaults) {
      if (content.content[property]) {
        customTextOptions.push(content.content[property]);
      } else {
        customTextOptions.push(content.defaults[property]);
      }
      content.defaults[property].property = property;
      if ((content.defaults[property].name).includes('Description')) {
        content.defaults[property].isDescription = true;
      } else {
        content.defaults[property].isDescription = false;
      }
    }
    this.customTextOptions = customTextOptions;
  },

  updateError(error) {
    this.error = error;
  },

  updateLoading(boolean) {
    boolean === true ? this.loadingCounter++ : this.loadingCounter--;
    this.loading = this.loadingCounter > 0;
  },

  updateLoadingUsers(boolean) {
    boolean === true ? this.userLoadingCounter++ : this.userLoadingCounter--;
    this.loadingUsers = this.userLoadingCounter > 0;
  },

  updateLoadingMessage(message) {
    this.loadingMessage = message;
  },

  updateShowErrorDialog(boolean) {
    this.showErrorDialog = boolean;
  },

  updateShowSyncErrorDialog(boolean) {
    this.showSyncErrorDialog = boolean;
  },

  updateShowSecondaryButton(boolean){
    this.showSecondaryButton = boolean;
  },

  updateLoggedIn(boolean){
    this.loggedIn.set(boolean);
  },

  updateShowTenantList(boolean){
    this.showTenantList.set(boolean);
  },

  updateLocations(locations) {
    locations = formatLocationHours(locations);
    locations = _.sortBy(locations, ['name']);
    this.locations = locations;
  },

  updateSingleLocation(location) {
    let index = _.findIndex(this.locations, { 'id': location.id });
    this.locations.splice(index, 1, location);
  },

  updateCurrentLocation(locationId) {
    this.currentLocation = this.locations.find(location => {
      return location.id === locationId;
    });
  },

  updateMenus(menus) {
    menus = formatMenuHours(menus);
    this.menus = menus;
  },

  updateMenuSearch(value) {
    this.menuSearch.set(value);
  },

  updateIsAdmin(boolean) {
    this.isAdmin.set(boolean);
  },

  updateItems(items) {
    this.items = items;
  },

  updateItem(item) {
    if (this.items.length) {
      let index = _.findIndex(this.items, { 'id': item.id });
      this.items.splice(index, 1, item);
    }
  },

  updateSuccess(success) {
    this.success = success;
  },

  updateTenant(tenant) {
    this.tenant = tenant;
  },

  updateTenantId(tenantId) {
    this.tenantId = tenantId;
    ls.set("tenant_id", tenantId);
    if (tenantId === null) {
      ls.remove('tenant_id');
    }
  },

  updateTenantRequired(boolean) {
    this.tenantRequired.set(boolean);
  },

  updateTenantSearch(value) {
    this.tenantSearch.set(value);
  },

  updateTenants(tenants) {
    this.tenants = _.sortBy(tenants, ['name']);
  },

  updateSingleUser(user) {
    // Update user list after editing user
    let index = _.findIndex(this.users, { 'id': user.id });
    this.users.splice(index, 1, user);
    if (user.id === this.user.id) this.updateUser(user);
  },

  updateUser(user) {
      // Set logged in user for 'your profile'
      this.user = user;
      ls.set("user", JSON.stringify([user]));
      if (user === null) {
        ls.remove('user');
      }
  },

  updateUserToken(token) {
    this.token = token;
    ls.set("token", token);
    if (token === null) {
      ls.remove('token');
    }

  },

  updateUsers(users) {
    this.users = users;
  },

  updateUserInUserList(user) {
    let userIndex = _.findIndex(this.users, cachedUser => cachedUser.id === user.id);
    this.users[userIndex] = user;
  },

  updateScheduledBroadcasts(broadcasts) {
    this.scheduledBroadcasts = broadcasts;
  },

  updateSentBroadcasts(broadcasts) {
    this.sentBroadcasts = broadcasts;
  },

  updateBroadcastMessage(broadcast) {
    if (broadcast.status === 'pending') {
      let index = _.findIndex(this.scheduledBroadcasts, { 'id': broadcast.id });
      this.scheduledBroadcasts.splice(index, 1, broadcast);
    }
  },

  updatePromoCodeList(promoCodeList) {
    this.currentPromoCodes = _.filter(promoCodeList, (promo) => {
      return !promo.expiration || (promo.expiration && moment(promo.expiration).format("MM/DD/YYYY") >= moment(new Date()).format("MM/DD/YYYY"));
    });
    this.expiredPromoCodes = _.filter(promoCodeList, (promo) => {
      return promo.expiration && moment(promo.expiration).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY");
    });
  },

  updatePromoCode(promoCode) {
    let index = _.findIndex(this.currentPromoCodes, { 'id': promoCode.id });
    this.currentPromoCodes.splice(index, 1, promoCode);
  },

  updateSalesList(salesList) {
    this.salesList = salesList;
    this.currentSales = _.filter(salesList, (sale) => {
      return moment(sale.end_time).format("MM/DD/YYYY") >= moment(new Date()).format("MM/DD/YYYY");
    });
    this.expiredSales = _.filter(salesList, (sale) => {
      return moment(sale.end_time).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY");
    });
  },

  updatePosStatus(value) {
    this.posConnectionStatus = value;
  },

  updatePunchCardSettings(punchCardSettings) {
    this.punchCardSettings = punchCardSettings;
  },
  updateFormattedReports(reports) {
    this.formattedReports = reports;
  },
  updateTaxes(taxes) {
    _.map(taxes, tax => {
      return this.createLocationNameList(tax);
    })
    this.taxes = taxes;
  },
  updateFees(fees) {
    _.map((fees), fee => {
      return this.createLocationNameList(fee);
    })
    this.fees = _.sortBy(fees, ['name']);
  },
  createLocationNameList(item) {
    let nameList = _.map(item.locations, locationId => {
      return getLocationNameById(locationId, store.locations);
    });
    item.location_names = nameList.join(', ');
  },
  addMore(type, items) {
    this[type] = [...this[type], ...items];
    this[type] = _.chain(this[type])
      .uniqBy(item => item.id)
      .value()
  },
  updateFulfillments(fulfillments) {
    this.fulfillments = fulfillments;
  },
  updateFulfillmentSearch(value) {
    this.fulfillmentSearch.set(value);
  },
  updateIsSyncing(status) {
    this.isSyncing.set(status);
  },
  updateSyncStatus(status) {
    this.sync_status.set(status);
  },
  updateSyncTaskId(id) {
    ls.set('sync_task_id', id);
    if (id === null) {
      ls.remove('sync_task_id');
    }
    this.sync_task_id.set(id);
  },
  updateSyncCounter(count) {
    this.sync_counter.set(count);
  },
  updateSyncErrorMessage(message) {
    this.sync_error_message = message;
  },
  updateItemSearch(value) {
    this.itemSearch.set(value);
  },
  updateCategorySearch(value) {
    this.categorySearch.set(value);
  },
  updateUserSearch(value) {
    this.userSearch.set(value);
  },
  updateSquareLoyalty(value)  {
    this.squareLoyalty = value;
  },
  updateModifier(modifier) {
    if (this.modifiers.length) {
      let index = _.findIndex(this.modifiers, { 'id': modifier.id });
      this.modifiers.splice(index, 1, modifier);
    }
  },
  updateModifiers(modifiers) {
    this.modifiers = modifiers;
  },
  updateModifierSearch(value) {
    this.modifierSearch.set(value);
  },
  updateModifierListSearch(value) {
    this.modifierListSearch.set(value);
  },
  updateModifierLists(modifierLists) {
    this.modifierLists = modifierLists;
  },
  updatePausedFulfillments(fulfillments) {
    this.pausedFulfillments = fulfillments;
  },
  updateNotifications(notifications) {
    notifications = _.orderBy(notifications, 'notification_date', 'desc');
    this.notifications = notifications;
    this.groupedNotifications = _.groupBy(notifications, "status");
  },
  updateScheduledNotifications(notifications) {
    this.scheduledNotifications = notifications;
  },
  updateSentNotifications(notifications) {
    this.sentNotifications = notifications;
  },
  updatewebsocketShouldReopen(value) {
    this.websocketShouldReopen.set(value);
  },
  updateActiveTheme(theme) {
    this.activeTheme = theme;
  },
  updateThemes(themes) {
    this.themes = themes;
  },
  updatePunchCards(punch_cards) {
    this.punch_cards = punch_cards;
  },
  updateCurrentPunchCard(punch_card) {
    this.current_punch_card = punch_card;
    this.updatePunchList();
  },
  updateSelectedHours(hours) {
    this.selectedHours = hours;
  },
  updatePunchList() {
    let punch_list = [];
    if (this.current_punch_card && this.current_punch_card.punches) {
      for (let i = 0; i < this.punchCardSettings.points_required; i++) {
        if (i >= this.current_punch_card.punches.length) {
          let body = { punched: false };
          punch_list.push(body);
        } else {
          let body = { punched: true };
          punch_list.push(body);
        }
        _.forEach(punch_list, (punch, index) => {
          punch.index = index;
        });
      }
    }
    this.punch_list = punch_list;
  },
  updateUsedPunchCards(used_card) {
    if (!used_card) {
      this.used_punch_cards = [];
    } else if (this.used_punch_cards.length) {
      this.used_punch_cards.push(used_card);
    } else {
      this.used_punch_cards = [];
      this.used_punch_cards.push(used_card);
    }
    this.used_punch_cards = _.uniqBy(this.used_punch_cards, 'id');
  },
  updateAvailableRewards(punch_card) {
    if (!punch_card) {
      this.availableRewards = [];
    } else if (this.availableRewards.length) {
      this.availableRewards.push(punch_card);
    } else {
      this.availableRewards = [];
      this.availableRewards.push(punch_card);
    }
    this.availableRewards = _.uniqBy(this.availableRewards, 'id');
  },
  updateReferrals(referrals) {
    this.referrals = referrals;
  },
  reset(updateUser) {
    this.categories = {};
    this.currentLocation = {};
    this.currentPromoCodes = {};
    this.content = {};
    this.customTextOptions = {};
    this.error = {};
    this.expiredPromoCodes = {};
    this.formattedReports = {};
    this.fulfillments = {};
    this.holidays = {};
    this.isSyncing.set(false);
    this.items = {};
    this.locations = {};
    this.modifierLists = {};
    this.modifiers = {};
    this.pausedFulfillments = {};
    this.posConnectionStatus = true;
    this.punchCardSettings = {};
    this.referrals = {};
    this.scheduledBroadcasts = {};
    this.selectedHours = [];
    this.sentBroadcasts = {};
    this.showErrorDialog.set(false);
    this.showSecondaryButton.set(false);
    this.showSyncErrorDialog.set(false);
    this.squareLoyalty = {};
    this.success = {};
    this.updateSyncTaskId(null);
    this.sync_status.set(null);
    this.sync_counter.set(0);
    this.sync_error_message = {};
    this.taxes = {};
    this.fees = {};
    this.tenant = {};
    this.themes = {};
    this.activeTheme = {};
    this.updateTenantId(null);
    this.updateTenantRequired(false);
    if (updateUser) {
      this.updateUserToken(null);
      this.updateUser(null);
      this.loggedIn.set(false);
      this.isAdmin.set(false);
      this.showTenantList.set(false);
    }
    this.users = {};
  }
});

export default store;
