import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import _ from "lodash";

import { getStartOfDayByDate, getEndOfDayByDate, loadingSpinner } from "../../services/utils";
import AdminCard from "../elements/adminCard";
import { HeaderText, Text } from "../elements/text";
import store from "../../store/store";
import AdminToolTip from "../elements/adminToolTip";
import { colors } from "../../styles/global";
import ReportsHourlySales from "./reportsHourlySales";
import AutocompleteVerbiage from './autcompleteVerbiage';
import tenantAPI from '../../services/api/tenantAPI';
import reportsAPI from '../../services/api/reportsAPI';

class ReportsAppReporting extends Component {
  constructor() {
    super();
    this.state = {
      today: moment(),
      orderGrowthSeries: [
        {
          name: "Order Growth",
          type: "column",
          data: [],
        },
      ],
      orderGrowthOptions: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          }
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            show: true,
          },
        },
        fill: {
          colors: colors.secondaryBlue
        }
      },
      analytics: {},
      userReports: {},
      orderReports: {},
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(() => {
        let today = moment().format("MM/DD/YYYY");
        this.formatReports(today, today);
      });
  };

  formatReports(fromDate, toDate) {
    reportsAPI.getReportsAnalyticsUsers(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate))
    .then((data) => {
      this.setState({ userReports: data });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { userReports } = this.state;

    return (
      <div>
        <AutocompleteVerbiage />
        <AdminCard title="">
          {store.loading ? (
            <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
              Loading...
            </div>
            ) : (
            <div>
              <HeaderText medium>Online Sales Summary</HeaderText>
              <div className="divider"></div>
              <div className="columns mt-2">
                <div className="column is-8">
                  <div className="columns is-multiline is-mobile">
                    {_.isEmpty(userReports) ?
                    <div className="column is-12">
                      {loadingSpinner()}
                    </div> : <>
                    <div className="column is-6">
                      <div className="display-column">
                        <HeaderText medium>
                          Registered Users / App Users
                          <AdminToolTip
                            title={
                              <div>
                                <div className="display-in-line">
                                  <Text weight="bold" className="pr-1">
                                    Registered:{" "}
                                  </Text>
                                  <Text>
                                    Users with a credit card linked to their
                                    account
                                  </Text>
                                </div>
                                <br />
                                <div className="display-in-line">
                                  <Text weight="bold" className="pr-1">
                                    Total:{" "}
                                  </Text>
                                  <Text>
                                    Users that created an account, but haven't
                                    added a credit card
                                  </Text>
                                </div>
                              </div>
                            }
                          />
                        </HeaderText>
                        <div className="display-in-line">
                          <HeaderText large>
                            {userReports.total_registered_users}
                          </HeaderText>
                          <div className="secondaryGrey is-size-4 pl-3 pr-3">
                            /
                          </div>
                          <Text weight="bold" color={colors.secondaryGrey} huge>
                            {userReports.total_app_users}
                          </Text>
                        </div>
                        <div className='secondaryGrey small-text'>Lifetime</div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <div className="display-column">
                        <HeaderText medium>New Users</HeaderText>
                        <HeaderText large color={colors.darkGreen}>
                          {userReports.total_new_users}
                        </HeaderText>
                        <div className='secondaryGrey small-text'>Past 3 months</div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <div className="display-column">
                        <HeaderText medium>
                          Active Users / Registered Users
                          <AdminToolTip
                            title={
                              <div>
                                <div className="display-in-line">
                                  <Text weight="bold" className="pr-1">
                                    Active:{" "}
                                  </Text>
                                  <Text>
                                    Users that have placed an order in the last
                                    three months
                                  </Text>
                                </div>
                                <br />
                                <div className="display-in-line">
                                  <Text weight="bold" className="pr-1">
                                    Registered:{" "}
                                  </Text>
                                  <Text>
                                    Users with a credit card linked to their
                                    account
                                  </Text>
                                </div>
                              </div>
                            }
                          />
                        </HeaderText>
                        <div className="display-in-line">
                          <HeaderText large>
                            {userReports.total_active_users}
                          </HeaderText>
                          <div className="secondaryGrey is-size-4 pl-3 pr-3">
                            /
                          </div>
                          <Text weight="bold" huge color={colors.secondaryGrey}>
                            {userReports.total_registered_users}
                          </Text>
                        </div>
                        <div className="display-in-line">
                          <Text
                            color={colors.secondaryGrey}
                            style={{ width: 50 }}
                            small
                            className="pr-3"
                          >
                            Past 3 months
                          </Text>
                          <Text small className="pl-3" color={colors.secondaryGrey}>
                            Lifetime
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="display-column">
                        <HeaderText medium>Total Repeat Customers</HeaderText>
                        <HeaderText large color={colors.darkGreen}>
                          {userReports.total_repeat_customers}
                        </HeaderText>
                        <div className='small-text secondaryGrey'>
                          Customers who have placed <br /> more than one order in the
                          <br /> past 3 months
                        </div>
                      </div>
                    </div>
                    </>}
                  </div>
                </div>
              </div>
              <ReportsHourlySales />
            </div>
          )}
        </AdminCard>
      </div>
    );
  }
}

export default observer(ReportsAppReporting);
