import { toJS } from "mobx";
import store from "../store/store";
import config from "../config/config";

const ws_url = config.ws_url;


export default class websocketClient {
    static openWebsocket = () => {
        try {
            let token = toJS(store.token);
            let tenant_id = store.tenantID || store.tenant.id;
            this.url = ws_url + 'websockets/tenants/' + tenant_id + '/';
            this.socket = new WebSocket(this.url, ["token", token]);

            this.socket.onopen = () => {
                store.updatewebsocketShouldReopen(true);
            };

            this.socket.onerror = (e) => {
                console.log('WS ERROR: ', e.message, e.code);
            };

            this.socket.onmessage = (e) => {
                try {
                    let data = JSON.parse(e.data);
                    if (data.data.event_type === 'authorization_updated') {
                        store.updatePosStatus(data.data.status);
                    }
                    if (data.data.event_type === 'sync_status') {
                        store.updateSyncTaskId(data.data.task_id);
                        store.updateIsSyncing(true);
                        store.updateShowSecondaryButton(false);
                        store.updateSyncErrorMessage(null);
                        store.updateShowSyncErrorDialog(false);
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            this.socket.onclose = (e) => {
                this.socket = null;
                setTimeout(() => {
                    if (store.tenant.id && store.websocketShouldReopen.get() === true) this.openWebsocket();
                }, 10000);

            };
        } catch (err) {
            console.log(err);
        }
    };

}
