import React, { Component } from "react";
import withNav from "../components/withNav";
import { Route } from "react-router-dom";
import MenuList from "./menuList";
import ItemList from "./itemList";
import CategoryList from "./categoryList";
import ModifierListList from './modifierListList';
import ModifierList from "./modifierList";
import { SideNavAndContentContainer } from '../components/elements/navs';

class Catalog extends Component {
  render() {
    return (
      <div>
        <SideNavAndContentContainer>
          <Route path="/catalog/menus" component={MenuList} />
          <Route path="/catalog/items" component={ItemList} />
          <Route path="/catalog/categories" component={CategoryList} />
          <Route path="/catalog/modifier-lists" component={ ModifierListList } />
          <Route path="/catalog/modifiers" component={ModifierList} />
        </SideNavAndContentContainer>
      </div>
    );
  }
}

export default withNav(Catalog);
