import styled from "styled-components";
import { colors } from "../../styles/global";
import { DataGrid } from '@material-ui/data-grid';

export const AdminDataGrid = styled(DataGrid)`
    &.MuiDataGrid-root {
        border: 0;
        padding: 0;

        .MuiDataGrid-columnSeparator {
            visibility: hidden;
        }

        .MuiDataGrid-colCellTitleContainer {
            height: 36px;
        }

        .MuiDataGrid-colCellTitle {
            font-family: 'SofiaProBlack', sans-serif;
        }

        .MuiDataGrid-row {

            .tableRowButton {
                visibility: hidden;
            }

            &:hover {

                .tableRowButton {
                    visibility: visible;
                }
            }

            &:last-child {
                .MuiDataGrid-cell {
                    border-bottom: none;
                }
            }

        }

        .MuiDataGrid-cell, .MuiDataGrid-colCell {
            padding: 0;

            ${props => props.showRowEditButtonsByParams} &:last-child {
                display: flex;
                justify-content: flex-end;
                padding-right: 30px;
            }
        }

        .MuiDataGrid-row.Mui-selected {
            background-color: ${colors.white};

            .tableRowButton {
                visibility: visible;
            }
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
            color: ${colors.brightGreen};
        }
    }
`