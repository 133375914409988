import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from '@material-ui/core';
import _ from 'lodash';

import store from '../../store/store';
import EditDrawer from '../elements/editDrawer';
import AdminCard from '../elements/adminCard';
import { formatDateTimeForView } from '../../services/utils';
import accountsAPI from '../../services/api/accountsAPI';


class UserOrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            orders: [],
            orderBy: 'name',
            order: 'desc',
            rowCollapsed: {},
        };
    }

    componentDidMount = () => {
        store.updateLoading(true);
        accountsAPI.getUserOrders(this.props.user.id)
            .then(response => {
                this.setState({ orders: response["data"] });
                store.updateLoading(false);
            });
    };

    handleSort(value) {
        let orders = this.state.orders;
        let orderBy = value;
        let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
        if (value === 'location') orders = _.orderBy(orders, [order => {
            let name = order.location ? order.location.name : '';
            return name;
        }], [order]);
        else if (value === 'date') orders = _.orderBy(orders, [order => order.fulfillment.ready_time], [order]);
        else if (value === 'total') orders = _.orderBy(orders, [order => order.total.amount], [order]);
        this.setState({ orders, order, orderBy });
    };

    getSortIcon(orderBy) {
        let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
            :
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
        return sortIcon;
    };

    toggleRow(order) {
        const rowCollapsed = order.id === this.state.rowCollapsed ? {} : order.id;
        this.setState({ rowCollapsed });
    };

    render() {
        const { user, orders, order, orderBy } = this.state;

        return (
            <EditDrawer
                title={`${user.full_name}'s Orders`}
                onClose={ () => this.props.openSecondaryDrawer('edit') }
                noSubmitButton
            >
                <AdminCard
                    title='Order History'
                    style={ { marginTop: '15px' } }
                >
                    { orders.length ?
                        <div style={ { width: '100%', flex: 1, overflowX: 'auto' } }>
                            <Table className='app-table-wrapper' size='small'>
                                <TableHead className='app-table-header'>
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell pl-0'>
                                            <TableSortLabel
                                                active={ orderBy === 'date' }
                                                direction={ orderBy === 'date' ? order : 'asc' }
                                                onClick={ () => this.handleSort('date') }
                                                IconComponent={ () => this.getSortIcon('date') }
                                            >
                                                Date
                                                { orderBy === 'date' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='app-table-cell'>
                                            <TableSortLabel
                                                active={ orderBy === 'total' }
                                                direction={ orderBy === 'total' ? order : 'asc' }
                                                onClick={ () => this.handleSort('total') }
                                                IconComponent={ () => this.getSortIcon('total') }
                                            >
                                                Total
                                                { orderBy === 'total' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='app-table-cell'>
                                            <TableSortLabel
                                                active={ orderBy === 'location' }
                                                direction={ orderBy === 'location' ? order : 'asc' }
                                                onClick={ () => this.handleSort('location') }
                                                IconComponent={ () => this.getSortIcon('location') }
                                            >
                                                Location
                                                { orderBy === 'location' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                    { orders.map(order =>
                                            <React.Fragment key={ order.id }>
                                                <TableRow>
                                                    <TableCell className={ `table-cell pl-0 ${ this.state.rowCollapsed === order.id ? 'remove-row-border' : '' }` }>
                                                        <IconButton
                                                            aria-label='expand row'
                                                            onClick={ () => { this.toggleRow(order); } }
                                                            size={ 'small' }
                                                            className='pr-2'                                                    >
                                                            { this.state.rowCollapsed === order.id ?
                                                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-0' icon={ ['fas', 'caret-down'] } /> :
                                                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-0' icon={ ['fas', 'caret-right'] } /> }
                                                        </IconButton>
                                                        { formatDateTimeForView(order.fulfillment.ready_time) }
                                                    </TableCell>
                                                    <TableCell className={ `table-cell ${ this.state.rowCollapsed === order.id ? 'remove-row-border' : '' }` }>{ order.total.formatted }</TableCell>
                                                    <TableCell className={ `table-cell ${ this.state.rowCollapsed === order.id ? 'remove-row-border' : '' }` }>{ order.location ? order.location.name : 'Location Not Found' }</TableCell>
                                                </TableRow>
                                                <TableRow className='table-row'>
                                                <TableCell className='table-cell remove-row-border' style={ { padding: 0 } } colSpan={ 3 }>
                                                        <Collapse in={ this.state.rowCollapsed === order.id } timeout='auto' unmountOnExit>
                                                            <div style={ { paddingBottom: 10, width: '100%', flex: 1, overflowX: 'auto' } }>
                                                                <Table size='small' className='sub-table'>
                                                                    <TableHead>
                                                                        <TableRow className='inner-table-row'>
                                                                            <TableCell className='semi-bold'>
                                                                                Qty
                                                                            </TableCell>
                                                                            <TableCell className='semi-bold'>
                                                                                Item
                                                                            </TableCell>
                                                                            <TableCell className='semi-bold'>
                                                                                Price
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        { order.line_items ? order.line_items.map(line_item =>
                                                                            <TableRow key={ line_item.id } className='table-row'>
                                                                                <TableCell className='table-cell'>{ line_item.quantity }</TableCell>
                                                                                <TableCell className='table-cell'>{ line_item.item_name }</TableCell>
                                                                                <TableCell className='table-cell'>{line_item.subtotal.formatted}</TableCell>
                                                                            </TableRow>) : null
                                                                        }
                                                                        <TableRow>
                                                                            <TableCell className='remove-row-border'></TableCell>
                                                                            <TableCell className='remove-row-border small-text'>Purchase Subtotal</TableCell>
                                                                            <TableCell className='remove-row-border small-text'>{ order.subtotal.formatted }</TableCell>
                                                                        </TableRow>
                                                                    { order.fees ? order.fees.map(fee =>
                                                                        <TableRow key={fee.id}>
                                                                            <TableCell className='remove-row-border pt-0'></TableCell>
                                                                            <TableCell className='remove-row-border pt-0 small-text'>{fee.name}</TableCell>
                                                                            <TableCell className='remove-row-border pt-0 small-text'>{ fee.amount.formatted }</TableCell>
                                                                        </TableRow>) : null
                                                                    }
                                                                    { order.discounts ? order.discounts.map(discount =>
                                                                        <TableRow key={discount.id}>
                                                                            <TableCell className='remove-row-border pt-0'></TableCell>
                                                                            <TableCell className='remove-row-border pt-0 small-text'>{discount.name}</TableCell>
                                                                            <TableCell className='remove-row-border pt-0 small-text'>{ discount.amount.formatted }</TableCell>
                                                                        </TableRow>) : null
                                                                    }
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                            ) }
                                    </TableBody>
                            </Table>
                        </div>
                        : <div>No orders placed.</div>
                    }
                </AdminCard>
            </EditDrawer>
        );
    }
}

export default observer(UserOrderHistory);
