import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import { Text } from '../elements/text';
import { Popover } from '@material-ui/core';


class AdminColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const {
            noPadding,
            title,
            description,
            colorValue,
            onChange,
            fieldError
        } = this.props;

        return (
            <div className={ noPadding ? '' : 'pt-5' }>
                <label className="app-field-label">
                    { title }
                </label>
                <Text>{ description }</Text>
                <div
                    className='color-picker-button'
                    style={ { backgroundColor: colorValue } }
                    onClick={this.handleClick}
                />
                <Text>{ colorValue }</Text>
                <Popover
                    open={ Boolean(anchorEl) }
                    anchorEl={ anchorEl }
                    onClose={ this.handleClose }
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }
                    transformOrigin={ {
                        vertical: 'top',
                        horizontal: 'center',
                    } }
                >
                    <ChromePicker
                        disableAlpha
                        color={ colorValue || 'black' }
                        onChangeComplete={ onChange }
                    />
                </Popover>
                { fieldError }
            </div>
        )
    }
}

export default AdminColorPicker;