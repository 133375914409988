import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../store/store";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Currency from 'react-currency-formatter';
import moment from "moment";
import _ from "lodash";
import { getStartOfDayByDate, getEndOfDayByDate, getSortIcon } from "../services/utils";
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminCard from '../components/elements/adminCard';
import ReportsCustomHeader from "../components/reports/reportsCustomHeader";
import ReportsTopMenuItems from "../components/reports/reportsTopMenuItems";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import AutocompleteVerbiage from '../components/reports/autcompleteVerbiage';
import tenantAPI from '../services/api/tenantAPI';
import reportsAPI from '../services/api/reportsAPI';

class ItemSales extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      itemSales: [],
      selectedLocation: 'All',
      order: "desc",
      currentPage: 0,
      rowsPerPage: 10,
      orderBy: "item_name",
    };
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
  }

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({page: 1})
      .then(locations => {
        this.setState({ locations });
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format('MM/DD/YYYY');
        this.formatReports(monthAgo, todaysDate);
      });
  }

  formatReports(fromDate, toDate) {
    let location = this.state.selectedLocation === 'All' ? null : this.state.selectedLocation;
      reportsAPI.getItemSales(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location)
        .then(data => {
          this.setState({itemSales: data.items});
        })
        .catch(err => console.error(err));
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format('MM/DD/YYYY');
    if (type === 'fromDate') {
      this.setState({fromDate: displayDate}, () => {
        this.formatReports(newDate, this.state.toDate);
      });
    } else if (type === 'toDate') {
      this.setState({toDate: displayDate}, () => {
        this.formatReports(this.state.fromDate, newDate);
      });
    }
  }

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
    });
  }

  handleSort(value) {
    let itemSales = _.cloneDeep(this.state.itemSales);
    let orderBy = value;
    let order = this.state.order === "asc" && this.state.orderBy === value ? "desc" : "asc";
    itemSales = _.orderBy(itemSales, [(item) => item[value]],[order]);
    this.setState({ itemSales, order, orderBy, currentPage: 0 });
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  }

  render() {
    const { currentPage, order, orderBy, rowsPerPage } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="Item Sales"
          mainNav="Reports"
          subNav="Location Reports"
          description="View the overall item sales breakdown for your locations."
        />
        <AutocompleteVerbiage />
        <ReportsTopMenuItems />
        <AdminCard
          title=""
          customHeader={
            <ReportsCustomHeader
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              handleChange={this.handleChange}
              selectedLocation={this.state.selectedLocation}
              handleSelectLocation={this.handleSelectLocation}
          />}
        >
          {store.loading ?
          <div
            className="admin-page-header-subtext"
            style={{ textAlign: "center", padding: 20 }}
          >
            Loading...
          </div> :
          this.state.itemSales && !this.state.itemSales.length ?
            <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
              No item sales for the selected days or location.
            </div> :
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0 set-thin-width">
                    <TableSortLabel
                      active={ orderBy === 'item_name' }
                      direction={ orderBy === 'item_name' ? order : 'asc' }
                      onClick={ () => this.handleSort('item_name') }
                      IconComponent={ () =>
                        getSortIcon(this.state.order === "asc" && this.state.orderBy === 'item_name')
                      }
                    >
                      Item Name
                      { orderBy === 'item_name' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="app-table-cell set-thin-width">Category</TableCell>
                  <TableCell className="app-table-cell set-thin-width">Amount Sold</TableCell>
                  <TableCell className="app-table-cell">Gross Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
                {this.state.itemSales &&
                  this.state.itemSales.length > 0 &&
                  this.state.itemSales.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                  .map((report, index) => (
                    <TableRow key={index} className="app-table-row">
                      <TableCell className="app-table-cell pl-0">
                        {report.item_name}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {report.item_categories}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {report.quantity_sold}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        <Currency
                          quantity={report.total_sales.amount}
                          currency={report.total_sales.currency}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={ [10, 25, 50] }
                      count={ this.state.itemSales && this.state.itemSales.length ? this.state.itemSales.length : 0 }
                      rowsPerPage={ this.state.rowsPerPage }
                      onRowsPerPageChange={ (event) => this.updateRows(event) }
                      page={ this.state.currentPage }
                      onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                      style={ { borderBottom: 'none' } }
                    />
                </TableRow>
              </TableBody>
            </Table>}
        </AdminCard>
      </div>
    );
  }
}

export default observer(ItemSales);
