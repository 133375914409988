import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { getLocationNameById, formatDateTimeForView } from "../../services/utils";
import store from "../../store/store";
import AdminCard from "../elements/adminCard";
import EditDrawer from "../elements/editDrawer";
import { Text } from "../elements/text";


class BroadcastsView extends Component {
  render() {
    const { message, onClose } = this.props;
    return (
      <EditDrawer
        noSubmitButton
        title="View SMS Message"
        onClose={ () => onClose(false) }
      >
        <AdminCard
          title="Sent SMS Message Details"
          style={{ marginTop: "15px" }}
        >
          <div className="app-field-label pt-4">Date Sent</div>
          <Text weight='bold'>
            <span className="pr-3">
              <FontAwesomeIcon className="secondaryBlue" size="1x" icon={['fal', 'paper-plane']} />
            </span>
            { formatDateTimeForView(message.broadcast_date) }
          </Text>
          <div className="app-field-label pt-4">Message</div>
          <Text weight='bold'>
            <span className="pr-3">
              <FontAwesomeIcon className="secondaryBlue" size="1x" icon={['fal', 'message-sms']} />
            </span>
            {message.message}
          </Text>
          <div className="app-field-label pt-4">Customers Reached</div>
          <Text weight='bold'>
            <span className="pr-3">
              <FontAwesomeIcon className="secondaryBlue" size="1x" icon={['fal', 'user-group']} />
            </span>
            {message.user_count} Customers
          </Text>
        </AdminCard>
        <AdminCard
          title="Sent SMS Message Location"
          style={{ marginTop: "15px" }}
          description={""}
        >
          <div className="pt-2">
            <span className="pr-3">
              <FontAwesomeIcon className="brightGreen" size="1x" icon={['fal', 'check']} />
            </span>
            { message.location ?
              <React.Fragment>
                {store.locations.length ? getLocationNameById(message.location, store.locations) : null}
              </React.Fragment>
              : 'All Locations'
              }
          </div>
        </AdminCard>
      </EditDrawer>
    );
  }
}

export default BroadcastsView;
