import React, { Component } from "react";
import { HeaderText, Text } from "./text";
import { Button } from "./buttons";
import { Card, CardHeader } from "./cards";
import { Link } from "./links";
import AdminToolTip from './adminToolTip';

class AdminCard extends Component {

    render() {
        const {
            bottomBorder,
            description,
            customHeader,
            largeTitle,
            linkText,
            noDescription,
            onHeaderActionClick,
            showLink,
            showUneditableFlag,
            style,
            successMessage,
            title,
            toolTip
        } = this.props;

        return (
            <Card style={style}>
                {successMessage ? 
                <div className="pb-2">{successMessage}</div>
                : null}
                <CardHeader bottomBorder={bottomBorder ? true : false} noDescription={noDescription ? true : description ? false : true}>
                    {customHeader ? customHeader : null}
                    <div>
                        <HeaderText small={!largeTitle} medium={largeTitle}>
                            {title}
                            {toolTip ? <AdminToolTip title={toolTip} /> : null}
                        </HeaderText>
                        <Text medium>
                            {description}
                        </Text>
                    </div>
                    {onHeaderActionClick && !showLink && (
                        <div>
                            {!showUneditableFlag ? (
                                <Button
                                    edit
                                    noBorder
                                    noPadding
                                    className="cursor-pointer"
                                    onClick={onHeaderActionClick}
                                >
                                    { linkText ? linkText : 'Edit'}
                                </Button>
                            ) : (
                                <Link
                                    onClick={onHeaderActionClick}
                                >
                                    Why can't I edit this?
                                </Link>
                            )}
                        </div>
                    )}
                </CardHeader>
                {this.props.children}
            </Card>
        );
    }
}

export default AdminCard;