import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminToggle from '../components/elements/adminToggle';
import { Text } from '../components/elements/text';
import PunchCardsEdit from '../components/promo/punchCardsEdit';
import tenantAPI from '../services/api/tenantAPI';


class PunchCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editType: ''
    };
  }

  componentDidMount() {
    tenantAPI.getPunchCardSettings();
  }

  handleDialogOpen = editType => {
    this.setState({ editType });
    this.setState({ open: true });
  }

  handleDialogClose = () => {
    this.setState({ open: false });
    tenantAPI.getPunchCardSettings();
  }

  handleUpdate = data => {
    tenantAPI.updatePunchCardSettings(JSON.stringify(data));
  }

  handleToggle = () => {
    this.handleUpdate({ is_enabled: !store.punchCardSettings.is_enabled });
  };

  render() {
    const punchCardSettings = store.punchCardSettings;
    return (
      <div className='admin-page-wrapper'>
        <AdminPageHeader
          title='Punch Cards'
          mainNav='Marketing'
          subNav='Rewards'
          description='Punch cards are a way to offer repeat customers a reward for multiple purchases.'
        />
        <div className='admin-page-content'>
          {punchCardSettings &&
          punchCardSettings.reward_type && (
            <div>
              <AdminCard
                largeTitle
                title='Enable/Disable Punch Cards'
                description='Customers will only be able to earn points and rewards if this is enabled.'
              >
                <Text weight={ 'bold' } style={ { marginBottom: 10 } }>Punch Cards</Text>
                <AdminToggle
                  className='app-toggle'
                  checked={ Boolean(punchCardSettings.is_enabled) }
                  onChange={ this.handleToggle }
                />
              </AdminCard>
              <AdminCard
                largeTitle
                title='Punch Card Reward'
                description='This is the reward that will be applied to a future purchase once a customer has earned enough points.'
                onHeaderActionClick={ () => this.handleDialogOpen('Punch Card Reward') }
              >
                <Table className='app-table-wrapper'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>Reward Type</TableCell>
                      <TableCell className='app-table-cell'>Reward Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>{`${punchCardSettings.reward_type === 'percent' ? '%' : 'Amount'} off an order`}</TableCell>
                      <TableCell className='app-table-cell'>{punchCardSettings.reward_type === 'percent' ? `${punchCardSettings.percent_off}%` : punchCardSettings.amount_off.formatted}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AdminCard>
              <AdminCard
                largeTitle
                title='Number of Points Required'
                description='A customer will earn a reward once they have earned this many points.'
                onHeaderActionClick={ () => this.handleDialogOpen('Number of Points Required') }
              >
                <Table className='app-table-wrapper'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>Points Required</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>{punchCardSettings.points_required} points required to earn reward</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AdminCard>
              <AdminCard
                largeTitle
                title='Minimum Order Amount'
                description='A customer will only earn a point if their order total is greater than or equal to the minimum amount.'
                onHeaderActionClick={ () => this.handleDialogOpen('Minimum Order Amount') }
              >
                <Table className='app-table-wrapper'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>Minimum Order Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                    <TableRow className='app-table-row'>
                      <TableCell className='set-width thin app-table-cell pl-0'>{ punchCardSettings.minimum_order_amount.formatted } minimum purchase to earn a point</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AdminCard>
              <PunchCardsEdit
                punchCardSettings={punchCardSettings}
                open={ this.state.open }
                editType={ this.state.editType }
                onClose={this.handleDialogClose}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(PunchCards);
