import React, { Component } from "react";
import Drawer from '@material-ui/core/Drawer';
import PushNotificationEdit from './pushNotificationEdit';
import PushNotificationPreview from './pushNotificationPreview';
import PushNotificationsTest from './pushNotificationTest';

const userTypes = [
    {
        label: "All Customers of a location",
        option: "all",
    },
    {
        label:
            "Recent Customers of a Location (made a purchase in the last 90 days)",
        option: "user",
    },
    {
        label: "All Admin",
        option: "admin",
    },
    {
        label: "All Staff",
        option: "staff",
    },
];


class PushNotificationDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerType: this.props.drawerType,
            notification: {}
        };
    };

    componentWillReceiveProps = nextProps => {
        if (nextProps.notification.id !== this.props.notification.id) {
            this.setState({ notification: nextProps.notification });
        }
        if (nextProps.drawerType !== this.props.drawerType) {
            this.setState({ drawerType: nextProps.drawerType });
        }
    };

    updateParentState = data => {
        this.setState(data);
    };

    handleClose = () => {
        this.setState({ notification: {} });
        this.props.onClose();
    };

    render() {
        const { drawerType, notification } = this.state;
        const { open } = this.props;

        return (
            <>
                { open ?
                    <Drawer
                        anchor="right"
                        open={ open }
                        onClose={ this.handleClose }
                        classes={ { paper: "app-drawer", paperAnchorRight: "app-drawer" } }
                    >
                        { drawerType === 'preview' ?
                            <PushNotificationPreview
                                notification={ notification }
                                onClose={ this.handleClose }
                                userTypes={ userTypes }
                                editNotification={ () => this.setState({ drawerType: 'edit' }) }
                                sendTest={ () => this.setState({ drawerType: 'test' }) }
                            />
                            : drawerType === 'edit' ?
                            <PushNotificationEdit
                                notification={ notification }
                                onClose={ this.handleClose }
                                userTypes={ userTypes }
                                openPreview={ () => this.setState({ drawerType: 'preview' }) }
                                updateParentState={this.updateParentState}
                                />
                                : drawerType === 'test' ?
                                    <PushNotificationsTest
                                        notification={ notification }
                                        onClose={ this.handleClose }
                                        userTypes={ userTypes }
                                        editNotification={ () => this.setState({ drawerType: 'edit' }) }
                                    />
                                    : null}
                    </Drawer> :
                    null
                }
            </>
        );
    }
}

export default (PushNotificationDrawer);