import React, { Component } from "react";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AdminCard from "../components/elements/adminCard";
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminToggle from '../components/elements/adminToggle';
import { Text } from "../components/elements/text";
import store from "../store/store";
import tenantAPI from '../services/api/tenantAPI';

class SquareLoyalty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_enabled: store.squareLoyalty ? store.squareLoyalty.is_enabled : false,
            pointsName: store.squareLoyalty ? store.squareLoyalty.points_name : 'Points',
        };
    }

    componentDidMount() {
        tenantAPI.getSquareLoyalty()
        .then(() => {
            this.setState({ is_enabled: store.squareLoyalty ? store.squareLoyalty.is_enabled : false });
        });
    }

    handleUpdate = data => {
        tenantAPI.updateSquareLoyalty(JSON.stringify(data), store.squareLoyalty.id);
    }

    handleToggle = () => {
        let enabled = this.state.is_enabled;
        this.setState({ is_enabled: !enabled });
        this.handleUpdate({ is_enabled: !enabled });
    };

    getPluralForm = (pointsName, pointQty) => {
        if (pointQty === 1) return pointsName;
        let lastTwoEnding = pointsName.slice(-2);
        let ending = pointsName.slice(-1);
        if (['ss', 'ch', 'sh'].includes(lastTwoEnding) || ['s', 'z', 'x'].includes(ending)) {
            return pointsName + 'es';
        } else {
            return pointsName + 's';
        }
    }

    rewardAccrual = (rule) => {
        let dollar_amount = rule.dollar_amount_for_points.formatted;
        let points = this.getPluralForm(store.squareLoyalty.points_name, rule.points);
        return `Earn ${rule.points} ${points} for every ${dollar_amount} spent.`;
    }

    render() {

        if (store.loading) return <div />;
        return (
            <div className="admin-page-wrapper">
                <AdminPageHeader
                    mainNav="Marketing"
                    title="Square Loyalty"
                    subNav="Rewards"
                    description={
                    <Text>
                        <div>Activate Square Loyalty for your locations. To get started, visit <a href="https://squareup.com/us/en/software/loyalty" target="_blank" rel="noopener noreferrer" style={{ color: "#D42019" }}>https://squareup.com/us/en/software/loyalty</a></div>
                        <div> to sign up your Square POS. Once activated, come back to this page and sync.</div>
                    </Text>}
                />
                {store.squareLoyalty && store.squareLoyalty.id ?
                <div>
                    <AdminCard
                        largeTitle
                        title="Enable/Disable Square Loyalty for Your App"
                        description="Enable or disable the ability to earn rewards with Square Loyalty through your mobile app. This does not apply to in-store purchases."
                    >
                        <div className="app-card-section-title bold pb-2">
                            Square Loyalty In-App Purchases
                        </div>
                        <AdminToggle
                            className='app-toggle'
                            checked={ Boolean(this.state.is_enabled) }
                            onChange={ this.handleToggle }
                        />
                    </AdminCard>
                    <AdminCard
                        largeTitle
                        title="Terminology"
                        description="The terminology of your loyalty program to fit your brand (Examples: Star/Stars, Point/Points, Punch/Punches)."
                    >
                        <Table className="app-table-wrapper">
                            <TableHead className="app-table-header">
                                <TableRow className="app-table-row">
                                    <TableCell className="app-table-cell pl-0" width="30%">Singular</TableCell>
                                    <TableCell className="app-table-cell pl-0">Plural</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="app-table-body">
                                <TableRow className="app-table-row">
                                    <TableCell className="app-table-cell pl-0">{store.squareLoyalty.points_name}</TableCell>
                                    <TableCell className="app-table-cell pl-0">{this.getPluralForm(store.squareLoyalty.points_name)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AdminCard>
                    <AdminCard
                        largeTitle
                        title="Earning Points"
                        description="Select the way your customers earn rewards based on your program structure."
                    >
                        <Table className="app-table-wrapper">
                            <TableHead className="app-table-header">
                                <TableRow className="app-table-row">
                                    <TableCell width="30%" className="app-table-cell pl-0">Rule Value</TableCell>
                                    <TableCell className="app-table-cell pl-0">Rule Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="app-table-body">
                                {store.squareLoyalty && store.squareLoyalty.reward_accrual && store.squareLoyalty.reward_accrual.map(rule => (
                                    <TableRow key={rule.id} className="app-table-row">
                                        <TableCell className="app-table-cell pl-0">{rule.points} {this.getPluralForm(store.squareLoyalty.points_name, rule.points)}</TableCell>
                                        <TableCell className="app-table-cell pl-0">{this.rewardAccrual(rule)}</TableCell>
                                    </TableRow>
                                ))
                                }
                                {!store.squareLoyalty &&
                                    <TableRow className="app-table-row">
                                        <TableCell className="app-table-cell pl-0">N/A</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                    <AdminCard
                        largeTitle
                        title="Redeeming Rewards"
                        description="Pick types of rewards that will keep your customers engaged and coming back, up to 100 rewards."
                    >
                        <Table className="app-table-wrapper">
                            <TableHead className="app-table-header">
                                <TableRow className="app-table-row">
                                    <TableCell width="30%" className="app-table-cell pl-0">Reward Value</TableCell>
                                    <TableCell className="app-table-cell pl-0">Reward Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="app-table-body">
                                {store.squareLoyalty && store.squareLoyalty.rewards && store.squareLoyalty.rewards.map(reward => (
                                    <TableRow key={reward.id} className="app-table-row">
                                        <TableCell className="app-table-cell pl-0">{reward.points_required} {this.getPluralForm(store.squareLoyalty.points_name)}</TableCell>
                                        <TableCell className="app-table-cell pl-0">{reward.name}</TableCell>
                                    </TableRow>
                                ))
                                }
                                {!store.squareLoyalty &&
                                    <TableRow className="app-table-row">
                                        <TableCell className="app-table-cell pl-0">N/A</TableCell>
                                        <TableCell className="app-table-cell pl-0">N/A</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                    <AdminCard
                        largeTitle
                        title="Selected Locations"
                        description="Your selected locations that are enrolled with Square Loyalty."
                    >
                        <Table className="app-table-wrapper">
                            <TableHead className="app-table-header">
                                <TableRow className="app-table-row">
                                    <TableCell width="30%" className="app-table-cell pl-0">Location</TableCell>
                                    <TableCell className="app-table-cell pl-0">Enabled</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="app-table-body">
                                {store.squareLoyalty && store.squareLoyalty.locations !== undefined && store.locations && store.locations.length > 0 && store.locations.map(location => (
                                    <TableRow key={location.id} className="app-table-row">
                                        <TableCell className="app-table-cell pl-0">
                                            {location.name}
                                        </TableCell>
                                        <TableCell className="app-table-cell pl-0">
                                            {store.squareLoyalty.locations.includes(location.id) ? <span className="brightGreen semi-bold">Enabled</span> : <span className="ordrslipRed semi-bold">Disabled</span>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {!store.squareLoyalty &&
                                <TableRow className="app-table-row">
                                    <TableCell className="app-table-cell pl-0">N/A</TableCell>
                                    <TableCell className="app-table-cell pl-0">Disabled</TableCell>
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                </div> : null}
            </div>
        );
    }
}

export default observer(SquareLoyalty);
