import React, { Component } from "react";
import { observer } from "mobx-react";
import HoursEdit from "./hoursEdit";
import { formatTimeForView, getAvailabilityOptions } from "../services/utils";
import AdminCard from './elements/adminCard';
import _ from 'lodash';
import AdminTable from './elements/adminTable';

const menuHoursDescription = "This menu will only be active during the times you set here.",
  storeHoursDescription = "The hours your physical location is open. Does not affect customer's ability to order.",
      pickupHoursDescription = "Customers will only be able to place orders for immediate pickup when their order would be ready within these set hours.",
      deliveryHoursDescription = "Customers will only be able to request that their order be delivered within these set hours.";

const tableColumns = [
  { field: "weekday", headerName: "Day", width: 200 },
  { field: "start_time", headerName: "Open", width: 120 },
  { field: "end_time", headerName: "Close", width: 120 }
];

class HoursCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hours: this.props.hours
    };
  }

  handleDialogOpen = () => {
    this.setState({ open: true });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  prepareTableData = hours => {
    let tableData = _.cloneDeep(hours);
    _.map(tableData, (availability, index) => {
      availability.id = index;
      availability.start_time = formatTimeForView(availability.start_time);
      availability.end_time = formatTimeForView(availability.end_time);
    });
    return tableData;
  }

  render() {
    const { hours, hoursType, hoursPropName, locationId } = this.props;
    const availabilityOptions = getAvailabilityOptions(hours);
    const tableData = this.prepareTableData(hours);
    return (
      <div>
        <AdminCard
          largeTitle
          title={`${hoursType} Hours`}
          description={
            hoursType.toLowerCase() === "menu" ? menuHoursDescription :
            hoursType.toLowerCase() === "store" ? storeHoursDescription :
            hoursType.toLowerCase() === "pickup" ? pickupHoursDescription :
            hoursType.toLowerCase() === "delivery" ? deliveryHoursDescription :
            ""
          }
          onHeaderActionClick={this.handleDialogOpen}
        >
          {hours &&
            hours.length > 0 && (
            <AdminTable
              columnData={tableColumns}
              rowData={tableData}
              noCardBorder
            />
          )}
          {!(hours && hours.length) && (
              <div className="app-card-content-wrapper">
                <div className="no-data-for-view in-card">
                  {hoursType} hours haven't been set yet.
                </div>
              </div>
          )}
        </AdminCard>
        <HoursEdit
          open={this.state.open}
          onClose={this.handleDialogClose}
          onUpdate={this.props.handleUpdate}
          hours={hours}
          hoursType={hoursType}
          hoursPropName={hoursPropName}
          availabilityOptions={availabilityOptions}
          locationId={locationId}
        />
      </div>
    );
  }
}

export default observer(HoursCard);
