import React, { Component } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RadioGroup from '@material-ui/core/RadioGroup';

import integrationsAPI from '../../services/api/integrationsAPI';
import store from '../../store/store';
import { getLocationNameById, EXTERNAL_FULFILLMENT_NAMES } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminDropdownField from "../elements/adminDropdownField";
import AdminToggle from '../elements/adminToggle';
import EditDrawer from '../elements/editDrawer';
import RadioLabel from '../elements/radioLabel';
import { Text } from '../elements/text';


const editOptions = [
    {
        value: 'new',
        label: 'Edit current location',
    },
    {
        value: 'current',
        label: 'Edit settings for connected locations',
    },
];


class LocationFulfillmentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_type: 'current',
            error: '',
            is_enabled: this.props.fulfillment ? this.props.fulfillment.is_enabled: true,
            minimum_subtotal: this.props.fulfillment ? this.props.fulfillment.minimum_subtotal.amount : 0,
            delivery_area_method: this.props.fulfillment ? this.props.fulfillment.delivery_area_method : 'radius',
            delivery_postal_codes: this.props.fulfillment ? this.props.fulfillment.delivery_postal_codes : [],
            delivery_radius: this.props.fulfillment ? this.props.fulfillment.delivery_radius : '',
            delivery_fee: this.props.fulfillment && this.props.fulfillment.delivery_fee ? this.props.fulfillment.delivery_fee.amount : 0,
            selectedFulfillment: this.props.fulfillment,
            apiErrors: {},
        };
    }

    getEditOptions = () => {
        const noFulfillment = _.isEmpty(this.props.fulfillment);
        let options = noFulfillment ? [] : _.clone(editOptions);
        _.forEach(toJS(store.fulfillments), f => {
            if (f.integration === this.props.fulfillmentType &! f.locations.includes(this.props.location.id)) {
                options.push({
                    value: f.id,
                    label: `${noFulfillment ? '' : 'Use settings from'} ${ f.locations[0] ? getLocationNameById(f.locations[0]) : 'no connected locations' }`,
                    min_subtotal: f.minimum_subtotal.formatted,
                    plus_one: f.locations.length > 1,
                    delivery_area_method: f.delivery_area_method,
                    delivery_postal_codes: f.delivery_postal_codes,
                    delivery_radius: f.delivery_radius,
                    delivery_fee: f.delivery_fee ? f.delivery_fee.formatted : null
                });
            }
        });
        if (noFulfillment) {
            options.push({
                value: 'new',
                label: 'No, use my own settings'
            })
        }
        return options;
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.fulfillment !== this.props.fulfillment) {
            this.setState({
                selectedFulfillment: nextProps.fulfillment,
                is_enabled: nextProps.fulfillment ? nextProps.fulfillment.is_enabled : true,
                minimum_subtotal: nextProps.fulfillment ? nextProps.fulfillment.minimum_subtotal.amount : 0,
                delivery_fee: nextProps.fulfillment && nextProps.fulfillment.delivery_fee ? nextProps.fulfillment.delivery_fee.amount : 0,
                delivery_area_method: nextProps.fulfillment && nextProps.fulfillment.delivery_area_method,
                delivery_radius: nextProps.fulfillment && nextProps.fulfillment.delivery_radius,
                delivery_postal_codes: nextProps.fulfillment && nextProps.fulfillment.delivery_postal_codes,
            });
        }
    }

    toggleEnabled = () => {
        this.setState({ is_enabled: !this.state.is_enabled });
    }

    handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        let selectedFulfillment,
            minimum_subtotal,
            delivery_fee,
            delivery_area_method,
            delivery_radius,
            delivery_postal_codes,
            is_enabled;

        if (name === 'edit_type') {
            if (value === 'new') {
                selectedFulfillment = {};
                is_enabled = true;
                minimum_subtotal = 0;
                if (this.props.fulfillmentType === 'basic_delivery') {
                    delivery_fee = 0;
                    delivery_area_method = 'radius';
                    delivery_radius = 0;
                    delivery_postal_codes = [];
                }
            }
            else {
                if (value === 'current') {
                    selectedFulfillment = this.props.fulfillment;
                }
                else {
                    selectedFulfillment = _.find(toJS(store.fulfillments), f => {
                    return f.id === value;
                    });
                }
                is_enabled = selectedFulfillment.is_enabled;
                minimum_subtotal = selectedFulfillment.minimum_subtotal.amount;
                if (this.props.fulfillmentType === 'basic_delivery') {
                    delivery_fee = selectedFulfillment.delivery_fee.amount;
                    delivery_area_method = selectedFulfillment.delivery_area_method;
                    delivery_radius = selectedFulfillment.delivery_radius;
                    delivery_postal_codes = selectedFulfillment.delivery_postal_codes;
                }
            }
            this.setState({
                selectedFulfillment,
                minimum_subtotal,
                delivery_fee,
                delivery_area_method,
                delivery_radius,
                delivery_postal_codes,
                is_enabled
            });
        }
        if (name === 'delivery_postal_codes') {
            value = value.replace(' ', '').split(',');
        }
        this.setState({ [name]: value });
    };

    updateFulfillment = payload => {
        integrationsAPI.updateFulfillment(this.state.selectedFulfillment.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    this.props.onUpdate();
                    this.handleClose();
                }
            });
    }

    createFulfillment = payload => {
        integrationsAPI.createFulfillment(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    this.props.onUpdate();
                    this.handleClose();
                }
            });
    }

    removeLocation = payload => {
        let locations = _.filter(this.props.fulfillment.locations, loc => { return loc !== this.props.location.id; });
        if (!locations.length) {
            // Delete integration if no locations are connected
            integrationsAPI.deleteFulfillment(this.props.fulfillment.id)
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        if (this.state.selectedFulfillment.id) {
                            // Update existing fulfillment
                            this.updateFulfillment(payload);
                        } else {
                            // Create new fulfillment
                            this.createFulfillment(payload);
                        }
                    }
                });
        } else {
            // Edit integration to remove location
            let data = {
                locations: locations
            };
            integrationsAPI.updateFulfillment(this.props.fulfillment.id, JSON.stringify(data))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        if (this.state.selectedFulfillment.id) {
                            // Update existing fulfillment
                            this.updateFulfillment(payload);
                        } else {
                            // Create new fulfillment
                            this.createFulfillment(payload);
                        }
                    }
                });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let payload = {
            is_enabled: this.state.is_enabled,
            minimum_subtotal: {
                amount: this.state.minimum_subtotal.toString(),
                currency: this.props.location.currency
            }
        };
        if (this.props.fulfillmentType === 'basic_delivery') {
            payload.delivery_fee = {
                amount: this.state.delivery_fee.toString(),
                currency: this.props.location.currency
            };
            payload.delivery_area_method = this.state.delivery_area_method;
            payload.delivery_radius = this.state.delivery_radius;
            payload.delivery_postal_codes = this.state.delivery_postal_codes;
        }
        if (this.state.edit_type !== 'current') {
            if (this.state.edit_type === 'new') {
                payload.locations = [this.props.location.id];
                payload.integration = this.props.fulfillmentType;
                if (_.isEmpty(this.props.fulfillment)) {
                    this.createFulfillment(payload);
                } else {
                    this.removeLocation(payload);
                }
            } else {
                this.state.selectedFulfillment.locations.push(this.props.location.id);
                payload.locations = this.state.selectedFulfillment.locations;
                if (_.isEmpty(this.props.fulfillment)) {
                    this.updateFulfillment(payload)
                } else {
                    this.removeLocation(payload);
                }
            }
        } else {
            // Update current fulfillment
            this.updateFulfillment(payload);
        }
    };

    handleClose = () => {
        this.setState({
            apiErrors: {},
            is_enabled: false,
            minimum_subtotal: 0,
            edit_type: 'current'
        });
        this.props.onClose();
    };

    getLocations() {
        const fulfillment = this.state.selectedFulfillment;

        if (fulfillment && fulfillment.locations) {
            if (fulfillment.locations.length) {
                return (
                    <div className='columns is-mobile is-vcentered is-multiline pl-0'>
                        { fulfillment.locations.map((location, index) => (
                            <div
                                key={ index }
                                style={ { marginLeft: 0 } }
                                className="small-text column is-half pb-0 pt-2"
                            >
                                <div className="columns is-mobile is-vcentered">
                                    <div style={ { paddingRight: 10 } } className="column is-one-fifth">
                                        <FontAwesomeIcon className="brightGreen" size="1x" icon={ ['fal', 'check'] } />
                                    </div>
                                    <div
                                        className="column is-three-fifths"
                                        style={ { paddingLeft: 0, paddingRight: 10 } }>
                                        { getLocationNameById(location, store.locations) }
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                );
            }
        }
        return (
            <div className='columns is-mobile is-vcentered is-multiline pl-0'>
                <div style={ { marginLeft: 0 } } className="small-text column is-half pb-0 pt-2">
                    <div className="columns is-mobile is-vcentered">
                        <div style={ { paddingRight: 10 } } className="column is-one-fifth">
                            <FontAwesomeIcon className="brightGreen" size="1x" icon={ ['fal', 'check'] } />
                        </div>
                        <div
                            className="column is-three-fifths"
                            style={ { paddingLeft: 0, paddingRight: 10 } }>
                            { this.props.location.name }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getConfigMsg = () => {
        if (_.isEmpty(this.props.fulfillment)) {
            return 'Looks like some locations have already been set up. Do you want to use the same settings as one of these locations?'
        } else {
            return 'You will be editing the settings for the following connected locations. Do you want to edit the current location, update all connected locations, or use the settings from a different location?'
        }
    }

    render() {
        const { fulfillmentType } = this.props;
        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ this.handleClose }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={ `Edit ${ EXTERNAL_FULFILLMENT_NAMES[fulfillmentType]} Settings`}
                    onSubmit={ this.handleSubmit }
                    onClose={ this.handleClose }
                >
                    <AdminCard
                        title={ `${ EXTERNAL_FULFILLMENT_NAMES[fulfillmentType]} Options`}
                        style={ { marginTop: '15px' } }
                        description={ `Customize ${ EXTERNAL_FULFILLMENT_NAMES[fulfillmentType].toLowerCase() } settings for your location(s) below.`}
                    >
                        <Text>
                            <span className='bold'>Note: </span>
                            Disabling this fulfillment will disable pickup for all connected locations.
                        </Text>
                        <AdminToggle
                            className='app-toggle'
                            name='is_enabled'
                            checked={ this.state.is_enabled }
                            onChange={ this.toggleEnabled }
                        />
                        <div className='pt-3'>
                            <Text weight='bold'>Configuration</Text>
                            <Text>{ this.getConfigMsg() }</Text>
                            <RadioGroup
                                name='edit_type'
                                value={ this.state.edit_type }
                                onChange={e => this.handleChange(e)}
                                className='radio-group'
                            >
                                { this.getEditOptions().map(option => (
                                    <React.Fragment key={ option.value }>
                                        <RadioLabel
                                            className='bold'
                                            value={ option.value }
                                            label={
                                                <div>
                                                    <Text weight='bold'>{ option.label }{ option.plus_one ? <span className='pl-2 bold ordrslipRed'>+1</span>: null}</Text>
                                                </div>
                                            }
                                        />
                                        { option.delivery_area_method ?
                                            <Text small style={ { paddingLeft: 30, paddingBottom: 10, marginTop: -8} }>
                                                Delivery Area: { option.delivery_area_method === 'radius' ? `${option.delivery_radius} miles` : String(option.delivery_postal_codes) }
                                            </Text>
                                            : null
                                        }
                                        { option.delivery_fee ?
                                            <Text small style={ { paddingLeft: 30, paddingBottom: 10, marginTop: -8} }>
                                                Delivery Fee: { option.delivery_fee.formatted }
                                            </Text>
                                            : null
                                        }
                                        { option.min_subtotal ?
                                            <Text small style={ { paddingLeft: 30, marginTop: -8 } }>
                                                Min. Subtotal: { option.min_subtotal }
                                            </Text>
                                            : null
                                        }
                                        { option.value === 'current' ? 
                                            <List style={ { marginTop: -8, paddingLeft: 15 }}>
                                                { this.props.fulfillment && this.props.fulfillment.locations.map(loc => (
                                                    <ListItem key={loc}>
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='fa-solid fa-circle' size='2xs' className='tertiaryOrange' />
                                                        </ListItemIcon>
                                                        <ListItemText primary={getLocationNameById(loc)} style={{fontSize: 12}} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                            : option.value === 'new' ?
                                                <List style={ { marginTop: -8, paddingLeft: 15 } }>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='fa-solid fa-circle' size='2xs' className='tertiaryOrange' />
                                                        </ListItemIcon>
                                                        <ListItemText primary={ this.props.location.name } style={ { fontSize: 12 } } />
                                                    </ListItem>
                                                </List>
                                        : null }
                                    </React.Fragment>
                                )) }
                            </RadioGroup>
                        </div>
                        { this.props.fulfillmentType === 'basic_delivery' ?
                            <div>
                                <div className='app-field-wrapper pt-3'>
                                    <div className='app-field-label'>Delivery Area</div>
                                    <div className='columns is-mobile'>
                                        <div className="column is-8">
                                            <input
                                                className='app-input-field'
                                                name={this.state.delivery_area_method === 'radius' ? 'delivery_radius' : 'delivery_postal_codes'}
                                                type={ this.state.delivery_area_method === 'radius' ? 'number' : 'text' }
                                                placeholder={ this.state.delivery_area_method === 'radius' ? '0' : 'Zip Codes' }
                                                value={ this.state.delivery_area_method === 'radius' ? this.state.delivery_radius : String(this.state.delivery_postal_codes) }
                                                onChange={ (event) => this.handleChange(event) }
                                            />
                                        </div>
                                        <div className="column is-4">
                                            <AdminDropdownField
                                                name="delivery_area_method"
                                                onChange={ (event) => this.handleChange(event) }
                                                value={ this.state.delivery_area_method }
                                            >
                                                <option value='radius'>Radius (mi)</option>
                                                <option value='postal_codes'>Zip Codes</option>
                                            </AdminDropdownField>
                                        </div>
                                    </div>
                                </div>
                                <div className='app-field-wrapper pt-3'>
                                    <div className='app-field-label'>Delivery Fee</div>
                                    <input
                                        className='app-input-field'
                                        name='delivery_fee'
                                        type='number'
                                        step='.01'
                                        placeholder='0'
                                        value={ this.state.delivery_fee.amount }
                                        onChange={ (event) => this.handleChange(event) }
                                    />
                                </div>
                            </div>
                            : null }
                        <div className='app-field-wrapper pt-3'>
                            <div className='app-field-label'>Minimum Subtotal</div>
                            <input
                                className='app-input-field'
                                name='minimum_subtotal'
                                type='number'
                                step='.01'
                                placeholder='0'
                                value={ this.state.minimum_subtotal }
                                onChange={ (event) => this.handleChange(event) }
                            />
                        </div>
                        <div className='app-field-wrapper'>
                            <div className='app-field-label mb-0'>Locations</div>
                            <Text>These settings will be applied to the location(s) below.</Text>
                            <div className='pt-3 pb-3'>
                                { this.getLocations() }
                            </div>
                        </div>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default LocationFulfillmentEdit;
