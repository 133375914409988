import React, { Component } from "react";
import withNav from "../components/withNav";
import { Route } from "react-router-dom";
import AppAppearance from "./appAppearance";
import AppTaxes from "./appTaxes";
import AppFees from "./appFees";
import { SideNavAndContentContainer } from '../components/elements/navs';
import Themes from './themes';
import AppSettings from './appSettings';

class AppCustomization extends Component {
  render() {
    return (
      <div>
        <SideNavAndContentContainer>
          <Route path="/app-settings/appearance" component={AppAppearance} />
          <Route path="/app-settings/themes" component={Themes} />
          <Route path="/app-settings/taxes" component={AppTaxes} />
          <Route path="/app-settings/fees" component={AppFees} />
          <Route path="/app-settings/settings" component={AppSettings} />
        </SideNavAndContentContainer>
      </div>
    );
  }
}

export default withNav(AppCustomization);
