import React, { Component } from "react";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Currency from "react-currency-formatter";
import moment from "moment";
import _ from "lodash";
import { getStartOfDayByDate, getEndOfDayByDate, getSortIcon } from "../../services/utils";
import AdminCard from "../../components/elements/adminCard";
import {
  DarkSubHeading,
  HeaderText,
  Text,
} from "../../components/elements/text";
import store from "../../store/store";
import ReportsCustomHeader from "./reportsCustomHeader";
import tenantAPI from '../../services/api/tenantAPI';
import reportsAPI from '../../services/api/reportsAPI';

class ReportsAnalytics extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      discounts: [],
      selectedLocation: "All",
      anchorEl: null,
      orderBy: "category_name",
      order: "desc",
      currentPage: 0,
      rowsPerPage: 10,
      options: [
        { value: "none", label: "None" },
        { value: "amount", label: "Total Amount Discounted" },
      ],
      selectedOption: { value: "none", label: "None" },
      anchorEl2: null,
      unsortedDiscounts: [],
    };
  }

  componentDidMount() {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then((locations) => {
        this.setState({ locations });
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format("MM/DD/YYYY");
        this.formatReports(monthAgo, todaysDate);
      });
  }

  formatReports(fromDate, toDate) {
    let location = this.state.selectedLocation === 'All' ? null : this.state.selectedLocation;
      reportsAPI.getDiscountReport(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location)
        .then((data) => {
          _.forEach(data.discounts, (discount, index) => {
            discount.index = index;
          });
          this.setState({
            discounts: data.discounts,
            unsortedDiscounts: data.discounts,
          });
        })
        .catch((err) => console.error(err));
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format("MM/DD/YYYY");
    if (type === "fromDate") {
      this.setState({ fromDate: displayDate }, () => {
        this.formatReports(newDate, this.state.toDate);
      });
    } else if (type === "toDate") {
      this.setState({ toDate: displayDate }, () => {
        this.formatReports(this.state.fromDate, newDate);
      });
    }
  }

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
      this.handleClose('anchorEl');
    });
  }

  handleClick = (type, event) => {
    this.setState({
      [type]: event.currentTarget,
    });
  };

  handleClose = (type) => {
    this.setState({ [type]: null });
  };

  handleSort(value) {
    let discounts = this.state.discounts;
    let orderBy = value;
    let order =
      this.state.order === "asc" && this.state.orderBy === value
        ? "desc"
        : "asc";
    if (value === "amount")
      discounts = _.orderBy(
        discounts,
        [(discount) => discount[value].amount],
        [order]
      );
    else discounts = _.cloneDeep(this.state.unsortedDiscounts);
    this.setState({ discounts, order, orderBy, currentPage: 0 });
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  }

  sortBy(value) {
    this.setState({ selectedOption: value });
    this.handleSort(value.value);
  }

  render() {
    const { anchorEl2 } = this.state;
    const sortByOpen = Boolean(anchorEl2);

    return (
      <AdminCard
        title=""
        customHeader={
        <ReportsCustomHeader
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          selectedLocation={this.state.selectedLocation}
          handleChange={this.handleChange}
          handleSelectLocation={this.handleSelectLocation}
        />
        }
      >
        {store.loading ? (
          <div
            className="admin-page-header-subtext"
            style={{ textAlign: "center", padding: 20 }}
          >
            Loading...
          </div>
        ) : this.state.discounts &&
          !this.state.discounts.length ? (
          <div
            className="admin-page-header-subtext"
            style={{ textAlign: "center", padding: 20 }}
          >
            No discount reports for the selected days or location.
          </div>
        ) : (
          <div>
            <HeaderText medium className="pt-2">
              Discount Activity Breakdown
            </HeaderText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text weight="bold">Sort By: </Text>
              <DarkSubHeading
                className="pl-2 pb-1 pt-1 cursor-pointer"
                onClick={(e) => this.handleClick("anchorEl2", e)}
              >
                {this.state.selectedOption.label}
                {getSortIcon(sortByOpen)}
              </DarkSubHeading>
              <Popover
                id="sort-by"
                open={sortByOpen}
                anchorEl={anchorEl2}
                onClose={() => this.handleClose("anchorEl2")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div>
                  <div>
                    {this.state.options.map((option) => (
                      <Text
                        hover
                        key={option.value}
                        className="p-2 pr-0"
                        onClick={(event) => this.sortBy(option)}
                      >
                        <div className="display-in-line align-items">
                          {option.label}
                          {this.state.selectedOption.value === option.value ? (
                            <FontAwesomeIcon
                              size="1x"
                              className="brightGreen ml-3"
                              icon={["far", "check"]}
                            />
                          ) : null}
                        </div>
                      </Text>
                    ))}
                  </div>
                </div>
              </Popover>
            </div>
            <Table className="app-table-wrapper mt-0">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0 set-thin-width">
                    Discount Name
                  </TableCell>
                  <TableCell className="app-table-cell set-thin-width">
                    Discount Code
                  </TableCell>
                  <TableCell className="app-table-cell set-thin-width">QTY Used</TableCell>
                  <TableCell className="app-table-cell">
                    Total Amount Discounted
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
                {this.state.discounts &&
                  this.state.discounts.length > 0 &&
                  this.state.discounts.slice(0, 5).map((report) => (
                    <TableRow key={report.index} className="app-table-row">
                      <TableCell className="app-table-cell pl-0 set-thin-width">
                        {report.coupon
                          ? report.coupon.name
                          : "Coupon Not Available"}
                      </TableCell>
                      <TableCell className="app-table-cell set-thin-width">
                        {report.coupon ? report.coupon.code : "N/A"}
                      </TableCell>
                      <TableCell className="app-table-cell set-thin-width">
                        {report.quantity}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        <Currency
                          quantity={report.amount.amount}
                          currency={report.amount.currency}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        )}
      </AdminCard>
    );
  }
}

export default observer(ReportsAnalytics);
