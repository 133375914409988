import React, { Component } from 'react';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import IntegrationsEdit from './integrationsEdit';
import UberTOU from './uberTOU';

class IntegrationsDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
        };
    }

    handleClose = updated => {
        this.setState({ step: 1 });
        this.props.onClose(updated);
    };

    acceptUberTOU = () => {
        this.setState({ step: 2 });
    };

    render() {
        const { step } = this.state;
        const { fulfillment, open, deleteIntegration, integration } = this.props;

        return (
            <Drawer
                anchor='right'
                open={ open }
                onClose={ this.handleClose }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                { integration !== 'uber' ?
                    <IntegrationsEdit
                        fulfillment={ fulfillment }
                        integration={ integration }
                        open={ open }
                        onClose={ this.handleClose }
                        deleteIntegration={ deleteIntegration }
                    /> :
                    integration === 'uber' && step === 1 ?
                        <UberTOU
                            open={ open }
                            onClose={ this.handleClose }
                            acceptUberTOU={ this.acceptUberTOU }
                        /> :
                        <IntegrationsEdit
                            fulfillment={ fulfillment }
                            integration={ integration }
                            open={ open }
                            onClose={ this.handleClose }
                        />
                }
            </Drawer>
        );
    }
}

export default IntegrationsDrawer;