import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import _ from 'lodash';

import store from "../store/store";
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from "../components/elements/adminPageHeader";
import { Button } from '../components/elements/buttons';
import SalesDrawer from "../components/sales/salesDrawer";
import { showLocations, showDateRange } from "../services/utils";
import tenantAPI from '../services/api/tenantAPI';
import promotionsAPI from '../services/api/promotionsAPI';


class Sales extends Component {
  state = {
    sales: {
      current: store.currentSales,
      expired: store.expiredSales,
    },
    open: false,
    inFocusSale: {},
    drawerType: '',
    anchorEl: null,
    orderBy: {current: 'name', expired: 'name'},
    order: {current: 'desc', expired: 'desc'},
    currentPage: {current: 0, expired: 0},
    rowsPerPage: {current: 10, expired: 10}
  };

  componentWillMount = () => {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(() => {
        this.getSales();
      });
  };

  toggleDrawer(drawerType) {
    this.setState({ drawerType, open: !this.state.open });
  }

  getSales = () => {
    promotionsAPI.getRecursiveSales({ page: 1 })
    .then(() => {
      let sales = {
        current: store.currentSales,
        expired: store.expiredSales
      }
      this.setState({ sales });
    });
  }

  handleDialogClose = (updated) => {
    this.toggleDrawer('');
    if (updated) {
      this.getSales();
    }
  }

  getDiscountAmount(sale) {
    let discount = sale.percent_off && sale.percent_off > 0 ? `${sale.percent_off}%` : sale.amount_off.formatted;
    return `${discount} Off`;
  }

  showStatus = (sale) => {
    let { start_time, end_time } = sale;
    let status = 'Active';
    start_time = moment(start_time).format('YYYY-MM-DD');
    if (end_time) end_time = moment(end_time).format('YYYY-MM-DD');
    if (end_time && moment().isAfter(end_time)) {
      status = 'Expired';
    } else if (moment().isBefore(start_time)) {
      status = 'Scheduled';
    }
    return status;
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleSort = (value, table) => {
    let { sales, order, orderBy, currentPage } = this.state;
    currentPage[table] = 0;
    orderBy[table] = value;
    order[table] = order[table] === 'asc' && orderBy[table] === value ? 'desc' : 'asc';
    if (value === 'name') sales[table] = _.orderBy(sales[table], [sale => sale[value].toLowerCase()], [order[table]]);
    else sales[table] = _.orderBy(sales[table], [sale => sale[value]], [order[table]]);
    this.setState({ sales, order, orderBy, currentPage });
  };

  getSortIcon(orderBy, table) {
    let sortIcon = this.state.order[table] === 'asc' && this.state.orderBy[table] === orderBy ?
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  updateRows(event, table) {
    let { currentPage, rowsPerPage } = this.state;
    currentPage[table] = 0
    rowsPerPage[table] = event.target.value
    this.setState({ rowsPerPage, currentPage });
  };

  updatePage(event, newPage, table) {
    let { currentPage } = this.state;
    currentPage[table] = newPage
    this.setState({ currentPage });
  };

  render() {
    const { sales, orderBy, order, currentPage, rowsPerPage } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="Sales"
          mainNav="Marketing"
          subNav="Promotions"
          description="All your sales are shown here."
          headerActionText="Add New"
          onHeaderActionClick={() => this.toggleDrawer('create')}
        />
        <AdminCard
          title="Current Sales"
          description="Sales that are currently active in your app and/or online ordering."
        >
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0">
                    <TableSortLabel
                      active={ orderBy.current === 'name' }
                      direction={ orderBy.current === 'name' ? order.current : 'asc' }
                      onClick={ () => this.handleSort('name', 'current') }
                      IconComponent={ () => this.getSortIcon('name', 'current') }
                    >
                      Name
                      { orderBy.current === 'name' ? (
                        <span className='is-hidden'>
                          { order.current === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="app-table-cell">Locations</TableCell>
                  <TableCell className="app-table-cell">Discount Amount</TableCell>
                  <TableCell className="app-table-cell">
                    <TableSortLabel
                      active={ orderBy.current === 'status' }
                      direction={ orderBy.current === 'status' ? order.current : 'asc' }
                      onClick={ () => this.handleSort('status', 'current') }
                      IconComponent={ () => this.getSortIcon('status', 'current') }
                    >
                      Status
                      { orderBy.current === 'status' ? (
                        <span className='is-hidden'>
                          { order.current === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="app-table-cell">Duration</TableCell>
                  <TableCell />
                </TableRow>
            </TableHead>
              <TableBody className="app-table-body">
              { sales.current && sales.current.length ?
                <>
                  { (rowsPerPage.current > 0 ? sales.current.slice(currentPage.current * rowsPerPage.current, currentPage.current * rowsPerPage.current + rowsPerPage.current) :
                  sales.current).map(sale => (
                    <TableRow
                      key={ sale.id }
                      className={ this.state.inFocusSale.id === sale.id ? "highlighted-row" : null }
                      onMouseOver={ () => this.setState({ inFocusSale: sale }) }
                    >
                      <TableCell className="app-table-cell pl-0" style={ { paddingLeft: 0 } }>
                        { sale.name }
                      </TableCell>
                      <TableCell className="app-table-cell">
                        { showLocations(sale) }
                      </TableCell>
                      <TableCell className="app-table-cell">
                        { this.getDiscountAmount(sale) }
                      </TableCell>
                      <TableCell className="app-table-cell">
                        { this.showStatus(sale) }
                      </TableCell>
                      <TableCell className="app-table-cell">
                        { showDateRange(sale) }
                      </TableCell>
                      <TableCell className="app-table-cell set-width">
                        { this.state.inFocusSale.id === sale.id ?
                          <Button
                            edit
                            onClick={ () => this.toggleDrawer('view') }
                          >
                            Edit
                          </Button>
                          : null }
                      </TableCell>
                    </TableRow>
                  )) }
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={ [10, 25, 50] }
                      count={ sales.current && sales.current.length ? sales.current.length : 0 }
                      rowsPerPage={ rowsPerPage.current }
                      onRowsPerPageChange={ (event) => this.updateRows(event, 'current') }
                      page={ currentPage.current }
                      onPageChange={ (event, newPage) => this.updatePage(event, newPage, 'current') }
                      style={ { borderBottom: 'none' } }
                    />
                  </TableRow>
                </>
               :
                <TableRow className='app-table-row'>
                  <TableCell className='app-table-cell'>
                    There aren't any current sales yet.
                  </TableCell>
                </TableRow>
              }
              </TableBody>
            </Table>
        </AdminCard>
        <AdminCard
          title="Expired Sales"
          description="Sales that have expired in your app and/or online ordering. (These are not editable.)"
        >
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell pl-0">
                  <TableSortLabel
                    active={ orderBy.expired === 'name' }
                    direction={ orderBy.expired === 'name' ? order.expired : 'asc' }
                    onClick={ () => this.handleSort('name', 'expired') }
                    IconComponent={ () => this.getSortIcon('name', 'expired') }
                  >
                    Name
                    { orderBy.expired === 'name' ? (
                      <span className='is-hidden'>
                        { order.expired === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                  <TableCell className="app-table-cell">Locations</TableCell>
                  <TableCell className="app-table-cell">Discount Amount</TableCell>
                  <TableCell className="app-table-cell">Status</TableCell>
                  <TableCell className="app-table-cell">Valid through</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
            { sales.expired && sales.expired.length ?
              <>
                { (rowsPerPage.expired > 0 ? sales.expired.slice(currentPage.expired * rowsPerPage.expired, currentPage.expired * rowsPerPage.expired + rowsPerPage.expired) :
                  sales.expired).map(sale => (
                  <TableRow
                    key={sale.id}
                    className={ `app-table-row ${ this.state.inFocusSale.id === sale.id ? "highlighted-row" : "" }` }
                    onMouseOver={() => this.setState({ inFocusSale: sale })}
                  >
                    <TableCell className="app-table-cell pl-0" style={{ paddingLeft: 0 }}>
                      {sale.name}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {showLocations(sale)}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {this.getDiscountAmount(sale)}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {this.showStatus(sale)}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {showDateRange(sale)}
                    </TableCell>
                    <TableCell className="app-table-cell set-width">
                      {this.state.inFocusSale.id === sale.id ?
                        <Button
                          edit
                          onClick={ () => this.toggleDrawer('expired') }
                        >
                          View
                        </Button>
                        : null}
                    </TableCell>
                    </TableRow>
                )) }
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={ [10, 25, 50] }
                          count={ sales.expired && sales.expired.length ? sales.expired.length : 0 }
                          rowsPerPage={ rowsPerPage.expired }
                          onRowsPerPageChange={ (event) => this.updateRows(event, 'expired') }
                          page={ currentPage.expired }
                          onPageChange={ (event, newPage) => this.updatePage(event, newPage, 'expired') }
                          style={ { borderBottom: 'none' } }
                        />
                      </TableRow>
                    </>
               :
                <TableRow className='app-table-row'>
                  <TableCell className='app-table-cell'>
                    There aren't any expired sales yet.
                  </TableCell>
                </TableRow>
              }
              </TableBody>
            </Table>
        </AdminCard>
        <SalesDrawer
          open={this.state.open}
          onClose={this.handleDialogClose}
          promoCode={this.state.inFocusSale}
          drawerType={this.state.drawerType}
        />
      </div>
    );
  }
}

export default withRouter(observer(Sales));