import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../styles/global';
import { getLocationNameById } from '../../services/utils';

const SearchInput = styled.span`
    position: relative;

    & span {
        position: absolute;
        padding: 10px;
        pointer-events: none;
        right: 0px;
    }
    & input {
        padding: 8px 16px;
        width: 100%;
        background-color: ${colors.white};
        border: 1px solid ${props => props.hasError ? colors.errorRed : colors.disabledGrey};
        border-radius: 5px;
        font-size: 14px;
        padding-right: 30px;
    }
`;

class AdminSearchInput extends Component {
    render() {
        const {
            fieldError,
            items,
            onChange,
            removeSelected,
            searchResults,
            selected,
            selectedItems,
            showResults,
            style,
            updateSelected,
            updateState,
            value,
            singleOption,
        } = this.props;

        return (
            <div style={style}>
                <SearchInput hasError={fieldError && fieldError !== null}>
                    <span className='small-text'>
                        <FontAwesomeIcon color={colors.disabledGrey} icon={['fal', 'search']} />
                    </span>
                    <input
                        placeholder="Search..."
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                    />
                    {fieldError}
                    {showResults && searchResults.length >= 1 ?
                    <div>
                        <div className="search-results">
                            {searchResults.map((result, index) => (
                                <div
                                    key={index}
                                    onMouseOver={() => updateState({ selected: result.id }) }
                                    className={selected === result.id ? "highlighted-result" : "result"}
                                    onClick={() => updateSelected(result)}>
                                    {result.name}
                                </div>
                            ))}
                        </div>
                    </div> : null}
                </SearchInput>
                {showResults ?
                <div className='columns is-mobile is-multiline pt-2'>
                    {selectedItems && selectedItems.map((item, index) => (
                        <div
                            key={index}
                            onMouseOver={() => updateState({ selected: item })}
                            style={{ marginLeft: 0 }}
                            className={`small-text column pb-0 ${singleOption ? 'is-half' : 'is-one-third'}`}
                        >
                            <div className="columns is-mobile is-vcentered">
                                <div style={{ paddingRight: 10 }} className="column is-one-fifth">
                                    <FontAwesomeIcon className="brightGreen" size="1x" icon={['fal', 'check']} />
                                </div>
                                <div
                                    className="column is-three-fifths"
                                    style={{ paddingLeft: 0, paddingRight: 10 }}>
                                    {getLocationNameById(item, items )}
                                </div>
                                <div
                                    style={{ paddingLeft: 0 }}
                                    className="column is-one-fifth"
                                    onClick={() => removeSelected(item)}
                                >
                                    {selected === item ?
                                        <FontAwesomeIcon color="#A8A8A8" size="1x" icon={['fal', 'times-circle']} />
                                        : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div> : null}
            </div>
        )
    }
}

AdminSearchInput.propTypes = {
    style: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default AdminSearchInput;