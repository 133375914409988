import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import store from "../store/store";
import { getLocationNameById } from "../services/utils";
import AdminCard from './elements/adminCard';
import { Text } from "./elements/text";
import AdminSearchInput from './elements/adminSearchInput';


class LocationSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {}
        };
    };

    applyToAll = () => {
        let selectedLocations = [];
        this.setState({ applyToAll: !this.state.applyToAll });
        if (!this.state.applyToAll) {
            let newLocs = [];
            _.forEach(store.locations, loc => {
                newLocs.push(loc.id);
            });
            selectedLocations = newLocs;
        } else {
            selectedLocations = [];
        }
        this.props.onUpdateLocations(selectedLocations);
    };

    removeSelectedLocation = (location) => {
        const { selectedLocations } = this.props;
        let index = selectedLocations.indexOf(location);
        if (index > -1) {
            selectedLocations.splice(index, 1);
        }
        this.props.onUpdateLocations(selectedLocations);
    };

    searchLocations = (text) => {
        this.setState({ searchText: text });
        if (text !== '') {
            const { locations } = store;
            const results = locations.filter(obj => {
                return obj.name.toLowerCase().includes(text.toLowerCase());
            });
            this.setState({ searchResults: results });
        } else {
            this.setState({ searchResults: [] });
        }
    };

    integrationName = () => {
        return this.props.itemName === "doordash" ? "DoorDash Integration" : "Uber Integration";
    };

    getDescription = () => {
        switch (this.props.itemName) {
            case 'promo code':
                return 'Choose the location(s) that this promo code will be valid at. This promo code will only apply to orders placed for the locations listed here.';
            case 'sale':
                return 'Choose the location(s) that this sale will be valid at. This sale will only apply to orders placed for the locations listed here.';

            case 'doordash':
            case 'uber':
                return (
                    `Choose the location that this ${ this.integrationName().toLowerCase() } will be enabled at.`
                );
            default:
                return `Choose the location(s) that this ${ this.props.itemName } will be active at.`;
        }
    };

    onSelectSearchResult = selectedLocations => {
        this.setState({ searchText: '', searchResults: [] });
        this.props.onUpdateLocations(selectedLocations);
    };

    render() {
        const { fieldError } = this.props;
        const { applyToAll, searchText, searchResults, selectedLocation } = this.state;

        return (
            <AdminCard title="Location Selection" style={ { marginBottom: 25 } }>
                <Text>{ this.getDescription() }</Text>
                <div className='app-field-wrapper mt-3'>
                    <div className={ `columns is-mobile ${ this.state.searchResults.length === 0 ? "is-vcentered" : "" }` }>
                        <div className="column is-half">
                            <AdminSearchInput
                                value={ searchText }
                                onChange={ this.searchLocations }
                            />
                            { fieldError }
                            { searchResults.length >= 1 ?
                                <div>
                                    <div className="search-results">
                                        { searchResults.map((result, index) => (
                                            <div
                                                key={ index }
                                                onMouseOver={ () => { this.setState({ selectedLocation: result.id }); } }
                                                className={ selectedLocation === result.id ? "highlighted-result" : "result" }
                                                onClick={ () => {
                                                    let selectedLocations = this.props.selectedLocations;
                                                    selectedLocations.push(result.id);
                                                    selectedLocations = _.uniqBy(selectedLocations);
                                                    this.onSelectSearchResult(selectedLocations);
                                                } }
                                            >
                                                { result.name }
                                            </div>
                                        )) }
                                    </div>
                                </div> : null }
                        </div>
                        <div
                            className={ `column medium-text is-half ${ searchResults.length === 0 ? "" : "mt-2" }` }
                            onClick={ () => {
                                this.applyToAll();
                            }
                            }>
                            <div>
                                { applyToAll && this.props.selectedLocations.length === store.locations.length ?
                                    <FontAwesomeIcon className="brightGreen cursor-pointer" size="1x" icon={ ['fas', 'check-square'] } /> :
                                    <FontAwesomeIcon color="#A8A8A8" className="cursor-pointer" size="1x" icon={ ['fal', 'square'] } /> }
                                <span style={ { paddingLeft: 5 } }>
                                    Apply to All Locations
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='columns is-mobile is-vcentered is-multiline'>
                    { this.props.selectedLocations.map((location, index) => (
                        <div
                            key={ index }
                            onMouseOver={ () => this.setState({ locationInFocus: location }) }
                            style={ { marginLeft: 0 } }
                            className="small-text column is-half"
                        >
                            <div className="columns is-mobile is-vcentered">
                                <div style={ { paddingRight: 10 } } className="column is-one-fifth">
                                    <FontAwesomeIcon className="brightGreen" size="1x" icon={ ['fal', 'check'] } />
                                </div>
                                <div
                                    className="column is-three-fifths"
                                    style={ { paddingLeft: 0, paddingRight: 10 } }>
                                    { getLocationNameById(location) }
                                </div>
                                <div
                                    style={ { paddingLeft: 0 } }
                                    className="column is-one-fifth"
                                    onClick={ () => {
                                        this.removeSelectedLocation(location);
                                    } }
                                >
                                    { this.state.locationInFocus === location ?
                                        <FontAwesomeIcon className="cursor-pointer" color="#A8A8A8" size="1x" icon={ ['fal', 'times-circle'] } />
                                        : null }
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
                { !this.props.selectedLocations.length ? <div className="red-error-text">
                    You must choose at least one location.
                </div> : null }
            </AdminCard>
        );
    }
}

export default (LocationSelection);