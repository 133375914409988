import React, { Component } from "react";
import withNav from "../components/withNav";
import { Route } from "react-router-dom";
import PunchCards from "./punchCards";
import SquareLoyalty from './squareLoyalty';
import { SideNavAndContentContainer } from '../components/elements/navs';

class Rewards extends Component {
    render() {
        return (
            <div>
                <SideNavAndContentContainer>
                    <Route path="/rewards/punch-cards" component={PunchCards} />
                    <Route path="/rewards/square-loyalty" component={SquareLoyalty} />
                </SideNavAndContentContainer>
            </div>
        );
    }
}

export default withNav(Rewards);
