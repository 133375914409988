import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';

import store from '../store/store';
import { errorMessage } from '../services/utils';
import AdminCard from './elements/adminCard';
import EditDrawer from './elements/editDrawer';
import tenantAPI from '../services/api/tenantAPI';


class AppAppearanceEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            loading: true,
            apiErrors: {}
        };
    }


    handleChange(event) {
        let { content } = this.state;
        const value = event.target.value;
        const name = event.target.name;

        let customText = (_.find(store.customTextOptions, { property: name }));
        content.content[name] = _.clone(customText);
        delete content.content[name].isDescription;
        content.content[name].value = value;
        this.setState({ content });
    };

    onSubmit = () => {
        store.updateContent(this.state.content);
        let payload = {
            'content': store.content.content
        };
        tenantAPI.postCustomTextFieldData(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    tenantAPI.getCustomTextFieldData();
                    this.props.onClose(true);
                }
            });
    };

    handleReset = () => {
        let payload = {
            'content': { mobile_initial_modal_text: store.content.content.mobile_initial_modal_text, online_initial_modal_text: store.content.content.online_initial_modal_text }
        };

        tenantAPI.postCustomTextFieldData(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    tenantAPI.getCustomTextFieldData();
                    this.props.onClose(true);
                }
            });
    };

    render() {
        const content = this.state.content.content;
        const defaults = store.content.defaults;

        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ () => this.props.onClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={ 'Customize In-App Text' }
                    onClose={ () => this.props.onClose(false) }
                    onSubmit={ this.onSubmit }
                    topButton
                    buttonAction={ this.handleReset }
                    buttonText='Reset to Defaults'
                    errorMessage={ errorMessage(this.state.apiErrors) }
                >
                    <AdminCard
                        title='Pickup Orders'
                        style={ { marginTop: '15px' } }
                        description={ '' }
                    >
                        <div className='app-card-section-wrapper'>
                            <div>
                                <label className='inputLabel is-uppercase'>Pickup Order Instructions (Label)</label>
                                <p className='pt-2'>Displayed above the Order Instructions text box on the cart screen.</p>
                                <input
                                    className='app-input-field'
                                    id='pickup_order_instructions_label'
                                    name='pickup_order_instructions_label'
                                    type='text'
                                    value={ content && content.pickup_order_instructions_label ? content.pickup_order_instructions_label.value : defaults.pickup_order_instructions_label.value }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                            </div>
                        </div>
                        <div className='app-card-section-wrapper pt-4'>
                            <div>
                                <label className='inputLabel is-uppercase'>Order Instructions (Description)</label>
                                <p className='pt-2'>Displayed below the Order Instructions text box on the cart screen.</p>
                                <input
                                    className='app-input-field'
                                    id='order_instructions_description'
                                    name='order_instructions_description'
                                    type='text'
                                    value={ content && content.order_instructions_description ? content.order_instructions_description.value : defaults.order_instructions_description.value }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                            </div>
                        </div>
                    </AdminCard>
                    <AdminCard
                        title='Delivery Orders'
                        description={ '' }
                    >
                        <div className='app-card-section-wrapper'>
                            <div>
                                <label className='inputLabel is-uppercase'>Delivery Order Instructions (Label)</label>
                                <p className='pt-2'>Displayed above the Delivery Instructions text box on the cart screen.</p>
                                <input
                                    className='app-input-field'
                                    id='delivery_order_instructions_label'
                                    name='delivery_order_instructions_label'
                                    type='text'
                                    value={ content && content.delivery_order_instructions_label ? content.delivery_order_instructions_label.value : defaults.delivery_order_instructions_label.value }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                            </div>
                        </div>
                    </AdminCard>
                    <AdminCard
                        title='Item Instructions'
                        description={ '' }
                    >
                        <div className='app-card-section-wrapper'>
                            <div>
                                <label className='inputLabel is-uppercase'>Item Instructions (Label)</label>
                                <p className='pt-2'>Displayed above the Item Instructions text box on the item detail screen.</p>
                                <input
                                    className='app-input-field'
                                    id='item_instructions_label'
                                    name='item_instructions_label'
                                    type='text'
                                    value={ content && content.item_instructions_label ? content.item_instructions_label.value : defaults.item_instructions_label.value }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                            </div>
                        </div>
                        <div className='app-card-section-wrapper pt-3'>
                            <div>
                                <label className='inputLabel is-uppercase'>Item Instructions (Description)</label>
                                <p className='pt-2'>Displayed below the Item Instructions text box on the item detail screen.</p>
                                <input
                                    className='app-input-field'
                                    id='item_instructions_description'
                                    name='item_instructions_description'
                                    type='text'
                                    value={ content && content.item_instructions_description ? content.item_instructions_description.value : defaults.item_instructions_description.value }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                            </div>
                        </div>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(AppAppearanceEdit);
