import { Drawer } from "@material-ui/core";
import React, { Component } from "react";
import BroadcastsEdit from './broadcastsEdit';
import BroadcastsView from './broadcastsView';


class BroadcastsDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            drawerType: this.props.drawerType
        }
    };

    componentWillReceiveProps = nextProps => {
        if (nextProps.message !== this.props.message) {
            this.setState({ message: nextProps.message });
        }
        if (nextProps.drawerType !== this.props.drawerType) {
            this.setState({ drawerType: nextProps.drawerType });
        }
    };

    render() {
        const { open, onClose } = this.props;
        const { message, drawerType } = this.state;
        return (
            <React.Fragment>
                { open ?
                    <Drawer
                    anchor="right"
                    open={ open }
                    onClose={ () => onClose(false) }
                    classes={ { paper: "app-drawer", paperAnchorRight: "app-drawer" } }
                    >
                        { drawerType === 'edit' ?
                            <BroadcastsEdit
                                onClose={ onClose }
                                message={ message }
                                open={ open }
                            />
                            : <BroadcastsView
                                onClose={ onClose }
                                message={ message }
                                open={ open }
                            /> }
                    </Drawer>
                    : null
                }
            </React.Fragment>
        );
    }
}

export default BroadcastsDrawer;
