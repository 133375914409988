import React, { Component } from "react";
import { observer } from "mobx-react";
import { FormControlLabel, Popover } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateFnsUtils from "@date-io/date-fns";
import AdminCheckbox from "../../components/elements/adminCheckbox";
import { DarkSubHeading } from "../../components/elements/text";
import store from "../../store/store";
import { getSortIcon } from "../../services/utils";

class ReportsCustomHeader extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      fromDate,
      toDate,
      selectedLocation,
      handleChange,
      handleSelectLocation,
      pickerWidth,
      hideAllLabel
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className="columns is-mobile mb-0 is-vcentered pt-3 pl-3">
        <div
          className="column is-narrow"
          style={{ padding: 0, height: 40, width: pickerWidth ? pickerWidth : "23%" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              value={fromDate}
              inputVariant="standard"
              variant="inline"
              format="MM/dd/YYYY"
              size="small"
              InputProps={{
                disableUnderline: true,
              }}
              style={{ padding: "8px 16px 0 16px" }}
              className="app-input-field no-padding"
              onChange={(value) => handleChange("fromDate", value)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="column is-narrow"> - </div>
        <div
          className="column is-narrow"
          style={{ padding: 0, height: 40, width: pickerWidth ? pickerWidth : "23%" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              minDate={fromDate}
              value={toDate}
              inputVariant="standard"
              variant="inline"
              format="MM/dd/YYYY"
              size="small"
              InputProps={{
                disableUnderline: true,
              }}
              style={{ padding: "8px 16px 0 16px" }}
              className="app-input-field no-padding"
              onChange={(value) => handleChange("toDate", value)}
            />
          </MuiPickersUtilsProvider>
        </div>
        {handleSelectLocation ?
        <div className="column is-narrow is-flex is-align-items-center	">
          <FontAwesomeIcon
            size="2x"
            className="pl-1 pr-2 disabledGrey"
            icon={["fal", "pipe"]}
          />
          <DarkSubHeading
            className="pb-1 pt-1 cursor-pointer"
            onClick={(e) => this.handleClick(e)}
          >
            Locations
            {getSortIcon(Boolean(anchorEl))}
          </DarkSubHeading>
          <Popover
            id="settings-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="popover-wrapper">
              <div className="item-location-list">
                  { hideAllLabel ? null :
                    <FormControlLabel
                    control={
                      <AdminCheckbox
                        checked={selectedLocation === "All"}
                        onChange={() => {
                          this.handleClose();
                          handleSelectLocation("All");
                        }}
                        name={"location"}
                      />
                    }
                    label="All Locations"
                    style={{ marginTop: "-5px" }}
                    />
                  }
                {store.locations.length &&
                  store.locations.map((location) => (
                    <div key={location.id}>
                      <FormControlLabel
                        control={
                          <AdminCheckbox
                            checked={selectedLocation === location.id}
                            onChange={() => {
                              this.handleClose();
                              handleSelectLocation(location.id);
                            }}
                            name={"location"}
                          />
                        }
                        label={location.name}
                        style={{ marginTop: "-5px" }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </Popover>
        </div> : null}
      </div>
    );
  }
}

export default observer(ReportsCustomHeader);
