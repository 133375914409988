import config from "../../config/config";
import store from "../../store/store";
import _ from 'lodash';
import websocketClient from '../websocketClient';
import integrationsAPI from './integrationsAPI';
import tenantAPI from './tenantAPI';

const base_url = config.base_url;
const tenant_id = store.tenantId;

export default class accountsAPI {
    static login(values) {
        store.updateLoading(true);
        let url = `${ base_url }kiosk/login/`;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        })
            .then(raw_response => raw_response.json())
            .then(response => {
                if (response.errors && response.errors.__all__) {
                    store.updateError(response.errors.__all__[0].message);
                    store.updateSuccess(false);
                    if (response.errors.__all__[0].code === "tenant_required") {
                        store.updateTenantRequired(true);
                    }
                } else if (response.errors && response.errors.email) {
                    store.updateError(response.errors.email[0].message);
                    store.updateSuccess(false);
                } else {
                    let data = response.data;
                    if (data.role === "admin") {
                        store.updateUserToken(data.api_token.key);
                        store.updateUser(data);
                        if (data.tenant) {
                            tenantAPI.getTenant(data.tenant)
                            store.updateTenantId(data.tenant);
                            tenantAPI.getPosStatus().then(
                                data => {
                                    store.updatePosStatus(data.status);
                                });
                            tenantAPI.getRecursiveLocations({ page: 1 });
                            this.getRecursiveUsers({ page: 1 });
                            integrationsAPI.getRecursiveFulfillments({ page: 1 });
                            // Open a new websocket
                            store.updatewebsocketShouldReopen(true);
                            websocketClient.openWebsocket();
                            store.updateLoggedIn(true);
                        } else {
                            store.updateIsAdmin(true);
                            store.updateShowTenantList(true);
                        }
                        store.updateError(null);
                        store.updateSuccess(true);
                    } else {
                        store.updateError("Please enter a correct email and password. Note that both fields may be case sensitive.");
                        store.updateSuccess(false);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
        store.updateLoading(false);
    }

    static async getRecursiveUsers(params, data, t0) {
        if (!t0) t0 = performance.now();  // Leaving this here to monitor performance on production
        let t1 = 0;
        store.updateLoadingUsers(true);
        let response = await this.getUsers(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveUsers(params, new_data, t0);
        } else {
            t1 = performance.now();
            new_data = _.union(new_data, response['data']);
            store.updateUsers(new_data);
        }
        if (t1) console.log(`Call to getRecursiveUsers took ${ t1 - t0 } milliseconds.`);
        store.updateLoadingUsers(false);
        return new_data;
    }

    static getUsers(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/users/${ paramString }&minimal_fields=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static searchUsers(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ tenant_id }/users/?search=${ searchQuery }&minimal_fields=1`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateUsers(response['data']);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getUserById(user_id, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/users/" + user_id + "/?show_recent_order_date=1", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateUser(user_id, data, is_profile) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/users/" + user_id + "/?show_recent_order_date=1", {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (store.users.length) store.updateSingleUser(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getUserRewards(user_id, program_id) {
        store.updateLoading(true);
        return new Promise((resolve) => {
            let url = `${ base_url }tenants/${ store.tenantId }/users/${ user_id }/loyalty-account/${ program_id }/`;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                });
        });
    }

    static adjustUserPoints(user_id, program_id, points) {
        store.updateLoading(true);
        return new Promise(resolve => {
            let url = `${ base_url }tenants/${ store.tenantId }/users/${ user_id }/loyalty-account/${ program_id }/adjust-points/`;
            fetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: JSON.stringify(points),
            })
                .then((raw_response) => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(error => {
                    store.updateLoading(false);
                    console.log(error);
                });
        });
    }

    static getUserOrders(user_id) {
        store.updateLoading(true);
        return new Promise((resolve) => {
            let url = `${ base_url }tenants/${ store.tenantId }/users/${ user_id }/orders/`;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                });
        });
    }

    static requestPasswordResetCode(data) {
        store.updateLoading(true);
        let url = `${ base_url }kiosk/password-reset/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                });
        });
    }

    static resetPassword(data) {
        store.updateLoading(true);
        let url = `${ base_url }kiosk/password-reset/confirm/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getPunchCard(userID, page) {
        store.updateLoading(true);
        return new Promise(resolve => {
            let url = `${ base_url }tenants/${ store.tenantId }/users/${ userID }/punch-cards/`;
            if (page) {
                url = `${ url }?page=${ page }`;
            }
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then((raw_response) => raw_response.json())
                .then(response => {
                    store.updateUsedPunchCards();
                    store.updateAvailableRewards();
                    let data = response.data;
                    if (page) {
                    } else {
                        store.updatePunchCards(data);
                    }
                    if (response.has_next_page) {
                        this.getPunchCard(userID, response.page + 1);
                    } else {
                        if (store.punch_cards.length) {
                            store.updateCurrentPunchCard(store.punch_cards[0]);
                        } else {
                            store.updateCurrentPunchCard(data);
                        }
                        _.forEach(store.punch_cards, punch_card => {
                            if (punch_card.is_used) {
                                store.updateUsedPunchCards(punch_card);
                            } else if (!punch_card.is_used && punch_card.is_full) {
                                store.updateAvailableRewards(punch_card);
                            } else {
                                store.updateCurrentPunchCard(punch_card);
                            }
                        });
                        store.updateLoading(false);
                        return resolve(response);
                    }
                }).catch(error => {
                    console.log(error);
                    store.updateLoading(false);
                });
        });
    }

    static adjustUserPunches(user_id, points) {
        store.updateLoading(true);
        return new Promise(resolve => {
            let url = `${ base_url }tenants/${ store.tenantId }/users/${ user_id }/punch-cards/adjust-points/`;
            fetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: JSON.stringify(points),
            })
                .then((raw_response) => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    store.updateLoading(false);
                });
        });
    }

}