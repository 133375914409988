import React, { Component } from "react";
import PropTypes from 'prop-types';
import AdminSwitch from './adminSwitch';
import { AdminSpan, DarkSubHeading } from "./text";

class AdminToggle extends Component {

    render() {
        const { 
            checked,
            checkedLabel,
            unCheckedLabel,
            onChange,
            labelText,
            headerText,
            hideLabel,
            weight
        } = this.props;

        return (
            <div>
                {headerText ? <DarkSubHeading>{headerText}</DarkSubHeading> : null}
                <AdminSwitch
                    className="app-toggle"
                    checked={checked}
                    onChange={onChange}
                />
                {labelText ? (
                    <AdminSpan isToggle>
                        {checked ? labelText.enabled : labelText.disabled}
                    </AdminSpan>
                ) : hideLabel ? null : (
                    <AdminSpan isToggle weight={weight ? "bold" : ""}>
                        {checked ? checkedLabel ? checkedLabel : "Enabled" : unCheckedLabel ? unCheckedLabel : "Disabled"}
                    </AdminSpan>
                )}
            </div>

        );
    }
}

AdminToggle.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    labelText: PropTypes.exact({
        enabled: PropTypes.string.isRequired,
        disabled: PropTypes.string.isRequired,
    }),
}

export default AdminToggle;
