import React, {Component} from 'react';
import {observer} from 'mobx-react';
import InputMask from 'react-input-mask';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';

import store from '../../store/store';
import {errorMessage, fieldError, formatDateTimeForView} from '../../services/utils';
import AdminDropdownField from '../elements/adminDropdownField';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { Text } from '../elements/text';
import UserLoyalty from './userLoyalty';
import accountsAPI from '../../services/api/accountsAPI';


class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            apiErrors: {},
            error: '',
            roles: ['user', 'admin', 'staff'],
            user: _.cloneDeep(this.props.user),
            userLoyalty: {}
        };
    }

    handleChange(event) {
        let {apiErrors, user} = this.state;
        let error = '';
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'phone') {
            if (value.replace('_', '').length < 16) error = 'Phone must be at least 10 digits.';
        } else if (name === 'email') {
            if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)) error = 'Please enter a valid email.';
        } else {
            if (!value.length) error = 'This field is required.';
        }

        user[name] = value;
        if (error.length) {
            apiErrors[name] = [{message: error}];
        } else {
            apiErrors = {};
        }
        this.setState({
            user: user,
            apiErrors: apiErrors
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        const user = this.state.user;
        const payload = {
            'full_name': user.full_name,
            'phone': user.phone,
            'email': user.email,
            'role': user.role
        };
        accountsAPI.updateUser(this.props.user.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({apiErrors: response.errors});
                    if (response.errors.__all__) this.setState({error: response.errors.__all__[0].message});
                }
                else {
                    this.props.onClose(true);
                }
            });
    };

    displayRole(role) {
        return (
            role === 'user' ? 'Customer' : role === 'staff' ? 'Staff' : 'Admin'
        );
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    render() {
        const { onClose } = this.props;
        const { user, anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <EditDrawer
                title={`Edit ${ user.full_name }`}
                onClose={() => onClose(false)}
                isSubmitting={false}
                onSubmit={this.handleSubmit}
                errorMessage={errorMessage(this.state.apiErrors, this.state.error)}
            >
                <form>
                    <AdminCard
                        title='Personal Information'
                        style={{marginTop: '15px'}}
                    >
                        <div className='app-field-wrapper'>
                            <label htmlFor='full_name' className='app-field-label mt-3'>
                                Name
                            </label>
                            <input
                                className={`app-input-field mt-0 ${ fieldError('full_name', this.state.apiErrors) ? 'red-border' : '' }`}
                                id='full_name'
                                name='full_name'
                                type='text'
                                value={this.state.user.full_name}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('full_name', this.state.apiErrors)}
                        </div>
                        <div className='app-field-wrapper'>
                            <label htmlFor='phone' className='app-field-label'>
                                Phone
                            </label>
                            <InputMask
                                mask='9 (999) 999-9999'
                                className={`app-input-field mt-0 ${ fieldError('phone', this.state.apiErrors) ? 'red-border' : '' }`}
                                id='phone'
                                name='phone'
                                type='text'
                                value={this.state.user.phone}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('phone', this.state.apiErrors)}
                        </div>
                        <div className='app-field-wrapper'>
                            <label htmlFor='email' className='app-field-label'>
                                Email
                            </label>
                            <input
                                className={fieldError('email', this.state.apiErrors) ? 'app-input-field red-border mt-0' : 'app-input-field mt-0'}
                                id='email'
                                name='email'
                                type='email'
                                value={this.state.user.email}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('email', this.state.apiErrors)}
                        </div>
                    </AdminCard>
                    <AdminCard title='Account Management'>
                        <div className='app-field-wrapper'>
                            <label htmlFor='role' className='app-field-label mt-3'>
                                User Role
                            </label>
                            <AdminDropdownField
                                id='role'
                                name='role'
                                onChange={ (event) => this.handleChange(event) }
                                value={ user.role }
                                style={ { width: '100%' } }
                            >
                                { this.state.roles &&
                                    this.state.roles.length &&
                                    this.state.roles.map((option, i) => (
                                        <option key={ i } value={ option }>
                                            { this.displayRole(option) }
                                        </option>
                                    )) }
                            </AdminDropdownField>
                        </div>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label' style={{marginTop: '15px'}}>
                                Date Joined
                            </label>
                            <Text>{formatDateTimeForView(user.date_joined)}</Text>
                        </div>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label' style={{marginTop: '15px'}}>
                                Recent Order
                                <FontAwesomeIcon
                                    className='cursor-pointer'
                                    icon={ ['fa', 'ellipsis-h'] }
                                    style={ { float: 'right', marginTop: '8px' } }
                                    onClick={ this.handleClick }
                                />
                                <Popover
                                    id='orders-popover'
                                    open={ open }
                                    anchorEl={ anchorEl }
                                    onClose={ this.handleClose }
                                    anchorOrigin={ {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    } }
                                    transformOrigin={ {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    } }
                                >
                                    <div onClick={ () => this.props.openSecondaryDrawer('order history') } className='loyalty-popover'>View All Orders</div>
                                </Popover>
                            </label>
                            <Text>{user.recent_order_date ? formatDateTimeForView(user.recent_order_date) : 'No Orders Placed'}</Text>
                        </div>
                        { this.props.profile ?
                            <div className='secondaryBlue bold medium-text cursor-pointer' onClick={ () => this.props.openSecondaryDrawer('password') }>
                                Reset Password
                            </div>
                        : null }
                    </AdminCard>
                </form>
                {store.squareLoyalty && store.squareLoyalty.is_enabled && user.phone_validated ?
                    <UserLoyalty
                        type="square loyalty"
                        userLoyalty={this.state.userLoyalty}
                        user={user}
                        style={ { margin: 0 } }
                        openSecondaryDrawer={ this.props.openSecondaryDrawer }
                    />
                    : store.punchCardSettings && store.punchCardSettings.is_enabled ?
                        <UserLoyalty
                            type="punchcards"
                            userLoyalty={this.state.userLoyalty}
                            user={user}
                            style={ { margin: 0 } }
                            openSecondaryDrawer={ this.props.openSecondaryDrawer }
                        />
                        : null
                }
            </EditDrawer>
        );
    }
}

export default observer(UserEdit);
