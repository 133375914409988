import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));

export default class reportsAPI {
    static getReportsAnalyticsUsers(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/analytics-users/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject(err);
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getSalesSummary(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/sales-summary/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getItemSales(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/item-sales/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getCategorySales(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/category-sales/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getDailySales(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/daily-sales/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getHourlySales(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/hourly-sales/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getDiscountReport(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/discounts-sales/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getTopMenuItems(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/top-items/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getTopCategories(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/top-categories/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getTaskStatus(task_id) {
        return new Promise((resolve, reject) => {
            const url = base_url + "tasks/" + task_id + "/";
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    static getLocationUsers(start, end, location) {
        return new Promise((resolve, reject) => {
            let url = base_url + "tenants/" + store.tenantId + "/reports/analytics-location-users/?start=" + start + "&end=" + end;
            if (location) url = url + "&location=" + location;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data && response.data.task_id) {
                        let that = this,
                            refreshId = setInterval(function () {
                                that.getTaskStatus(response.data.task_id)
                                    .then(data => {
                                        if (data.result != null) {
                                            clearInterval(refreshId);
                                            resolve(data.result);
                                            return;
                                        }
                                    })
                                    .catch(err => {
                                        clearInterval(refreshId);
                                        reject();
                                    });
                            }, 500);
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

}