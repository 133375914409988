import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import store from '../../store/store';
import tenantAPI from '../../services/api/tenantAPI';


class ToggleGiftCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      enable_giftcards: this.props.location.enable_giftcards
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location)
      this.setState({
        location: nextProps.location,
        enable_giftcards: nextProps.location.enable_giftcards
      });
  }

  toggleEnabled = () => {
    const location = this.state.location;
    let enable_giftcards = this.state.enable_giftcards === true ? false : true;
    let body = {};
    if (enable_giftcards) {
      body['enable_giftcards'] = enable_giftcards;
    } else {
      body['enable_giftcards'] = enable_giftcards;
    }
    const values = JSON.stringify(body);
    tenantAPI.updateLocation(location.id, values)
      .then(response => {
        if (response.errors && response.errors["enable_giftcards"]) {
          store.updateShowSecondaryButton(true);
          store.updateSyncErrorMessage('STOP! You need to accept new permissions in Square. Please log out of all Square accounts not associated with this dashboard before proceeding. Hitting "Continue" will redirect to your Square login to accept these permissions. Please make sure that you log into the correct Square account to avoid data loss.');
          store.updateShowSyncErrorDialog(true);
        } else {
          this.props.onUpdate(response);
        }
      })
      .catch(err => console.error(err));
  };

  render() {
    return (
      <AdminCard
        largeTitle
        title="Enable/Disable Gift Cards"
        description="When enabled, customers will be able to use gift cards for orders placed at this location. (This setting will not affect other locations.)"
      >
        <AdminToggle
          className='app-toggle'
          checked={ Boolean(this.state.enable_giftcards) }
          onChange={ this.toggleEnabled }
        />
      </AdminCard>
    );
  }
}

export default ToggleGiftCards;
