import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import store from "../store/store";

class ErrorDialog extends Component {

  render() {
    const { classes, onClose, ...other } = this.props;

    return (
      <Dialog
        onClose={() => store.updateShowErrorDialog(false)}
        aria-labelledby="error-dialog"
        {...other}
      >
        <div className="app-card" style={{ marginTop: 0 }}>
          <div className="app-card-header">
            <div className="app-card-header-text">
              <div className="app-card-header-title">Request Error</div>
            </div>
          </div>
          <div className="app-card-content-wrapper">
            <div style={{ fontWeight: 800 }}>Something went wrong with that request.</div>
            <div style={{ marginTop: 10 }}>Some steps that might help:</div>
            <ul style={{ marginTop: 5 }}>
              <li>First, try refreshing this page.</li>
              <li>If that doesn't work, try accessing this page in a few hours.</li>
              <li>You can always contact Ordrslip Customer Success at support@ordrslip.com for assistance as well.</li>
            </ul>
          </div>
          <div className="app-card-actions-wrapper">
            <button
              type="button"
              className="app-button primary margin-left"
              onClick={() => store.updateShowErrorDialog(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ErrorDialog;
