import React, { Component } from "react";
import tenantAPI from '../../services/api/tenantAPI';
import store from "../../store/store";
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';

class ToggleSquareInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      enable_square_inventory: this.props.location.enable_square_inventory
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location)
      this.setState({
        location: nextProps.location,
        enable_square_inventory: nextProps.location.enable_square_inventory
      });
  }

  toggleEnabled = () => {
    const location = this.state.location;
    let enable_square_inventory = !this.state.enable_square_inventory;
    const values = JSON.stringify({ enable_square_inventory: enable_square_inventory });
    tenantAPI.updateLocation(location.id, values)
      .then(response => {
          if (response.errors && response.errors["enable_square_inventory"]) {
            store.updateShowSecondaryButton(true);
            store.updateSyncErrorMessage('STOP! You need to accept new permissions in Square. Please log out of all Square accounts not associated with this dashboard before proceeding. Hitting "Continue" will redirect to your Square login to accept these permissions. Please make sure that you log into the correct Square account to avoid data loss.');
            store.updateShowSyncErrorDialog(true);
          }
        this.props.onUpdate(response);
      })
      .catch(err => console.error(err));
  };

  render() {

    return (
      <AdminCard
        largeTitle
        title="Enable/Disable Square POS Inventory"
        description={<div>When enabled, you will manage your item inventory through the Square Register or Square Dashboard, 
        and items will be automatically marked out of stock for your app when the Square stock reaches zero (0). 
        If disabled, you can continue to use the Ordrslip Admin Dashboard inventory management. 
        <span className="bold"> NOTE: You will need to accept new Square permissions in order to enable this feature.</span></div>}
      >
        <AdminToggle
          className="app-toggle"
          checked={this.state.enable_square_inventory || false}
          onChange={this.toggleEnabled}
          headerText="Square POS Inventory"
        />
      </AdminCard>
    );
  }
}

export default ToggleSquareInventory;
