import React, { Component } from 'react';
import { observer } from 'mobx-react';
import store from '../store/store';
import AdminPageHeader from '../components/elements/adminPageHeader';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getFulfillment } from '../services/utils';
import integrationsAPI from '../services/api/integrationsAPI';
import tenantAPI from '../services/api/tenantAPI';
import { Text } from '../components/elements/text';
import AdminToggle from '../components/elements/adminToggle';
import AdminCard from '../components/elements/adminCard';
import IntegrationsEdit from '../components/integrations/integrationsEdit';
import IntegrationsDrawer from '../components/integrations/integrationsDrawer';

class IntegrationsUber extends Component {
  state = {
    open: false,
    fulfillment: {},
  };

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(() => this.getFulfillments());
  };

  getFulfillments = () => {
    integrationsAPI.getRecursiveFulfillments({ page: 1 })
      .then((response) => {
        let fulfillments = getFulfillment(response, 'uber');
        this.setState({ fulfillment: fulfillments[0] });
      });
  };

  handleDialogOpen = () => {
    this.setState({ open: true });
  };

  handleDialogClose = updated => {
    this.setState({ open: false });
    if (updated === true) this.getFulfillments();
  };

  toggleLocationEnabled = locationID => {
    const { fulfillment } = this.state;
    let locations = fulfillment.locations;
    if (locations.includes(locationID)) {
      locations.splice(locations.indexOf(locationID), 1);
    } else {
      locations.push(locationID);
    }
    let payload = { locations: locations };
    integrationsAPI.updateFulfillment(this.state.fulfillment.id, JSON.stringify(payload))
      .then(response => this.setState({fulfillment: response.data}));
  };

  render() {
    const { fulfillment } = this.state;
    return (
      <div className='admin-page-wrapper'>
        <AdminPageHeader
          title='Uber'
          mainNav='Configuration'
          subNav='Integrations'
          description='Activate Uber for your locations.'
          headerActionText={ _.isEmpty(fulfillment) ? 'Add New' : '' }
          onHeaderActionClick={ _.isEmpty(fulfillment) ? () => this.handleDialogOpen('create') : null }
        />
        <div>
          { ! _.isEmpty(fulfillment) ?
            <div>
              <AdminCard title='Enable/Disable Uber Integration' largeTitle>
                <Table className='app-table-wrapper'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='app-table-cell'>
                        Location
                      </TableCell>
                      <TableCell className='set-thin-width has-text-centered app-table-cell'>
                        Enabled
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                    { store.locations &&
                      store.locations.length &&
                      store.locations.map(location => (
                        <TableRow
                          key={ location.id }
                          className='app-table-row'
                        >
                          <TableCell className='app-table-cell'>
                            { location.name }
                          </TableCell>
                          <TableCell className='set-thin-width has-text-centered app-table-cell'>
                            <AdminToggle
                              className='app-toggle'
                              checked={ fulfillment.locations.includes(location.id) }
                              onChange={ () => this.toggleLocationEnabled(location.id) }
                              hideLabel
                            />
                          </TableCell>
                        </TableRow>
                      )) }
                  </TableBody>
                </Table>
              </AdminCard>
            </div> :
            <Text>No Uber integration exists for this tenant.</Text>
          }
        </div>
        <IntegrationsDrawer
          fulfillment={ {} }
          integration={ 'uber' }
          open={ this.state.open }
          onClose={ this.handleDialogClose }
        />
      </div>
    );
  }
}

export default observer(IntegrationsUber);
