import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import Drawer from '@material-ui/core/Drawer';
import { errorMessage, fieldError } from "../../services/utils";
import AdminDropdownField from "../elements/adminDropdownField";
import store from '../../store/store';
import tenantAPI from '../../services/api/tenantAPI';


class PunchCardsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reward_type: this.props.punchCardSettings.reward_type,
            reward_amount: this.props.punchCardSettings.reward_type === 'percent' ? this.props.punchCardSettings.percent_off : this.props.punchCardSettings.amount_off.amount,
            points_required: this.props.punchCardSettings.points_required,
            minimum_order_amount: this.props.punchCardSettings.minimum_order_amount.amount,
            apiErrors: {}
        };
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const currency = store.locations[0].currency;
        let payload = {};
        if (this.props.editType === 'Punch Card Reward') {
            payload.reward_type = this.state.reward_type;
            payload.percent_off = this.state.reward_type === 'percent' ? this.state.reward_amount : null;
            payload.amount_off = this.state.reward_type === 'amount' ? { amount: this.state.reward_amount, currency: currency } : { amount: '0', currency: currency }
        }
        if (this.props.editType === 'Number of Points Required') {
            payload.points_required = this.state.points_required
        }
        if (this.props.editType === 'Minimum Order Amount') {
            payload.minimum_order_amount = { amount: this.state.minimum_order_amount, currency: currency };
        }
        tenantAPI.updatePunchCardSettings(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    this.setState({ apiErrors: {} });
                    this.props.onClose();
                }
            });
    };

    getDescription() {
        switch (this.props.editType) {
            case 'Punch Card Reward':
                return 'This is the reward that will be applied to a future purchase once a customer has earned enough points.'

            case 'Number of Points Required':
                return 'A customer will earn a reward once they have earned this many points.'

            case 'Minimum Order Amount':
                return 'A customer will only earn a point if their order total is greater than or equal to the minimum amount.'

            default:
                return '';
        }
    }

    render() {
        const { editType, open, onClose } = this.props;
        return (
            <Drawer
                anchor="right"
                open={ open }
                onClose={ onClose }
                classes={ { paper: "app-drawer", paperAnchorRight: "app-drawer" } }
            >
                <EditDrawer
                    title={`Edit ${editType}`}
                    onSubmit={ this.handleSubmit }
                    onClose={ onClose }
                    errorMessage={ errorMessage(this.state.apiErrors) }
                >
                    <AdminCard
                        title={editType}
                        style={ { marginTop: "15px" } }
                        description={this.getDescription()}
                    >
                        <form>
                            { editType === 'Punch Card Reward' ?
                                <div>
                                    <div className="app-field-wrapper">
                                        <label htmlFor="reward_type" className="app-field-label">
                                            Reward Type
                                        </label>
                                        <AdminDropdownField
                                            style={{width: '100%'}}
                                            name="reward_type"
                                            onChange={ (event) => this.handleChange(event) }
                                            value={ this.state.reward_type }
                                            className={ fieldError("reward_type", this.state.apiErrors) ? "red-border" : "" }
                                        >
                                            <option value='percent'>Percent Off an Order</option>
                                            <option value='amount'>Amount Off an Order</option>
                                        </AdminDropdownField>
                                        { fieldError('reward_type', this.state.apiErrors) }
                                    </div>
                                    <div className="app-field-wrapper">
                                        <label htmlFor="reward_amount" className="app-field-label">
                                            Reward Amount
                                        </label>
                                        <input
                                            className={ `app-input-field mt-0 ${ fieldError("reward_amount", this.state.apiErrors) ? 'red-border' : ''}`}
                                            name="reward_amount"
                                            type="number"
                                            placeholder="0"
                                            value={ this.state.reward_amount }
                                            onChange={ (event) => this.handleChange(event) }
                                        />
                                        { fieldError('amount_off', this.state.apiErrors) }
                                        { fieldError('percent_off', this.state.apiErrors) }
                                    </div>
                                </div>
                                : editType === 'Number of Points Required' ?
                                    <div className="app-field-wrapper">
                                        <label htmlFor="points_required" className="app-field-label">
                                            Points Required
                                        </label>
                                        <input
                                            className={ `app-input-field mt-0 ${ fieldError("points_required", this.state.apiErrors) ? 'red-border' : '' }` }
                                            name="points_required"
                                            type="number"
                                            placeholder="0"
                                            value={ this.state.points_required }
                                            onChange={ (event) => this.handleChange(event) }
                                        />
                                        { fieldError('points_required', this.state.apiErrors) }
                                    </div>
                                    : editType === 'Minimum Order Amount' ?
                                        <div className="app-field-wrapper">
                                            <label htmlFor="minimum_order_amount" className="app-field-label">
                                                Minimum Order Amount
                                            </label>
                                            <input
                                                className={ `app-input-field mt-0 ${ fieldError("minimum_order_amount", this.state.apiErrors) ? 'red-border' : '' }` }
                                                name="minimum_order_amount"
                                                type="number"
                                                placeholder="Min. Order Amount"
                                                value={ this.state.minimum_order_amount }
                                                onChange={ (event) => this.handleChange(event) }
                                            />
                                            { fieldError('minimum_order_amount', this.state.apiErrors) }
                                        </div>
                                : null }
                        </form>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default PunchCardsEdit;
