import React, { Component } from "react";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import Currency from 'react-currency-formatter';
import moment from "moment";
import _ from "lodash";

import { getStartOfDayByDate, getEndOfDayByDate, getSortIcon } from "../services/utils";
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminCard from '../components/elements/adminCard';
import ReportsCustomHeader from "../components/reports/reportsCustomHeader";
import store from "../store/store";
import AutocompleteVerbiage from '../components/reports/autcompleteVerbiage';
import tenantAPI from '../services/api/tenantAPI';
import promotionsAPI from '../services/api/promotionsAPI';
import reportsAPI from '../services/api/reportsAPI';

class ReportsDiscounts extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      discounts: [],
      selectedLocation: 'All',
      order: "desc",
      currentPage: 0,
      rowsPerPage: 10,
      orderBy: "name",
      anchorEl: null,
    };
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
  }

  componentDidMount() {
    tenantAPI.getRecursiveLocations({page: 1})
      .then(locations => {
        this.setState({ locations });
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format('MM/DD/YYYY');
        this.formatReports(monthAgo, todaysDate);
      });
    promotionsAPI.getRecursivePromoCodes({page: 1});
  }

  formatReports(fromDate, toDate) {
    let location = this.state.selectedLocation === 'All' ? null : this.state.selectedLocation;
      reportsAPI.getDiscountReport(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location)
        .then(data => {
          _.forEach(data.discounts, (discount, index) => {
            discount.index = index;
          });
          this.setState({discounts: data.discounts, currentPage: 0});
        })
        .catch(err => console.error(err));
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format('MM/DD/YYYY');
    if (type === 'fromDate') {
      this.setState({fromDate: displayDate}, () => {
        this.formatReports(newDate, this.state.toDate);
      });
    } else if (type === 'toDate') {
      this.setState({toDate: displayDate}, () => {
        this.formatReports(this.state.fromDate, newDate);
      });
    }
  }

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
      this.handleClose('anchorEl');
    });
  }

  handleClick = (type, event) => {
    this.setState({
      [type]: event.currentTarget,
    });
  };

  handleClose = (type) => {
    this.setState({ [type]: null });
  };

  handleSort(value) {
    let discounts = this.state.discounts;
    let orderBy = value;
    let order =
      this.state.order === "asc" && this.state.orderBy === value
        ? "desc"
        : "asc";
    let generic = value === 'code' ? 'N/A' : 'Coupon Not Available';
    discounts = _.orderBy(discounts, [discount => 
    discount['coupon'] && discount['coupon'][value] ? 
    discount['coupon'][value].toLowerCase() : generic], [order]);
    this.setState({ discounts, order, orderBy, currentPage: 0 });
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  }

  render() {
    const { currentPage, order, orderBy, rowsPerPage } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
            title="Discounts"
            mainNav="Reports"
            subNav="Location Reports"
            description="View the discount breakdown for your locations."
        />
          <AutocompleteVerbiage />
          <AdminCard
            title=""
            customHeader={
            <ReportsCustomHeader
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              selectedLocation={this.state.selectedLocation}
              handleChange={this.handleChange}
              handleSelectLocation={this.handleSelectLocation}
            />}
          >
            {store.loading ? (
              <div
                className="admin-page-header-subtext"
                style={{ textAlign: "center", padding: 20 }}
              >
                Loading...
              </div>
            ) :
            !store.loading && this.state.discounts && !this.state.discounts.length ?
              <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
                No discount reports for the selected days or location.
              </div> :
              <Table className="app-table-wrapper mt-0">
                <TableHead className="app-table-header">
                  <TableRow className="app-table-row">
                    <TableCell className="app-table-cell pl-0">
                      <TableSortLabel
                        active={ orderBy === 'name' }
                        direction={ orderBy === 'name' ? order : 'asc' }
                        onClick={ () => this.handleSort('name') }
                        IconComponent={ () => getSortIcon(this.state.order === "asc" && this.state.orderBy === 'name') }
                      >
                        Discount Name
                        { orderBy === 'name' ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="app-table-cell set-thin-width">
                      <TableSortLabel
                        active={ orderBy === 'code' }
                        direction={ orderBy === 'code' ? order : 'asc' }
                        onClick={ () => this.handleSort('code') }
                        IconComponent={ () => getSortIcon(this.state.order === "asc" && this.state.orderBy === 'code') }
                      >
                        Discount Code
                        { orderBy === 'code' ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="app-table-cell set-thin-width">QTY Used</TableCell>
                    <TableCell className="app-table-cell">Total Amount Discounted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="app-table-body">
                  {rowsPerPage > 0 &&
                    this.state.discounts &&
                    this.state.discounts.length > 0 &&
                    this.state.discounts.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                    .map(report => (
                      <TableRow key={report.index} className="app-table-row">
                        <TableCell className="app-table-cell pl-0">
                          {report.coupon ? report.coupon.name : "Coupon Not Available"}
                        </TableCell>
                        <TableCell className="app-table-cell">
                          {report.coupon ? report.coupon.code : "N/A"}
                        </TableCell>
                        <TableCell className="app-table-cell">
                          {report.quantity}
                        </TableCell>
                        <TableCell className="app-table-cell">
                          <Currency
                            quantity={report.amount.amount}
                            currency={report.amount.currency}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={ [10, 25, 50] }
                        count={ this.state.discounts && this.state.discounts.length ? this.state.discounts.length : 0 }
                        rowsPerPage={ this.state.rowsPerPage }
                        onRowsPerPageChange={ (event) => this.updateRows(event) }
                        page={ this.state.currentPage }
                        onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                        style={ { borderBottom: 'none' } }
                      />
                  </TableRow>
                </TableBody>
              </Table>}
          </AdminCard>
      </div>
    );
  }
}

export default observer(ReportsDiscounts);
