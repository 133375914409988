import React, { Component } from "react";
import Drawer from '@material-ui/core/Drawer';
import { toJS } from 'mobx';
import _ from 'lodash';
import PromoCodeView from './promoCodeView';
import PromoCodeEdit from './promoCodeEdit';


class PromoCodeDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerType: this.props.drawerType,
            duplicatePromoCode: {}
        }
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.drawerType !== this.props.drawerType) {
            this.setState({ drawerType: nextProps.drawerType });
        }
    };

    onClose = updated => {
        this.setState({ duplicatePromoCode: {} });
        this.props.onClose(updated);
    }

    duplicatePromo = () => {
        let duplicatePromoCode = _.cloneDeep(toJS(this.props.promoCode));
        delete duplicatePromoCode.id;
        if (this.props.promoCode.amount_off) {
            duplicatePromoCode.amount_off.amount = duplicatePromoCode.amount_off.amount.toString();
        } else {
            duplicatePromoCode.amount_off = { amount: "0", currency: 'USD' };
        }
        this.setState({duplicatePromoCode, drawerType: 'create'})
    }

    render() {
        const { drawerType } = this.state;
        const { open, promoCode } = this.props;

        return (
            <>
                {open ?
                    <Drawer
                        anchor="right"
                        open={open}
                        classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}
                        onClose={() => this.onClose(false)}
                    >
                        {drawerType === 'view' ?
                            <PromoCodeView
                                promoCode={promoCode}
                                duplicatePromo={this.duplicatePromo}
                            /> :
                            drawerType === 'edit' ?
                                <PromoCodeEdit
                                    promoCode={promoCode}
                                    onClose={this.onClose}
                                /> : drawerType === 'create' ?
                                    <PromoCodeEdit
                                        onClose={this.onClose}
                                        promoCode={this.state.duplicatePromoCode}
                                    /> :
                                null}
                    </Drawer> :
                    null
                }
            </>
        );
    }
}

export default (PromoCodeDrawer);