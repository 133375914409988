import React, { Component } from "react";
import PropTypes from 'prop-types';
import { DarkSubHeading } from "./text";
import { colors } from "../../styles/global";
import styled from "styled-components";

const HeaderContainer = styled.div`
    ${props => props.border === "bottom" ? 
        `border-bottom: ${colors.disabledGrey} solid 1px;` +
        "margin-bottom: 20px"
    : null}
    ${props => props.border === "top" ? 
        `border-top: ${colors.disabledGrey} solid 1px;` +
        "margin-top: 20px"
    : null}
    ${props => props.marginTop ? 
        `margin-top: ${props.marginTop}`
    : null}
`

class AdminSectionHeader extends Component {

    render() {
        const {
            title,
            border,
            marginTop
        } = this.props;

        return(
            <HeaderContainer 
                border={border}
                marginTop={marginTop ? `${marginTop}px` : undefined}
            >
                <DarkSubHeading>
                    { title ? title : "" }
                </DarkSubHeading>
                {this.props.children}
            </HeaderContainer>
        )
    }
}

AdminSectionHeader.propTypes = {
    title: PropTypes.string,
    border: PropTypes.oneOf(['bottom', 'top']),
    marginTop: PropTypes.number,
}

export default AdminSectionHeader;