import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import store from "../store/store";
import tenantAPI from '../services/api/tenantAPI';

class SyncErrorDialog extends Component {

  render() {
    const { classes, onClose, ...other } = this.props;

    return (
      <Dialog
        onClose={() => store.updateShowSyncErrorDialog(false)}
        aria-labelledby="error-dialog"
        {...other}
      >
        <div className="app-card" style={{ marginTop: 0 }}>
          <div className="app-card-header">
            <div className="app-card-header-text">
              <div className="app-card-header-title">Sync Error</div>
            </div>
          </div>
          <div className="app-card-content-wrapper">
            <div style={{ fontWeight: 800 }}>{ store.sync_error_message != null ? store.sync_error_message : 'Something went wrong with syncing.' }</div>
            <div style={{ marginTop: 10 }}>Contact Customer Success at support@ordrslip.com for assistance.</div>
          </div>
          <div className="app-card-actions-wrapper">
            <button
              type="button"
              className="app-button primary margin-left"
              onClick={() => store.updateShowSyncErrorDialog(false)}
            >
              Close
            </button>
            {store.showSecondaryButton !== false ?
            <button
              type="button"
              className="app-button primary margin-left"
              onClick={ () => tenantAPI.squareOAuth()}
            >
              Continue
            </button>
              : null
            }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default SyncErrorDialog;
