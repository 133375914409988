import React, { Component } from "react";
import { ErrorOutline } from "@material-ui/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import _ from "lodash";
import { toJS } from 'mobx';

import integrationsAPI from "../../services/api/integrationsAPI";
import store from "../../store/store";

import AdminCard from '../elements/adminCard';
import AdminCheckbox from '../elements/adminCheckbox';
import AdminSectionHeader from "../elements/adminSectionHeader";
import AdminToggle from '../elements/adminToggle';
import { Button } from "../elements/buttons";
import { Drawer } from "@material-ui/core";
import EditDrawer from '../elements/editDrawer';
import {FieldLabel} from "../elements/formElements";
import {Text} from "../elements/text";
import tenantAPI from '../../services/api/tenantAPI';

class PauseOrdering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      enable_order_pausing: false,
      availableFulfillments: [],
      pausedFulfillments: [],
      pausedFulfillmentCount: 0,
      submitText: 'Next',
      selectedFulfillments: [],
      cancelText: 'Cancel',
      selectedPause: {},
      errorMessage: null,
      drawerOpen: false,
      drawerContent: 'edit', // resume, edit, disable
      title: '',
      hasFooter: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location) {
      let location = nextProps.location;
      let availableFulfillments = [];
      let pausedFulfillments = [];
      this.setState({
        location: nextProps.location,
        enable_order_pausing: nextProps.location.enable_order_pausing
      });
      integrationsAPI.getRecursiveFulfillments({page: 1})
        .then(() => {
          integrationsAPI.getRecursivePausedFulfillments(location.id, {page: 1})
            .then(response => {
              this.setState({pausedFulfillments: response});
            })
          _.forEach(toJS(store.fulfillments), fulfillment => {
            if (!location.paused_integrations.includes(fulfillment.integration) && fulfillment.is_enabled && fulfillment.locations.includes(location.id)) {
              availableFulfillments.push(fulfillment);
            }
          });
          this.setState({availableFulfillments, pausedFulfillments});
        });
    }
  }

  toggleEdit = () => {
    this.setState({
      drawerContent: 'edit',
      selectedFulfillments: [],
      submitText: 'Pause Orders',
      cancelText: 'Do Not Pause',
      title: 'Pause Orders'
    });
    this.toggleDrawer('edit');
  }

  toggleAndClearPauses(){
    this.setState({
      pausedFulfillmentCount: this.state.pausedFulfillments.length
    })
    let counter = 0;
    this.state.pausedFulfillments.forEach(fulfillment => {
      integrationsAPI.deletePause(this.state.location.id, fulfillment)
        .then(() => {
          counter++;
          if(counter === this.state.pausedFulfillmentCount){
            this.fetchPausesAndFulfillments();
            this.toggleDrawer();
            const values = JSON.stringify({'enable_order_pausing': false});
            tenantAPI.updateLocation(this.state.location.id, values)
              .then(response => this.props.onUpdate(response));
          }
        }).catch( error => console.log(error))
    })
  }

  fetchPausesAndFulfillments() {
    let availableFulfillments = [];
    let pausedFulfillments = [];
    integrationsAPI.getRecursiveFulfillments({page: 1, locations: this.state.location.id})
      .then(() => {
        integrationsAPI.getRecursivePausedFulfillments(this.state.location.id, {page: 1})
          .then(response => {
            this.setState({pausedFulfillments: response});
          })
        _.forEach(store.fulfillments, fulfillment => {
          if (!this.state.location.paused_integrations.includes(fulfillment.integration) && fulfillment.is_enabled) {
            availableFulfillments.push(fulfillment);
          }
        });
        this.setState({availableFulfillments, pausedFulfillments,selectedPause: {}});
      });
  }

  savePause() {
    let body = {
      'start_time': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
      'end_time': moment(new Date(2030, 12, 31, 23, 59, 59, 999999)).utc().format('YYYY-MM-DDTHH:mm:ss'),
      'integrations': this.state.selectedFulfillments
    }
    integrationsAPI.createPause(this.state.location.id, JSON.stringify(body))
      .then(response => {
        let selectedFulfillments = this.state.selectedFulfillments;
        let availableFulfillments = this.state.availableFulfillments;
        let fulfillments = availableFulfillments.filter(available => {
          return !selectedFulfillments.includes(available.integration);
        });
        let pauses = this.state.pausedFulfillments;
        pauses.push(response.data);
        this.setState({
          pausedFulfillments: pauses,
          availableFulfillments: fulfillments,
          errorMessage: ''
        });
        this.updateRecentPauses(this.state.selectedFulfillments);
        this.toggleDrawer();
      }).catch(error => console.log(error))
  }

  handleFormSubmit = () => {
    switch (this.state.drawerContent) {
      case 'edit':
        if (!this.state.selectedFulfillments.length) {
          this.setState({
            errorMessage: 'You need to select a fulfillment type to pause orders.'
          })
        } else {
          this.savePause();
        }
        break;
      case 'resume':
        this.resumeOrders();
        break;
      case 'disable':
        this.toggleAndClearPauses();
        break;
      default:
        return null
    }
  }

  showResumeOrders = item => e => {
    this.setState({
      submitText: 'Resume Orders',
      cancelText: 'Cancel',
      selectedPause: item,
      title: "Resume Orders",
      drawerContent: 'resume'
    });
    this.toggleDrawer('resume');
  }

  resumeOrders = () => {
    let body = this.state.selectedPause;
    integrationsAPI.deletePause(this.state.location.id, body)
      .then(() => {
        this.removeRecentlyUnpausedIntegrationsFromPausedIntegrationsList(this.state.selectedPause.integrations);
        this.fetchPausesAndFulfillments();
        this.toggleDrawer();
      }).catch(error => console.log(error))
  }

  removeRecentlyUnpausedIntegrationsFromPausedIntegrationsList = recentlyUnpausedIntegrations => {
    let tempLocationInState = _.cloneDeep(this.state.location);
    tempLocationInState.paused_integrations = _.difference(tempLocationInState.paused_integrations, recentlyUnpausedIntegrations);
    this.setState({ location: tempLocationInState});
  }

  updateRecentPauses = recentlyPausedIntegrations => {
    let tempLocationInState = _.cloneDeep(this.state.location);
    tempLocationInState.paused_integrations = _.concat(tempLocationInState.paused_integrations, recentlyPausedIntegrations);
    this.setState({ location: tempLocationInState});
  }

  toggleSelectFulfillment(fulfillment) {
    if (this.state.selectedFulfillments.includes(fulfillment)) {
      let fulfillments = this.state.selectedFulfillments;
      let filtered = fulfillments.filter(selectedFulfillment =>
        fulfillment !== selectedFulfillment
      );
      this.setState({
        selectedFulfillments: filtered
      });
    } else {
      let fulfillments = this.state.selectedFulfillments;
      fulfillments.push(fulfillment);
      this.setState({
        selectedFulfillments: fulfillments
      });
    }
  }

  selectedCheck(fulfillment) {
    return this.state.selectedFulfillments.includes(fulfillment.integration)
  }

  integrationName = (type) => {
    switch (type) {
      case "basic_delivery":
        return "In-House Delivery";
      case "basic_pickup":
        return "Pickup";
      case "curbside_pickup":
        return "Curbside Pickup";
      case "uber":
        return "Uber";
      case "doordash":
        return "DoorDash";
      default:
        return "";
    }
  }

  displayDate(dateTime) {
    let day = moment(dateTime).format('YYYY-MM-DD');
    let time = moment(dateTime).format('hh:mm a');
    let today = moment().format('YYYY-MM-DD');
    let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    if (day === today) {
      return `Today at ${time}`;
    } else if (day === tomorrow) {
      return `Tomorrow at ${time}`;
    } else {
      return `${moment(dateTime).format('dddd, MMMM Do YYYY')} at ${time}`;
    }
  }

  toggleEnabled = () => {
    const location = this.state.location;
    if(this.state.enable_order_pausing === true && this.state.pausedFulfillments.length) {
      this.setState({
        submitText: 'Continue',
        cancelText: 'Cancel',
        drawerContent: 'disable',
        title: "Disable Pausing Orders",
      });
      this.toggleDrawer('disable');
    }else{
      let is_enabled = this.state.enable_order_pausing === true ? false : true;
      this.setState({
        enable_order_pausing: is_enabled
      });
      const values = JSON.stringify({'enable_order_pausing': is_enabled});
      tenantAPI.updateLocation(location.id, values)
        .then(response => this.props.onUpdate(response));
    }
  };

  toggleDrawer = (type) => {
    let drawerOpen = !this.state.drawerOpen;
    let drawerContent = type;
    this.setState({ drawerContent, drawerOpen, errorMessage: '' });
  };

  render() {
    let {availableFulfillments, pausedFulfillments} = this.state;

    return (
      <div>
        <AdminCard
          title="Enable/Disable Pausing Mobile/Online Ordering"
          description="Pause the influx of incoming mobile/online orders until you choose to unpause mobile/online orders."
          linkText="Pause Orders"
          largeTitle
          onHeaderActionClick={this.toggleEdit}
          showLink={!this.state.enable_order_pausing}
        >
          <AdminToggle
            className="app-toggle"
            checked={this.state.enable_order_pausing}
            onChange={this.toggleEnabled}
          />
          {this.state.enable_order_pausing ?
            <AdminSectionHeader
              title="Fulfillment Status"
            >
              <Text weight="bold" small className="top-margin-10">Currently Accepting Orders</Text>
              <div className="display-in-line" style={{width: "70%"}}>
                {availableFulfillments.length ? availableFulfillments.map((item, index) =>
                  <div key={index} className="top-margin-5 display-in-line" style={{marginRight: '15px', alignItems: 'baseline'}}>
                    <div className="circle-padding">
                      <FontAwesomeIcon className='brightGreen' size="sm" icon={['fas', 'circle']}/>
                    </div>
                    <Text medium>{item ? this.integrationName(item.integration) : null}</Text>
                  </div>
                ) : <Text>No order types currently available</Text>}
              </div>
              <Text weight="bold" small className="top-margin-10">Currently Not Accepting Orders</Text>
              <div className="top-margin-5 align-items">
                {pausedFulfillments.length ? pausedFulfillments.map((item, index) =>
                  <div key={index} className="all-orders-accepted top-margin-10">
                    <div style={{padding: 15}}>
                      <div className="display-in-line" style={{justifyContent: 'space-between'}}>
                        <div className="display-in-line">
                          <div className="alarm-padding">
                            <FontAwesomeIcon className='disabledGrey' size="lg" icon={['fas', 'alarm-snooze']}/>
                          </div>
                          <div>
                            <Text medium weight="bold">
                              Restart: Until I Unpause
                            </Text>
                            <span className='disabledGrey medium-text'>Stopped: {this.displayDate(item.start_time)}</span>
                            {item.integrations.map((integration, index) =>
                              <div key={index} className="top-margin-5 display-in-line">
                                <div className="circle-padding">
                                  <FontAwesomeIcon className='ordrslipRed' size="sm" icon={['fas', 'circle']}/>
                                </div>
                                <Text medium>{this.integrationName(integration)}</Text>
                              </div>
                            )}
                          </div>
                        </div>
                        <div onClick={this.showResumeOrders(item)} style={{alignSelf: 'center', cursor: 'pointer'}}>
                          <div style={{paddingLeft: 5}}>
                            <FontAwesomeIcon className='brightGreen' size="2x" icon={['fas', 'play-circle']}/>
                          </div>
                          <Text small style={{textAlign: "center"}}>Resume</Text>
                          <Text small style={{textAlign: "center"}}>Orders</Text>
                        </div>
                      </div>
                    </div>
                  </div>) :
                  <div className="all-orders-accepted">
                    <div style={{padding: 15}}>
                      All order types are being accepted.
                    </div>
                </div>}
              </div>
            </AdminSectionHeader>
          : null}
        </AdminCard>
        <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer} classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}>
          <EditDrawer
            cancelText={this.state.cancelText}
            submitText={this.state.submitText}
            title={this.state.title}
            isSubmitting={false}
            onSubmit={this.handleFormSubmit}
            noFooter={this.state.drawerContent === 'edit'}
            fixedFooter={['resume', 'disable'].includes(this.state.drawerContent)}
            onClose={this.toggleDrawer}
          >
            {this.state.drawerContent === 'edit' ?
            <div>
              <AdminCard
                title="Fulfillment Selection"
                style={{ marginTop: "15px" }}
                description="Choose which order type(s) to pause:"
              >
                {this.state.errorMessage && typeof this.state.errorMessage == "string" && (
                  <div className="form-error-message display-in-line center-items">
                    <ErrorOutline className="error-icon" />
                    {this.state.errorMessage}
                  </div>
                )}
                {this.state.availableFulfillments.length ? this.state.availableFulfillments.map((fulfillment, index) =>
                  <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                    <AdminCheckbox
                        checked={this.selectedCheck(fulfillment)}
                        onChange={() => this.toggleSelectFulfillment(fulfillment.integration)}
                    />
                    <FieldLabel>{this.integrationName(fulfillment.integration)}</FieldLabel>
                  </div>
                ) : null}
                {this.state.pausedFulfillments.length ? this.state.pausedFulfillments.map(pause =>
                pause.integrations.map(integration =>
                <div className="display-in-line" style={{justifyContent:'space-between', alignItems:'center'}}>
                  <div style={{display: 'flex', alignItems: 'center'}} key={integration}>
                      <AdminCheckbox disabled />
                    <FieldLabel className='disabledGrey'>{this.integrationName(integration)}</FieldLabel>
                  </div>
                  <span className='disabledGrey small-text'>Order type is currently paused</span>
                </div>
                )) : null}
              </AdminCard>
              <AdminCard
                title="Time Selection"
                description={
                <div>
                  Choose how long to stop accepting orders.
                  <div className='disabledGrey small-text'>More options coming soon</div>
                </div>}
              >
                <div>
                  <div className="app-card-content-wrapper">
                    {store.error && typeof store.error == "string" && (
                      <div className="form-error-message display-in-line center-items">
                        <ErrorOutline className="error-icon" />
                        {store.error}
                      </div>
                    )}
                  </div>
                  <div className="top-margin-5 display-in-line" style={{marginBottom: '10px'}}>
                    <Text className="display-in-line" medium weight="bold">
                      Order Type(s):
                      {this.state.selectedFulfillments.map((integration, index) =>
                        <p key={ index } style={ { paddingLeft: 2 } } className="display-in-line ordrslipRed">
                          {this.integrationName(integration)}
                          {index < this.state.selectedFulfillments.length - 1 ? <span>,</span> : null}
                        </p>
                      )}
                    </Text>
                  </div>
                  <div className="top-margin-10 display-in-line" style={{ alignItems: 'baseline' }}>
                    <div className="circle-padding">
                      <FontAwesomeIcon className='brightGreen' size="sm" icon={['fas', 'circle']}/>
                    </div>
                    <Text medium>Until I Unpause</Text>
                  </div>
                </div>
              </AdminCard>
              <AdminCard title="Pause Orders Confirmation">
                <div>
                  <div className="app-card-content-wrapper">
                    {store.error && typeof store.error == "string" && (
                      <div className="form-error-message display-in-line center-items">
                        <ErrorOutline className="error-icon" />
                        {store.error}
                      </div>
                    )}
                  </div>
                  <div className="top-margin-5 display-in-line">
                    <div className="alarm-padding">
                        <FontAwesomeIcon className='disabledGrey' size="lg" icon={['fas', 'alarm-snooze']}/>
                    </div>
                    <Text large weight="semi-bold">Restart:</Text><Text className="pl-1" large> Until I Unpause</Text>
                  </div>
                  {this.state.selectedFulfillments.map((integration, index) =>
                    <div key={index} className="top-margin-5 display-in-line"
                        style={{alignItems: 'baseline', marginLeft: '25px', marginTop: '5px'}}>
                      <div className="circle-padding">
                        <FontAwesomeIcon className='ordrslipRed' size="sm" icon={['fas', 'circle']}/>
                      </div>
                      <Text medium>{this.integrationName(integration)}</Text>
                    </div>
                  )}
                  <div className="top-margin-10">
                    <Text>Pausing orders will not affect the orders already scheduled or in the queue.</Text>
                    <Text className="pt-2">Do you want to continue to pause new orders?</Text>
                  </div>
                  <div className="column is-narrow pl-0">
                    <Button
                      type="button"
                      onClick={this.toggleDrawer}
                    >
                      Do Not Pause
                    </Button>
                    <Button
                      primary
                      type="submit"
                      disabled={this.state.isSubmitting}
                      onClick={this.handleFormSubmit}
                    >
                      Pause Orders
                    </Button>
                  </div>
                </div>
              </AdminCard>
            </div> : this.state.drawerContent === 'resume' ?
              <AdminCard title="Resume Orders Confirmation">
                <div className="app-card-content-wrapper">
                  {store.error && typeof store.error == "string" && (
                    <div className="form-error-message display-in-line center-items">
                      <ErrorOutline className="error-icon" />
                      {store.error}
                    </div>
                  )}
                </div>
                <div>
                  <Text>You will be resuming orders for:</Text>
                </div>
                {this.state.selectedPause.integrations ? this.state.selectedPause.integrations.map((integration, index) =>
                  <div 
                    key={index}
                    className="top-margin-5 display-in-line"
                    style={{alignItems: 'baseline', marginLeft: '25px', marginTop: '5px'}}
                  >
                    <div className="circle-padding">
                      <FontAwesomeIcon className='ordrslipRed' size="sm" icon={['fas', 'circle']}/>
                    </div>
                    <Text medium>{this.integrationName(integration)}</Text>
                  </div>
                ) : null}
                <Text>Would you like to continue?</Text>
              </AdminCard> : this.state.drawerContent === 'disable' ?
              <AdminCard title="Pausing Orders In Use">
                  <Text>
                    This feature is currently in use. Would you like to resume all active pauses and disable this feature?
                    <Text className="pl-1 is-inline" weight="bold">Note: orders can possibly start coming in immediately after disabling this feature.</Text>
                  </Text>
              </AdminCard>: null}
          </EditDrawer>
          </Drawer>
      </div>
    );
  }
}

export default PauseOrdering;
