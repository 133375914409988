import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import _ from "lodash";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination } from "@material-ui/core";

import { getStartOfDayByDate, getEndOfDayByDate } from "../services/utils";
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminCard from '../components/elements/adminCard';
import ReportsCustomHeader from "../components/reports/reportsCustomHeader";
import AutocompleteVerbiage from '../components/reports/autcompleteVerbiage';
import reportsAPI from '../services/api/reportsAPI';
import tenantAPI from '../services/api/tenantAPI';
import store from '../store/store';


class ReportsUserSummary extends Component {
  constructor() {
    super();
    this.state = {
      locations: store.locations,
      fromDate: new Date(moment().subtract(1, "month")),
      toDate: new Date(),
      today: moment(),
      userReports: {},
      rowsPerPage: 10,
      currentPage: 0,
    };
  }

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(locations => {
        this.setState({ locations });
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format('MM/DD/YYYY');
        this.formatReports(locations, monthAgo, todaysDate);
      });
  }

  formatReports(locations, fromDate, toDate) {
    return new Promise(resolve => {
      let userReports = [];
      _.forEach(locations, (location, index) => {
        reportsAPI.getLocationUsers(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location.id)
          .then(data => {
            let locReport = {
              'location': {
                name: location.name,
                id: location.id
              },
              'reports': data
            };
            userReports.push(locReport);
            this.setState({ userReports });
          })
          .catch(err => console.error(err));
      });
      resolve(userReports);
    });
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format('MM/DD/YYYY');
    if (type === 'fromDate') {
      this.setState({fromDate: displayDate}, () => {
        this.formatReports(this.state.locations, newDate, this.state.toDate);
      });
    } else if (type === 'toDate') {
      this.setState({toDate: displayDate}, () => {
        this.formatReports(this.state.locations, this.state.fromDate, newDate);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { userReports, rowsPerPage, currentPage } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="User Summary"
          mainNav="Reports"
          subNav="Location Reports"
          description="View the new and returning user numbers to your app."
        />
        <AutocompleteVerbiage />
        <AdminCard
          title=""
          customHeader={
            <ReportsCustomHeader
              fromDate={ this.state.fromDate }
              toDate={ this.state.toDate }
              pickerWidth="30%"
              handleChange={ this.handleChange }
          />}
        >
          <Table className="app-table-wrapper collapsible mt-0">
            <TableHead className="app-table-header">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell" align="left">Locations</TableCell>
                <TableCell className="app-table-cell" align="left">New Customers</TableCell>
                <TableCell className="app-table-cell" align="left">Total Customers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="app-table-body">
              { rowsPerPage > 0 && userReports &&
                userReports.length > 0 &&
                userReports.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) &&
                userReports.map((report, index) => (
                  <React.Fragment key={ index }>
                    <TableRow key={ report.location.id } className="app-table-row">
                      <TableCell className="app-table-cellcursor-pointer">
                        { report.location.name }
                      </TableCell>
                      <TableCell className="app-table-cell" align="left">
                        { report.reports.total_new_users }
                      </TableCell>
                      <TableCell className="app-table-cell" align="left">
                        { report.reports.total_active_users }
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )) }
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={ [10, 25, 50] }
                  count={ userReports && userReports.length ? userReports.length : 0 }
                  rowsPerPage={ rowsPerPage }
                  onRowsPerPageChange={ (event) => this.updateRows(event) }
                  page={ currentPage }
                  onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                  style={ { borderBottom: 'none' } }
                />
              </TableRow>
            </TableBody>
          </Table>
        </AdminCard>
      </div>
    );
  }
}

export default observer(ReportsUserSummary);
