import React, { Component } from "react";
import { observer } from "mobx-react";
import { toJS } from 'mobx';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';

import store from '../store/store';
import EditDrawer from './elements/editDrawer';
import HoursEditCard from './hoursEditCard';
import { errorMessage, weekdays } from '../services/utils';
import tenantAPI from '../services/api/tenantAPI';


class HoursEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            error: '',
            hours: [],
            payload: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        let hours = this.state.hours;
        weekdays.forEach(day => {
            let previousDay = _.find(toJS(hours), { weekday: day });
            let newDay = _.find(toJS(nextProps.hours), { weekday: day });

            if (previousDay) { // Update existing object
                previousDay.start_time = newDay ? newDay.start_time : null;
                previousDay.end_time = newDay ? newDay.end_time : null;
                previousDay.closed = !Boolean(newDay && newDay.start_time);
            }
            else { //Create new object
                hours.push({
                    weekday: day,
                    start_time: newDay ? newDay.start_time : null,
                    end_time: newDay ? newDay.end_time : null,
                    closed: !Boolean(newDay && newDay.start_time)
                });
            }
        })
        this.setState({hours});
    }

    handleSubmit = () => {
        let hoursName = `${ (this.props.hoursType).toLowerCase() }_hours`; // Create "store_hours" etc object for api
        let payload = { [hoursName]: _.filter(store.selectedHours, (item => { return item.start_time; })) }; // Filter out null hours for api
        payload[hoursName].forEach(item => delete item.closed); // Remove 'closed' property for api
        this.setState({ payload: payload[hoursName] }); // Save the payload to compare to error messages

        tenantAPI.updateLocation(this.props.locationId, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                    if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                }
                else {
                    this.props.onUpdate(response);
                    this.props.onClose();
                }
            });
    };

    fieldError = (day) => {
        // Errors are returned in an array, we must match the index to the index
        // of the day in the saved payload to show the error message on the correct row
        if (this.state.apiErrors.hasOwnProperty(`${(this.props.hoursType).toLowerCase()}_hours`)) {
            if (this.state.payload.length > 0) {
                let dayIndex = this.state.payload.findIndex(item => {
                    return item.weekday === day
                })
                if (dayIndex > -1) {
                    let error = this.state.apiErrors.store_hours[dayIndex]
                    if (error.message) {
                        let msg = JSON.parse(error.message).__all__;
                        if (msg) {
                            return (
                                <div className="red-error-text" style={{marginTop: -12}}>
                                    {msg[0].message}
                                </div>
                            );
                        }
                    }
                }
            }
        }
        return null;
    };

    render() {
        const { open, hoursType } = this.props;

        return (
            <Drawer
                anchor="right"
                open={open}
                classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}
                onClose={ this.props.onClose }
            >
                <EditDrawer
                    title={`Edit ${hoursType} Hours`}
                    onClose={ this.props.onClose }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(this.state.apiErrors) }
                >
                    <HoursEditCard
                        apiErrors={ this.state.apiErrors }
                        error={ this.state.error }
                        hours={ this.state.hours }
                        hoursType={ hoursType }
                        payload={ this.state.payload }
                    />
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(HoursEdit);
