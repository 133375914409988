import React, { Component } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AdminDropdownField from '../elements/adminDropdownField';
import HoursInput from '../elements/hoursInput';
import { colors } from "../../styles/global";
import { parseHours } from "../../services/utils";
import AdminCheckbox from './adminCheckbox';


class HoursInputRow extends Component {
    render() {
        const {
            customLabel,
            disabled,
            hoursType,
            labelText,
            onChange,
            openTime,
            closeTime,
            toggleChecked,
            fromName,
            toName,
            hasError,
            openPlaceHolder,
            closedPlaceHolder,
            closedTitle,
            checkedValue,
        } = this.props

        return (
            <div className="app-field-wrapper">
                <label className="app-field-label">
                    {labelText}
                </label>
                {customLabel && customLabel}
                <div className="columns is-multiline">
                    <div className="column is-narrow">
                        <HoursInput
                            id={hoursType}
                            name={`${fromName}_timestamp`}
                            value={parseHours(openTime)}
                            onChange={(event) => onChange(event)}
                            placeholder={openPlaceHolder ? openPlaceHolder : 'Open'}
                            style={{ width: 75, marginTop: 0 }}
                            disabled={disabled}
                            hasError={hasError}
                        />
                    </div>
                    <div className="column is-narrow pl-0">
                        <AdminDropdownField
                            id={hoursType}
                            name={`${fromName}_ampm`}
                            onChange={(event) => onChange(event)}
                            value={parseHours(openTime, true)}
                            disabled={disabled}
                            hasError={hasError}
                        >
                            <option>AM</option>
                            <option>PM</option>
                        </AdminDropdownField>
                    </div>
                    <div className="column is-narrow">
                        <HoursInput
                            id={hoursType}
                            name={`${toName}_timestamp`}
                            value={parseHours(closeTime)}
                            onChange={(event) => onChange(event)}
                            placeholder={closedPlaceHolder ? closedPlaceHolder : 'Close'}
                            style={{ width: 75, marginTop: 0 }}
                            disabled={disabled}
                            hasError={hasError}
                        />
                    </div>
                    <div className="column is-narrow pl-0">
                        <AdminDropdownField
                            id={hoursType}
                            name={`${toName}_ampm`}
                            onChange={(event) => onChange(event)}
                            value={parseHours(closeTime, true) || "PM"}
                            disabled={disabled}
                            hasError={hasError}
                        >
                            <option>AM</option>
                            <option>PM</option>
                        </AdminDropdownField>
                    </div>
                    <div className="column is-narrow">
                        <FormControlLabel
                            control={
                                <AdminCheckbox
                                    checked={checkedValue !== undefined ? checkedValue : disabled}
                                    onChange={() => toggleChecked(hoursType)}
                                    name={`${hoursType}_closed`}
                                />
                            }
                            label={closedTitle ? closedTitle : "Closed"}
                            style={{ marginTop: "-5px", color: colors.disabledGrey }}
                        />
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default HoursInputRow;
