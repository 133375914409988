import styled from "styled-components";
import { colors } from "../../styles/global";

export const HeaderText = styled.div`
    font-size: ${props =>
        props.small ? "14px" :
        props.medium ? "16px" :
        props.large ? "24px" :
        "16px"
    };
    font-family: ${props => props.large ? `'SofiaProBlack', sans-serif` : `'MulishExtraBold', sans-serif;`};
    color: ${props => props.color ? props.color : colors.primaryBlack};
`

export const Text = styled.div`
    font-size: ${props =>
        props.small ? "12px" :
        props.medium ? "14px" :
        props.large ? "16px" :
        props.huge ? "24px" :
        "14px"
    };
    font-family: ${props => props.weight === "bold" ? `'MulishExtraBold', sans-serif;` : props.weight === "semi-bold" ? `'MulishBold', sans-serif;` : `'Mulish', sans-serif;`};
    color: ${props => props.color ? props.color : colors.primaryBlack};
    &:hover {
        background-color: ${props => props.hover ? colors.editHighlight : 'transparent'};
        text-decoration: none;
    }
`

export const DarkSubHeading = styled.div`
    font-size: 14px;
    color: ${colors.primaryBlack};
    font-family: 'MulishBold', sans-serif;
`

export const AdminSpan = styled.span`
    font-size: ${props =>
        props.small ? "12px" :
        props.medium ? "14px" :
        props.large ? "16px" :
        "14px"
    };
    font-family: ${props => props.weight === "bold" ? `'MulishBold', sans-serif;` : `'Mulish', sans-serif;`};
`

export const BlueText = styled(Text)`
    color: ${colors.secondaryBlue};
    text-decoration: ${props => props.underline ? "underline" : "none"};
`