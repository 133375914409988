import React from "react";
import moment from "moment";
import { toJS } from "mobx";
import _ from "lodash";
import store from "../store/store"
import websocketClient from "./websocketClient"
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../components/loadingSpinner";
import { Text } from "../components/elements/text";
import AdminToolTip from '../components/elements/adminToolTip';


export const parseHours = (timestamp, amPm) => {
  // Returns a time or am/pm
  if (timestamp) {
    if (timestamp.includes('x')) {
      return timestamp
    } else if (amPm) {
      return moment(timestamp, 'HH:mm:ss').format('a').toUpperCase();
    }
    else return moment(timestamp, 'HH:mm:ss').format('hh:mm');
  } else {
    return '';
  }
}

export const formatTimeForView = time => {
  if (time) return moment(time, "HH:mm:ss").format("h:mm A");
  else return null;
};

export const formatDateTimeForView = datetime => {
  const date = moment(datetime).local().format('MMM D, YYYY');
  const time = moment(datetime).local().format('h:mm a');
  return `${date}, ${time}`;
};

export const formatDateForView = date => {
  const formattedDate = moment(date).format("MM/DD/YYYY");
  return formattedDate;
};

export const createTimeOptions = () => {
  const periodsInADay = moment.duration(1, "day").as("minutes");
  const timeOptions = [];
  const startTimeMoment = moment("0000", "HH:mm:ss");
  for (let i = 0; i < periodsInADay; i += 15) {
    startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
    timeOptions.push(startTimeMoment.format("HH:mm:ss"));
  }
  timeOptions.push("23:59:59");
  return timeOptions;
};

export const getAvailabilityOptions = hours => {
  const weekdays = [
    { weekday: "Monday" },
    { weekday: "Tuesday" },
    { weekday: "Wednesday" },
    { weekday: "Thursday" },
    { weekday: "Friday" },
    { weekday: "Saturday" },
    { weekday: "Sunday" }
  ];
  const availability = _.cloneDeep(hours);
  _.map(availability, a => {
    delete a.id;
    return a;
  });
  let availabilityOptions = _.unionBy(
    toJS(availability),
    weekdays,
    "weekday"
  );
  availabilityOptions = sortHoursByDay(availabilityOptions);
  return availabilityOptions;
};

export const getLocationNameById = (locationId) => {
  return _.find(store.locations, { id: locationId }).name;
};

export const getNameById = (id, list_name) => {
  let name_field = 'name';
  if (list_name === 'users') name_field = 'full_name';
  return _.find(store[list_name], {id: id})[name_field]
}

export const getAvailableAtLocations = item => {
  let availLocs = store.locations.filter(function (loc) {
    return !item.hide_at_locations.includes(loc.id);
  });
  return availLocs;
};

export const formatUserRoleForView = role => {
  let updated = role.charAt(0).toUpperCase() + role.slice(1);
  if (updated === "User") updated = "Customer";
  return updated;
};

export const createNumberOptions = (first, last, increment) => {
  let options = [];
  for(let i = first; i <= last; i = i + increment) {
    options.push(i);
  }
  return options;
}

export const formatLocationHours = locations => {
  const hoursPropertyKeys = ['store_hours', 'pickup_hours','delivery_hours'];
  // for each location...
  _.forEach(locations, location => {
    // for each hours property (store, pickup, and delivery)...
    _.forEach(hoursPropertyKeys, hoursPropertyKey => {
      location[hoursPropertyKey] = sortHoursByDay(location[hoursPropertyKey]);
    })
  })
  return locations;
}

export const formatMenuHours = menus => {
  // for each menu...
  _.forEach(menus, menu => {
    menu['availability'] = sortHoursByDay(menu['availability']);
  })
  return menus;
}

export const sortHoursByDay = hours => {
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  // sort the hours objects by their weekday property, based on an array of weekdays in the correct order
  let sortedHours = [];
  _.forEach(weekdays, weekday => {
    let day = _.find(hours, { 'weekday': weekday });
    if (day) sortedHours.push(day);
  })
  return sortedHours;
}

export const getStartOfDayByDate = date => {
  let startOfDay = moment(date, "MM/DD/YYYY").startOf('day').utc().format("YYYY-MM-DD HH:mm:ss");
  return startOfDay;
}

export const getEndOfDayByDate = date => {
  let endOfDay = moment(date, "MM/DD/YYYY").endOf('day').utc().format("YYYY-MM-DD HH:mm:ss");
  return endOfDay;
}

export const EXTERNAL_FULFILLMENT_NAMES = {
  "basic_delivery": "In-House Delivery",
  "basic_pickup": "Pickup",
  "curbside_pickup": "Curbside",
  "doordash": "DoorDash",
  "uber": "Uber",
}

export const addIdsToTableData = tableData => {
  let tableDataWithIds = _.cloneDeep(tableData);
  _.map(tableDataWithIds, (obj, index) => {
    obj.id = index;
  });
  return tableDataWithIds;
}

export const formatDateTimeForTableView = dateTime => {
  return moment(dateTime).format('ddd MMM D, YYYY');
}

export const displayUserType = (userType, byLocation) => {
  if (byLocation.length) {
    return "By Location";
  } else if (userType === "user") {
    return "All Customers";
  } else if (userType === "all") {
    return "All Users";
  } else if (userType === "staff") {
    return "All Staff";
  } else if (userType === "admin") {
    return "All Admin";
  }
}


export const logout = (props, updateUser=true) => {
  // Disconnect any open websockets on logout
  store.updatewebsocketShouldReopen(false);
  if (websocketClient.socket && websocketClient.socket.readyState === 1) websocketClient.socket.close();
  props.history.push('/');
  store.reset(updateUser);
}

export const errorMessage = (errors, msg) => {
  if (!_.isEmpty(errors) || msg) {
    return (
      <div style={{ marginBottom: 20 }}>
        <div className="form-error-message">
          <ErrorOutline className="error-icon" />
          {msg ? msg : "Highlighted fields need your attention"}
        </div>
      </div>
    );
  }
  return (null);
};

export const fieldError = (type, errors) => {
  if (errors[type] && errors[type][0]) {
    return (
      <div className="red-error-text">
        {errors[type][0].message}
      </div>
    );
  } else {
    return null;
  }
};

export const formatPhone = (phone) => {
  return phone
    .substr(2)
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export const validatePassword = (password) => {
  let passwordError = '',
    number, upper, lower;

  if (password.length > 56) {
    passwordError = "Password is too long";
  }
  if (/^(?=.*\d)/.test(password)) {
    number = true;
  } else {
    number = false;
  }
  if (/^(?=.*[A-Z])/.test(password)) {
    upper = true;
  } else {
    upper = false;
  }
  if (/^(?=.*[a-z])/.test(password)) {
    lower = true;
  } else {
    lower = false;
  }
  if (password.length < 8 || password === '') {
    passwordError = "Password is too short";
  } else {
    passwordError = '';
  }

  return {
    passwordError: passwordError,
    lower: lower,
    upper: upper,
    number: number
  };
}

export const displayImageName = (file, fileUrl, item) => {
  if (fileUrl) {
    return file.name;
  } else {
    if (item.image) {
      const regex = /([^\/]+$)/;
      const match = regex.exec(item.image);
      return match.length ? match[0] : match;
    } else {
      return "No File Chosen";
    }
  }
};

export const getSortIcon = (bool) => {
  let sortIcon = bool ? (
    <FontAwesomeIcon
      size="1x"
      className="ordrslipRed ml-3"
      icon={["fas", "caret-up"]}
    />
  ) : (
    <FontAwesomeIcon
      size="1x"
      className="ordrslipRed ml-3"
      icon={["fas", "caret-down"]}
    />
  );
  return sortIcon;
}

export const loadingSpinner = (message) => {
  return (
    <div className="loading-box" style={{ marginTop: 0 }}>
      <div className="spinner-wrapper">
        <LoadingSpinner />
      </div>
      <div className="text-wrapper spinner-wrapper">
        {message ? message : 'Loading...'}
      </div>
    </div>
  );
}

export const displayHours = (day, availability) => {
  let menuDay = _.find(toJS(availability), { weekday: day });
  if (menuDay) {
    return (
      <span>{ formatTimeForView(menuDay.start_time) } - { formatTimeForView(menuDay.end_time) }</span>
    );
  } else {
    return 'N/A';
  }
};

export const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const getMaxRedemptions = (promoCode) => {
  switch (promoCode.max_redemptions_per_user) {
      case null:
          return "Unlimited Times"
      case 1:
          return "One Time"
      default:
          return `${promoCode.max_redemptions_per_user} Times`;
  }
};

export const getLocations = (promoCode) => {
  if (promoCode && promoCode.locations) {
      if (promoCode.locations.length) {
          return (
              <div className='columns is-mobile is-vcentered is-multiline pl-0'>
                  {promoCode.locations.map((location, index) => (
                      <div
                          key={index}
                          style={{ marginLeft: 0 }}
                          className="description column is-half pb-0 pt-2"
                      >
                          <div className="columns is-mobile is-vcentered">
                              <div style={{ paddingRight: 10 }} className="column is-one-fifth">
                                  <FontAwesomeIcon className="green-font" size="1x" icon={['fal', 'check']} />
                              </div>
                              <div
                                  className="column is-three-fifths"
                                  style={{ paddingLeft: 0, paddingRight: 10 }}>
                                  {getLocationNameById(location, store.locations)}
                              </div>
                          </div>
                      </div>
                  ))}
              </div>
          );
      }
      return (<div className="description">
                  <Text>Valid at all locations.</Text>
              </div>)
  }
  return null;
}

export const showDateRange = (sale) => {
  let end_time = !sale.end_time ? 'Ongoing' : formatDateForView(sale.end_time);
  return formatDateForView(sale.start_time) + ' - ' + end_time;
}

export const getFulfillment = (fulfillments, integration) => {
  return fulfillments.filter(fulfillment => {
    return fulfillment.integration === integration;
  }).map(fulfillment => {
    let location_names = [];
    if (fulfillment.locations.length > 0) {
      fulfillment.locations.forEach(location => {
        location_names.push(getLocationNameById(location, store.locations).toLowerCase());
      });
    }
    fulfillment.location_names = location_names;
    return fulfillment;
  });
};

export const showLocations = item => {
  return (
    <React.Fragment>
      { getLocationNameById(item.locations[0]) }
      { item.locations.length > 1 ?
        <AdminToolTip
          interactive
          arrow
          icon={ <span className="ordrslipRed"> +{ item.locations.length - 1 }</span> }
          title={
            <Text medium>
              { item.locations.length && item.locations.map((location, index) =>
                <div key={ location }>
                  { getLocationNameById(location) }
                </div>
              ) }
            </Text>
          }
        /> : null }
    </React.Fragment>
  );
};
