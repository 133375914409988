import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import { HeaderText } from '../components/elements/text';
import tenantAPI from '../services/api/tenantAPI';
import { Button } from '../components/elements/buttons';
import ThemeEdit from '../components/tenant/themeEdit';


class Themes extends Component {
  constructor(props) {
    super(props);
    this.state = {
        currentPage: 0,
        drawerType: 'create',
        inFocus: '',
        open: false,
        orderBy: 'name',
        order: 'desc',
        rowsPerPage: 10,
        rowCollapsed: {},
        themes: store.themes,
        selectedTheme: {}
    };
  }

  componentDidMount() {
    this.getThemes();
  };

    getThemes = () => {
        tenantAPI.getRecursiveThemes({ page: 1 })
            .then(() => {
                this.setState({ themes: store.themes });
            });
    };

    updateRows(event) {
        this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
    };

    updatePage(event, newPage) {
        this.setState({ currentPage: newPage });
    };

    editTheme = theme => {
        this.setState({ selectedTheme: theme });
        this.toggleDrawer('edit');
    };

    handleSort(value) {
        let { themes } = this.state;
        let orderBy = value;
        let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
        if (value === 'name') themes = _.orderBy(themes, [theme => theme[value].toLowerCase()], [order]);
        else themes = _.orderBy(themes, [theme => theme[value]], [order]);
        this.setState({ themes, order, orderBy, currentPage: 0 });
    };

    getSortIcon(orderBy) {
        let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
            :
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
        return sortIcon;
    };

    toggleDrawer(drawerType) {
        if (drawerType === 'create') this.setState({ selectedTheme: {} });
        this.setState({ drawerType, open: !this.state.open });
    };

    handleDialogClose = updated => {
        this.setState({ selectedTheme: {} });
        this.toggleDrawer('');
        if (updated) this.getThemes();
    };

    toggleRow(theme) {
        const rowCollapsed = theme.id === this.state.rowCollapsed ? {} : theme.id;
        this.setState({ rowCollapsed });
    };

  render() {
      const { themes, order, orderBy, rowsPerPage, currentPage } = this.state;
        return (
            <div className='admin-page-wrapper'>
                <AdminPageHeader
                    title='Themes'
                    mainNav='Configuration'
                    subNav='App Customization'
                    description='Customize the look of your app to provide a better experience for your customers.'
                    headerActionText="Add New"
                    onHeaderActionClick={ () => this.toggleDrawer('create') }
                />
                <div>
                    <AdminCard>
                        <Table className='app-table-wrapper'>
                            <TableHead className='app-table-header'>
                                <TableRow className='app-table-row'>
                                    <TableCell className='app-table-cell'>
                                        <TableSortLabel
                                            active={ orderBy === 'name' }
                                            direction={ orderBy === 'name' ? order : 'asc' }
                                            onClick={ () => this.handleSort('name') }
                                            IconComponent={ () => this.getSortIcon('name') }
                                        >
                                            Theme Name
                                            { orderBy === 'name' ? (
                                                <span className='is-hidden'>
                                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                </span>
                                            ) : null }
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className='app-table-cell'>
                                        <TableSortLabel
                                            active={ orderBy === 'is_default' }
                                            direction={ orderBy === 'is_default' ? order : 'asc' }
                                            onClick={ () => this.handleSort('is_default') }
                                            IconComponent={ () => this.getSortIcon('is_default') }
                                        >
                                            Default
                                            { orderBy === 'is_default' ? (
                                                <span className='is-hidden'>
                                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                </span>
                                            ) : null }
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className='app-table-cell'>
                                        <TableSortLabel
                                            active={ orderBy === 'is_active' }
                                            direction={ orderBy === 'is_active' ? order : 'asc' }
                                            onClick={ () => this.handleSort('is_active') }
                                            IconComponent={ () => this.getSortIcon('is_active') }
                                        >
                                            Mobile App
                                            { orderBy === 'is_active' ? (
                                                <span className='is-hidden'>
                                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                </span>
                                            ) : null }
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className='app-table-cell'>
                                        <TableSortLabel
                                            active={ orderBy === 'is_online' }
                                            direction={ orderBy === 'is_online' ? order : 'asc' }
                                            onClick={ () => this.handleSort('is_online') }
                                            IconComponent={ () => this.getSortIcon('is_online') }
                                        >
                                            Online Ordering
                                            { orderBy === 'is_online' ? (
                                                <span className='is-hidden'>
                                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                </span>
                                            ) : null }
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className='app-table-cell' style={ { width: '20%' } } />
                                </TableRow>
                            </TableHead>
                            <TableBody className='app-table-body'>
                                { themes.length ?
                                    <React.Fragment>
                                        { (rowsPerPage > 0 ? themes.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : themes).map(theme => (
                                            <React.Fragment key={ theme.id }>
                                                <TableRow
                                                    className={ `app-table-row ${ this.state.inFocus === theme.id && this.state.rowCollapsed !== theme.id ? 'highlighted-row ' : '' }` }
                                                    onMouseOver={ () => this.setState({ inFocus: theme.id }) }
                                                >
                                                    <TableCell className={ `app-table-cell pl-0 ${ this.state.rowCollapsed === theme.id ? 'remove-row-border' : '' }` }>
                                                        <IconButton
                                                            aria-label='expand row'
                                                            onClick={ () => { this.toggleRow(theme); } }
                                                            className='p-0 pr-3'
                                                        >
                                                            { this.state.rowCollapsed === theme.id ?
                                                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } /> :
                                                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-right'] } /> }
                                                        </IconButton>
                                                        { theme.name }
                                                    </TableCell>
                                                    <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === theme.id ? 'remove-row-border' : '' }` }>
                                                        { theme.is_default ? <span className='brightGreen semi-bold'>True</span> : <span className='ordrslipRed semi-bold'>False</span> }
                                                    </TableCell>
                                                    <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === theme.id ? 'remove-row-border' : '' }` }>
                                                        { theme.is_active ? <span className='brightGreen semi-bold'>True</span> : <span className='ordrslipRed semi-bold'>False</span> }
                                                    </TableCell>
                                                    <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === theme.id ? 'remove-row-border' : '' }` }>
                                                        { theme.is_online ? <span className='brightGreen semi-bold'>True</span> : <span className='ordrslipRed semi-bold'>False</span> }
                                                    </TableCell>
                                                    <TableCell className={ `app-table-cell has-text-right ${ this.state.rowCollapsed === theme.id ? 'remove-row-border' : '' }` }>
                                                        { this.state.inFocus === theme.id ?
                                                            <Button
                                                                edit
                                                                onClick={ () => this.editTheme(theme) }
                                                            >
                                                                Edit
                                                            </Button> : null }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className='app-table-row'>
                                                    <TableCell className='app-table-cell' style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ 9 }>
                                                        <Collapse in={ this.state.rowCollapsed === theme.id } timeout='auto' unmountOnExit>
                                                            <div className='menu-details'>
                                                                <div>
                                                                    <HeaderText medium>Images</HeaderText>
                                                                    <div className='columns pt-2'>
                                                                        <div className="column is-one-third">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Logo</div>
                                                                            <div style={ { maxHeight: 300, maxWidth: 300 } }>
                                                                                <img style={ { borderRadius: 5, objectFit: 'cover', height: '100%', width: '100%' } } src={ theme.splash_logo } alt='Logo' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-third">
                                                                            <div className='has-text-weight-bold app-card-section-title'>App Icon</div>
                                                                            <div style={ { maxHeight: 300, maxWidth: 300 } }>
                                                                                <img style={ { borderRadius: 5, objectFit: 'cover', height: '100%', width: '100%' } } src={ theme.app_icon } alt='App Icon' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-third">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Background</div>
                                                                            <div style={ { maxHeight: 300, maxWidth: 300 } }>
                                                                                <img style={ { borderRadius: 5, objectFit: 'cover', height: '100%', width: '100%' } } src={ theme.splash_background } alt='Background' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <HeaderText medium>Colors</HeaderText>
                                                                    <div className='columns pt-2'>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Primary Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the header and footer background.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.primary_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Header Text Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the header and footer text.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.header_text_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Text Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the text.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.text_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Heading Text Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the title text.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.heading_text_color } }>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='columns pt-2'>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Primary Button Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the large buttons.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.primary_button_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Primary Button Text Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the text on large buttons.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.primary_button_text_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Secondary Button Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the small buttons.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.secondary_button_color } }>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-one-quarter">
                                                                            <div className='has-text-weight-bold app-card-section-title'>Secondary Button Text Color</div>
                                                                            <div className='app-card-header-subtext' style={ { height: 50 } }>
                                                                                Color of the text on small buttons.
                                                                            </div>
                                                                            <div style={ { border: "1px solid grey", height: 50, maxWidth: 150, backgroundColor: theme.secondary_button_text_color } }>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )) }
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={ [10, 25, 50] }
                                                count={ themes && themes.length ? themes.length : 0 }
                                                rowsPerPage={ this.state.rowsPerPage }
                                                onRowsPerPageChange={ (event) => this.updateRows(event) }
                                                page={ this.state.currentPage }
                                                onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                                                style={ { borderBottom: 'none' } }
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                    :
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell'>
                                            There are no themes.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                </div>
                <ThemeEdit
                    open={ this.state.open }
                    theme={ this.state.selectedTheme }
                    onClose={ this.handleDialogClose }
                />
            </div>
        );
    }
}

export default observer(Themes);
