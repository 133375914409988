import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../../store/store";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { getStartOfDayByDate, getEndOfDayByDate, getSortIcon } from "../../services/utils";
import AdminCard from '../elements/adminCard';
import ReportsCustomHeader from "./reportsCustomHeader";
import { DarkSubHeading, HeaderText, Text } from "../elements/text";
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import reportsAPI from '../../services/api/reportsAPI';

class ReportsTopMenuItems extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      selectedLocation: 'All',
      reports: {},
      topMenuItems: [],
      anchorEl: null,
      orderBy: "quantity",
      order: "desc",
      options: [
        { value: "quantity", label: "Amount Sold" },
        { value: "total_sales", label: "Gross Sales" },
      ],
      selectedOption: { value: "quantity", label: "Amount Sold" },
    };
  }

  formatReports(fromDate, toDate) {
    let location = this.state.selectedLocation === 'All' ? null : this.state.selectedLocation;
    reportsAPI.getTopMenuItems(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location)
      .then(data => {
        if (this._isMounted) {
          this.setState({ topMenuItems: data.top_items });
        }
      })
      .catch(err => console.error(err));
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({selectedLocation: 'All'});
    if (this._isMounted) {
      let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
      let todaysDate = moment().format('MM/DD/YYYY');
      this.formatReports(monthAgo, todaysDate);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    if (this._isMounted) {
      newDate = moment(newDate).format('MM/DD/YYYY');
      if (type === 'fromDate') {
        this.setState({fromDate: displayDate}, () => {
          this.formatReports(newDate, this.state.toDate);
        });
      } else if (type === 'toDate') {
        this.setState({toDate: displayDate}, () => {
          this.formatReports(this.state.fromDate, newDate);
        });
      }
    }
  }

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
    });
  }

  sortBy(value) {
    this.setState({ selectedOption: value });
    this.handleSort(value.value);
  }

  handleSort(value) {
    let topMenuItems = _.cloneDeep(this.state.topMenuItems);
    let orderBy = value;
    let order = this.state.order === "desc" && this.state.orderBy === value ? "asc" : "desc";
    topMenuItems = _.orderBy(topMenuItems, [(topItem) => topItem[value]],[order]);
    this.setState({ topMenuItems, order, orderBy });
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const sortByOpen = Boolean(anchorEl);

    return (
      <AdminCard
        title=""
        customHeader={
          <ReportsCustomHeader
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            handleChange={this.handleChange}
            selectedLocation={this.state.selectedLocation}
            handleSelectLocation={this.handleSelectLocation}
        />}
      >
        {this.state.topMenuItems &&
          this.state.topMenuItems.length ?
          <div>
            <HeaderText medium className="pt-2">
              Most Popular Items (MTD)
            </HeaderText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text weight="bold">Sort By: </Text>
              <DarkSubHeading
                className="pl-2 pb-1 pt-1 cursor-pointer"
                onClick={(e) => this.handleClick(e)}
              >
                {this.state.selectedOption.label}
                {getSortIcon(sortByOpen)}
              </DarkSubHeading>
              <Popover
                id="sort-by"
                open={sortByOpen}
                anchorEl={anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div>
                  <div>
                    {this.state.options.map((option) => (
                      <Text
                        hover
                        key={option.value}
                        className="p-2 pr-0"
                        onClick={(event) => this.sortBy(option)}
                      >
                        <div className="display-in-line align-items">
                          {option.label}
                          {this.state.selectedOption.value === option.value ? (
                            <FontAwesomeIcon
                              size="1x"
                              className="brightGreen ml-3"
                              icon={["far", "check"]}
                            />
                          ) : null}
                        </div>
                      </Text>
                    ))}
                  </div>
                </div>
              </Popover>
            </div>
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0 set-thin-width">Item Name</TableCell>
                  <TableCell className="app-table-cell set-thin-width">Category</TableCell>
                  <TableCell className="app-table-cell set-thin-width">Amount Sold</TableCell>
                  <TableCell className="app-table-cell">Gross Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
              {store.loading ?
                <div
                  className="admin-page-header-subtext"
                  style={{ textAlign: "center", padding: 20 }}
                >
                  Loading...
                </div> :
                this.state.topMenuItems &&
                  this.state.topMenuItems.length > 0 &&
                  this.state.topMenuItems.map(report => (
                    <TableRow key={report.item_id} className="app-table-row">
                      <TableCell className="app-table-cell pl-0">
                        {report.item_name}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {report.item_categories}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {report.quantity}
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {report.total_sales}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div> :
          <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
            No most popular menu items for the selected days or location.
          </div>
        }
      </AdminCard>
    );
  }
}

export default observer(ReportsTopMenuItems);
