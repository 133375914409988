import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { StylesProvider, ThemeProvider, createTheme } from "@material-ui/core/styles";
import { routes } from "./routes";
import login from "./screens/login";
import PrivateRoute from "./components/privateRoute";
import LoadingBoxWrapper from "./components/loadingBoxWrapper";
import ErrorDialogWrapper from "./components/errorDialogWrapper";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fal, far, fas, fad, fab);

const THEME = createTheme({
  typography: {
    "fontFamily": `'Mulish', sans-serif`,
  }
});
class App extends Component {
  render() {
    return (
      <div>
        <StylesProvider injectFirst>
          <ThemeProvider theme={THEME}>
            <Switch>
              <Route path="/login" component={login} />
              {routes.map((route, i) => (
                <PrivateRoute key={i} {...route} />
              ))}
            </Switch>
            <LoadingBoxWrapper />
            <ErrorDialogWrapper />
          </ThemeProvider>
        </StylesProvider>
      </div>
    );
  }
}

export default App;
