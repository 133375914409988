import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import Chart from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from "lodash";

import {
  getStartOfDayByDate,
  getEndOfDayByDate,
  loadingSpinner,
} from "../../services/utils";
import AdminCard from "../elements/adminCard";
import {  HeaderText } from "../elements/text";
import store from "../../store/store";
import { colors } from "../../styles/global";
import ReportsCustomHeader from "./reportsCustomHeader";
import reportsAPI from '../../services/api/reportsAPI';

class ReportsHourlySales extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, "month")),
      toDate: new Date(),
      locations: store.locations,
      selectedLocation: "All",
      series: [
        {
          name: "Hourly Sales",
          type: "column",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            show: true,
          },
        },
        xaxis: {
          tickAmount: 10,
        },
        yaxis: {
          seriesName: "Hourly Sales",
        },
        fill: {
          colors: colors.secondaryBlue,
        },
      },
    };
  }

  componentDidMount = () => {
      let monthAgo = moment().subtract(1, "month").format("MM/DD/YYYY");
      let today = moment().format("MM/DD/YYYY");
      this.formatReports(monthAgo, today);
  };

  formatReports(fromDate, toDate) {
    reportsAPI.getHourlySales(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate))
      .then((data) => {
        let labels = Object.keys(data.hourly_order_stats);
        labels = labels.map((label) => moment(label, "H").format("h a"));
        let values = Object.values(data.hourly_order_stats);
        let tableData = [];
        _.forEach(values, (value) => {
          tableData.push(value.subtotal.amount);
        });
        this.setState({
          dailySales: data.hourly_order_stats,
          series: [
            {
              data: tableData,
            },
          ],
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: labels,
            },
            yaxis: {
              ...this.state.options.yaxis,
              labels: {
                formatter: (value) =>
                  getSymbolFromCurrency(this.state.locations[0].currency) +
                  value,
              },
            },
          },
        });
      })
      .catch((err) => console.error(err));
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format("MM/DD/YYYY");
    if (type === "fromDate") {
      this.setState({ fromDate: displayDate }, () => {
        this.formatReports(newDate, this.state.toDate);
      });
    } else if (type === "toDate") {
      this.setState({ toDate: displayDate }, () => {
        this.formatReports(this.state.fromDate, newDate);
      });
    }
  };

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
    });
  };

  render() {
    return (
      <div>
        <AdminCard
          title=""
          style={{ padding: 0 }}
          customHeader={
            <ReportsCustomHeader
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              selectedLocation={this.state.selectedLocation}
              handleChange={this.handleChange}
              handleSelectLocation={this.handleSelectLocation}
            />
          }
        >
          <div>
            <HeaderText medium className="pt-2">
              Hourly Sales
            </HeaderText>
            <div className='small-text secondaryGrey'>
              The times that orders have been placed for your location(s).
            </div>
            {store.loading ? (
              loadingSpinner()
            ) : (
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={350}
              />
            )}
          </div>
        </AdminCard>
      </div>
    );
  }
}

export default observer(ReportsHourlySales);
