import React, { Component } from "react";
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import InputMask from 'react-input-mask';

import { errorMessage, fieldError } from "../../services/utils";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { observer } from "mobx-react";
import tenantAPI from '../../services/api/tenantAPI';

class SalesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referral: this.props.referral,
            apiErrors: {},
            error: '',
        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.referral !== this.props.referral) {
            this.setState({ referral: _.cloneDeep(nextProps.referral) });
        }
    };

    handleClose = updated => {
        this.setState({ apiErrors: {}, error: '', referral: this.props.referral });
        this.props.onClose(updated);
    };

    handleChange = event => {
        let { referral, apiErrors } = this.state;
        let error = '';
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'phone' &! value === '') {
            if (value.replace('_', '').length < 16) error = 'Phone must be at least 10 digits.';
        } else if (name === 'email' &! value === '') {
            if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)) error = 'Please enter a valid email.';
        }

        referral[name] = value;
        if (error.length) {
            apiErrors[name] = [{ message: error }];
        } else {
            apiErrors = {};
        }

        this.setState({ referral, apiErrors });
    };

    handleSubmit = () => {
        const { referral } = this.state;
        if ((!referral.email || referral.email === '') && (!referral.phone || referral.phone == '')) {
            this.setState({ error: 'Referral must have either an email or a phone number.' });
            return;
        }
        let payload = {
            business_name: referral.business_name,
            contact_name: referral.contact_name,
            email: referral.email,
            phone: referral.phone,
            status: referral.status || 'pending'
        };
        if (referral.id) {
            tenantAPI.updateReferral(referral.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        } else {
            tenantAPI.createReferral(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        }
    };

    deleteReferral = referralID => {
        tenantAPI.deleteReferral(referralID)
            .then(() => {
                this.handleClose(true);
            });
    };

    render() {
        const { apiErrors, error, referral } = this.state;
        const { open } = this.props;
        const edit = Boolean(this.state.referral.id);

        return (
            <Drawer
                anchor='right'
                open={ open }
                onClose={ () => this.handleClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={ `${ edit ? "Edit" : "New" } Referral` }
                    onClose={ () => this.handleClose(false) }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(apiErrors, error) }
                    deleteButton={ edit }
                    buttonAction={ () => this.deleteReferral(referral.id) }
                    buttonText='Delete Referral'
                >
                    <AdminCard
                        title="Referral Details"
                        style={ { marginTop: "15px" } }
                    >
                        <div className="app-field-wrapper">
                            <label className="app-field-label" htmlFor="business_name" style={ { marginTop: "15px" } }>Business Name</label>
                            <input
                                className={ fieldError("business_name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0" }
                                id="business_name"
                                name="business_name"
                                type="text"
                                placeholder="Business Name"
                                value={ referral.business_name || '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('business_name', apiErrors) }
                        </div>
                        <div className="app-field-wrapper">
                            <label className="app-field-label" htmlFor="contact_name" style={ { marginTop: "15px" } }>Contact Name</label>
                            <input
                                className={ fieldError("contact_name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0" }
                                id="contact_name"
                                name="contact_name"
                                type="text"
                                placeholder="Contact Name"
                                value={ referral.contact_name || '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('contact_name', apiErrors) }
                        </div>
                        <div className="app-field-wrapper">
                            <label className="app-field-label" htmlFor="email" style={ { marginTop: "15px" } }>Email</label>
                            <input
                                className={ fieldError("email", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0" }
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Email"
                                value={ referral.email || '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('email', apiErrors) }
                        </div>
                        <div className='app-field-wrapper'>
                            <label htmlFor='phone' className='app-field-label'>
                                Phone
                            </label>
                            <InputMask
                                mask='9 (999) 999-9999'
                                className={ `app-input-field mt-0 ${ fieldError('phone', this.state.apiErrors) ? 'red-border' : '' }` }
                                id='phone'
                                name='phone'
                                type='text'
                                value={ referral.phone || '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('phone', this.state.apiErrors) }
                        </div>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(SalesEdit);
