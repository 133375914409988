import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import { Button } from '../components/elements/buttons';
import { showLocations } from '../services/utils';
import FeeEdit from '../components/feeEdit';
import tenantAPI from '../services/api/tenantAPI';


class AppFees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fees: store.fees,
            inFocus: {},
            currentPage: 0,
            rowsPerPage: 10,
            open: false
        };
    }

    getFees = () => {
        tenantAPI.getRecursiveFees({ page: 1 })
            .then(this.setState({ fees: store.fees }));
    };

    componentDidMount = () => {
        if (!store.locations.length) {
            tenantAPI.getRecursiveLocations({ page: 1 })
                .then(() => {
                    if (!store.fees.length) {
                        this.getFees();
                }
            });
        } else {
            if (!store.fees.length) {
                this.getFees();
            }
        }
    };

    toggleEnabledByFee = fee => {
        tenantAPI.updateFee(fee.id, { is_enabled: !fee.is_enabled })
            .then(() => {
                this.getFees();
            });
    };

    updateRows(event) {
        this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
    };

    updatePage(event, newPage) {
        this.setState({ currentPage: newPage });
    };

    editFee = fee => {
        this.setState({ open: true });
    };

    createFee = () => {
        this.setState({ inFocus: {}, open: true });
    };

    handleClose = updated => {
        this.setState({ open: false });
        if (updated) this.getFees();
    };

    render() {
        const { currentPage, rowsPerPage } = this.state;
        if (!store.locations.length && !store.fees.length) return <div />;
        return (
            <div className='admin-page-wrapper'>
                <AdminPageHeader
                    title='Fees'
                    mainNav='Configuration'
                    subNav='App Customization'
                    description='The fees you enable here will be added to orders placed in your mobile app and online ordering website.'
                    headerActionText="Add New"
                    onHeaderActionClick={ this.createFee }
                />
                <div>
                    <AdminCard
                        title='App Fees'
                        description='Enable fees for your mobile app.'
                        largeTitle
                    >
                        <Table className='app-table-wrapper'>
                            <TableHead className='app-table-header'>
                                <TableRow className='app-table-row'>
                                    <TableCell className='app-table-cell'>Name</TableCell>
                                    <TableCell className='app-table-cell'>Locations</TableCell>
                                    <TableCell className='app-table-cell'>Amount</TableCell>
                                    <TableCell className='app-table-cell'>Enabled</TableCell>
                                    <TableCell className='app-table-cell' style={ { width: '20%' } } />
                                </TableRow>
                            </TableHead>
                            <TableBody className='app-table-body'>
                                { store.fees.length ?
                                    <React.Fragment>
                                        { (rowsPerPage > 0 ? store.fees.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : store.fees).map(fee => (
                                            <TableRow
                                                key={ fee.id }
                                                className={ `app-table-row ${ this.state.inFocus.id === fee.id ? "highlighted-row" : "" }` }
                                                onMouseOver={ () => this.setState({ inFocus: fee }) }
                                            >
                                                <TableCell className='app-table-cell'>
                                                    { fee.name }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { showLocations(fee) }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { fee.amount.formatted }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { fee.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                                                </TableCell>
                                                <TableCell className='app-table-cell has-text-right'>
                                                    { this.state.inFocus.id === fee.id ?
                                                        <Button
                                                            edit
                                                            onClick={ () => this.editFee(fee) }
                                                        >
                                                            Edit
                                                        </Button> : null }
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={ [10, 25, 50] }
                                                count={ store.fees && store.fees.length ? store.fees.length : 0 }
                                                rowsPerPage={ this.state.rowsPerPage }
                                                onRowsPerPageChange={ (event) => this.updateRows(event) }
                                                page={ this.state.currentPage }
                                                onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                                                style={ { borderBottom: 'none' } }
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                    :
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell'>
                                            There are no fees.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                </div>
                <FeeEdit
                    open={ this.state.open }
                    onClose={ this.handleClose }
                    fee={ this.state.inFocus }
                />
            </div>
        );
    }
}

export default observer(AppFees);
