import React, { Component } from "react";
import { observer } from "mobx-react";
import SyncButton from "./syncButton";
import { SideNav, SideNavLink, SideNavHeader, TopNavToolbar, SideNavHeading, SubHeading, IconPadding, SubSection, Label } from "./elements/navs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "../assets/img/ordrslip_logo_icon_and_signature_white.png";
import store from "../store/store";
import { Redirect, withRouter } from "react-router-dom";
import { ReactComponent as ChartMixed } from '../assets/icons/chart-mixed-light.svg';
import { ReactComponent as Sliders } from '../assets/icons/sliders-light.svg';
import { ReactComponent as HouseBlank } from '../assets/icons/house-blank-light.svg';
import config from "../config/config";
import {logout} from "../services/utils";
import PosConnectionSuccess from "./posConnectionSuccess";
import { colors } from '../styles/global';
import TenantList from "./tenant/tenantList";
import ToggleAutoSync from "./tenant/toggleAutoSync";
import tenantAPI from '../services/api/tenantAPI';

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      open: [],
      configuration: ['app-settings', 'catalog', 'locations', 'integrations'],
      marketing: ['messages', 'rewards', 'promotions', 'users'],
      reports: ['analytics', 'reports'],
      toLogin: false,
      posSuccess: false,
    }
  }

  getPOSStatus = () => {
    if (store.tenant.id) {
      tenantAPI.getPosStatus().then(
        data => {
          store.updatePosStatus(data.status);
          clearInterval(this.interval)
        });
    }
  }

  componentWillMount = () => {
    this.interval = setInterval(() => {
      this.getPOSStatus();
    }, 2000)
    if(window.location.search === '?success'){
      this.setState({posSuccess: true});
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  componentDidMount() {
    const { location } = this.props;
    let { configuration, marketing, reports, open } = this.state;
    let config = { name: 'configuration', tabs: configuration };
    let market = { name: 'marketing', tabs: marketing };
    let report = { name: 'reports', tabs: reports };
    
    [config, market, report].forEach((tab) => {
      if (tab.tabs.some(str => {
        if (location.pathname.includes(str)) {
          open.push(str);
        }
        if (tab.name === 'reports' && str === 'reports') open.push('location-reports');
        return location.pathname.includes(str);
      })) {
        open.push(tab.name);
      }
    });
    if (!open.length) open.push('dashboard');
    this.setState({ open });
  }

  handleDialogSuccessClose = () => {
    this.props.history.push(`/`);
    this.setState({posSuccess: false})
  };

  isActive = (section, isSubsection) => {
    if (isSubsection) {
      const { location } = this.props;
      return location.pathname.split('/').pop() === section
    }
    return this.state.open.includes(section);
  }

  toggleSection(section) {
    let open = this.state.open;
    if (open.length === 1 && open[0] === 'dashboard') {
      open.pop();
    }

    if (open.includes(section)) {
      let index = open.indexOf(section);
      if (index > -1) {
        open.splice(index, 1);
        this.setState({ open });
      }
    } else {
      open.push(section);
      this.setState({ open });
    }
  }

  openOrClosedIcon(section) {
    if (this.state.open.includes(section)) {
      return <FontAwesomeIcon size="1x" color={colors.white} icon={['fas', 'caret-down']} />;
    } else {
      return <FontAwesomeIcon size="1x" color={colors.white} icon={['fas', 'caret-right']} />;
    }
  }

  icon(iconName, section) {
    return (
      <FontAwesomeIcon size="lg" color={this.isActive(section) ? colors.ordrslipRed : colors.white} icon={["fal", iconName]} />
    );
  }

  render() {
    const showTenantList = store.showTenantList.get();
    const isAdmin = store.isAdmin.get();
    if (this.state.toLogin === true) {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <SideNav>
          <PosConnectionSuccess
            open={this.state.posSuccess}
            onClose={this.handleDialogSuccessClose} 
          />
          <TopNavToolbar>
            <img src={image} alt="ordrslip" className="header-icon" />
          </TopNavToolbar>
          <SideNavHeader isActive={this.isActive('dashboard')} to="/" style={{ paddingTop: 20 }}>
            <HouseBlank className={this.isActive('dashboard') ? 'svgIcon active' : 'svgIcon'} />
            <IconPadding>Dashboard</IconPadding>
          </SideNavHeader>
          <SideNavHeading heading isActive={this.isActive('marketing')} onClick={() => this.toggleSection('marketing')}>
            <div className="display-in-line">
              {this.icon('megaphone', 'marketing')}
              <IconPadding>Marketing</IconPadding>
            </div>
            <FontAwesomeIcon size="2x" color={colors.ordrslipRed} icon={["fal", 'angle-down']} />
          </SideNavHeading>
          {this.isActive('marketing') ?
          <SubSection>
            <SideNavHeading onClick={() => this.toggleSection('messages')}>
              {this.openOrClosedIcon('messages')}
              <IconPadding>Messages</IconPadding>
            </SideNavHeading>
            {this.isActive('messages') ?
            <div className="display-column">
              <SubHeading to="/messages/broadcast-messages" activeSection={this.isActive('broadcast-messages', true)}>
                <Label>SMS Messages</Label>
              </SubHeading>
              <SubHeading to="/messages/popup-messages" activeSection={this.isActive('popup-messages', true)}>
                <Label>Pop-Up Messages</Label>
              </SubHeading>
              <SubHeading to="/messages/push-notifications">
                <Label>Push Notifications</Label>
              </SubHeading>
            </div>
            : null}
            <SideNavHeading onClick={() => this.toggleSection('rewards')}>
              {this.openOrClosedIcon('rewards')}
              <IconPadding>Rewards</IconPadding>
            </SideNavHeading>
            {this.isActive('rewards') ?
              <div className="display-column">
                <SubHeading to="/rewards/punch-cards" activeSection={this.isActive('punch-cards', true)}>
                  <Label>Punch Cards</Label>
                </SubHeading>
                {store.tenant.pos_name === 'square' && (
                <SubHeading to="/rewards/square-loyalty" activeSection={this.isActive('square-loyalty', true)}>
                  <Label>Square Loyalty</Label>
                </SubHeading>)}
              </div>
            : null}
            <SideNavHeading onClick={() => this.toggleSection('promotions')}>
              {this.openOrClosedIcon('promotions')}
              <IconPadding>Promotions</IconPadding>
            </SideNavHeading>
            {this.isActive('promotions') ?
              <div className="display-column">
                <SubHeading to="/promotions/sales" activeSection={this.isActive('sales', true)}>
                  <Label>Sales</Label>
                </SubHeading>
                <SubHeading to="/promotions/promo-codes" activeSection={this.isActive('promo-codes', true)}>
                  <Label>Promo Codes</Label>
                </SubHeading>
              </div>
            : null}
            <SideNavHeading onClick={() => this.toggleSection('users')}>
              {this.openOrClosedIcon('users')}
              <IconPadding>Users</IconPadding>
            </SideNavHeading>
            {this.isActive('users') ?
              <div className="display-column">
                <SubHeading to="/users/all-users" activeSection={this.isActive('all-users', true)}>
                  <Label>All Users</Label>
                </SubHeading>
                  { !isAdmin ?
                    <SubHeading to="/users/profile" activeSection={ this.isActive('profile', true) }>
                  <Label>Your Profile</Label>
                    </SubHeading>
                  : null }
              </div>
            : null}
          </SubSection> : null}
          <SideNavHeading heading isActive={this.isActive('reports')} onClick={() => this.toggleSection('reports')}>
            <div className="display-in-line">
              <ChartMixed className={this.isActive('reports') ? 'svgIcon active' : 'svgIcon'} />
              <IconPadding>Reports</IconPadding>
            </div>
            <FontAwesomeIcon size="2x" color={colors.ordrslipRed} icon={["fal", 'angle-down']} />
          </SideNavHeading>
          {this.isActive('reports') ? 
          <SubSection>
            <SideNavLink to="/reports/analytics" activeSection={this.isActive('analytics', true)}>
              <Label>Analytics</Label>
            </SideNavLink>
            <SideNavHeading onClick={() => this.toggleSection('location-reports')}>
              {this.openOrClosedIcon('location-reports')}
              <IconPadding>Location Reports</IconPadding>
            </SideNavHeading>
            {this.isActive('location-reports') ? 
            <div className="display-column">
              <SubHeading to="/reports/sales-summary" activeSection={this.isActive('sales-summary', true)}>
                <Label>Sales Summary</Label>
              </SubHeading>
              <SubHeading to="/reports/item-sales" activeSection={this.isActive('item-sales', true)}>
                <Label>Item Sales</Label>
              </SubHeading>
              <SubHeading to="/reports/category-sales" activeSection={this.isActive('category-sales', true)}>
                <Label>Category Sales</Label>
              </SubHeading>
              <SubHeading to="/reports/discounts" activeSection={this.isActive('discounts', true)}>
                <Label>Discounts</Label>
              </SubHeading>
              <SubHeading to="/reports/users" activeSection={this.isActive('users', true)}>
                <Label>User Summary</Label>
              </SubHeading>
            </div>
            : null}
          </SubSection> : null}
          <SideNavHeading heading isActive={this.isActive('configuration')} onClick={() => this.toggleSection('configuration')}>
            <div className="display-in-line">
              <Sliders className={this.isActive('configuration') ? 'svgIcon active' : 'svgIcon'} />
              <IconPadding>Configuration</IconPadding>
            </div>
            <FontAwesomeIcon size="2x" color={colors.ordrslipRed} icon={["fal", 'angle-down']} />
          </SideNavHeading>
          {this.isActive('configuration') ?
            <SubSection>
              <SideNavHeading onClick={() => this.toggleSection('app-settings')}>
                {this.openOrClosedIcon('app-settings')}
                <IconPadding>App Customization</IconPadding>
              </SideNavHeading>
              {this.isActive('app-settings') ?
              <div className="display-column">
                <SubHeading to="/app-settings/taxes" activeSection={this.isActive('taxes', true)}>
                  <Label>Taxes</Label>
                </SubHeading>
                <SubHeading to="/app-settings/fees" activeSection={this.isActive('fees', true)}>
                  <Label>Fees</Label>
                </SubHeading>
                <SubHeading to="/app-settings/appearance" activeSection={this.isActive('appearance', true)}>
                  <Label>Content</Label>
                </SubHeading>
                <SubHeading to="/app-settings/themes" activeSection={this.isActive('themes', true)}>
                  <Label>Themes</Label>
                </SubHeading>
                <SubHeading to="/app-settings/settings" activeSection={this.isActive('settings', true)}>
                  <Label>Settings</Label>
                </SubHeading>
              </div> : null}
              <SideNavHeading onClick={() => this.toggleSection('catalog')}>
                {this.openOrClosedIcon('catalog')}
                <IconPadding>Catalog/Item Library</IconPadding>
              </SideNavHeading>
              {this.isActive('catalog') ?
              <div className="display-column">
                <SubHeading to="/catalog/menus" activeSection={this.isActive('menus', true)}>
                  <Label>Menus</Label>
                </SubHeading>
                <SubHeading to="/catalog/categories" activeSection={this.isActive('categories', true)}>
                  <Label>Categories</Label>
                </SubHeading>
                <SubHeading to="/catalog/items" activeSection={this.isActive('items', true)}>
                  <Label>Items</Label>
                </SubHeading>
                <SubHeading to="/catalog/modifier-lists" activeSection={this.isActive('modifier-lists', true)}>
                  <Label>Modifier Lists</Label>
                </SubHeading>
                <SubHeading to="/catalog/modifiers" activeSection={this.isActive('modifiers', true)}>
                  <Label>Modifiers</Label>
                </SubHeading>
              </div> : null}
              <SideNavHeading onClick={() => this.toggleSection('locations')}>
                {this.openOrClosedIcon('locations')}
                <IconPadding>Locations</IconPadding>
              </SideNavHeading>
              {this.isActive('locations') ?
              <div className="display-column">
                {store.locations.length ? store.locations.map((location, i) => (
                  <SubHeading
                    key={i}
                    to={`/locations/${location.id}`}
                    activeSection={this.isActive(location.id, true)}
                  >
                    <Label>{location.name}</Label>
                  </SubHeading>
                )) : null}
              </div> : null}
              <SideNavHeading onClick={() => this.toggleSection('integrations')}>
                {this.openOrClosedIcon('integrations')}
                <IconPadding>Integrations</IconPadding>
              </SideNavHeading>
              {this.isActive('integrations') ?
              <div className="display-column">
                <SubHeading to="/integrations/doordash" activeSection={this.isActive('doordash', true)}>
                  <Label>DoorDash</Label>
                </SubHeading>
                {store.tenant.pos_name === 'square' && (
                  <React.Fragment>
                    {/* <SubHeading to="/integrations/uber" activeSection={this.isActive('uber', true)}>
                      <Label>Uber</Label>
                    </SubHeading> */}
                    <SubHeading to="/integrations/square-hardware" activeSection={this.isActive('square-hardware', true)}>
                      <Label>Square Hardware</Label>
                    </SubHeading>
                  </React.Fragment>
                )}
              </div> : null}
            </SubSection>
            : null }
          <SideNavHeader isActive={ this.isActive('referrals') } to="/referrals" style={ { paddingTop: 20 } }>
            { this.icon('comment-dollar', 'referrals') }
            <IconPadding>Referrals</IconPadding>
          </SideNavHeader>
          <div className="float-bottom" style={{ paddingBottom: 20 }}>
            <SideNavHeading>
              <FontAwesomeIcon size="lg" color={colors.white} icon={["far", "user-circle"]} />
              <IconPadding>{store.user && store.user.id ? store.user.full_name : null}</IconPadding>
            </SideNavHeading>
            <Label style={{ paddingLeft: 30 }}>
              <SideNavHeading style={{ paddingTop: 0 }}>
                <FontAwesomeIcon color={colors.white} icon={['fal', 'map-marker-alt']} />
                <IconPadding onClick={() => {
                  if (isAdmin) {
                    store.updateShowTenantList(true)
                  }
                }}>{store.tenant.name}</IconPadding>
              </SideNavHeading>
            </Label>
              <div className="display-in-line">
                <SideNavHeading style={{ paddingRight: 10 }}>
                  <FontAwesomeIcon size="lg" color={colors.white} icon={["fal", "cash-register"]} />
                </SideNavHeading>
                <div className="display-column">
                  <div className="link-title">POS Connection</div>
                  <div>
                    {store.posConnectionStatus === true ? (
                    <div className="brightGreen display-in-line center-items">
                        <FontAwesomeIcon className="brightGreen" size="1x" icon={['fal', 'check']} />
                        <IconPadding>Enabled</IconPadding>
                      </div>
                    ) : (
                      <div className="errorRed display-in-line center-items">
                        <FontAwesomeIcon className="errorRed" size="1x" icon={['fal', 'times']} />
                        <IconPadding>Disabled</IconPadding>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {store.tenant.pos_name === "square" ? 
                  <SideNavHeading style={{ paddingRight: 10 }}>
                    <FontAwesomeIcon size="lg" color={colors.white} icon={["fal", "rotate"]} />
                    <ToggleAutoSync tenant={store.tenant} enable_auto_sync={store.tenant.enable_auto_sync}/>
                  </SideNavHeading> : null}
            </div> 
            <SyncButton />
            <div
              className={config.environment.name === "Production" ? 
              "sync-text version-text bottom-spacing" : 
              "sync-text version-text"}
            >
              Version {config.version} |
              {isAdmin ? 
                  <span className="footer-links" onClick={() => store.updateShowTenantList(true)}>Switch Tenant</span>
                : <a href="http://info.ordrslip.com/knowledge-base" target="_blank" rel="noopener noreferrer" className="footer-links">Help</a>
                }|
              <span className="footer-links" onClick={() => logout(this.props)}>Logout</span>
            </div>
            {config.environment.name !== "Production" && (
              <div className="sync-text version-text bottom-spacing">{config.environment.name}</div>
            )}
        </SideNav>
        {showTenantList && <TenantList />}
      </React.Fragment>
    );
  }
}

export default withRouter(observer(NavBar));
