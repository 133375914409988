import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';

import menuAPI from '../../services/api/menuAPI';
import store from '../../store/store';
import { errorMessage } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminCheckbox from '../elements/adminCheckbox';
import EditDrawer from '../elements/editDrawer';


class MenuAddCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            error: '',
            categories: this.props.categories
        };
    };

    handleClose = updated => {
        this.props.onClose(updated);
    };

    handleSubmit = () => {
        let payload = {
            categories: this.state.categories
        };
        menuAPI.updateMenu(this.props.menuId, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                    if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                }
                else {
                    this.handleClose(true);
                }
            });
    };

    toggleSelectCategory = category => {
        const { categories } = this.state;
        if (categories.includes(category)) {
            categories.splice(categories.indexOf(category), 1);
            this.setState({ categories });
        } else {
            categories.push(category);
            this.setState({ categories });
        }
    };

    render() {
        const { open } = this.props;
        const { apiErrors, categories } = this.state;

        return (
            <Drawer
                anchor='right'
                open={ open }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
                onClose={ () => this.handleClose(false) }
            >
                <EditDrawer
                    title='Edit Menu Categories'
                    onClose={ () => this.handleClose(false) }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(apiErrors) }
                >
                    <AdminCard
                        title='Category Selection'
                        description='When this menu is available in the app, only the categories selected here will be available. If you don’t see a category here that is available in your POS, sync your Admin Dashboard.'
                        style={ { marginTop: '15px' } }
                    >
                        <div className='mt-4'>
                            { store.categories.length && store.categories.map(category => (
                                <div className='columns is-mobile is-vcentered' key={ category.id }>
                                    <div className='column is-narrow pt-0 pb-0'>
                                        <AdminCheckbox
                                            checked={ categories.includes(category.id) }
                                            onChange={ () => this.toggleSelectCategory(category.id) }
                                            style={ { padding: '5px 12px' } }
                                        />
                                    </div>
                                    <div className='column is-narrow pt-0 pb-0'>
                                        { category.name }
                                    </div>
                                </div>
                            )) }
                        </div>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(MenuAddCategories);
