import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { Link, NavLink } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

export const SideNavAndContentContainer = styled.div`
    display: flex;
`

export const SideNavContainer = styled.div`
    display: flex;
    width: 250px;
    height: 100vh;
`

export const ContentContainer = styled.div`
    width: calc(100vw - 250px);
    height: 100vh;
    overflow: scroll;
    background-color: ${colors.backgroundGrey};
`

export const SideNav = styled.nav`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    overflow: scroll;
    width: 250px;
    background-color: ${colors.navBarBlack};
`

export const SideNavLink = styled(NavLink)`
    padding: 10px;
    padding-left: 4px;
    color: ${colors.white};
    font-size: 14px;
    font-family: 'SofiaProBlack', sans-serif;
    text-transform: "capitalize";
    border-radius: 0 100px 100px 0;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.activeSection ? colors.darkGray : 'transparent'};
    margin-right: ${props => props.activeSection ? '25px' : 0};

    &:hover {
        color: ${colors.disabledGrey};
        text-decoration: none;
    }
`

export const SideNavHeading = styled.div`
    padding: 10px;
    color: ${props => 
        props.isActive === true ? colors.ordrslipRed : 
        colors.white
    };
    font-size: 14px;
    font-family: 'SofiaPro-Bold', sans-serif;
    text-transform: "capitalize";
    border-radius: 100px 0 0 100px;
    border-right: 3px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${props => props.heading ?
        `justify-content: space-between;
        align-items: center;
        padding-right: 40px;`
    : null}

    &.active {
        color: ${colors.ordrslipRed};
    }

    &:hover {
        cursor: pointer;
        color: ${colors.disabledGrey};
        text-decoration: none;
    }
`

export const SubHeading = styled(NavLink)`
    padding: 10px;
    color: ${colors.white};
    font-size: 14px;
    font-family: 'Sofia', sans-serif;
    text-transform: "capitalize";
    border-radius: 100px 0 0 100px;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.activeSection ? colors.darkGray : 'transparent'};
    margin-right: ${props => props.activeSection ? '25px' : 0};

    &.active {
        border-radius: 0px 25px 25px 0px;
    }

    &:hover {
        cursor: pointer;
        color: ${colors.disabledGrey};
        text-decoration: none;
    }
`

export const SideNavHeader = styled(({isActive, ...props}) => <Link {...props} />)`
    padding: 10px;
    color: ${props => 
        props.isActive ? colors.ordrslipRed : 
        colors.white
    };
    font-size: 14px;
    font-family: 'SofiaPro-Bold', sans-serif;
    text-transform: ${props => "capitalize"};
    border-right: 6px solid transparent;
    display: flex;
    flex-direction: row;

    &:hover {
        color: ${colors.disabledGrey};
        text-decoration: none;
    }
`

export const TopNavToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    min-height: 48px;
`

export const IconPadding = styled.div`
    padding-left: 10px;
`

export const SubSection = styled.div`
    margin: 0 18px;
    border-left: 2px solid ${colors.ordrslipRed};
`

export const Label = styled.div`
    padding-left: 25px;
`