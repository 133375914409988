import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import { Text } from '../components/elements/text';
import AdminToggle from '../components/elements/adminToggle';
import tenantAPI from '../services/api/tenantAPI';
import SupportEmailEdit from '../components/location/supportEmailEdit';


class AppSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
        };
    }

    handleToggle = () => {
        tenantAPI.updateTenant({ login_home_button_enabled: !store.tenant.login_home_button_enabled });
    };

    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        return (
            <div>
                <div className='admin-page-wrapper'>
                    <AdminPageHeader
                        title='Settings'
                        mainNav='Configuration'
                        subNav='App Customization'
                        description='Customize the settings of your app to provide a better experience for your customers.'
                    />
                    <div>
                        <AdminCard
                            title='Show/Hide Login Button on Home screen'
                            description={ `Customers will see a more prominent 'Login/Register' button on the home screen of the app. The profile icon in the top-right will still be shown either way.` }
                            largeTitle
                        >
                            <AdminToggle
                                weight='bold'
                                className='app-toggle'
                                checked={ Boolean(store.tenant.login_home_button_enabled) }
                                onChange={ this.handleToggle }
                            />
                        </AdminCard>
                        <AdminCard
                            largeTitle
                            title="Support Email Address"
                            description="This email address will receive support request emails sent from your mobile app and online ordering page for all locations."
                            onHeaderActionClick={ this.toggleDrawer }
                        >
                            <Text>{ store.tenant && store.tenant.support_email ? store.tenant.support_email : "No email has been selected." }</Text>
                        </AdminCard>
                    </div>
                </div>
                <SupportEmailEdit
                    open={ this.state.open }
                    onClose={ this.toggleDrawer }
                    support_email={ store.tenant.support_email }
                />
            </div>
        );
    }
}

export default observer(AppSettings);
