import React, { Component } from "react";
import Drawer from '@material-ui/core/Drawer';
import { toJS } from 'mobx';
import _ from 'lodash';
import SalesView from "./salesView";
import SalesEdit from "./salesEdit";
import moment from "moment";
import promotionsAPI from '../../services/api/promotionsAPI';

const emptyPromoCode = {
    name: '',
    amount_off: { amount: "0", currency: 'USD' },
    percent_off: '',
    minimum_order_amount: { amount: "0", currency: 'USD' },
    apply_to_minimum: true,
    locations: [],
    sale_hours: [],
    is_active: true,
    discount_type: 'amount',
    description: '',
    status: true,
};

class SalesDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerType: this.props.drawerType,
            duplicatePromoCode: {}
        }
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.drawerType !== this.props.drawerType) {
            this.setState({ drawerType: nextProps.drawerType });
        }
    };

    onClose = (updated) => {
        this.setState({ duplicatePromoCode: emptyPromoCode });
        this.props.onClose(updated);
    }

    duplicatePromo = () => {
        let duplicatePromoCode = _.cloneDeep(toJS(this.props.promoCode));
        delete duplicatePromoCode.id;
        if (this.props.promoCode.amount_off) {
            duplicatePromoCode.amount_off.amount = duplicatePromoCode.amount_off.amount.toString();
        } else {
            duplicatePromoCode.amount_off = { amount: "0", currency: 'USD' };
        }
        if (!this.props.promoCode.max_discount_amount) {
            duplicatePromoCode.max_discount_amount =  { amount: "0", currency: 'USD' };
        }
        this.setState({duplicatePromoCode, drawerType: 'create'});
    }

  deactivateSale = () => {
    const { promoCode } = this.props;
    let payload = {
        is_active: false,
        end_time: moment().format('YYYY-MM-DDTHH:mm:ss')
    };
      promotionsAPI.updateSale(promoCode.id, JSON.stringify(payload))
          .then(() => {
              this.props.onClose();
          });
    }

    render() {
        const { drawerType } = this.state;
        const { dateRange, open, promoCode } = this.props;

        return (
            <>
                {open ?
                    <Drawer
                        anchor="right"
                        open={open}
                        classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}
                        onClose={this.props.onClose}
                    >
                        {['view', 'expired'].includes(drawerType) ?
                            <SalesView
                                promoCode={promoCode}
                                duplicatePromo={this.duplicatePromo}
                                drawerType={drawerType}
                                onClose={this.onClose}
                                deactivateSale={this.deactivateSale}
                                emptyPromoCode={emptyPromoCode}
                            /> :
                        drawerType === 'create' ?
                            <SalesEdit
                                onClose={this.onClose}
                                drawerType={drawerType}
                                promoCode={this.state.duplicatePromoCode}
                                dateRange={dateRange}
                                emptyPromoCode={emptyPromoCode}
                            />
                        : null}
                    </Drawer> :
                    null
                }
            </>
        );
    }
}

export default (SalesDrawer);