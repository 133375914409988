import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import UserEdit from './userEdit';
import UserAdjustPoints from './userAdjustPoints';
import UserResetPassword from './userResetPassword';
import UserOrderHistory from './userOrderHistory';


class UserDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerType: this.props.drawerType,
            isLoyalty: false,
            user: this.props.user
        }
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ drawerType: nextProps.drawerType });
        if (nextProps.user !== this.props.user) {
            this.setState({ user: nextProps.user });
        }
    };

    onClose = updated => {
        this.props.onClose(updated);
    };

    openSecondaryDrawer = (drawerType, isLoyalty) => {
        this.setState({ drawerType, isLoyalty });
    };

    render() {
        const { open } = this.props;
        const { drawerType } = this.state;

        return (
            <Drawer
                anchor='right'
                open={open}
                onClose={() => this.onClose(false)}
                classes={{paper: 'app-drawer', paperAnchorRight: 'app-drawer'}}
            >
                { this.props.user ?
                    <div>
                        { drawerType === 'edit' ?
                            <UserEdit
                                user={ this.props.user }
                                onClose={ this.onClose }
                                openSecondaryDrawer={ this.openSecondaryDrawer }
                                profile={ this.props.profile }
                            />
                            : drawerType === 'password' ?
                                <UserResetPassword
                                    user={ this.props.user }
                                    onClose={ this.onClose }
                                />
                                : drawerType === 'order history' ?
                                    <UserOrderHistory
                                        user={ this.props.user }
                                        openSecondaryDrawer={ this.openSecondaryDrawer }
                                        onClose={ this.onClose }
                                    />
                                    : <UserAdjustPoints
                                        user={ this.props.user }
                                        isLoyalty={ this.state.isLoyalty }
                                        onClose={ this.onClose }
                                        openSecondaryDrawer={ this.openSecondaryDrawer }
                                    />
                            }
                    </div>
                : null}
            </Drawer>
        );
  }
}

export default observer(UserDrawer);
