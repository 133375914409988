import React, { Component } from "react";
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';

import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { Text } from '../elements/text';
import { errorMessage, fieldError } from "../../services/utils";
import tenantAPI from '../../services/api/tenantAPI';

class PopupsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            customTextOptions: this.props.customTextOptions,
            error: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customTextOptions !== this.props.customTextOptions) {
            this.setState({ customTextOptions: _.cloneDeep(nextProps.customTextOptions) });
        }
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let { customTextOptions } = this.state;
        let item = _.find(customTextOptions, x => { return x.property === name; });

        if (name.includes("url_link")) {
            item.value[event.target.id] = value;
        } else {
            item.value = value;
        }
        this.setState({ customTextOptions });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let payload = {
            content: Object.assign({}, ...(this.state.customTextOptions.map(item => ({[item.property]: item}))))
        }
        tenantAPI.postCustomTextFieldData(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    this.setState({ apiErrors: {} });
                    this.handleClose('updated');
                }
            })
            .catch(err => console.error(err));
    };

    handleClose = (updated) => {
        this.setState({
            apiErrors: {},
            customTextOptions: this.props.customTextOptions,
            error: ''
        });
        this.props.onClose(updated);
    };

    render() {
        const { type } = this.props;
        return (
            <Drawer
                anchor="right"
                open={ this.props.open }
                classes={ { paper: "app-drawer", paperAnchorRight: "app-drawer" } }
                onClose={ this.handleClose }
            >
                <EditDrawer
                    title={ `Edit ${ type } Pop-Up Message` }
                    onSubmit={ this.handleSubmit }
                    onClose={ this.handleClose }
                >
                    <AdminCard
                        style={{ marginTop: "15px" }}
                        title={`Customize ${type} Pop-Up Text`}
                        description="Note: All fields are optional."
                    >
                        {errorMessage(this.state.apiErrors)}
                        <form>
                            {this.state.customTextOptions.map((customTextOption, index) => (
                                <React.Fragment key={index}>
                                    {index ? <Text weight="bold" className='pb-3'>
                                        {index === 1 ?
                                                `Link Within ${type} Pop-Up` :
                                                `Acknowledgement ${type} Pop-Up Button`}
                                    </Text> : null}
                                    <label htmlFor={customTextOption.property} className="app-field-label">{customTextOption.name}</label>
                                    <Text className='pb-2'>{customTextOption.description}</Text>
                                    {customTextOption.property.includes("url_link") ?
                                        <div>
                                            <div className='app-field-wrapper'>
                                                <label htmlFor={customTextOption.property} className="app-field-label">WELCOME POP-UP (TEXT)</label>
                                                <input
                                                    className={fieldError(customTextOption.property, this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                                    id='text'
                                                    name={customTextOption.property}
                                                    type="text"
                                                    placeholder="Ex: See our page for different events"
                                                    value={customTextOption.value.text}
                                                    onChange={(event) => this.handleChange(event)}
                                                />
                                                {fieldError(customTextOption.property, this.state.apiErrors)}
                                            </div>
                                            <div className='app-field-wrapper'>
                                                <label htmlFor={customTextOption.property} className="app-field-label">WELCOME POP-UP (URL)</label>
                                                <input
                                                    className={fieldError(customTextOption.property, this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                                    id='url'
                                                    name={customTextOption.property}
                                                    type="text"
                                                    placeholder="Ex: yourwebsite.com"
                                                    value={customTextOption.value.url}
                                                    onChange={(event) => this.handleChange(event)}
                                                />
                                                {fieldError(customTextOption.property, this.state.apiErrors)}
                                            </div>
                                        </div>
                                        :
                                        <div className='app-field-wrapper'>
                                            <input
                                                className={fieldError(customTextOption.property, this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                                id={customTextOption.property}
                                                name={customTextOption.property}
                                                type="text"
                                                placeholder={index === 0 ? 'Ex: Welcome to [Restaurant Name]!' : 'Ex: Sounds Good'}
                                                value={customTextOption.value}
                                                onChange={(event) => this.handleChange(event)}
                                            />
                                            {fieldError(customTextOption.property, this.state.apiErrors)}
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </form>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default PopupsEdit;
