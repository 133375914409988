import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { observer } from "mobx-react";

class PosConnectionDialog extends Component {
  state = {
  };

  handleDialogClose = response => {
    this.props.onClose(response);
  };

  render() {
    const { classes, onClose, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleDialogClose}
        aria-labelledby="pos-connection-error"
        {...other}
      >
        <div className="app-card" style={{ marginTop: 0 }}>
          <div className="app-card-header">
            <div className="app-card-header-text">
              <div className="app-card-header-title">POS Connection Error</div>
            </div>
          </div>
          <div className="app-card-content-wrapper">
            <div className="app-card-red-text" style={{ marginTop: 10 }}>Ordrslip cannot access your POS data.</div>
            <div className="app-card-red-text" style={{ marginTop: 10 }}>The connection between Ordrslip and your POS could not be established.</div>
            <div className="app-card-red-text" style={{ fontWeight: 800, marginBottom: 20 }}>Customers will not be able to place orders in your app until you grant access.</div>
          </div>
          <div className="app-card-actions-wrapper center">
            <button className="app-button primary" onClick={onClose}>
              Go to Access Page
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default observer(PosConnectionDialog);
