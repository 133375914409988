import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter, Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import store from '../store/store';
import { displayHours, weekdays, getLocationNameById } from '../services/utils';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSectionHeader from '../components/elements/adminSectionHeader';
import AdminToggle from '../components/elements/adminToggle';
import { Button } from '../components/elements/buttons';
import { Text } from '../components/elements/text';
import withNav from '../components/withNav';
import CategoryEdit from '../components/catalog/categoryEdit';
import MenuAddEdit from '../components/catalog/menuAddEdit';
import ItemEdit from '../components/catalog/itemEdit';
import { colors } from '../styles/global';
import MenuAddCategories from '../components/catalog/menuAddCategories';
import menuAPI from '../services/api/menuAPI';


class MenuDetails extends Component {
  state = {
    categories: [],
    inFocus: '',
    inFocusItem: '',
    isDragging: false,
    menu: {},
    menuId: this.props.match.params.menuId,
    open: false,
    openAddCategories: false,
    openCategoryEdit: false,
    openItemEdit: false,
    rowCollapsed: {},
    selectedCategory: {},
    selectedItem: {}
  };

  editCategory = category => {
    this.setState({ selectedCategory: category });
    this.setState({ openCategoryEdit: !this.state.openCategoryEdit });
  };

  editItem = itemID => {
    menuAPI.getItemById(itemID)
      .then(response => {
        this.setState({ selectedItem: response, openItemEdit: !this.state.openItemEdit });
      });
  };

  componentWillMount = () => {
    this.getMenu();
    this.getCategories();
  };

  getMenu = () => {
    menuAPI.getMenuById(this.state.menuId)
      .then(response => {
        this.setState({ menu: response });
      });
  };

  getCategories = () => {
    menuAPI.getRecursiveCategories({ page: 1, menus: this.state.menuId }) // Get menu categories
      .then(response => {
        let categories = _.sortBy(response, ['order']);
        this.setState({ categories });
      });
    menuAPI.getRecursiveCategories({ page: 1 }); // Get all categories for store
  };

  handleClose = (updated, name) => {
    if (name === 'menu') this.setState({ open: false });
    if (name === 'category') this.setState({ openCategoryEdit: false });
    if (name === 'item') this.setState({ openItemEdit: false });
    if (name === 'add categories') this.setState({ openAddCategories: false });
    if (updated) this.getMenu();
  };

  toggleEnabled = () => {
    let { menu } = this.state;
    const values = JSON.stringify({ is_enabled: !menu.is_enabled });
    menuAPI.updateMenu(menu.id, values)
      .then(response => {
        this.setState({ menu: response });
      })
  };

  editMenu = () => {
    this.setState({ open: true });
  };

  editCategories = () => {
    this.setState({ openAddCategories: true });
  };

  toggleRow(category) {
    const rowCollapsed = category.id === this.state.rowCollapsed ? {} : category.id;
    this.setState({ rowCollapsed });
  };

  updateCategoryOrder = () => {
    let payload = { 'objects': [] };
    this.state.categories.forEach(cat => {
      payload.objects.push({ 'id': cat.id, 'order': cat.order });
    });
    menuAPI.bulkUpdate('categories', JSON.stringify(payload))
      .then(response => {
        if (response.errors) {
          this.setState({ apiErrors: response.errors });
          return;
        } else {
          this.setState({ apiErrors: {} });
          this.getCategories();
        }
      })
      .catch(err => console.error(err));
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    if (result.destination) {
      let { categories } = this.state;
      categories = this.reorder(
        categories,
        result.source.index,
        result.destination.index
        );
        categories.forEach((item, index) => {
          item.order = index + 1;
        });
        this.setState({ categories, isDragging: false });
        this.updateCategoryOrder();
      } else {
      // dropped outside the list
      this.setState({ isDragging: false });
    }
  };

  onBeforeCapture = () => {
    // Hide the collapse before dragging
    this.setState({ rowCollapsed: {}, isDragging: true });
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? colors.white : '',
    border: isDragging ? `2px solid ${ colors.secondaryBlue }` : 'none',
    display: isDragging ? 'table' : '',
    ...draggableStyle
  });

  render() {
    const {
      inFocusItem,
      isDragging,
      menu,
    } = this.state;

    if (!menu) return <Redirect to='/catalog/menus' />;
    return (
      <div className='admin-page-wrapper'>
        <AdminPageHeader
          tertiaryNav='Menus'
          tertiaryLink='/catalog/menus'
          mainNav='Configuration'
          subNav='Item Library'
          title={ menu.name }
          description='Edit your menu by updating hours, reorganizing categories, and more.'
        />
        <div>
          <AdminCard
            title='Enable/Disable Menu'
            description='Customers will only be able to see this menu if it is enabled.'
            onHeaderActionClick={ this.editMenu }
          >
            <AdminToggle
              className='app-toggle'
              checked={ Boolean(menu.is_enabled) }
              onChange={ this.toggleEnabled }
            />
            <div className='pt-5'>
              <AdminSectionHeader title='Name, Hours, & Selected Locations' />
              <Text>
                This menu will only be active during the times and the locations that you set here.
              </Text>
              <Text className='pt-3' weight='bold'>Menu Name</Text>
              <div className='profile-container'>
                <FontAwesomeIcon icon='fa-solid fa-circle-arrow-right' className='mr-2 tertiaryOrange' />
                <Text>{ menu.name }</Text>
              </div>
              <div className='columns pt-3'>
                <div className='column is-one-quarter'>
                  <Text weight='bold' className='pb-2'>Menu Hours</Text>
                  { weekdays.map(day => (
                    <div className='profile-container' key={ day }>
                      <FontAwesomeIcon icon='fa-regular fa-clock' className='mr-2 tertiaryOrange' />
                      <Text>
                        <span className='bold'>{ day }:  </span>
                        { displayHours(day, menu.availability) }
                      </Text>
                    </div>
                  )) }
                </div>
                <div className='column is-two-fifths'>
                  <Text weight='bold' className='pb-2'>Selected Locations</Text>
                  <div className='columns is-mobile is-vcentered is-multiline p-3'>
                    { store.locations.length && menu.locations && menu.locations.map(location => (
                      <div
                        key={ location }
                        style={ { marginLeft: 0 } }
                        className='small-text column is-half p-0'
                      >
                        <div className='profile-container'>
                          <FontAwesomeIcon className='brightGreen pr-3' icon={ ['fal', 'check'] } />
                          <Text>
                            { getLocationNameById(location) }
                          </Text>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              </div>
            </div>
          </AdminCard>
          <AdminCard
            title='Categories'
            onHeaderActionClick={ this.editCategories }
            linkText='Add/Edit Categories'
            style={ { paddingBottom: isDragging ? 60 : 20 } }
          >
            <Table className='app-table-wrapper'>
              <TableHead className='app-table-header'>
                <TableRow className='app-table-row'>
                  <TableCell className='app-table-cell' style={ { width: '20%' } }>Category/Item Name</TableCell>
                  <TableCell className='app-table-cell' style={ { width: '40%' } }>Description</TableCell>
                  <TableCell className='app-table-cell' style={ { width: '10%' } }>Enabled</TableCell>
                  <TableCell className='app-table-cell' style={ { width: '8%' } }>
                  </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext
                onDragEnd={ this.onDragEnd }
                onBeforeCapture={ this.onBeforeCapture }
              >
                <Droppable droppableId='droppable'>
                  { (provided, snapshot) => (
                    <TableBody
                      ref={ provided.innerRef }
                      { ...provided.droppableProps }
                      className='app-table-body'
                    >
                      { this.state.categories.length ?
                        <React.Fragment>
                          { this.state.categories.map((category, index) => (
                            <React.Fragment key={ category.id }>
                              <Draggable draggableId={ category.id } index={ index }>
                                { (provided, snapshot) => (
                                  <TableRow
                                    ref={ provided.innerRef }
                                    { ...provided.draggableProps }
                                    { ...provided.dragHandleProps }
                                    style={ this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    ) }
                                    className={ `app-table-row ${ this.state.inFocus === category.id ? 'highlighted-row' : '' }` }
                                    onMouseOver={ () => this.setState({ inFocus: category.id }) }
                                  >
                                    <TableCell
                                      style={ { width: 225 } }
                                      className={ `app-table-cell pl-0 ${ this.state.rowCollapsed === category.id ? 'remove-row-border' : '' }` }
                                    >
                                      <FontAwesomeIcon icon='fa-solid fa-grip-dots-vertical' size='1x' />
                                      <IconButton
                                        aria-label='expand row'
                                        onClick={ () => { this.toggleRow(category); } }
                                        className='p-0 pr-3'
                                      >
                                        { this.state.rowCollapsed === category.id ?
                                          <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } /> :
                                          <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-right'] } /> }
                                      </IconButton>
                                      { category.image ?
                                        <FontAwesomeIcon icon='fa-solid fa-image' className='pr-2 secondaryBlue' />
                                        : <FontAwesomeIcon icon='fa-regular fa-image-slash' className='pr-2 disabledGrey' />
                                      }
                                      { category.name }
                                    </TableCell>
                                    <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === category.id ? 'remove-row-border' : '' }` }>
                                      { `${ category.items.length } Items` }
                                    </TableCell>
                                    <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === category.id ? 'remove-row-border' : '' }` }>
                                      { category.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                                    </TableCell>
                                    <TableCell className={ `app-table-cell has-text-right ${ this.state.rowCollapsed === category.id ? 'remove-row-border' : '' }` }>
                                      { this.state.inFocus === category.id ?
                                        <Button
                                          edit
                                          onClick={ () => this.editCategory(category) }
                                        >
                                          Edit
                                        </Button> : null }
                                    </TableCell>
                                  </TableRow>
                                ) }
                              </Draggable>
                              <div style={ { display: 'none' } }>{ provided.placeholder }</div>
                              <TableRow className='app-table-row' style={ { display: isDragging ? 'none' : '', height: isDragging ? 0 : 'auto' } }>
                                <TableCell className='app-table-cell p-0' style={ { border: 'none' } } colSpan={ 5 }>
                                  <Collapse
                                    in={ this.state.rowCollapsed === category.id }
                                    timeout='auto'
                                    unmountOnExit
                                    collapsedSize='0px'
                                  >
                                    <div style={ { paddingLeft: 20, width: '100%', flex: 1, overflowX: 'auto' } }>
                                      <Table className='app-table-wrapper mt-0'>
                                        <TableHead className='app-table-header'>
                                          <TableRow className='app-table-row'>
                                            <TableCell className='app-table-cell' style={ { width: '20%' } } />
                                            <TableCell className='app-table-cell' style={ { width: '40%' } } />
                                            <TableCell className='app-table-cell' style={ { width: '10%' } } />
                                            <TableCell className='app-table-cell' style={ { padding: 0, width: '8%' } } />
                                          </TableRow>
                                        </TableHead>
                                        <TableBody className='app-table-body'>
                                          { category.items && category.items.length ?
                                            category.items.map(item =>
                                              <TableRow
                                                key={ item.id }
                                                className={ `app-table-row ${ inFocusItem === item.id ? 'highlighted-row ' : '' }` }
                                                onMouseOver={ () => this.setState({ inFocusItem: item.id }) }
                                              >
                                                <TableCell className='app-table-cell'>
                                                  { item.image ?
                                                    <FontAwesomeIcon icon='fa-solid fa-image' className='pr-2 secondaryBlue' />
                                                    : <FontAwesomeIcon icon='fa-regular fa-image-slash' className='pr-2 disabledGrey' />
                                                  }
                                                  { item.name }
                                                </TableCell>
                                                <TableCell className='app-table-cell pl-0'>
                                                  { item.description }
                                                </TableCell>
                                                <TableCell className='app-table-cell' style={ { paddingLeft: 10 } }>
                                                  { item.is_available ? <span className='brightGreen semi-bold'>Available</span> : <span className='ordrslipRed semi-bold'>Unavailable</span> }
                                                </TableCell>
                                                <TableCell className='app-table-cell has-text-right'>
                                                  { inFocusItem === item.id ?
                                                    <Button
                                                      edit
                                                      onClick={ () => this.editItem(item.id) }
                                                    >
                                                      Edit
                                                    </Button> : null }
                                                </TableCell>
                                              </TableRow>) : null }
                                        </TableBody>
                                      </Table>
                                    </div>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>)) }
                        </React.Fragment> :
                        <TableRow className='app-table-row'>
                          <TableCell className='app-table-cell'>
                            This menu has no categories yet.
                          </TableCell>
                        </TableRow>
                       }
                    </TableBody>
                  ) }
                </Droppable>
              </DragDropContext>
            </Table>
          </AdminCard>
        </div>
        <MenuAddEdit
          onClose={ (updated) => this.handleClose(updated, 'menu') }
          open={ this.state.open }
          menu={_.cloneDeep(menu)}
        />
        { menu.categories &&
          <MenuAddCategories
          onClose={ (updated) => this.handleClose(updated, 'add categories') }
          open={ this.state.openAddCategories }
          categories={ this.state.menu.categories }
          menuId={this.state.menu.id}
          />
        }
        <CategoryEdit
          category={ this.state.selectedCategory }
          onClose={ (updated) => this.handleClose(updated, 'category') }
          open={ this.state.openCategoryEdit }
        />
        <ItemEdit
          item={ this.state.selectedItem }
          onClose={ (updated) => this.handleClose(updated, 'item') }
          open={ this.state.openItemEdit }
        />
      </div>
    );
  }
}

export default withNav(withRouter(observer(MenuDetails)));
