import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import Drawer from '@material-ui/core/Drawer';
import { errorMessage, fieldError } from "../../services/utils";
import tenantAPI from '../../services/api/tenantAPI';


class WaitTimeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait_time_base_pickup: this.props.location.wait_time_base_pickup,
      wait_time_base_delivery: this.props.location.wait_time_base_delivery,
      wait_time_per_order: this.props.location.wait_time_per_order,
      wait_time_per_item: this.props.location.wait_time_per_item,
      apiErrors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      wait_time_base_pickup: nextProps.location.wait_time_base_pickup,
      wait_time_base_delivery: nextProps.location.wait_time_base_delivery,
      wait_time_per_order: nextProps.location.wait_time_per_order,
      wait_time_per_item: nextProps.location.wait_time_per_item,
    });
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      wait_time_base_pickup: this.state.wait_time_base_pickup || 0,
      wait_time_base_delivery: this.state.wait_time_base_delivery || 0,
      wait_time_per_order: this.state.wait_time_per_order || 0,
      wait_time_per_item: this.state.wait_time_per_item || 0
    };
    tenantAPI.updateLocation(this.props.location.id, JSON.stringify(payload))
      .then(response => {
        if (response.errors) {
          this.setState({ apiErrors: response.errors });
        } else {
          this.setState({ apiErrors: {} });
          this.props.onUpdate(response);
          this.props.onClose();
        }
      })
      .catch(err => console.error(err));
  };

  render() {
    return (
      <Drawer anchor="right" open={this.props.open} classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }} onClose={this.props.onClose}>
        <EditDrawer title="Edit Wait Time Formula" onSubmit={this.handleSubmit} onClose={this.props.onClose}>
          <AdminCard
            largeTitle
            title="Wait Time Formula"
            style={{ marginTop: "15px" }}
            description="Wait time is the estimated number of minutes a customer is told their order will take to prepare. The per order time and per item time are for both pickup and delivery orders."
          >
            {errorMessage(this.state.apiErrors)}
            <form>
              <div className="app-field-wrapper">
                <label htmlFor="wait_time_base_pickup" className="app-field-label">
                  Pickup BASE TIME (MINS)
                </label>
                <input
                  className={fieldError("wait_time_base_pickup", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="wait_time_base_pickup"
                  name="wait_time_base_pickup"
                  type="number"
                  placeholder="Pickup Base Time"
                  value={this.state.wait_time_base_pickup}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('wait_time_base_pickup', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="wait_time_base_delivery" className="app-field-label">
                  Delivery BASE TIME (MINS)
                </label>
                <input
                  className={fieldError("wait_time_base_delivery", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="wait_time_base_delivery"
                  name="wait_time_base_delivery"
                  type="number"
                  placeholder="Delivery Base Time"
                  value={this.state.wait_time_base_delivery}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('wait_time_base_delivery', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="wait_time_per_order" className="app-field-label">
                  PER ORDER TIME (MINS)
                </label>
                <input
                  className={fieldError("wait_time_per_order", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="wait_time_per_order"
                  name="wait_time_per_order"
                  type="number"
                  placeholder="Per Order Time"
                  value={this.state.wait_time_per_order}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('wait_time_per_order', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="wait_time_per_item" className="app-field-label">
                  PER ITEM TIME (MINS)
                </label>
                <input
                  className={fieldError("wait_time_per_item", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="wait_time_per_item"
                  name="wait_time_per_item"
                  type="number"
                  placeholder="Per Item Time"
                  value={this.state.wait_time_per_item}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('wait_time_per_item', this.state.apiErrors)}
              </div>
            </form>
          </AdminCard>
        </EditDrawer>
      </Drawer>
    );
  }
}

export default WaitTimeEdit;
