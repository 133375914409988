import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { FormControlLabel } from "@material-ui/core";

import { errorMessage, fieldError } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminCheckbox from '../elements/adminCheckbox';
import EditDrawer from '../elements/editDrawer';
import { HeaderText, Text } from '../elements/text';
import LocationSelection from '../locationSelection';
import ImageCard from '../imageCard';
import messagesAPI from '../../services/api/messagesAPI';


class PushNotificationEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            notification: _.cloneDeep(this.props.notification),
            selectedLocations: !_.isEmpty(this.props.notification) ? this.props.notification.locations : [],
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            schedule_type: this.props.notification.notification_date ? 'scheduled' : 'now',
            apiErrors: {},
            error: '',
            delete_image: false,
            fileUrl: '',
            file: null,
        };
    };

    handleClose = () => {
        this.setState({
            notification: this.props.notification,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            selectedLocations: [],
            schedule_type: 'now',
            apiErrors: {},
            error: '',
            delete_image: false,
            fileUrl: '',
            file: null
        }, () => {
            this.props.onClose();
        });
    };

    handleChange = event => {
        let { notification } = this.state;
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'schedule_type') {
            this.setState({ schedule_type: value });
            return;
        }
        if (['url_text', 'url_link'].includes(name)) {
            if (!notification.action_link) {
                notification.action_link = { [name]: value };
            } else {
                notification.action_link[name] = value;
            }
            this.setState({ notification });
            return;
        }
        notification[name] = value;
        this.setState({ notification });
    };

    deleteNotification = () => {
        messagesAPI.deletePushNotification(this.state.notification.id)
            .then(() => {
                this.handleClose(true);
        });
    };

    handleSubmit = () => {
        const { notification, delete_image } = this.state;
        let payload = {
            locations: notification.locations || [],
            status: notification.status || 'scheduled',
            title: notification.title,
            notification_date: notification.notification_date,
            message: notification.message,
            user_days: notification.user_days,
            user_type: notification.user_type,
            action_link: {
                url_link: '',
                url_text: ''
            }
        };
        if (['all', 'user'].includes(notification.user_type)) {
            if (!this.state.selectedLocations.length &! notification.locations.length) {
                this.setState({ apiErrors: { location: [{ message: 'You must choose a location.' }] } });
                return;
            }
        }
        if (!notification.notification_date) payload.notification_date = moment().add(10, "m").toDate();
        if (notification.action_link && notification.action_link.url_link) payload.action_link = notification.action_link;
        if (delete_image) payload.delete_image = true;
        if (notification.id) {
            // Update Notification
            messagesAPI.updatePushNotification(notification.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        if (this.state.fileUrl) {
                            let data = new FormData();
                            data.append('image', this.state.file);
                            messagesAPI.addPushNotificationImage(notification.id, data)
                                .then(response => {
                                    if (response.errors) {
                                        this.setState({ apiErrors: response.errors });
                                    } else {
                                        this.props.updateParentState({ notification: response.data });
                                        this.props.openPreview();
                                    }
                                });
                        } else {
                            this.props.updateParentState({ notification: response.data });
                            this.props.openPreview();
                        }
                    }
                })
        } else {
            // Create Notification
            messagesAPI.createPushNotification(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        if (this.state.fileUrl) {
                            let data = new FormData();
                            data.append('image', this.state.file);
                            messagesAPI.addPushNotificationImage(response.data.id, data)
                                .then(response => {
                                    if (response.errors) {
                                        this.setState({ apiErrors: response.errors });
                                    } else {
                                        this.props.updateParentState({ notification: response.data });
                                        this.props.openPreview();
                                    }
                                });
                        } else {
                            messagesAPI.getRecursiveNotifications({ page: 1 });
                            this.props.updateParentState({ notification: response.data });
                            this.props.openPreview();
                        }
                    }
                });
        }
    };

    updateLocations = locations => {
        const { notification } = this.state;
        notification.locations = locations;
        this.setState({ notification });
    };

    updateDate = event => {
        let { notification } = this.state;
        notification.notification_date = event;
        this.setState({ notification });
    };

    updateImage = (fileUrl, file) => {
        this.setState({ fileUrl, file, delete_image: false });
    };

    deleteImage = () => {
        this.setState({ fileUrl: '', file: null, delete_image: true });
    };

    changeUserType = user_type => {
        let { notification } = this.state;
        notification.user_type = user_type;
        if (user_type === 'user') {
            notification.user_days = '90';
        } else {
            notification.user_days = 'All';
        }
        this.setState({ notification });
    };

    errorMessage = (type, errors) => {
        if (errors[type] && errors[type][0]) {
            return errors[type][0].message;
        }
    };

    getImgUrl = () => {
        let url = null;
        const { notification, delete_image, fileUrl } = this.state;
        if (!delete_image) {
            if (notification.image) {
                url = notification.image;
            }
            if (!notification.image && fileUrl) {
                url = fileUrl;
            }
        }
        return url;
    };

    render() {
        const {
            apiErrors,
            error,
            notification,
        } = this.state;
        const { userTypes } = this.props;
        const editing = Boolean(notification.id);

        return (
            <EditDrawer
                title={ `${ editing ? 'Edit' : 'New' } Push Notification` }
                onClose={ this.handleClose }
                onSubmit={ this.handleSubmit }
                errorMessage={ errorMessage(apiErrors, error) }
                deleteButton={ editing }
                buttonAction={ editing ? this.deleteNotification : null }
                buttonText={ editing ? 'Delete Notification' : '' }
                submitText={ 'Schedule & Preview' }
            >
                <AdminCard
                    title='Push Notification Details'
                    style={ { marginTop: '15px' } }
                >
                    <div className='app-field-wrapper'>
                        <label htmlFor='broadcast_date' className='app-field-label'>
                            Date and Time
                        </label>
                        <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                            <KeyboardDateTimePicker
                                disablePast
                                inputVariant='outlined'
                                style={ { width: '100%' } }
                                value={ notification.notification_date || moment().add(10, "m").toDate() }
                                onChange={ (e) => this.updateDate(e) }
                            />
                        </MuiPickersUtilsProvider>
                        { fieldError('notification_date', apiErrors) }
                    </div>

                </AdminCard>
                <AdminCard
                    title='Customize Push Notification Message'
                    style={ { marginTop: '15px' } }
                    description={ '' }
                >
                    <div className='app-field-wrapper mt-3'>
                        <label htmlFor='title' className='app-field-label'>
                            Notification Title
                        </label>
                        <input
                            className={ `app-input-field mt-0 ${ fieldError('title', this.state.apiErrors)  ? 'red-border' : ''}`}
                            id='title'
                            name='title'
                            type='text'
                            placeholder='Type title here...'
                            value={ notification.title || '' }
                            onChange={ (e) => this.handleChange(e) }
                        />
                        { fieldError('title', this.state.apiErrors) }
                    </div>
                    <div className='app-field-wrapper'>
                        <label htmlFor='message' className='app-field-label'>
                            Message
                        </label>
                        <Text small>Limit 200 characters</Text>
                        <textarea
                            rows='4'
                            maxLength='200'
                            style={ { width: '100%', padding: 10 } }
                            className={ `app-input-field mt-0 ${ fieldError('message', apiErrors) ? 'red-border' : '' }` }
                            name='message'
                            placeholder='Type message here…'
                            value={ notification.message || '' }
                            onChange={ (e) => this.handleChange(e) }
                        />
                        <div>{ fieldError('message', apiErrors) }</div>
                    </div>
                    <div className='app-field-wrapper'>
                        <label htmlFor='url_text' className='app-field-label'>
                            Button Text (Optional)
                        </label>
                        <input
                            className={ `app-input-field mt-0 ${ fieldError('action_link', this.state.apiErrors) ? 'red-border' : '' }` }
                            id='url_text'
                            name='url_text'
                            type='text'
                            placeholder='Type button text here...'
                            value={ notification.action_link ? notification.action_link.url_text || '' : '' }
                            onChange={ (e) => this.handleChange(e) }
                        />
                        { fieldError('action_link', this.state.apiErrors) }
                    </div>
                    <div className='app-field-wrapper'>
                        <label htmlFor='url_text' className='app-field-label'>
                            Button Link (Optional)
                        </label>
                        <input
                            className={ `app-input-field mt-0 ${ fieldError('action_link', this.state.apiErrors) ? 'red-border' : '' }` }
                            id='url_link'
                            name='url_link'
                            type='link'
                            placeholder='Insert link here for button'
                            value={ notification.action_link ? notification.action_link.url_link || '' : '' }
                            onChange={ (e) => this.handleChange(e) }
                        />
                        { fieldError('action_link', this.state.apiErrors) }
                    </div>
                    <div className='mt-3'>
                        <HeaderText small>Header Image</HeaderText>
                        <Text>
                            Add a header image to your push notification.
                        </Text>
                        <ImageCard
                            imgUrl={ this.getImgUrl() }
                            file={ this.state.file }
                            fileUrl={ this.state.fileUrl }
                            altText={ notification.title }
                            cardTitle='Image'
                            description='Add an image to your push notification.'
                            item={ notification }
                            updateImage={ this.updateImage }
                            deleteImage={ this.deleteImage }
                            delete_image={ this.state.delete_image }
                        />
                    </div>
                </AdminCard>
                <AdminCard
                    title="User Selection"
                    style={ { marginTop: "15px" } }
                    description={ "" }
                >
                    { this.errorMessage("user_type", apiErrors)
                        ? errorMessage(apiErrors, this.errorMessage("user_type", apiErrors))
                        : null }
                    <div>
                        { userTypes.map((option, index) => (
                            <FormControlLabel
                                key={ index }
                                control={
                                    <AdminCheckbox
                                        checked={ notification.user_type === option.option }
                                        onChange={ () => this.changeUserType(option.option) }
                                        name={ option.option }
                                    />
                                }
                                label={ option.label }
                                style={ {
                                    marginTop: "-5px",
                                    display: "flex",
                                    flexDirection: "row",
                                } }
                            />
                        )) }
                    </div>
                </AdminCard>
                { ['all', 'user'].includes(notification.user_type) ?
                    <LocationSelection
                    itemName='push notification'
                    selectedLocations={ notification.locations || [] }
                    onUpdateLocations={ this.updateLocations }
                    fieldError={ fieldError('locations', apiErrors) }
                /> : null }
            </EditDrawer>
        );
    }
}

export default (PushNotificationEdit);