import React, { Component } from "react";
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';
import AdminCard from "./elements/adminCard";
import { fieldError } from "../services/utils";
import { observer } from "mobx-react";
import store from "../store/store";
import EditDrawer from "./elements/editDrawer";
import tenantAPI from "../services/api/tenantAPI";
import config from "../config/config";

class SupportEmailDrawer extends Component {
    state = {
        apiErrors: {},
        body: {
            name: store.user && store.user.id ? store.user.full_name : "",
            email: store.user && store.user.id ? store.user.email : "",
            phone: store.user && store.user.id ? store.user.phone : "",
            description: "",
            os_version: "AD",
            version: config.version,
            device: "AD",
            os: "AD"
        },
    };

    onClose = () => {
        this.props.onClose();
        this.setState({
            apiErrors: {},
            body: {
                name: store.user && store.user.id ? store.user.full_name : "",
                email: store.user && store.user.id ? store.user.email : "",
                phone: store.user && store.user.id ? store.user.phone : "",
                description: "",
                os_version: "AD",
                version: config.version,
                device: "AD",
                os: "AD"
            }
        });
    }

    handleChange = (event) => {
        let value = event.target.value;
        let type = event.target.name;
        let { body } = this.state;
        body[type] = value;
        this.setState(body);
    }

    sendMessage = () => {
        let payload = this.state.body;
        payload["device"] = window.navigator.userAgent;

        tenantAPI.sendSupportEmail(payload)
        .then((response) => {
            if (response.errors) {
                const errors = response.errors;
                let apiErrors = {};
                let keys = Object.keys(errors);
                _.forEach(keys, error => {
                    apiErrors[error] = [{message: errors[error]}];
                })
                this.setState({ apiErrors });
            } else {
                this.onClose();
            }
        });
    }

    render() {
        const { apiErrors, body } = this.state;
        const { open } = this.props;

        return (
            <>
                {open ?
                    <Drawer
                        anchor="right"
                        open={open}
                        classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}
                        onClose={() => this.onClose(false)}
                    >
                        <EditDrawer
                            title="Contact Support"
                            onClose={this.onClose}
                            onSubmit={() => this.sendMessage()}
                            submitText="Send Support Request"
                        >
                            <AdminCard
                                title="Having an issue?"
                                description={`Fill out the form below and we will reach out to you as soon as we can.`}
                                style={{ marginTop: "15px" }}
                            >
                            <div className="app-field-wrapper">
                                <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Name</label>
                                <input
                                    className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    value={body.name}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                {fieldError('name', apiErrors)}
                            </div>
                            <div className="app-field-wrapper">
                                <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Email</label>
                                <input
                                    className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    value={body.email}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                {fieldError('email', apiErrors)}
                            </div>
                            <div className="app-field-wrapper">
                                <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Phone</label>
                                <input
                                    className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={body.phone}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                {fieldError('phone', apiErrors)}
                            </div>
                            <div className="app-field-wrapper">
                                <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Message</label>
                                <textarea
                                    className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                    id="description"
                                    name="description"
                                    type="text"
                                    rows="3"
                                    placeholder="Description of issue you are having or what you would like to let customer support know."
                                    value={body.description}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                {fieldError('description', apiErrors)}
                            </div>
                            </AdminCard>
                        </EditDrawer>
                    </Drawer>
                : null}
            </>
        );
    }
}

export default observer(SupportEmailDrawer);