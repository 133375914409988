import React, { Component } from 'react';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import menuAPI from '../../services/api/menuAPI';
import store from '../../store/store';
import { errorMessage, fieldError } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import DraggableTable from '../elements/draggableTable';
import EditDrawer from '../elements/editDrawer';
import { Text } from '../elements/text';

class ModifierListEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            modifierList: this.props.modifierList,
            reordered: false
        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.modifierList !== this.props.modifierList) {
            this.setState({
                modifierList: nextProps.modifierList,
            });
        }
    };

    toggleEnabled = () => {
        let { modifierList } = this.state;
        modifierList.is_enabled = !modifierList.is_enabled;
        this.setState({ modifierList });
    };

    handleChange(event) {
        let { modifierList } = this.state;
        const value = event.target.value;
        const name = event.target.name;
        modifierList[name] = value;
        this.setState({ modifierList });
    };

    handleSubmit = () => {
        const { modifierList, reordered } = this.state;
        let payload = {
            is_enabled: modifierList.is_enabled,
            minimum: modifierList.minimum,
            maximum: modifierList.maximum
        };
        if (modifierList.is_enabled) {
            payload.hide_at_locations = modifierList.hide_at_locations;
        };
        if (reordered) {
            // Bulk update modifiers
            let modsPayload = {'objects': []}
            modifierList.modifiers.forEach((mod, index) => {
                modsPayload.objects.push({ 'id': mod.id, 'order': mod.order });
            });
            menuAPI.bulkUpdate('modifiers', JSON.stringify(modsPayload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        return;
                    } else {
                        this.setState({ apiErrors: {} });
                    }
                })
                .catch(err => console.error(err));
        }
        menuAPI.updateModifierList(modifierList.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    this.setState({ apiErrors: {} });
                    this.handleClose(true);
                }
            })
            .catch(err => console.error(err));
    };

    handleClose = updated => {
        this.setState({ apiErrors: {}, reordered: false, modifierList: this.props.modifierList });
        this.props.onClose(updated);
    };

    toggleHideAtLocation = locationId => {
        const { modifierList } = this.state;
        if (modifierList.hide_at_locations.includes(locationId)) {
            let idx = modifierList.hide_at_locations.indexOf(locationId);
            modifierList.hide_at_locations.splice(idx, 1);
        } else {
            modifierList.hide_at_locations.push(locationId);
        }
        this.setState({ modifierList });
    };

    updateDragged = list => {
        let { modifierList } = this.state;
        modifierList.modifiers = list;
        this.setState({ modifierList, reordered: true });
    };

    render() {
        const { modifierList, apiErrors } = this.state;
        if (_.isEmpty(modifierList)) return <div />;
        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ () => this.handleClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={ `Edit ${ modifierList.name }` }
                    onSubmit={ this.handleSubmit }
                    onClose={ () => this.handleClose(false) }
                    errorMessage={ errorMessage(apiErrors) }
                >
                    <AdminCard
                        title='Enable/Disable Modifier List'
                        style={ { marginTop: '15px' } }
                        description='When enabled, this modifier list will appear on your mobile app and/or online ordering.'
                    >
                        <AdminToggle
                            className='app-toggle'
                            checked={ modifierList.is_enabled }
                            onChange={ this.toggleEnabled }
                        />
                    </AdminCard>
                    { modifierList.is_enabled ?
                        <AdminCard
                            title='Modifier List Availability'
                            description='This modifier list will show as available in the mobile app based on these settings.'
                        >
                            <Table className='app-table-wrapper' size='small'>
                                <TableHead className='app-table-header'>
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell pl-0 half-table-width'>
                                            Location
                                        </TableCell>
                                        <TableCell className='app-table-cell'>
                                            Displayed In App
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { store.locations &&
                                        store.locations.length &&
                                        store.locations.map(location => (
                                            <React.Fragment key={ location.id }>
                                                <TableRow
                                                    className='table-row'
                                                    onClick={ this.handleOnRowClick }
                                                >
                                                    <TableCell className='app-table-cell pl-0'>
                                                        { location.name }
                                                    </TableCell>
                                                    <TableCell className='app-table-cell'>
                                                        <AdminToggle
                                                            className='app-toggle'
                                                            checked={ !modifierList.hide_at_locations.includes(location.id) }
                                                            onChange={ () => this.toggleHideAtLocation(location.id) }
                                                            hideLabel
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )) }
                                </TableBody>
                            </Table>
                        </AdminCard> : null }
                    <AdminCard
                        title='Organize Items Within Modifier List'
                        description='Organize your items the way they will display in your app and/or online ordering.'
                    >
                        { modifierList.modifiers.length ?
                            <DraggableTable
                                list={ modifierList.modifiers }
                                headerData={ ['Modifier Name', 'Enabled'] }
                                fieldData={['name', 'is_enabled']}
                                updateParentState={ this.updateDragged }
                            />
                            : <Text>There are no modifiers in this list.</Text>
                        }
                    </AdminCard>
                    <AdminCard
                        title='Minimum/Maximum Ordered from Modifier List'
                        description='Setting a minimum/maximum will limit users to the amount they can order from this modifier list. (Optional)'
                    >
                        <div className='app-field-wrapper'>
                            <div className='app-field-label' style={ { marginTop: '15px' } }>Minimum</div>
                            <input
                                className={ `app-input-field mt-0 ${ fieldError('minimum', apiErrors) ? 'red-border' : '' }` }
                                name='minimum'
                                type='number'
                                placeholder='Minimum'
                                value={ modifierList.minimum }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            <div>{ fieldError('minimum', apiErrors) }</div>
                        </div>
                        <div className='app-field-wrapper'>
                            <div className='app-field-label' style={ { marginTop: '15px' } }>Maximum</div>
                            <input
                                className={ `app-input-field mt-0 ${ fieldError('maximum', apiErrors) ? 'red-border' : '' }` }
                                name='maximum'
                                type='number'
                                placeholder='Maximum'
                                value={ modifierList.maximum ? modifierList.maximum : '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            <div>{ fieldError('maximum', apiErrors) }</div>
                        </div>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default ModifierListEdit;
