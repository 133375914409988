import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import store from "../store/store";
import AdminCard from "../components/elements/adminCard";
import AdminToggle from "../components/elements/adminToggle";
import AdminPageHeader from "../components/elements/adminPageHeader";
import tenantAPI from '../services/api/tenantAPI';


class SquareHardware extends Component {

  toggleRegisterConnection = location => {
    const payload = JSON.stringify({ enable_square_register: !location.enable_square_register });
    this.updateLocation(location.id, payload);
  };

  toggleAutoComplete = location => {
    const payload = JSON.stringify({ auto_complete_orders_enabled: !location.auto_complete_orders_enabled });
    this.updateLocation(location.id, payload);
  };

  updateLocation = (location_id, payload) => {
    tenantAPI.updateLocation(location_id, payload)
    .then(() => tenantAPI.getRecursiveLocations({ page: 1 }));
  };

  render() {
    return (
      <div>
        <div className="admin-page-wrapper">
          <AdminPageHeader
            title="Square Hardware"
            mainNav="Configuration"
            subNav="Integrations"
            description="Manage the connection between Ordrslip and your Square register. With this enabled, orders placed with your Ordrslip app will appear in your Square register alongside your in-store orders."
          />
          <div >
            <div className="pt-2">
              <div className="app-card-section-wrapper">
                <div className="error-message">
                  <div className="columns error-message not-bold">
                    <div className="is-1 display-in-line center-items">
                      <FontAwesomeIcon size="2x" className='error-icon' icon={['far', 'exclamation-circle']} />
                    </div>
                    <div className="is-11">
                      <span className="bold">NOTE:</span> Square does not currently support special instructions on orders or items. <span className="bold">If you choose to send app orders to your Square register, you
                        should disable special instructions for both orders and items at all locations.</span> To do this, click "Locations" in the sidebar, select a location, and scroll down to "Enable/Disable Special Instructions".
                      </div>
                  </div>
                </div>
              </div>
              <AdminCard title="Enable/Disable Square Register Connection" largeTitle>
                <Table className="app-table-wrapper">
                    <TableHead className="app-table-header">
                      <TableRow className="app-table-row">
                        <TableCell className="app-table-cell">
                          Location
                        </TableCell>
                        <TableCell className="set-thin-width has-text-centered app-table-cell">
                          Enabled
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="app-table-body">
                    {store.locations &&
                      store.locations.length &&
                      store.locations.map(location => (
                        <TableRow
                        key={location.id}
                        className="app-table-row"
                        >
                          <TableCell className="app-table-cell">
                          {location.name}
                          </TableCell>
                          <TableCell className="set-thin-width has-text-centered app-table-cell">
                            <AdminToggle
                              className="app-toggle"
                              checked={location.enable_square_register}
                              onChange={() => this.toggleRegisterConnection(location) }
                              hideLabel
                            />
                          </TableCell>
                      </TableRow>
                      ))}
                    </TableBody>
                </Table>
              </AdminCard>
              <AdminCard
                title="Enable/Disable Auto Complete Orders"
                description={ <span>In order to use Square KDS you need to have that location set to auto complete orders. Square KDS does not have an option to mark an order as complete, just that it is ready, so you will need to enable auto complete or use the Square Register to complete the orders manually. If orders are not completed they will not show up properly in your sales reports. <span className='bold'>Note: Curbside pickup orders will not be auto completed.</span></span> }
                largeTitle
              >
                <Table className="app-table-wrapper">
                    <TableHead className="app-table-header">
                      <TableRow className="app-table-row">
                        <TableCell className="app-table-cell">
                          Location
                        </TableCell>
                        <TableCell className="set-thin-width has-text-centered app-table-cell">
                          Auto Complete Enabled
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="app-table-body">
                    {store.locations &&
                      store.locations.length &&
                      store.locations.map(location => (
                        <TableRow
                        key={location.id}
                        className="app-table-row"
                        >
                          <TableCell className="app-table-cell">
                          {location.name}
                          </TableCell>
                          <TableCell className="set-thin-width has-text-centered app-table-cell">
                            <AdminToggle
                              className="app-toggle"
                              checked={ location.auto_complete_orders_enabled }
                              onChange={() => this.toggleAutoComplete(location) }
                              hideLabel
                            />
                          </TableCell>
                      </TableRow>
                      ))}
                    </TableBody>
                </Table>
              </AdminCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(SquareHardware);
