import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import store from '../../store/store';
import AdminSearchInput from './adminSearchInput';
import { getNameById } from '../../services/utils';
import { toJS } from 'mobx';


class AdminSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inFocus: '',
            searchResults: [],
            searchText: '',
            selectedItem: {}
        };
    };

    removeselectedItem = (item) => {
        const { selectedItems } = this.props;
        let index = selectedItems.indexOf(item);
        if (index > -1) {
            selectedItems.splice(index, 1);
        }
        this.props.onUpdateItems(selectedItems);
    };

    searchItems = text => {
        let { list_name } = this.props;
        let search_field = 'name';
        if (list_name === 'users') search_field = 'full_name';
        this.setState({ searchText: text });
        if (text !== '') {
            const results = store[list_name].filter(obj => {
                return obj[search_field].toLowerCase().includes(text.toLowerCase());
            });
            this.setState({ searchResults: results });
        } else {
            this.setState({ searchResults: [] });
        }
    };

    onSelectSearchResult = selectedItems => {
        this.setState({ searchText: '', searchResults: [] });
        this.props.onUpdateItems(selectedItems);
    };

    render() {
        const { searchText, searchResults, selectedItem } = this.state;
        const { list_name } = this.props;

        return (
            <div>
                <div className={ `columns is-mobile ${ this.state.searchResults.length === 0 ? 'is-vcentered' : '' }` }>
                    <div className='column is-full'>
                        <AdminSearchInput
                            value={ searchText }
                            onChange={ this.searchItems }
                        />
                        { searchResults.length >= 1 ?
                            <div>
                                <div className='search-results'>
                                    { searchResults.map((result, index) => (
                                        <div
                                            key={ index }
                                            onMouseOver={ () => { this.setState({ selectedItem: result.id }); } }
                                            className={ selectedItem === result.id ? 'highlighted-result' : 'result' }
                                            onClick={ () => {
                                                let selectedItems = this.props.selectedItems;
                                                selectedItems.push(result.id);
                                                selectedItems = _.uniqBy(selectedItems);
                                                this.onSelectSearchResult(selectedItems);
                                            } }
                                        >
                                            { list_name === 'users' ? result.full_name : result.name }
                                        </div>
                                    )) }
                                </div>
                            </div> : null }
                    </div>
                </div>
                <div className='columns is-mobile is-vcentered is-multiline'>
                    { this.props.selectedItems.map((item, index) => (
                        <div
                            key={ index }
                            onMouseOver={ () => this.setState({ inFocus: item }) }
                            style={ { marginLeft: 0 } }
                            className='small-text column is-half'
                        >
                            <div className='columns is-mobile is-vcentered'>
                                <div style={ { paddingRight: 10 } } className='column is-one-fifth pt-0'>
                                    <FontAwesomeIcon className='brightGreen' size='1x' icon={ ['fal', 'check'] } />
                                </div>
                                <div
                                    className='column is-three-fifths pt-0'
                                    style={ { paddingLeft: 0, paddingRight: 10 } }>
                                    { getNameById(item, list_name) } </div>
                                <div
                                    style={ { paddingLeft: 0 } }
                                    className='column is-one-fifth pt-0'
                                    onClick={ () => {
                                        this.removeselectedItem(item);
                                    } }
                                >
                                    { this.state.inFocus === item ?
                                        <FontAwesomeIcon className='cursor-pointer' color='#A8A8A8' size='1x' icon={ ['fal', 'times-circle'] } />
                                        : null }
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
                { !this.props.selectedItems.length ? <div className='red-error-text pt-0'>
                    You must choose at least one.
                </div> : null }
            </div>
        );
    }
}

export default (AdminSelection);