import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { RadioGroup } from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import RadioLabel from "../elements/radioLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AdminCard from "../elements/adminCard";
import EditDrawer from "../elements/editDrawer";
import PushNotification from './pushNotification';
import messagesAPI from '../../services/api/messagesAPI';
import AdminSelection from '../elements/adminSelection';

const testUserTypes = [
  {
    label: "All Admin",
    option: "admin",
  },
  {
    label: "All Staff",
    option: "staff",
  },
  {
    label: "Choose Specific User(s)",
    option: "custom",
  },
];


class PushNotificationTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      searchResults: [],
      searchText: '',
      selectedUser: {},
      selectedUsers: this.props.notification.users || [],
      success: false,
      userInFocus: '',
      userType: "admin",
    };
  };

  getUserType = () => {
    const { notification, userTypes } = this.props;
    let user_type = _.find(userTypes, (type) => notification.user_type === type.option);
    return user_type.label;
  };

  updateUsers = users => {
    let selectedUsers = [];
    _.forEach(users, (user) => {
      selectedUsers.push(user)
    });
    this.setState({ selectedUsers });
  };

  sendTest = () => {
    let payload = {
      users: this.state.selectedUsers,
      user_type: this.state.userType
    };
    messagesAPI.sendTestPushNotification(this.props.notification.id, JSON.stringify(payload))
      .then(response => {
        if (response.errors) {
          this.setState({ error: true, success: false });
        } else {
          this.setState({ success: true, error: false });
        }
      });
  };

  render() {
    const { onClose, notification } = this.props;
    return (
      <EditDrawer
        title="Send Test Push Notification"
        onClose={onClose}
        onSubmit={this.sendTest}
        errorMessage={ this.state.error ?
          <div style={ { marginBottom: 20 } }>
            <div className="form-error-message">
              <ErrorOutline className="error-icon" />
              { "Unable to send test notification." }
            </div>
          </div>
          : null }
        cancelText={ this.state.success ? "Close" : "Cancel" }
        noSubmitButton={ this.state.success }
        submitText="Send Test"
        successMessage={
          this.state.success ? (
            <div className="green-success-box">
              <FontAwesomeIcon
                size="lg"
                color="#fff"
                icon={ ["far", "check-circle"] }
              />
              <div className="bold white-success-text">
                Your test notification has successfully sent
              </div>
            </div>
          ) : null }
      >
        <AdminCard
          title='Test Push Notification Preview'
          style={ { marginTop: '15px' } }
          description=''
        >
          <PushNotification notification={ notification } />
          <div className="app-field-wrapper">
            <label className="app-field-label">Test User Selection</label>
            <RadioGroup
              name="test_user_type"
              value={ this.state.userType }
              onChange={ (e) => { this.setState({ userType: e.target.value })} }
              className="radio-group"
            >
              { testUserTypes.map((option, index) => (
                <RadioLabel
                  key={ index }
                  value={ option.option }
                  label={ option.label }
                />
              )) }
            </RadioGroup>
            { this.state.userType === "custom" ? (
              <AdminSelection
                list_name='users'
                selectedItems={ this.state.selectedUsers }
                onUpdateItems={ this.updateUsers }
              />
            ) : null }
          </div>
        </AdminCard>
      </EditDrawer>
    );
  }
}

export default observer(PushNotificationTest);
