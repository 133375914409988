import React, { Component } from "react";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HolidayHoursEdit from "./holidayHoursEdit";
import store from "../../store/store";
import AdminCard from '../elements/adminCard';
import { Text } from "../elements/text";
import { formatDateForView } from "../../services/utils";

class HolidayHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      location: props.location,
    };
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  handleDeleteHoliday = response => {
    this.props.deleteHoliday(response);
    this.toggleDrawer();
  };

  openStatus = holiday => {
    return (holiday.store_from || holiday.pickup_from || holiday.pickup_to
      ? "Special Hours"
      : "Closed");
  };

  render() {
    const { location } = this.props;
    const holidays = location.holidays;

    return (
      <div>
        <AdminCard
          largeTitle
          title="Holiday Hours"
          description="Holiday hours you set here will override the default hours for the date of the holiday only."
          onHeaderActionClick={this.toggleDrawer}
          toolTip={ <><Text>Need more info on how to set up holiday hours? Visit our knowledge base article to see how to set up a holiday.</Text><br /><a href="http://info.ordrslip.com/support/updating-holiday-hours" rel="noopener noreferrer" target="_blank" className="ordrslipRed">info.ordrslip.com/support/updating-holiday-hours</a></>}
        >
          <Table className="app-table-wrapper">
            <TableHead className="app-table-header">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell pl-0" style={{ width: 150 }}>
                    Date
                </TableCell>
                <TableCell className="app-table-cell" style={{ width: 200 }}>
                    Open Status
                </TableCell>
                <TableCell className="app-table-cell">
                    Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="app-table-body">
              {holidays && holidays.length ? (
                holidays.map(holiday => (
                  <TableRow key={holiday.id} className="app-table-row">
                    <TableCell className="app-table-cell pl-0">{formatDateForView(holiday.date)}</TableCell>
                    <TableCell className="app-table-cell">{this.openStatus(holiday)}</TableCell>
                    <TableCell className="app-table-cell">{holiday.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="app-table-cell pl-0">There are no holidays scheduled.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </AdminCard>
        <HolidayHoursEdit
          open={this.state.open}
          onClose={this.toggleDrawer}
          location={location}
          deleteHoliday={this.handleDeleteHoliday}
          onUpdate={ this.props.onUpdate }
        />
      </div>
    );
  }
}

export default observer(HolidayHours);
