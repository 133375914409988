import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import _ from 'lodash';
import moment from "moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import store from "../../store/store";
import { errorMessage, fieldError, weekdays } from "../../services/utils";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import RadioLabel from "../elements/radioLabel";
import { Text } from "../elements/text";
import LocationSelection from '../locationSelection';
import PromoHours from "../promo/promoHours";
import { observer } from "mobx-react";
import promotionsAPI from '../../services/api/promotionsAPI';

const emptyPromoCode = {
    name: '',
    amount_off: { amount: "0", currency: 'USD' },
    percent_off: '',
    minimum_order_amount: { amount: "0", currency: 'USD' },
    apply_to_minimum: true,
    locations: [],
    sale_hours: [],
    is_active: true,
    discount_type: 'amount',
    description: '',
    status: true,
};

class SalesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            promoCode: !_.isEmpty(this.props.promoCode) ? this.props.promoCode : emptyPromoCode,
            selectedLocations: !_.isEmpty(this.props.promoCode) && this.props.promoCode.locations.length ? this.props.promoCode.locations : [],
            discountType: !_.isEmpty(this.props.promoCode) && this.props.promoCode.percent_off ? 'percent' : 'amount',
            itemsType: 'order',
            apiErrors: {},
            error: '',
            selectedDays: [],
            fromDate: new Date(),
            toDate: new Date(moment().add(1, 'day')),
            duration: 'ongoing' // 'datetime' or 'ongoing'
        };
    };

    componentDidMount = () => {
        const { promoCode, selectedDays } = this.state;
        if (!promoCode.sale_hours) promoCode.sale_hours = [];
        weekdays.forEach(day => {
            promoCode.sale_hours.push({
                weekday: day,
                start_time: null,
                end_time: null,
            });
        });
        let availability = _.cloneDeep(promoCode.sale_hours);
        let hours = promoCode.sale_hours || [];
        hours.map((hour) => selectedDays.push(hour.weekday));
        if (promoCode.sale_hours) {
            weekdays.forEach((weekday) => {
                promoCode.sale_hours.forEach((coupon_hour) => {
                    if (weekday !== coupon_hour.weekday) {
                        promoCode.sale_hours.push({
                            weekday: weekday,
                            start_time: null,
                            end_time: null,
                        });
                        promoCode.sale_hours = _.uniqBy(availability, 'weekday');
                    }
                })
            })
        }
        this.setState({ promoCode, selectedDays });
    };

    toggleRadios = (name, value) => {
        const promoCode = this.state.promoCode;
        switch (name) {
            case 'expires':
                promoCode.expiration = value === 'true' ? new Date() : null;
                this.setState({ promoCode });
                break;
            case 'discount_type':
                promoCode.discount_type = value;
                this.setState({ promoCode });
                break;
            case 'duration':
                if (value === 'ongoing') promoCode.end_time = null;
                this.setState({ duration: value });
                break;
            default:
                promoCode[name] = !this.state.promoCode[name]
                this.setState({ promoCode });
                break;
        }
    }

    toggleItemsType = (value) => {
        const promoCode = this.state.promoCode;
        switch (value) {
            case 'order':
                promoCode.items = []
                promoCode.categories = []
                this.setState({ promoCode, itemsType: value });
                break;

            case 'items':
                promoCode.categories = []
                this.setState({ promoCode, itemsType: value });
                break;

            case 'categories':
                promoCode.items = []
                this.setState({ promoCode, itemsType: value });
                break;

            default:
                break;
        }
    }

    clearStateValues = () => {
        this.setState({
            promoCode: emptyPromoCode,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            selectedLocations: [],
            discountType: 'amount',
            itemsType: 'order',
            apiErrors: {},
            error: '',
        })
    }

    handleClose = (updated) => {
        this.clearStateValues();
        this.props.onClose(updated);
    };

    validateApplyItemsAmount = (value) => {
        let {promoCode, apiErrors } = this.state;
        value = typeof(value) === 'number' ? value : value.toUpperCase();
        promoCode.apply_items_amount = value;
        if (!value.match(/^[0-9]+$|\W*(ALL)\W*/)) {
            apiErrors.apply_items_amount = [{message: "Must be only digits or ALL", code: 'invalid'}];
        } else {
            delete apiErrors.apply_items_amount;
        }
        this.setState({ promoCode, apiErrors });
    }

    handleChange = (event) => {
        let promoCode = this.state.promoCode;
        let name, value = '';
        if (event.target) {
            name = event.target.name;
            value = event.target.type === 'number' && 
                !['amount_off', 'minimum_order_amount'].includes(name) ? 
                parseInt(event.target.value) : event.target.value;
            if (['amount_off', 'minimum_order_amount'].includes(name)) {
                let currency;
                if (this.state.selectedLocations[0] && this.state.selectedLocations[0].currency) {
                    currency = this.state.selectedLocations[0].currency;
                } else if (this.state.selectedLocations[0]) {
                    currency = _.find(store.locations, { id: this.state.selectedLocations[0] }).currency;
                }
                value = {
                    amount: String(value),
                    currency,
                }
            }
        } else {
            // Datepicker has no event.target
            value = event;
            name = 'expiration';
        }
        promoCode[name] = value;
        this.setState({ promoCode });
    }

    getDiscountAmount(promoCode) {
        if (promoCode.percent_off && promoCode.percent_off > 0) return `${promoCode.percent_off}% Off`;
        else if (promoCode.amount_off && promoCode.amount_off.formatted) return `${promoCode.amount_off.formatted} Off`;
        else return null;
    }

    getItemsType(promoCode) {
        if (promoCode.categories && promoCode.categories.length) {
            return 'categories'
        } else if (promoCode.items && promoCode.items.length) {
            return 'items'
        } else {
            return 'order'
        }
    }

    handleSubmit = () => {
        if (!this.state.selectedLocations.length) {
            this.setState({ error: 'You must choose at least one location.' });
            return;
        }
        let payload = _.cloneDeep(toJS(this.state.promoCode));
        delete payload.id;
        payload.status = true;
        payload.is_active = true;
        payload.locations = this.state.selectedLocations;
        payload.start_time = this.state.fromDate;
        payload.end_time = this.state.duration === 'ongoing' ? null : this.state.toDate;
        payload.sale_hours = payload.sale_hours.filter((hour) => this.state.selectedDays.includes(hour.weekday) && hour.start_time !== null);
        if (payload.discount_type === 'percent') {
            payload.amount_off.amount = '0';
        } else if (payload.discount_type === 'amount') {
            delete payload.percent_off;
        }
        payload.amount_off.currency = _.find(store.locations, loc => loc.id === this.state.selectedLocations[0]).currency;
        payload.minimum_order_amount.currency = _.find(store.locations, loc => loc.id === this.state.selectedLocations[0]).currency;
        promotionsAPI.postSale(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                    if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                }
                else {
                    this.handleClose(true);
                }
            });
    };

    updateLocations = locations => {
        this.setState({ selectedLocations: locations });
    };

    updateHours = (sale_hours=[], selectedDays, timeframe) => {
        let isAllDay = timeframe === 'all';
        let { promoCode } = this.state;
        if (isAllDay) {
            sale_hours.forEach((hour) => {
                hour.start_time = '00:00';
                hour.end_time = '23:59';
            });
        }
        promoCode.sale_hours = sale_hours;
        promoCode.sale_hours = _.uniqBy(promoCode.sale_hours, 'weekday');
        this.setState({ promoCode, selectedDays });
    }

    updateDates = (type, newDate) => {
        let displayDate = new Date(newDate);
        newDate = moment(newDate).format('MM/DD/YYYY');
        if (type === 'fromDate') {
            this.setState({ fromDate: displayDate });
            if (displayDate >= new Date(this.state.toDate)) {
                this.setState({ toDate: new Date(moment(displayDate).add(1, 'day').format('MM/DD/YYYY')) });
            }
        } else if (type === 'toDate') {
          this.setState({toDate: displayDate});
        }
    }

    starIcon = () => {
        const { drawerType } = this.props;
        if (drawerType === 'expired') {
            return <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>;
        } else {
            return <FontAwesomeIcon icon="fa-solid fa-star" size="lg" className='tertiaryOrange pr-2 pb-1' />;
        }
    }

    render() {
        const {
            apiErrors,
            error,
            promoCode
        } = this.state;
        const addPromo = this.state.promoCode.id ? false : true;

        return (
            <EditDrawer
                title={`${addPromo ? "New" : "Edit"} Sale`}
                onClose={ () => this.handleClose(false) }
                onSubmit={this.handleSubmit}
                errorMessage={errorMessage(apiErrors, error)}
                deleteButton={addPromo ? false : true}
                buttonAction={() => addPromo ? null : this.deactivateSale(this.state.promoCode.id)}
                buttonText={addPromo ? '' : 'Deactivate Sale'}
            >
                <AdminCard
                    title="Sale Details"
                    style={{ marginTop: "15px" }}
                >
                    <div className="app-field-wrapper">
                        <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Sale Name</label>
                        <Text className='mb-2'>A name for your sale. (Customers will see this in the app when using this sale.)</Text>
                        <input
                            className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Sale Name"
                            value={promoCode.name || ''}
                            onChange={(event) => this.handleChange(event)}
                        />
                        {fieldError('name', apiErrors)}
                    </div>
                    <div className="app-field-wrapper">
                        <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Sale Description</label>
                        <Text className='mb-2'>Details about your sale. (Customers will see these details in the app when using this sale.)</Text>
                        <textarea
                            className={fieldError("description", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                            id="description"
                            name="description"
                            type="text"
                            placeholder="Sale Description"
                            value={promoCode.description}
                            onChange={(event) => this.handleChange(event)}
                        />
                        {fieldError('description', apiErrors)}
                    </div>
                    <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            Start Date
                        </div>
                        <Text className='mb-2'>This sale will start this date.</Text>
                        <div
                            className="column is-narrow"
                            style={{ padding: 0, height: 40, width: "30%" }}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                autoOk
                                disablePast
                                value={this.state.fromDate}
                                inputVariant="standard"
                                variant="inline"
                                format="MM/dd/YYYY"
                                size="small"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                style={{ padding: "8px 16px 0 16px" }}
                                className="app-input-field no-padding"
                                onChange={(value) => this.updateDates('fromDate', value)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            End Date
                        </div>
                        <Text className='mb-2'>Choose the day the sale will end. Choose ongoing if you'd like the sale to continue indefinitely.</Text>
                        <RadioGroup
                            aria-label="duration"
                            name="duration"
                            value={this.state.duration}
                            onChange={(event) => this.toggleRadios("duration", event.target.value)}
                            className="radio-group"
                        >
                            <RadioLabel value="ongoing" label="Ongoing Sale" />
                            <RadioLabel value="datetime" label="Set end date" />
                        </RadioGroup>
                        {this.state.duration === 'datetime' ?
                        <div
                            className="column is-narrow"
                            style={{ padding: 0, height: 40, width: "30%" }}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disablePast
                                    autoOk
                                    minDate={this.state.toDate}
                                    value={this.state.toDate}
                                    inputVariant="standard"
                                    variant="inline"
                                    format="MM/dd/YYYY"
                                    size="small"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={{ padding: "8px 16px 0 16px" }}
                                    className="app-input-field no-padding"
                                    onChange={(value) => this.updateDates('toDate', value)}
                                />
                            </MuiPickersUtilsProvider>
                        </div> : null}
                    </div>
                </AdminCard>
                <PromoHours
                    isSale={true}
                    apiErrors={ apiErrors }
                    error={ error }
                    hours={ promoCode.sale_hours }
                    hoursType={ 'Sale' }
                    payload={ promoCode.sale_hours }
                    updateHours={ this.updateHours }
                />
                <AdminCard title="Discount Options">
                    <div className='app-field-wrapper'>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Type</div>
                        <RadioGroup
                            aria-label="discount_type"
                            name="discount_type"
                            value={promoCode.discount_type}
                            onChange={(event) => this.toggleRadios("discount_type", event.target.value)}
                            className="radio-group"
                        >
                            <RadioLabel value={'amount'} label="Flat Amount" />
                            <RadioLabel value={'percent'} label="Percentage" />
                        </RadioGroup>
                    </div>
                    <div className='app-field-wrapper'>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Amount</div>
                        <input
                            className={`app-input-field mt-0 ${fieldError(`${promoCode.discount_type}_off`, apiErrors) ? "red-border" : ''}`}
                            id={`${promoCode.discount_type}_off`}
                            name={`${promoCode.discount_type}_off`}
                            type="number"
                            placeholder={`Discount ${promoCode.discount_type === 'amount' ? 'Amount' : 'Percentage'}`}
                            value={promoCode.discount_type === 'amount' ? promoCode.amount_off.amount : promoCode.percent_off}
                            onChange={(event) => this.handleChange(event)}
                        />
                        <div>{fieldError(`${promoCode.discount_type}_off`, apiErrors)}</div>
                    </div>
                    <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            Minimum Order Amount
                        </div>
                        <div>
                            <Text>Require a minimum subtotal in order to apply sale.</Text>
                            <input
                                className={`app-input-field mt-0 ${fieldError('minimum_order_amount', apiErrors) ? "red-border" : ''}`}
                                id='minimum_order_amount'
                                name='minimum_order_amount'
                                type="number"
                                placeholder='Minimum Order Amount'
                                value={promoCode.minimum_order_amount.amount}
                                onChange={(event) => this.handleChange(event)}
                            />
                        </div>
                        <div>{fieldError('minimum_order_amount', apiErrors)}</div>
                    </div>
                </AdminCard>
                <LocationSelection
                    itemName='sale'
                    selectedLocations={ this.state.selectedLocations }
                    onUpdateLocations={ this.updateLocations }
                    fieldError={ fieldError('locations', apiErrors) }
                />
            </EditDrawer>
        );
    }
}

export default observer(SalesEdit);
