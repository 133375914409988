import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from '../../store/store';


class AutocompleteVerbiage extends Component {
    render() {
        return (
            <React.Fragment>
                {store.tenant.pos_name === 'square' ?
                    <div className="app-card-section-wrapper pt-2">
                        <div className="error-message">
                            <div className="columns error-message not-bold">
                                <div className="is-1 display-in-line center-items">
                                    <FontAwesomeIcon size="2x" className='error-icon' icon={ ['far', 'exclamation-circle'] } />
                                </div>
                                <div className="is-11">
                                    <span>
                                        <span className='bold'>Note:</span> In order to use Square KDS you need to have that location set to auto complete orders. Square KDS does not have an option to mark an order as complete, just that it is ready, so you will need to enable auto complete or use the Square Register to complete the orders manually. If orders are not completed they will not show up properly in your sales reports. <span className='bold'>Note: Curbside pickup orders will not be auto completed.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
        </React.Fragment>
        )
    }
}

export default AutocompleteVerbiage