import React, { Component } from "react";
import AdminCheckbox from "../elements/adminCheckbox";
import { IconPadding } from "../elements/navs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@material-ui/core";
import AdminToolTip from "../elements/adminToolTip";
import tenantAPI from '../../services/api/tenantAPI';

class ToggleAutoSync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: this.props.tenant,
      enable_auto_sync: this.props.tenant.enable_auto_sync,
      show_dialog: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.tenant && nextProps.tenant !== this.state.tenant)
      this.setState({
        location: nextProps.tenant,
        enable_auto_sync: nextProps.tenant.enable_auto_sync
      });
  }

  toggleEnabled = () => {
    let enable_auto_sync = !this.state.enable_auto_sync;
    if (enable_auto_sync) {
      this.updateShowDialog(true)
    } else {
      tenantAPI.updateTenant({ enable_auto_sync: enable_auto_sync });
    }
  };

  handleConfirm = () => {
     tenantAPI.updateTenant({ enable_auto_sync: true });
     this.updateShowDialog(false)
  }

  tooltip = () => {
    return (
      <div style={{textAlign: "center"}}> 
        <div className="tooltip-content">
          Automatically syncs all catalog changes made in your Square account every 5 minutes.
        </div>
      </div>
    );
  }

    updateShowDialog = (show_dialog) => {
      this.setState({
        show_dialog
      });
    }

    showDialog = () => {
      return (
        <Dialog 
            open={this.state.show_dialog}
            onClose={() => this.updateShowDialog(false)}
            aria-labelledby="auto-sync-confirmation">
              <div className="app-card" style={{ marginTop: 0 }}>
                <div className="app-card-header">
                  <div className="app-card-header-text">
                    <div className="app-card-header-title">Enable Auto Sync</div>
                  </div>
                </div>
                <div className="app-card-content-wrapper">
                  <div style={{ marginTop: 10 }}>Automatically syncs catalog changes made in your Square account every 5minutes.</div>
                  <div style={{ marginTop: 10 }}>If adding new items or categories that you would like displayed in the app. They must still be enabled, however updates and deletions are handled automatically.</div>
                  <div style={{ marginTop: 10 }}>Note: If you have a change that cannot wait you still have the ability to manually sync.</div>
                </div>

                <div className="app-card-actions-wrapper">
                  <button
                    type="button"
                    className="app-button secondary margin-left"
                    onClick={() => this.updateShowDialog(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="app-button primary margin-left"
                    onClick={() => this.handleConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
        </Dialog>
      )
    }

  render() {
    return (
      <>
          {this.showDialog()}
          <AdminCheckbox
                checked={ this.state.enable_auto_sync || false }
                onChange={ this.toggleEnabled }
                name="Auto Sync"
            />
          <div className="link-title">Auto Sync Enabled</div>
          <IconPadding>
            <AdminToolTip 
              title="Automatically syncs all catalog changes made in your Square account every 5 minutes."
              icon={<FontAwesomeIcon color="#ffffff" icon={['fal', 'question-circle']} />}
            />
          </IconPadding>
      </>
    );
  }
}

export default ToggleAutoSync;
