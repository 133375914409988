import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import store from '../../store/store';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import { Text } from '../elements/text';
import AdminToolTip from '../elements/adminToolTip';

class ItemAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCollapsed: {},
      variations: [],
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  stockStatus(location, variation) {
    let out_of_stock_locations = variation ? variation.out_of_stock_locations : this.props.item.out_of_stock_locations;

    if (out_of_stock_locations.includes(location.id)) {
      return <span className='ordrslipRed'>Out of Stock</span>
    } else {
      return <span className='brightGreen'>In Stock</span>
    }
  };

  toggleRow(location) {
    const rowCollapsed = location.id === this.state.rowCollapsed ? {} : location.id;
    this.setState({ rowCollapsed });
  };

  render() {
    const { item } = this.props;
    return (
      <div>
        <AdminCard
          title='Item Availability'
          description='This item will show as available in the mobile app based on these settings.'
        >
          <div className='app-card-section-wrapper'>
            <div className='app-field-label'>Global Availability</div>
            <AdminToggle
              className='app-toggle'
              checked={item.is_available}
              onChange={this.props.toggleEnabled}
            />
          </div>
          {item.is_available && (
            <div className='app-card-section-wrapper'>
              <div className='app-card-section-title top-margin-10'>
                <div className='app-field-label'>Location Availability</div>
              </div>
              <div style={{ width: '100%', flex: 1, overflowX: 'auto' }}>
                <Table className='app-table-wrapper' size='small'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='app-table-cell' style={ { width: '33%' } }>
                        Location
                      </TableCell>
                      <TableCell className='app-table-cell' style={ { width: '33%' } }>
                        In Stock
                      </TableCell>
                      <TableCell className='app-table-cell' style={ { width: '33%' } }>
                        Show In App
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                    {store.locations &&
                      store.locations.length > 0 &&
                      store.locations.map(location => (
                        <React.Fragment key={location.id}>
                        <TableRow
                          className='app-table-row'
                          onClick={this.handleOnRowClick}
                        >
                            <TableCell className="app-table-cell pr-0 remove-row-border">
                            {location.name}
                          </TableCell>
                            <TableCell className="app-table-cell pl-0 pr-0 remove-row-border">
                            {location.enable_square_inventory ?
                            <div className='display-in-line'>
                              {this.stockStatus(location)}
                              <span className='location-name-check' type='button' data-tip='' >
                              <AdminToolTip
                                title={ <Text>You have “Square POS Inventory” enabled for this location.
                                  To adjust the stock of this item for this location, you must use your Square
                                  POS or Square Dashboard, or disable “Square POS Inventory” for this location.</Text> }
                                icon={ <FontAwesomeIcon className='secondaryBlue' icon={ ['fal', 'question-circle'] } /> }
                              />
                              </span>
                            </div> :
                            <AdminToggle
                              className='app-toggle'
                              checked={!item.out_of_stock_locations.includes(location.id)}
                              onChange={() => this.props.toggleOosAtLocation(location.id)}
                              hideLabel
                            />}
                          </TableCell>
                            <TableCell className="app-table-cell pl-0 pr-0 remove-row-border">
                          <AdminToggle
                            className='app-toggle'
                            checked={!item.hide_at_locations.includes(location.id)}
                            onChange={() => this.props.toggleHideAtLocation(location.id)}
                            hideLabel
                          />
                          </TableCell>
                        </TableRow>
                        <TableRow className='app-table-row'>
                          <TableCell className='app-table-cell' style={{ padding: 0 }} colSpan={9}>
                          <Collapse in={true} timeout='auto' unmountOnExit>
                            <div style={{ padding: '0 10px 10px 10px', width: '100%', flex: 1, overflowX: 'auto'  }}>
                                  <Table size='small' className='sub-table'>
                              <TableHead>
                                <TableRow className='inner-table-row'>
                                  <TableCell className='semi-bold' style={ { width: '33%' } }>
                                    Variation
                                  </TableCell>
                                  <TableCell className='semi-bold' style={ { width: '33%' } }>
                                    In Stock
                                  </TableCell>
                                  <TableCell className='semi-bold' style={ { width: '33%' } }>
                                    Show In App
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.variations ? item.variations.map(variation => 
                                  <TableRow key={variation.id} className='table-row'>
                                    <TableCell className='table-cell'>{variation.name}</TableCell>
                                    <TableCell className='table-cell pl-0'>
                                      {location.enable_square_inventory ?
                                      <div className='display-in-line'>
                                        {this.stockStatus(location, variation)}
                                          <span className='location-name-check' type='button' data-tip='' >
                                            <AdminToolTip
                                              title={ <Text>You have “Square POS Inventory” enabled for this location.
                                              To adjust the stock of this item for this location, you must use your Square
                                                POS or Square Dashboard, or disable “Square POS Inventory” for this location.</Text> }
                                              icon={ <FontAwesomeIcon className='primaryBlack' icon={ ['fal', 'question-circle'] } /> }
                                            />
                                        </span>
                                      </div> :
                                      <AdminToggle
                                        className='app-toggle'
                                        checked={!variation.out_of_stock_locations.includes(location.id)}
                                        onChange={() => this.props.toggleVariationOosLocations(location.id, variation)}
                                        hideLabel
                                      />
                                      }
                                    </TableCell>
                                    <TableCell className='table-cell pl-0'>
                                        <AdminToggle
                                          className='app-toggle'
                                          checked={ !variation.hide_at_locations.includes(location.id) }
                                          onChange={ () => this.props.toggleVariationHideAtLocations(location.id, variation) }
                                          hideLabel
                                        />
                                    </TableCell>
                                </TableRow>) : null}
                              </TableBody>
                            </Table>
                            </div>
                          </Collapse>
                          </TableCell>
                        </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </AdminCard>
      </div>
    );
  }
}

export default ItemAvailability;
