import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';

import store from "../store/store";
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminToggle from '../components/elements/adminToggle';
import { HeaderText, Text } from '../components/elements/text';
import PopUpsEdit from "../components/messages/popupsEdit";
import tenantAPI from '../services/api/tenantAPI';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      customTextOptionsMobile: [],
      customTextOptionsOnline: [],
      open: false,
      type: 'Mobile'
    };
  }

  togglePopup = (popup) => {
    let popupName = `${popup}_initial_modal_enabled`;
    tenantAPI.updateTenant({ [popupName]: !store.tenant[popupName] });
  };

  componentDidMount() {
    this.getcustomTextData();
  }

  getcustomTextData = () => {
    tenantAPI.getCustomTextFieldData()
      .then(response => {
        let content = response;
        this.setState({ content });
        let customTextOptions = this.extractContent(content);
        let customTextOptionsMobile = _.filter(customTextOptions, x => { return x.name.includes('Mobile'); });
        let customTextOptionsOnline = _.filter(customTextOptions, x => { return x.name.includes('Online'); });
        this.setState({ customTextOptionsMobile, customTextOptionsOnline });
      });
  }

  extractContent = (content) => {
    const propertiesList = [
      "online_initial_modal_text",
      "mobile_initial_modal_text",
      "mobile_initial_modal_url_link",
      "online_initial_modal_url_link",
      "mobile_initial_modal_button_text",
      "online_initial_modal_button_text"
    ]
    let customTextOptions = [];
    for (let property in content.defaults) {
      if (propertiesList.includes(property)) {
        if (content.content[property]) {
          customTextOptions.push(content.content[property]);
        } else {
          customTextOptions.push(content.defaults[property]);
        }
        content.defaults[property].property = property;
      }
    }
    return customTextOptions;
  };

  handleDialogOpen = (type) => {
    this.setState({ type });
    this.setState({ open: true });
  };

  handleDialogClose = (updated) => {
    this.setState({ open: false });
    if (updated) this.getcustomTextData();
  };

  renderTextContent = (customTextOption, index) => {
    let type = customTextOption.name.includes('Mobile') ? 'Mobile' : 'Online Ordering'
    return (
      <React.Fragment>
        <Text weight="bold" className='pb-3'>
          {index === 0 ? `${type} App Pop-Up` :
            index === 1 ?
              `Link Within ${type} Pop-Up` :
              `Acknowledgement ${type} Pop-Up Button`}
        </Text>
        <div className="app-field-label">{customTextOption.name}</div>
        <Text className='pb-2'>{customTextOption.description}</Text>
        {customTextOption.property.includes("url_link") ?
          <div>
            <div className="app-field-label">WELCOME POP-UP (TEXT)</div>
            <div className="columns is-mobile">
                <div className="column is-narrow pr-0">
                <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" className="tertiaryOrange"/>
                </div>
              <div className="column is-narrow">{customTextOption.value.text ? customTextOption.value.text : "Ex: See our page for different events"}</div>
            </div>
            <div className="app-field-label">WELCOME POP-UP (LINK)</div>
            <div className="columns is-mobile">
                <div className="column is-narrow pr-0">
                <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" className="tertiaryOrange"/>
                </div>
              <div className="column is-narrow">{customTextOption.value.url ? customTextOption.value.url : "Ex: yourwebsite.com"}</div>
            </div>
          </div>
          :
            <div className="columns is-mobile">
                <div className="column is-narrow pr-0">
                <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" className="tertiaryOrange"/>
                </div>
            <div className="column is-narrow">{customTextOption.value ? customTextOption.value : index === 0 ? 'Ex: Welcome to [Restaurant Name]!' : 'Ex: Sounds Good'}</div>
            </div>
          }
      </React.Fragment>
    )
  }
  render() {
    const tenant = store.tenant;
    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="Pop-Up Messages"
          mainNav="Marketing"
          subNav="Messages"
          description="Pop-Up Messages will appear to users the first time they open your app."
          />
        <div className="admin-page-content">
          <AdminCard
            title="Enable/Disable Mobile Pop-Up Message"
            largeTitle
            description="Customers will only be able to see this message if it is enabled."
            onHeaderActionClick={() => this.handleDialogOpen('Mobile')}
          >
            <div className="app-field-wrapper">
              <Text weight="bold" className='pb-3'>Mobile App: Pop-Up</Text>
              <AdminToggle
                className='app-toggle'
                checked={ Boolean(tenant.mobile_initial_modal_enabled) }
                onChange={ () => this.togglePopup('mobile') }
              />
            </div>
            <HeaderText medium>Customize Mobile Pop-Up Text</HeaderText>
            <Text medium>The text you set here will show to the users the first time they open your app.</Text>
            {this.state.customTextOptionsMobile.map((customTextOption, index) => (
              <div className='pt-3 pb-3 medium-text' key={index}>
                {this.renderTextContent(customTextOption, index)}
              </div>
            ))}
          </AdminCard>
          {tenant.online_ordering_enabled  ? <AdminCard
            title="Enable/Disable Online Ordering Pop-Up Message"
            description="Customers will only be able to see this message if it is enabled."
            largeTitle
            onHeaderActionClick={() => this.handleDialogOpen('Online')}
          >
            <div className="app-field-wrapper">
              <Text weight="bold" className='pb-3'>Online Ordering: Pop-Up</Text>
              <AdminToggle
                className='app-toggle'
                checked={ Boolean(tenant.online_initial_modal_enabled) }
                onChange={ () => this.togglePopup('online') }
              />
            </div>
            <HeaderText medium>Customize Online Ordering Pop-Up Text</HeaderText>
            <Text medium>The text you set here will show to the users the first time they open your online ordering link.</Text>
            {this.state.customTextOptionsOnline.map((customTextOption, index) => (
              <div className='pt-3 pb-3 medium-text' key={index}>
                {this.renderTextContent(customTextOption, index)}
              </div>
            ))}
          </AdminCard> : null}
        </div>
        <PopUpsEdit
          open={this.state.open}
          onClose={this.handleDialogClose}
          customTextOptions={this.state.type === 'Mobile' ? this.state.customTextOptionsMobile : this.state.customTextOptionsOnline}
          content={this.state.content.content}
          type={this.state.type}
        />
      </div>
    );
  }
}

export default observer(Popup);
