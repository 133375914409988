import React, { Component } from "react";
import logo from "../assets/img/logo-orange.png";
import { Redirect } from "react-router";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { observer } from "mobx-react";
import store from "../store/store";
import PasswordResetDialog from "../components/passwordResetDialog";
import TenantList from "../components/tenant/tenantList";
import accountsAPI from '../services/api/accountsAPI';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      apiErrors: {},
      data: {
        email: null,
        password: null,
        tenant_name: null,
      }
    };
  }

  handleDialogOpen = () => {
    store.passwordResetStepperStep = 1;
    this.setState({ open: true });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  handleChange(value, type) {
    let data = this.state.data;
    data[type] = value;
    this.setState({ data });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const tenantRequired = store.tenantRequired.get();
    const data = {...this.state.data}
    if (tenantRequired) {
      data["tenant_name"] = data["tenant_name"] || "" 
    }
    accountsAPI.login(data)
  }

  render() {
    const loggedIn = store.loggedIn.get();
    const showTenantList = store.showTenantList.get();
    const tenantRequired = store.tenantRequired.get();

    return (
      <div className="login-page-wrapper columns is-centered">
        <div className="login-page-column column is-narrow">
          <img
            src={logo}
            alt="ordrslip"
            style={{ height: 40, margin: "0 auto", paddingBottom: 10 }}
            className="logo"
          />
          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-header-text">
                <h1 className="app-card-header-title has-text-centered">Login to Ordrslip Admin Dashboard</h1>
              </div>
            </div>
            <div className="app-card-content-wrapper">
              {loggedIn && <Redirect to="/" />}
              {store.success === false && store.error && (
                <div className="error-message mt-0 mb-3">
                  <ErrorOutline className="error-icon" />
                  {store.error}
                </div>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className="app-field-wrapper">
                  <label htmlFor="email" className="app-field-label">
                    Email*
                  </label>
                  <input
                    className="app-input-field"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={(event) => this.handleChange(event.target.value, 'email')}
                  />
                </div>
                <div className="app-field-wrapper">
                  <label htmlFor="password" className="app-field-label">
                    Password*
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="******"
                    className="app-input-field"
                    onChange={(event) => this.handleChange(event.target.value, 'password')}
                  />
                </div>
                {tenantRequired === true && (
                  <div className="app-field-wrapper">
                    <label htmlFor="tenant_name" className="app-field-label">
                      Restaurant Name*
                    </label>
                    <input
                      id="tenant_name"
                      name="tenant_name"
                      type="text"
                      placeholder="Restaurant Name*"
                      className="app-input-field"
                      onChange={(event) => this.handleChange(event.target.value, 'tenant_name')}
                    />
                  </div>
                )}
                <div className="pb-5 secondaryBlue bold">
                  <p
                    onClick={this.handleDialogOpen}
                  >
                    Forgot your password?
                  </p>
                </div>
                <button
                  type="submit"
                  className="app-button primary mt-5"
                >
                  Submit
                </button>
              </form>
                <PasswordResetDialog
                  open={this.state.open}
                  onClose={this.handleDialogClose}
                />
                {showTenantList && <TenantList />}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default observer(Login);
