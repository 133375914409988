import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminToggle from '../components/elements/adminToggle';
import { Text } from '../components/elements/text';
import tenantAPI from '../services/api/tenantAPI';


class AppTaxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            rowsPerPage: 10,
        }
    }

    handleToggle = () => {
        tenantAPI.updateTenant({ enable_square_taxes: !store.tenant.enable_square_taxes });
    };

    getTaxes = () => {
        tenantAPI.getRecursiveTaxes({ page: 1 });
    };

    componentDidMount = () => {
        if (!store.locations.length) {
            tenantAPI.getRecursiveLocations({ page: 1 }).then(() => {
                if (!store.taxes.length) {
                    this.getTaxes()
                }
            })
        } else {
            if (!store.taxes.length) {
                this.getTaxes();
            }
        }
    };

    toggleEnabledByTax = tax => {
        tenantAPI.updateTax(tax.id, { is_app_enabled: !tax.is_app_enabled })
            .then(() => {
                this.getTaxes();
            });
    };

    updateRows(event) {
        this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
    };

    updatePage(event, newPage) {
        this.setState({ currentPage: newPage });
    };

    render() {
        const { currentPage, rowsPerPage } = this.state;
        if (!store.locations.length && !store.taxes.length) return <div />;
        return (
            <div className='admin-page-wrapper'>
                <AdminPageHeader
                    title='Taxes'
                    mainNav='Configuration'
                    subNav='App Customization'
                    description='The taxes you enable here will be available in your mobile app. (To change the location, tax percentage, or to apply taxes to specific items, you must go to your POS system.)'
                />
                <div>
                    {store.tenant.pos_name === 'square' ?
                        <AdminCard
                            title='Enable/Disable Square Item Specific Taxes'
                            largeTitle
                            description={<Text>When enabled, you will have the ability to apply taxes to specific items. <span className='semi-bold'>NOTE: to apply taxes to specific items, you will need to edit the item within your Square POS or Square Dashboard.</span></Text>}
                        >
                            <Text weight={'bold'} style={{marginBottom: 10}}>Square Item Specific Taxes</Text>
                            <AdminToggle
                                className='app-toggle'
                                checked={ Boolean(store.tenant.enable_square_taxes) }
                                onChange={ this.handleToggle }
                            />
                        </AdminCard> : null
                    }
                    <AdminCard
                        title='App Taxes'
                        description='Enable taxes for your mobile app.'
                        largeTitle
                    >
                        <Table className='app-table-wrapper'>
                            <TableHead className='app-table-header'>
                                <TableRow className='app-table-row'>
                                    <TableCell className='app-table-cell'>Name</TableCell>
                                    <TableCell className='app-table-cell'>Location</TableCell>
                                    <TableCell className='app-table-cell'>Percentage</TableCell>
                                    <TableCell className='app-table-cell'>Enabled</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='app-table-body'>
                                {store.taxes.length ?
                                    <React.Fragment>
                                        { (rowsPerPage > 0 ? store.taxes.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : store.taxes).map(tax => (
                                            <TableRow key={ tax.id } className='app-table-row'>
                                                <TableCell className='app-table-cell'>
                                                    { tax.name }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { tax.location_names }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { tax.rate }%
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    <AdminToggle
                                                        hideLabel
                                                        className='app-toggle'
                                                        checked={ Boolean(tax.is_app_enabled) }
                                                        onChange={ () => this.toggleEnabledByTax(tax) }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={ [10, 25, 50] }
                                                count={ store.taxes && store.taxes.length ? store.taxes.length : 0 }
                                                rowsPerPage={ this.state.rowsPerPage }
                                                onRowsPerPageChange={ (event) => this.updateRows(event) }
                                                page={ this.state.currentPage }
                                                onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                                                style={ { borderBottom: 'none' } }
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                    :
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell'>
                                            There are no taxes.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </AdminCard>
                </div>
            </div>
        );
    }
}

export default observer(AppTaxes);
