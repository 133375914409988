import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import Drawer from '@material-ui/core/Drawer';
import { errorMessage, fieldError } from "../../services/utils";
import tenantAPI from '../../services/api/tenantAPI';

class SocialMediaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facebook_url: this.props.location.facebook_url,
      twitter_url: this.props.location.twitter_url,
      instagram_url: this.props.location.instagram_url,
      yelp_url: this.props.location.yelp_url,
      apiErrors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.id !== this.props.location.id) {
      this.setState({
        facebook_url: nextProps.location.facebook_url,
        twitter_url: nextProps.location.twitter_url,
        instagram_url: nextProps.location.instagram_url,
        yelp_url: nextProps.location.yelp_url,
      });
    }
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let payload = this.state;
    delete payload.apiErrors;
    tenantAPI.updateLocation(this.props.location.id, JSON.stringify(payload))
      .then(response => {
        if (response.errors) {
          this.setState({ apiErrors: response.errors });
        } else {
          this.setState({ apiErrors: {} });
          this.props.onUpdate(response);
          this.props.onClose();
        }
      })
      .catch(err => console.error(err));
  }

  render() {
    return (
      <Drawer anchor="right" open={this.props.open} onClose={this.props.onClose} classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}>
        <EditDrawer title="Edit Location Social Media" onSubmit={this.handleSubmit} onClose={this.props.onClose}>
          <AdminCard
            title="Location Social Media"
            style={{ marginTop: "15px" }}
            description="Set your location’s social media accounts here."
          >
          {errorMessage(this.state.apiErrors)}
            <form>
              <div className="app-field-wrapper">
                <label htmlFor="facebook_url" className="app-field-label">
                  Facebook
                </label>
                <input
                  className={fieldError("facebook_url", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="facebook_url"
                  name="facebook_url"
                  type="text"
                  placeholder="Facebook Link"
                  value={this.state.facebook_url}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('facebook_url', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="twitter_url" className="app-field-label">
                  Twitter
                </label>
                <input
                  className={fieldError("twitter_url", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="twitter_url"
                  name="twitter_url"
                  type="text"
                  placeholder="Twitter Link"
                  value={this.state.twitter_url}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('twitter_url', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="instagram_url" className="app-field-label">
                  Instagram
                </label>
                <input
                  className={fieldError("instagram_url", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="instagram_url"
                  name="instagram_url"
                  type="text"
                  placeholder="Instagram Link"
                  value={this.state.instagram_url}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('instagram_url', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="yelp_url" className="app-field-label">
                  Yelp
                </label>
                <input
                  className={fieldError("yelp_url", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="yelp_url"
                  name="yelp_url"
                  type="text"
                  placeholder="Yelp Link"
                  value={this.state.yelp_url}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('yelp_url', this.state.apiErrors)}
              </div>
            </form>
          </AdminCard>
        </EditDrawer>
      </Drawer>
    );
  }
}

export default SocialMediaEdit;
