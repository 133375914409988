import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withNav from "../components/withNav";
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import { Button } from '../components/elements/buttons';
import tenantAPI from '../services/api/tenantAPI';
import { formatDateTimeForView } from '../services/utils';
import store from '../store/store';
import ReferralsEdit from '../components/tenant/referralsEdit';


class Referrals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            drawerType: 'create',
            inFocus: '',
            open: false,
            orderBy: 'name',
            order: 'desc',
            rowsPerPage: 10,
            referrals: store.referrals,
            selectedReferral: {}
        };
    }

    getReferrals = () => {
        tenantAPI.getRecursiveReferrals({ page: 1 })
            .then(() => this.setState({ referrals: store.referrals }));
    };

    componentDidMount = () => {
        if (!store.referrals.length) this.getReferrals();
    };

    updateRows(event) {
        this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
    };

    updatePage(event, newPage) {
        this.setState({ currentPage: newPage });
    };

    editReferral = referral => {
        this.setState({ selectedReferral: referral });
        this.toggleDrawer('edit');
    };

    handleSort(value) {
        let {referrals} = this.state;
        let orderBy = value;
        let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
        if (value === 'business_name') referrals = _.orderBy(referrals, [referral => referral[value].toLowerCase()], [order]);
        else referrals = _.orderBy(referrals, [referral => referral[value]], [order]);
        this.setState({ referrals, order, orderBy, currentPage: 0 });
    };

    getSortIcon(orderBy) {
        let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
            :
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
        return sortIcon;
    };

    toggleDrawer(drawerType) {
        if (drawerType === 'create') this.setState({ selectedReferral: {} });
        this.setState({ drawerType, open: !this.state.open });
    };

    handleDialogClose = updated => {
        this.setState({ selectedReferral: {} });
        this.toggleDrawer('');
        if (updated) this.getReferrals();
    };

    render() {
        const { referrals, order, orderBy, rowsPerPage, currentPage } = this.state;
        return (
            <div>
                <div className='admin-page-wrapper'>
                    <AdminPageHeader
                        title='Referrals'
                        description=''
                        headerActionText="Add New"
                        onHeaderActionClick={ () => this.toggleDrawer('create') }
                    />
                    <div>
                        <AdminCard>
                            <Table className='app-table-wrapper'>
                                <TableHead className='app-table-header'>
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell'>
                                            <TableSortLabel
                                                active={ orderBy === 'business_name' }
                                                direction={ orderBy === 'business_name' ? order : 'asc' }
                                                onClick={ () => this.handleSort('business_name') }
                                                IconComponent={ () => this.getSortIcon('business_name') }
                                            >
                                                Business Name
                                                { orderBy === 'business_name' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='app-table-cell'>
                                            <TableSortLabel
                                                active={ orderBy === 'status' }
                                                direction={ orderBy === 'status' ? order : 'asc' }
                                                onClick={ () => this.handleSort('status') }
                                                IconComponent={ () => this.getSortIcon('status') }
                                            >
                                                Status
                                                { orderBy === 'status' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='app-table-cell'>
                                            <TableSortLabel
                                                active={ orderBy === 'created' }
                                                direction={ orderBy === 'created' ? order : 'asc' }
                                                onClick={ () => this.handleSort('created') }
                                                IconComponent={ () => this.getSortIcon('created') }
                                            >
                                                Date Created
                                                { orderBy === 'created' ? (
                                                    <span className='is-hidden'>
                                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                    </span>
                                                ) : null }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='app-table-cell' style={ { width: '20%' } } />
                                    </TableRow>
                                </TableHead>
                                <TableBody className='app-table-body'>
                                    { referrals.length ?
                                        <React.Fragment>
                                            { (rowsPerPage > 0 ? referrals.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : referrals).map(referral => (
                                                <TableRow
                                                    key={ referral.id }
                                                    className={ this.state.inFocus.id === referral.id ? "highlighted-row" : null }
                                                    onMouseOver={ () => this.setState({ inFocus: referral }) }
                                                >
                                                    <TableCell className='app-table-cell'>
                                                        { referral.business_name }
                                                    </TableCell>
                                                    <TableCell className='app-table-cell'>
                                                        { referral.status }
                                                    </TableCell>
                                                    <TableCell className='app-table-cell'>
                                                        { formatDateTimeForView(referral.created) }
                                                    </TableCell>
                                                    <TableCell className='app-table-cell has-text-right'>
                                                        { this.state.inFocus.id === referral.id ?
                                                            <Button
                                                                edit
                                                                onClick={ () => this.editReferral(referral) }
                                                            >
                                                                Edit
                                                            </Button> : null }
                                                    </TableCell>
                                                </TableRow>
                                            )) }
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={ [10, 25, 50] }
                                                    count={ referrals && referrals.length ? referrals.length : 0 }
                                                    rowsPerPage={ this.state.rowsPerPage }
                                                    onRowsPerPageChange={ (event) => this.updateRows(event) }
                                                    page={ this.state.currentPage }
                                                    onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                                                    style={ { borderBottom: 'none' } }
                                                />
                                            </TableRow>
                                        </React.Fragment>
                                        :
                                        <TableRow className='app-table-row'>
                                            <TableCell className='app-table-cell'>
                                                There are no referrals.
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </AdminCard>
                    </div>
                </div>
                <ReferralsEdit
                    referral={ this.state.selectedReferral }
                    drawerType={ this.state.drawerType }
                    open={ this.state.open }
                    onClose={ this.handleDialogClose }
                />
            </div>
        );
    }
}

export default withNav(observer(Referrals));
