import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import withNav from "../components/withNav";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import _ from "lodash";

import store from "../store/store";
import { formatUserRoleForView } from "../services/utils";
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminSearchInput from "../components/elements/adminSearchInput";
import { Button } from '../components/elements/buttons';
import UserDrawer from "../components/user/userDrawer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import accountsAPI from '../services/api/accountsAPI';
import tenantAPI from '../services/api/tenantAPI';


class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      inFocusUser: '',
      open: false,
      orderBy: 'full_name',
      order: 'desc',
      rowsPerPage: 10,
      searchedUsers: false,
      selectedUser: {},
      typingTimeout: 0,
      users: store.users
    };
  }

  handleDialogClose = updated => {
    this.toggleDrawer();
    if (updated) this.setState({users: store.users});
  }

  getUsers = () => {
    store.updateUserSearch('');
    if (!store.users.length &! store.loadingUsers) {
      accountsAPI.getRecursiveUsers({ page: 1 });
    }
    tenantAPI.getPunchCardSettings();
    if (store.tenant.pos_name === 'square') {
      tenantAPI.getSquareLoyalty();
    };
    if (store.loadingUsers) {
      store.updateLoading(true);
      this.interval = setInterval(() => {
        if (!store.loadingUsers) {
          this.setState({ users: store.users });
          store.updateLoading(false);
          clearInterval(this.interval);
        }
      }, 1000);
    } else {
      this.setState({ users: store.users });
    }
  }

  componentDidMount = () => {
    this.getUsers();
  };

  toggleDrawer = user => {
    this.setState({ selectedUser: user });
    this.setState({ open: !this.state.open });
  };

  getApiSearch = (query) => {
    store.updateLoading(true);
    if (!this.state.searchedUsers) {
      accountsAPI.getRecursiveUsers({ page: 1 })
        .then(() => {
          this.setState({
            users: store.users, currentPage: 0
          });
          store.updateLoading(false);
        });
    } else {
      accountsAPI.searchUsers(query)
        .then(response => {
          this.setState({
            users: response.data, currentPage: 0
          });
          store.updateLoading(false);
        });
    }
  }

  searchUsers = (query) => {
    store.updateUserSearch(query);
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typingTimeout: setTimeout(function () {
        self.getApiSearch(query);
      }, 1500),
      searchedUsers: query !== "",
    });
  }

  filterUsers = (userType) => {
    let users = this.state.users;
    if (userType === 'customer') {
      userType = 'user';
    }
    if (userType === 'all') {
      users = store.users;
    } else {
      users = store.users.filter(user => user.role === userType);
    }
    this.setState({ users, currentPage: 0 });
  };

  userTypeCount = userType => {
    return _.filter(store.users, x => { return; });
  };

  renderUsersFilter = userType => {
    const usersCount = _.countBy(store.users, 'role');
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterUsers(userType.toLowerCase()) }>
          { userType }{ userType === 'all' ? ' Users' : '' }
        </Button>
        ({ userType === 'All' ? store.users.length : userType === 'Customer' ? usersCount.user || 0 : usersCount[userType.toLowerCase()] || 0 })
      </React.Fragment>
    );
  };

  handleSort(value) {
    let users = this.state.users;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    users = _.orderBy(users, [user => user[value].toLowerCase()], [order]);
    this.setState({ users, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={['fas', 'caret-up']} />
      :
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={['fas', 'caret-down']} />;
    return sortIcon;
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  }

  render() {
    const { users, order, orderBy, rowsPerPage, currentPage } = this.state;

    return (
      <div>
        <div className="admin-page-wrapper">
          <AdminPageHeader
            title="All Users"
            mainNav="Marketing"
            subNav="Users"
            description="All users of your app are listed here."
          />
          <div >
            <div>
              <AdminCard>
                <div className='columns is-mobile is-vcentered mb-0'>
                  <div className="column pb-0 is-two-fifths">
                    <div className='medium-text'>
                      {this.renderUsersFilter('All')}<span className='pl-1 pr-1'> | </span>
                      {this.renderUsersFilter('Admin')}<span className='pl-1 pr-1'> | </span>
                      {this.renderUsersFilter('Customer')}<span className='pl-1 pr-1'> | </span>
                      {this.renderUsersFilter('Staff')}
                    </div>
                  </div>
                  <div className="column pb-0 is-one-fifth is-offset-two-fifths">
                      <AdminSearchInput
                        value={store.userSearch}
                        onChange={this.searchUsers}
                        style={{ paddingBottom: 12 }}
                      />
                  </div>
                </div>
                <div className='divider' />
                  <Table className="app-table-wrapper">
                    <TableHead className="app-table-header">
                      <TableRow className="app-table-row">
                        <TableCell className="app-table-cell">
                          <TableSortLabel
                            active={orderBy === "full_name"}
                            direction={orderBy === "full_name" ? order : 'asc'}
                            onClick={() => this.handleSort("full_name")}
                            IconComponent={() => this.getSortIcon("full_name")}
                          >
                            Name
                            {orderBy === "full_name" ? (
                              <span className='is-hidden'>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="app-table-cell">
                          <TableSortLabel
                            active={orderBy === "email"}
                            direction={orderBy === "email" ? order : 'asc'}
                            onClick={() => this.handleSort("email")}
                            IconComponent={() => this.getSortIcon("email")}
                          >
                            Email
                            {orderBy === "email" ? (
                              <span className='is-hidden'>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="app-table-cell">
                          <TableSortLabel
                            active={orderBy === "role"}
                            direction={orderBy === "role" ? order : 'asc'}
                            onClick={() => this.handleSort("role")}
                            IconComponent={() => this.getSortIcon("role")}
                          >
                            Role
                            {orderBy === "role" ? (
                              <span className='is-hidden'>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="app-table-cell" >
                        </TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody className="app-table-body">
                        {this.state.users.length ?
                          <>
                              {(rowsPerPage > 0 ? users.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : users).map((user) => (
                                <TableRow
                                  key={user.id}
                                  className={`app-table-row ${this.state.inFocusUser === user.id ? 'highlighted-row ' : ''}`}
                                  onMouseOver={() => this.setState({ inFocusUser: user.id })}
                                >
                                  <TableCell className="app-table-cell">
                                    {user.full_name}
                                  </TableCell>
                                  <TableCell className="app-table-cell">
                                    {user.email}
                                  </TableCell>
                                  <TableCell className="app-table-cell">
                                    {formatUserRoleForView(user.role)}
                                  </TableCell>
                                  <TableCell className="app-table-cell" style={{width: 75}}>
                                    {this.state.inFocusUser === user.id ?
                                      <Button edit onClick={() => this.toggleDrawer(user)}>
                                        Edit
                                      </Button>
                                      : null}
                                  </TableCell>
                                </TableRow>
                              ))}
                            <TableRow className="app-table-row">
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                count={this.state.users && this.state.users.length ? this.state.users.length : 0}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={(event) => this.updateRows(event)}
                                page={this.state.currentPage}
                                onPageChange={(event, newPage) => this.updatePage(event, newPage)}
                                style={{ borderBottom: 'none' }}
                              />
                            </TableRow>
                          </>
                          : <TableRow className="app-table-row">
                              <TableCell className='app-table-cell'> No users were found.</TableCell>
                            </TableRow>
                          }
                      </TableBody>
                  </Table>
              </AdminCard>
            </div>
          </div>
        </div>
        <UserDrawer
          open={this.state.open}
          user={this.state.selectedUser}
          onClose={ this.handleDialogClose }
          drawerType='edit'
        />
      </div>
    );
  }
}

export default withNav(withRouter(observer(UserList)));
