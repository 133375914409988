import React, { Component } from "react";
import { Route } from "react-router-dom";
import withNav from "../components/withNav";
import ReportsSalesSummary from "./reportsSalesSummary";
import ReportsItemSales from "./reportsItemSales";
import ReportsCategorySales from "./reportsCategorySales";
import ReportsDiscounts from "./reportsDiscounts";
import { SideNavAndContentContainer } from '../components/elements/navs';
import reportsAnalytics from "./reportsAnalytics";
import ReportsUserSummary from './reportsUserSummary';

class Reports extends Component {
  render() {
    return (
      <div>
        <SideNavAndContentContainer>
          <Route path="/reports/analytics" component={reportsAnalytics} />
          <Route path="/reports/sales-summary" component={ReportsSalesSummary} />
          <Route path="/reports/item-sales" component={ReportsItemSales} />
          <Route path="/reports/category-sales" component={ReportsCategorySales} />
          <Route path="/reports/discounts" component={ReportsDiscounts} />
          <Route path="/reports/users" component={ReportsUserSummary} />
        </SideNavAndContentContainer>
      </div>
    );
  }
}

export default withNav(Reports);
