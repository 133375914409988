import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TablePagination, TableSortLabel } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from '../store/store';
import { displayHours, getLocationNameById, weekdays } from '../services/utils';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSearchInput from '../components/elements/adminSearchInput';
import { Button } from '../components/elements/buttons';
import { Text } from '../components/elements/text';
import MenuAddEdit from '../components/catalog/menuAddEdit';
import menuAPI from '../services/api/menuAPI';


class MenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: store.menus,
      currentPage: 0,
      inFocus: '',
      open: false,
      orderBy: 'name',
      order: 'desc',
      rowsPerPage: 10,
      searchedMenus: false,
      selectedMenu: {},
      rowCollapsed: {},
      typing: false,
      typingTimeout: 0
    };
  };

  componentWillMount = () => {
    this.getMenus();
  };

  getMenus = () => {
    menuAPI.getRecursiveMenus({ page: 1 })
      .then(() => {
        store.updateLoading(false);
        this.setState({ menus: store.menus });
      });
  };

  handleClose = updated => {
    this.setState({ open: false });
    if (updated) this.getMenus();
  };

  editMenu = menu => {
    this.props.history.push(`/menu/${ menu.id }`);
  };

  getApiSearch = query => {
    store.updateLoading(true);
    if (!this.state.searchedMenus) {
      this.getMenus();
    } else {
      menuAPI.searchMenus(query)
        .then(response => {
          this.setState({ menus: response.data, currentPage: 0 });
          store.updateLoading(false);
        });
    }
  };

  searchMenus = query => {
    store.updateMenuSearch(query);
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getApiSearch(query);
      }, 500),
      searchedMenus: query !== ''
    });
  };

  filterMenus = enabledType => {
    let menus = this.state.menus;
    switch (enabledType) {
      case 'All Menus':
        menus = store.menus;
        break;

      case 'Enabled':
        menus = _.filter(store.menus, ['is_enabled', true]);
        break;

      case 'Disabled':
        menus = _.filter(store.menus, ['is_enabled', false]);
        break;

      default:
        menus = store.menus;
        break;
    }
    this.setState({ menus, currentPage: 0 });
  };

  renderFilter = enabledType => {
    const menuCount = _.countBy(store.menus, 'is_enabled');
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterMenus(enabledType) }>
          { enabledType }
        </Button>
        ({ enabledType === 'All Menus' ? store.menus.length : enabledType === 'Enabled' ? menuCount.true || 0 : menuCount.false || 0 })
      </React.Fragment>
    );
  };

  handleSort(value) {
    let menus = this.state.menus;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'name') {
      menus = _.orderBy(menus, [menu => menu[value].toLowerCase()], [order]);
    } else if (['locations', 'categories'].includes(value)) {
      menus = _.orderBy(menus, [menu => menu[value].length], [order]);
    } else if (value === 'items') {
      menus = _.orderBy(menus, [menu => this.getItemCount(menu)], [order]);
    } else {
      menus = _.orderBy(menus, [menu => menu[value]], [order]);
    }
    this.setState({ menus, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  toggleRow(menu) {
    const rowCollapsed = menu.id === this.state.rowCollapsed ? {} : menu.id;
    this.setState({ rowCollapsed });
  };

  render() {
    const { menus, order, orderBy, rowsPerPage, currentPage } = this.state;

    return (
      <div className='admin-page-wrapper'>
        <AdminPageHeader
          title='Menus'
          mainNav='Configuration'
          subNav='Item Library'
          description='Create a new menu, customize an existing menu or add a menu to an existing location.'
          headerActionText='Add New'
          onHeaderActionClick={ () => this.setState({ open: true }) }
        />
        <div>
          <AdminCard>
            <div className='columns is-mobile is-vcentered mb-0'>
              <div className='column pb-0 is-two-fifths'>
                <div className='medium-text'>
                  { this.renderFilter('All Menus') }<span className='pl-1 pr-1'> | </span>
                  { this.renderFilter('Enabled') }<span className='pl-1 pr-1'> | </span>
                  { this.renderFilter('Disabled') }<span className='pl-1 pr-1'> | </span>
                </div>
              </div>
              <div className='column pb-0 is-one-fifth is-offset-two-fifths'>
                  <AdminSearchInput
                    value={ store.menuSearch }
                    onChange={ this.searchMenus }
                    style={ { paddingBottom: 12 } }
                  />
              </div>
            </div>
            <div className='divider' />
            <Table className='app-table-wrapper'>
              <TableHead className='app-table-header'>
                <TableRow className='app-table-row'>
                  <TableCell className='app-table-cell'>
                    <TableSortLabel
                      active={ orderBy === 'name' }
                      direction={ orderBy === 'name' ? order : 'asc' }
                      onClick={ () => this.handleSort('name') }
                      IconComponent={ () => this.getSortIcon('name') }
                    >
                      Menu Name
                      { orderBy === 'name' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='app-table-cell'>
                    <TableSortLabel
                      active={ orderBy === 'locations' }
                      direction={ orderBy === 'locations' ? order : 'asc' }
                      onClick={ () => this.handleSort('locations') }
                      IconComponent={ () => this.getSortIcon('locations') }
                    >
                      Locations
                      { orderBy === 'locations' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='app-table-cell'>
                    <TableSortLabel
                      active={ orderBy === 'categories' }
                      direction={ orderBy === 'categories' ? order : 'asc' }
                      onClick={ () => this.handleSort('categories') }
                      IconComponent={ () => this.getSortIcon('categories') }
                    >
                      Categories
                      { orderBy === 'categories' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='app-table-cell'>
                    <TableSortLabel
                      active={ orderBy === 'items' }
                      direction={ orderBy === 'items' ? order : 'asc' }
                      onClick={ () => this.handleSort('items') }
                      IconComponent={ () => this.getSortIcon('items') }
                    >
                      Items
                      { orderBy === 'items' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='app-table-cell'>
                    <TableSortLabel
                      active={ orderBy === 'is_enabled' }
                      direction={ orderBy === 'is_enabled' ? order : 'asc' }
                      onClick={ () => this.handleSort('is_enabled') }
                      IconComponent={ () => this.getSortIcon('is_enabled') }
                    >
                      Enabled
                      { orderBy === 'is_enabled' ? (
                        <span className='is-hidden'>
                          { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                        </span>
                      ) : null }
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className='app-table-cell' style={ { width: '20%' } }>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='app-table-body'>
                { menus && menus.length ?
                  <>
                    { (rowsPerPage > 0 ? menus.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : menus).map(menu => (
                      <React.Fragment key={ menu.id }>
                        <TableRow
                          className={ `app-table-row ${ this.state.inFocus === menu.id && this.state.rowCollapsed !== menu.id ? 'highlighted-row ' : '' }` }
                          onMouseOver={ () => this.setState({ inFocus: menu.id }) }
                        >
                          <TableCell className={ `app-table-cell pl-0 ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            <IconButton
                              aria-label='expand row'
                              onClick={ () => { this.toggleRow(menu); } }
                              className='p-0 pr-3'
                            >
                              { this.state.rowCollapsed === menu.id ?
                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } /> :
                                <FontAwesomeIcon size='xs' className='ordrslipRed ml-3' icon={ ['fas', 'caret-right'] } /> }
                            </IconButton>
                            { menu.name }
                          </TableCell>
                          <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            { menu.locations.length }
                          </TableCell>
                          <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            { menu.category_count }
                          </TableCell>
                          <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            { menu.item_count }
                          </TableCell>
                          <TableCell className={ `app-table-cell ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            { menu.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                          </TableCell>
                          <TableCell className={ `app-table-cell has-text-right ${ this.state.rowCollapsed === menu.id ? 'remove-row-border' : '' }` }>
                            { this.state.inFocus === menu.id ?
                              <Button
                                edit
                                onClick={ () => this.editMenu(menu) }
                              >
                                Edit
                              </Button> : null }
                          </TableCell>
                        </TableRow>
                        <TableRow className='app-table-row'>
                          <TableCell className='app-table-cell' style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ 9 }>
                            <Collapse in={ this.state.rowCollapsed === menu.id } timeout='auto' unmountOnExit>
                              <div className='menu-details'>
                                <Text weight="bold" className='pb-2'>Quick Menu Details</Text>
                                <Text>
                                  Here are your hours/locations for this menu. To view more information about this menu, click “Edit”.
                                </Text>
                                <div className="columns pt-3">
                                  <div className="column is-one-quarter">
                                    <Text weight="bold" className='pb-2'>Menu Hours</Text>
                                    { weekdays.map(day => (
                                      <Text key={ day } className='pb-1'>
                                        <span className='bold'>{ day }:  </span>
                                        {displayHours(day, menu.availability)}
                                      </Text>
                                    ))}
                                  </div>
                                  <div className="column is-three-quarters">
                                    <Text weight="bold" className='pb-2'>Selected Locations</Text>
                                    { store.locations.length && menu.locations.map(location => (
                                      <div key={ location } className='pb-1'>
                                        <FontAwesomeIcon className="brightGreen pr-2" size="1x" icon={ ['fal', 'check'] } />
                                        { getLocationNameById(location) }
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )) }
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={ [10, 25, 50] }
                        count={ menus.length ? menus.length : 0 }
                        rowsPerPage={ this.state.rowsPerPage }
                        onRowsPerPageChange={ (event) => this.updateRows(event) }
                        page={ this.state.currentPage }
                        onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                        style={ { borderBottom: 'none' } }
                      />
                    </TableRow>
                  </>
                  :
                  <TableRow className='app-table-row'>
                    <TableCell className='app-table-cell'>
                      No menus matched your search
                    </TableCell>
                  </TableRow> }
              </TableBody>
            </Table>
          </AdminCard>
        </div>
        <MenuAddEdit
          onClose={ this.handleClose }
          open={this.state.open}
        />
      </div>
    );
  }
}

export default observer(MenuList);
