import React, { Component } from "react";
import { Route } from "react-router-dom";
import withNav from "../components/withNav";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import IntegrationsUber from "./integrationsUber";
import IntegrationsDoordash from "./integrationsDoordash";
import SquareHardware from "./squareHardware";
import { SideNavAndContentContainer } from '../components/elements/navs';

class Integrations extends Component {
  render() {
    return (
      <div>
        <SideNavAndContentContainer>
          <Route path="/integrations/doordash" component={IntegrationsDoordash} />
          <Route path="/integrations/uber" component={IntegrationsUber} />
          <Route path="/integrations/square-hardware" component={SquareHardware} />
        </SideNavAndContentContainer>
      </div>
    );
  }
}

export default withNav(withRouter(observer(Integrations)));
