import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from '@material-ui/core/styles';
import { colors } from "../../styles/global";


const StyledCheckbox = withStyles({
    root: {
        color: colors.disabledGrey,
        '&$checked': {
            color: colors.brightGreen,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


class AdminCheckbox extends Component {
    render() {
        return (
            <StyledCheckbox
                checked={this.props.checked}
                onChange={this.props.onChange}
                name={this.props.name}
                disabled={ this.props.disabled }
                className='cursor-pointer'
            />

        )
    }
}

export default AdminCheckbox;