import React, { Component } from 'react';
import _ from 'lodash';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import InputMask from 'react-input-mask';

import integrationsAPI from "../../services/api/integrationsAPI";
import { fieldError, errorMessage } from "../../services/utils";
import store from "../../store/store";
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import EditDrawer from '../elements/editDrawer';
import { HeaderText, Text } from '../elements/text';
import LocationSelection from '../locationSelection';
import AdminDropdownField from '../elements/adminDropdownField';
import { toJS } from 'mobx';

const merchantTypes = {
    'MERCHANT_TYPE_ESSENTIALS': 'Essentials',
    'MERCHANT_TYPE_FLOWER': 'Flowers',
    'MERCHANT_TYPE_GROCERY': 'Grocery Store',
    'MERCHANT_TYPE_LIQUOR': 'Liquor Store',
    'MERCHANT_TYPE_PET_SUPPLY': 'Pet Supplies',
    'MERCHANT_TYPE_PHARMACY': 'Pharmacy',
    'MERCHANT_TYPE_RESTAURANT': 'Restaurant',
    'MERCHANT_TYPE_RETAIL': 'Retail',
    'MERCHANT_TYPE_SPECIALTY_FOOD': 'Specialty Food'
}


class IntegrationsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            is_enabled: _.isEmpty(this.props.fulfillment) ? true : this.props.fulfillment.is_enabled,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            selectedLocations: _.isEmpty(this.props.fulfillment) ? [] : this.props.fulfillment.locations,
            apiErrors: {},
            error: '',
            fulfillment: this.props.fulfillment,
            delivery_fee: this.props.fulfillment.delivery_fee ? this.props.fulfillment.delivery_fee.amount : '0',
            minimum_subtotal: this.props.fulfillment.minimum_subtotal ? this.props.fulfillment.minimum_subtotal.amount : '0',
            uber: {
                name: '',
                merchant_type: 'MERCHANT_TYPE_RESTAURANT',
                email: '',
                phone_number: '',
                country_code: '',
                subscriber_number: ''
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        const { fulfillment } = nextProps;
        if (fulfillment.id !== this.state.fulfillment.id) {
            this.setState({ selectedLocations: fulfillment.locations ? fulfillment.locations : [] })
            let delivery_fee = fulfillment.delivery_fee ? fulfillment.delivery_fee.amount : ''
            let minimum_subtotal = fulfillment.minimum_subtotal ? fulfillment.minimum_subtotal.amount : ''
            this.setState({
                fulfillment: fulfillment,
                delivery_fee,
                minimum_subtotal,
                is_enabled: fulfillment.is_enabled,
                delivery_area_method: fulfillment.delivery_area_method,
            });
        }
    };

    integrationName = (type) => {
        return type === 'doordash' ? 'DoorDash Integration' : 'Uber Integration';
    };

    getOptionsName = (name) => {
        return name === 'doordash' ? 'DoorDash' : _.capitalize(name);
    };

    toggleIsEnabled = () => {
        let is_enabled = !this.state.is_enabled;
        this.setState({ is_enabled });
    };

    addOrUpdateIntegration = () => {
        if (!this.state.selectedLocations.length) {
            this.setState({ error: 'You must choose at least one location.' });
            return;
        }
        let { fulfillment, uber } = this.state;
        let body = {};
        let delivery_fee = {};
        let minimum_subtotal = {};

        body.is_enabled = this.state.is_enabled;
        body.locations = this.state.selectedLocations;
        body.integration = this.props.integration;

        if (this.props.integration === 'uber') {
            body.uber = uber;
            const chars = {
                ' ': '',
                '(': '',
                ')': '',
                '-': ''
            }
            body.uber.phone_number = uber.phone_number.replace(/[-)(\s]/g, m => chars[m]);
            body.uber.subscriber_number = uber.subscriber_number.replace(/[-)(\s]/g, m => chars[m]);

        } else if (this.props.integration === 'doordash') {
            minimum_subtotal.amount = this.state.minimum_subtotal.toString();
            minimum_subtotal.currency = fulfillment.minimum_subtotal ? fulfillment.minimum_subtotal.currency : this.getLocationCurrency(this.state.selectedLocations[0]);;
            body.minimum_subtotal = minimum_subtotal;
            body.delivery_radius = fulfillment.delivery_radius ? fulfillment.delivery_radius : 0;
            body.business_name = fulfillment.business_name;
            delivery_fee.amount = this.state.delivery_fee.toString();
            delivery_fee.currency = fulfillment.delivery_fee ? fulfillment.delivery_fee.currency : this.getLocationCurrency(this.state.selectedLocations[0]);
            body.delivery_fee = delivery_fee;
        }

        if (fulfillment.id) {
            integrationsAPI.updateFulfillment(fulfillment.id, JSON.stringify(body))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        this.setState({ apiErrors: {} });
                        this.props.onClose(true);
                    }
                })
                .catch(err => console.error(err));
        } else {
            integrationsAPI.createFulfillment(JSON.stringify(body))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        this.setState({ apiErrors: {} });
                        this.props.onClose(true);
                    }
                })
                .catch(err => console.error(err));
        }
    };

    getLocationCurrency(id) {
        return _.find(store.locations, { id: id }).currency;
    };

    getDeliveryText(type) {
        if (type === 'doordash') {
            return (
                <div>
                    DoorDash charges $7.00 / order. It’s recommended that the user doesn’t pay more than $4.99 for delivery.
                    Restaurants are recommended to pay the difference between the fee you set and the $7.00 DoorDash charges.
                    For more info, visit {`\n`}
                    <span
                        type='button'
                        onClick={() => window.open('https://info.ordrslip.com/support/doordash', '_blank')}
                        className='ordrslipRed semi-bold cursor-pointer'>
                         https://info.ordrslip.com/support/doordash
                    </span>
                </div>
            );
        }
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let fulfillment = this.state.fulfillment;
        fulfillment[name] = value;
        this.setState({ fulfillment });
    };

    handleChangeUber(event) {
        let { apiErrors, uber } = this.state;
        let error = '';
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'phone') {
            if (value.replace('_', '').length < 16) error = 'Phone must be at least 10 digits.';
        } else if (name === 'email') {
            if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)) error = 'Please enter a valid email.';
        } else {
            if (!value.length) error = 'This field is required.';
        }

        if (name === 'phone') {
            uber.phone_number = value;
            uber.country_code = value[0]
            uber.subscriber_number = value.slice(-14)
        }
        else {
            uber[name] = value;
        }
        if (error.length) {
            apiErrors[name] = [{ message: error }];
        } else {
            apiErrors = {};
        }
        this.setState({ apiErrors, uber });
    };

    updateLocations = locations => {
        this.setState({ selectedLocations: locations });
    };

    displayMerchantType = option => {
        return merchantTypes[option];
    };

    handleClose = updated => {
        this.setState({ apiErrors: {}, error: '' });
        this.props.onClose(updated);
    }

    render() {
        const { fulfillment, apiErrors, error, is_enabled, uber } = this.state;
        const { deleteIntegration, integration } = this.props;

        return (
            <EditDrawer
                title={ `${ fulfillment.id ? 'Edit' : 'Add'} ${this.integrationName(integration)}`}
                onClose={this.handleClose}
                isSubmitting={false}
                onSubmit={this.addOrUpdateIntegration}
                errorMessage={errorMessage(apiErrors, error)}
                deleteButton={integration !== 'uber'}
                buttonAction={() => deleteIntegration(fulfillment.id)}
                buttonText='Delete Integration'
            >
                <AdminCard
                    title={ integration === 'uber' ? 'Integration Info' : `Enable/Disable ${this.getOptionsName(integration)} Integration`}
                    style={{ marginTop: '15px' }}
                    description=''
                >
                    { integration === 'uber' ? null :
                        <AdminToggle
                        className='app-toggle'
                        checked={ is_enabled }
                        onChange={ this.toggleIsEnabled }
                    />}
                    <div className='pt-5'>
                        { integration === 'doordash' ?
                            <>
                            <HeaderText small>Integration Info</HeaderText>
                                <label className='inputLabel is-uppercase'>Business Name</label>
                                <input
                                    className={ fieldError('business_name', apiErrors) ? 'app-input-field red-border' : 'app-input-field' }
                                    id='business_name'
                                    name='business_name'
                                    type='text'
                                    value={ fulfillment.business_name || '' }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                { fieldError('business_name', apiErrors) }
                            </>
                            : null }
                        { integration === 'uber' ?
                            <React.Fragment>
                                <label className='inputLabel is-uppercase'>Business Name</label>
                                <input
                                    className={fieldError('name', apiErrors) ? 'app-input-field red-border' : 'app-input-field'}
                                    id='name'
                                    name='name'
                                    type='text'
                                    value={uber.name}
                                    onChange={(event) => this.handleChangeUber(event)}
                                />
                                {fieldError('name', apiErrors)}
                                <div className='pt-4'>
                                    <label className='inputLabel is-uppercase'>Contact Email</label>
                                    <input
                                        className={fieldError('email', apiErrors) ? 'app-input-field red-border' : 'app-input-field'}
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={ uber.email}
                                        onChange={(event) => this.handleChangeUber(event)}
                                    />
                                    {fieldError('email', apiErrors)}
                                </div>
                                <div className='pt-4'>
                                    <label className='inputLabel is-uppercase'>Contact Phone</label>
                                    <InputMask
                                        mask='9 (999) 999-9999'
                                        className={ `app-input-field mt-0 ${ fieldError('phone', this.state.apiErrors) ? 'red-border' : '' }` }
                                        id='phone'
                                        name='phone'
                                        type='text'
                                        value={ uber.phone_number }
                                        onChange={ (event) => this.handleChangeUber(event) }
                                    />
                                    {fieldError('phone', apiErrors)}
                                </div>
                                <div className='pt-4'>
                                    <label className='inputLabel is-uppercase'>Merchant Type</label>
                                        <AdminDropdownField
                                            id='merchant_type'
                                            name='merchant_type'
                                            onChange={ (event) => this.handleChangeUber(event) }
                                            value={ uber.merchant_type }
                                            style={ { width: '100%' } }
                                        >
                                            { _.keys(merchantTypes).map((option, i) => (
                                                    <option key={ i } value={ option }>
                                                        { this.displayMerchantType(option) }
                                                    </option>
                                                )) }
                                        </AdminDropdownField>
                                    { fieldError('merchant_type', apiErrors)}
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                </AdminCard>
                { integration === 'uber' ? null :
                    <AdminCard
                    title={ `${ this.getOptionsName(integration) } Delivery Options` }
                    description={ this.getDeliveryText(integration) }
                >
                    {integration === 'doordash' && (
                        <React.Fragment>
                            <div className='error-message small-text mb-4' style={{ display: 'flex', flexDirection: 'row', fontWeight: 400}}>
                                <div>
                                    <ErrorOutline className='error-icon' />
                                </div>
                                <div>
                                    <span className='bold'>NOTE: </span>
                                    <span>Due to DoorDash delivery guidelines, delivery orders will be restricted to 3-5 miles from your business' location.</span>
                                </div>
                            </div>
                            <label className='inputLabel is-uppercase'>DELIVERY FEE</label>
                            <input
                            className={fieldError('delivery_fee', apiErrors) ? 'app-input-field red-border' : 'app-input-field'}
                            id='delivery_fee'
                            type='text'
                            placeholder={integration === 'doordash' ? 'No more than $7.50' : '0'}
                            value={this.state.delivery_fee}
                            onChange={(event) => this.setState({delivery_fee: event.target.value})}
                            />
                            {fieldError('delivery_fee', apiErrors)}
                        </React.Fragment>
                    )}
                    {integration === 'uber' ? 
                        <div className='pt-4'>
                            <label className='inputLabel is-uppercase'>DELIVERY RADIUS</label>
                            <input
                                className={fieldError('delivery_radius', apiErrors) ? 'app-input-field red-border' : 'app-input-field'}
                                id='delivery_radius'
                                name='delivery_radius'
                                type='number'
                                placeholder='0'
                                value={fulfillment.delivery_radius}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('delivery_radius', apiErrors)}
                        </div>
                    : null}
                    </AdminCard>
                }
                { integration === 'uber' ? null :
                    <AdminCard
                    title='Minimum Subtotal'
                    description={`Require a minimum subtotal for each ${this.getOptionsName(integration)} integration order.`}
                >
                    <label className='inputLabel is-uppercase'>MINIMUM SUBTOTAL</label>
                    <input
                        className={fieldError('minimum_subtotal', apiErrors) ? 'app-input-field red-border' : 'app-input-field'}
                        id='minimum_subtotal'
                        type='text'
                        value={this.state.minimum_subtotal}
                        onChange={(event) => this.setState({ minimum_subtotal: event.target.value })}
                    />
                    {fieldError('minimum_subtotal', apiErrors)}
                    </AdminCard>
                }
                <LocationSelection
                    selectedLocations={ this.state.selectedLocations }
                    itemName={ integration }
                    onUpdateLocations={ this.updateLocations }
                    fieldError={ fieldError('locations', apiErrors) }
                />
            </EditDrawer>
        );
    }
}

export default IntegrationsEdit;