import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";

import store from "../../store/store";
import { fieldError, getLocations, showDateRange } from "../../services/utils";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { Text } from "../elements/text";
import RadioLabel from "../elements/radioLabel";
import AdminToolTip from '../elements/adminToolTip';
import promotionsAPI from '../../services/api/promotionsAPI';

const whyCantIEditMsg = "Editing a sale can cause a bad user experience for customers. If this sale must be changed due to a mistake, you can delete this sale and create a new one."

const emptyPromoCode = {
    name: '',
    amount_off: { amount: "0", currency: 'USD' },
    percent_off: '',
    minimum_order_amount: { amount: "0", currency: 'USD' },
    apply_to_minimum: true,
    locations: [],
    sale_hours: [],
    is_active: true,
    discount_type: 'amount',
    description: '',
    status: true,
};

class SalesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            promoCode: !_.isEmpty(this.props.promoCode) ? this.props.promoCode : emptyPromoCode,
            selectedLocations: !_.isEmpty(this.props.promoCode) && this.props.promoCode.locations.length ? this.props.promoCode.locations : [],
            discountType: !_.isEmpty(this.props.promoCode) && this.props.promoCode.percent_off ? 'percent' : 'amount',
            itemsType: 'order',
            apiErrors: {},
            error: '',
            selectedDays: [],
            fromDate: !_.isEmpty(this.props.promoCode) ? this.props.promoCode.start_time : new Date(),
            toDate: !_.isEmpty(this.props.promoCode) && this.props.promoCode.end_time ? this.props.promoCode.end_time : new Date(moment().add(1, 'day')),
            duration: !_.isEmpty(this.props.promoCode) &! this.props.promoCode.end_time ? 'ongoing' : 'datetime' // 'datetime' or 'ongoing'
        };
    };

    clearStateValues = () => {
        this.setState({
            promoCode: emptyPromoCode,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            selectedLocations: [],
            discountType: 'amount',
            itemsType: 'order',
            apiErrors: {},
            error: '',
        })
    }

    handleClose = () => {
        this.clearStateValues();
        this.props.onClose();
    };

    handleChange = (event) => {
        let promoCode = this.state.promoCode;
        let name, value = '';
        if (event.target) {
            name = event.target.name;
            value = event.target.type === 'number' && 
                !['amount_off', 'max_discount_amount', 'minimum_order_amount'].includes(name) ? 
                parseInt(event.target.value) : event.target.value;
            if (['amount_off', 'max_discount_amount', 'minimum_order_amount'].includes(name)) {
                value = {
                    amount: value,
                    currency: this.state.selectedLocations[0] ? this.state.selectedLocations[0].currency : store.locations[0].currency
                }
            }
        } else {
            // Datepicker has no event.target
            value = event;
            name = 'expiration';
        }
        promoCode[name] = value;
        this.setState({ promoCode });
    }

    getDiscountAmount(promoCode) {
        if (promoCode.percent_off && promoCode.percent_off > 0) return `${promoCode.percent_off}% Off`;
        else if (promoCode.amount_off && promoCode.amount_off.formatted) return `${promoCode.amount_off.formatted} Off`;
        else return null;
    }

    handleSubmit = () => {
        if (!this.state.selectedLocations.length) {
            this.setState({ error: 'You must choose at least one location.' });
            return;
        }

        let { promoCode } = this.state;
        let payload = {
            name: promoCode.name,
            description: promoCode.description,
        };
        payload.start_time = this.state.fromDate;
        payload.end_time = this.state.duration === 'ongoing' ? null : this.state.toDate;
        promotionsAPI.updateSale(this.state.promoCode.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                    if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                }
                else {
                    this.handleClose(true);
                }
            });
    };

    updateDates = (type, newDate) => {
        let displayDate = new Date(newDate);
        newDate = moment(newDate).format('MM/DD/YYYY');
        if (type === 'fromDate') {
            this.setState({ fromDate: displayDate });
            if (displayDate >= new Date(this.state.toDate)) {
                this.setState({ toDate: new Date(moment(displayDate).add(1, 'day').format('MM/DD/YYYY')) })
            }
        } else if (type === 'toDate') {
            this.setState({ toDate: displayDate });
        }
    }

    starIcon = () => {
        const { drawerType } = this.props;
        if (drawerType === 'expired') {
            return <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>;
        } else {
            return <FontAwesomeIcon icon="fa-solid fa-star" size="lg" className='tertiaryOrange pr-2 pb-1' />;
        }
    }

    toggleRadios = (name, value) => {
        const promoCode = this.state.promoCode;
        if (value === 'ongoing') promoCode.end_time = null;
        this.setState({ duration: value });
    };

    render() {
        const {
            apiErrors,
            promoCode
        } = this.state;
        const { deactivateSale, drawerType, duplicatePromo } = this.props;
        const addPromo = this.state.promoCode.id ? false : true;

        return (
            <EditDrawer
                title={`${drawerType === 'expired' ? "View Inactive" : "Edit"} Sale`}
                onClose={this.handleClose}
                onSubmit={this.handleSubmit}
                noFooter={drawerType === 'expired' ? true : false}
                deleteButton={addPromo ? false : true}
                buttonAction={drawerType === 'expired' ? duplicatePromo : deactivateSale} 
                buttonText={drawerType === 'expired' ? 'Duplicate Sale' : 'Deactivate Sale'}
            >
                <AdminCard
                    title="Sale Details"
                    style={{ marginTop: "15px" }}
                >
                    <div className="app-field-wrapper">
                        <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Sale Name</label>
                        {drawerType === 'view' ? 
                        <>
                            <Text className='mb-2'>A name for your sale. (Customers will see this in the app when using this sale.)</Text>
                            <input
                                className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Sale Name"
                                value={promoCode.name}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('name', apiErrors)}
                        </> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {this.starIcon()}
                            <Text weight="bold">{promoCode.name}</Text>
                        </div>}
                        {fieldError('name', apiErrors)}
                    </div>
                    {!promoCode.description ? null :
                    <div className="app-field-wrapper">
                        <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Sale Description</label>
                        {drawerType === 'view' ? 
                        <>
                            <Text className='mb-2'>Details about your sale. (Customers will see these details in the app when using this sale.)</Text>
                            <textarea
                                className={fieldError("description", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                id="description"
                                name="description"
                                type="text"
                                placeholder="Sale Description"
                                value={promoCode.description}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('description', apiErrors)}
                        </> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {this.starIcon()}
                            <Text weight="bold">{promoCode.description}</Text>
                        </div>}
                    </div>}
                    <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                        {drawerType === 'view' ?
                        <>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                Start Date
                                    { new Date(this.state.fromDate) <= new Date() ?
                                        <AdminToolTip title={
                                    <>
                                        <Text weight='bold'>Why can’t I edit this?</Text>
                                        <br />
                                        <Text>You cannot edit the start date of a sale that has already started.</Text>
                                    </>
                                } /> : null }
                            </div>
                            <Text className='mb-2'>This sale will start this date.</Text>
                            <div
                                className="column is-narrow"
                                style={{ padding: 0, height: 40, width: "30%" }}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disabled={new Date(this.state.fromDate) <= new Date()}
                                        minDate={_.min([new Date(), new Date(moment(this.state.fromDate))])}
                                        autoOk
                                        value={this.state.fromDate}
                                        inputVariant="standard"
                                        variant="inline"
                                        format="MM/dd/YYYY"
                                        size="small"
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        style={{ padding: "8px 16px 0 16px" }}
                                        className="app-input-field no-padding"
                                        onChange={(value) => this.updateDates("fromDate", value)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                End Date
                                { new Date(this.state.toDate) < new Date() ?
                                    <AdminToolTip title={
                                        <>
                                            <Text weight='bold'>Why can’t I edit this?</Text>
                                            <br />
                                            <Text>You cannot edit the end date of a sale that has already ended.</Text>
                                        </>
                                    } /> : null
                                }
                            </div>
                            <Text className='mb-2'>Choose the day the sale will end. Choose ongoing if you'd like the sale to continue indefinitely.</Text>
                            <RadioGroup
                                aria-label="duration"
                                name="duration"
                                value={this.state.duration}
                                onChange={(event) => this.toggleRadios("duration", event.target.value)}
                                className="radio-group"
                            >
                                <RadioLabel value="ongoing" label="Ongoing Sale" />
                                <RadioLabel value="datetime" label="Set end date" />
                            </RadioGroup>
                            {this.state.duration === 'datetime' ?
                            <div
                                className="column is-narrow"
                                style={{ padding: 0, height: 40, width: "30%" }}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disablePast
                                        disabled={new Date(this.state.toDate) < new Date()}
                                        autoOk
                                        minDate={ _.max([new Date(), new Date(moment(this.state.toDate))]) }
                                        value={ _.max([new Date(), new Date(moment(this.state.toDate))])}
                                        inputVariant="standard"
                                        variant="inline"
                                        format="MM/dd/YYYY"
                                        size="small"
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        style={{ padding: "8px 16px 0 16px" }}
                                        className="app-input-field no-padding"
                                        onChange={(value) => this.updateDates("toDate", value)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div> : null}
                        </> : 
                        <>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                Sale Duration
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {this.starIcon()}
                                <Text weight="bold">{showDateRange(promoCode)}</Text>
                            </div>
                        </>
                        }
                    </div>
                </AdminCard>
                <AdminCard
                    title="Sale Availability"
                    className='columns is-mobile is-vcentered is-multiline'
                    style={{ marginTop: -40 }}
                >
                {promoCode.sale_hours && promoCode.sale_hours.length ? promoCode.sale_hours.map((hours, index) => (
                    <div
                        key={ index }
                        style={ { marginLeft: 0 } }
                    >
                        <div className="columns is-mobile is-vcentered">
                            <div className="column is-half is-flex">
                                <div style={ { paddingRight: 10 } }>
                                    <FontAwesomeIcon className="green-font" size="1x" icon={ ['fal', 'check'] } />
                                </div>
                                <Text>{ hours.weekday }</Text>
                            </div>
                            <div
                                className="column is-half"
                                style={ { right: 0 } }>
                                <Text>{ moment(hours.start_time, 'HH:mm:ss').format('hh:mm a') } - { moment(hours.end_time, 'HH:mm:ss').format('hh:mm a') }</Text>
                            </div>
                        </div>
                    </div>
                )) : <Text>Available all day during store hours.</Text>}
                </AdminCard>
                <AdminCard
                    title="Discount Options"
                    toolTip={drawerType === 'view' ? 
                        <>
                            <Text weight='bold'>Why can't I edit a sale?</Text>
                            <br />
                            <Text>{whyCantIEditMsg}</Text>
                        </> : ''
                    }
                >
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            Discount Type/Amount
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {this.starIcon()}
                            <Text weight="bold">{this.getDiscountAmount(promoCode)}</Text>
                        </div>
                    </div>
                    <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            Minimum Order Amount
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {this.starIcon()}
                            <Text weight="bold">{promoCode.minimum_order_amount.formatted}</Text>
                        </div>
                        <div>{fieldError('minimum_order_amount', apiErrors)}</div>
                    </div>
                    {promoCode.max_discount_amount ?
                    <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>
                            Maximum Discount Amount
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {this.starIcon()}
                            <Text weight="bold">{promoCode.max_discount_amount.formatted}</Text>
                        </div>
                        <div>{fieldError('max_discount_amount', apiErrors)}</div>
                    </div> : null}
                </AdminCard>
                <AdminCard title="Location Selection" style={{marginBottom: 25}}>
                    <div className='pt-3 pb-3'>
                        {getLocations(promoCode)}
                    </div>
                </AdminCard>
            </EditDrawer>
        );
    }
}

export default (SalesView);
