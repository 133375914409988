import React, { Component } from "react";
import tenantAPI from '../../services/api/tenantAPI';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import { Text } from '../elements/text';


class toggleSpecialInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      enable_item_special_instructions: this.props.location.enable_item_special_instructions,
      enable_order_special_instructions: this.props.location.enable_order_special_instructions
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location)
      this.setState({
        location: nextProps.location,
        enable_item_special_instructions: nextProps.location.enable_item_special_instructions,
        enable_order_special_instructions: nextProps.location.enable_order_special_instructions
      });
  };

  updateLocation = values => {
    tenantAPI.updateLocation(this.state.location.id, values)
      .then(response => this.props.onUpdate(response))
  };

  toggleItemEnabled = () => {
    let enable_item_special_instructions = !this.state.enable_item_special_instructions;
    const values = JSON.stringify({ enable_item_special_instructions: enable_item_special_instructions });
    this.updateLocation(values);
  };

  toggleOrderEnabled = () => {
    let enable_order_special_instructions = !this.state.enable_order_special_instructions;
    const values = JSON.stringify({ enable_order_special_instructions: enable_order_special_instructions });
    this.updateLocation(values);
  };

  render() {
    return (
      <AdminCard
        largeTitle
        title="Enable/Disable Special Instructions"
        description="When enabled, customers will be able to add special instructions to an item and/or orders at this location. (This setting will not affect other locations.)"
      >
        <div className="app-card-content-wrapper">
          <Text weight="bold" className='pb-2'>Item Special Instructions</Text>
          <AdminToggle
            className='app-toggle'
            checked={ Boolean(this.state.enable_item_special_instructions) }
            onChange={ this.toggleItemEnabled }
          />
        </div>
        <div className="app-card-content-wrapper">
          <Text weight="bold" className='pt-3 pb-2'>Order Special Instructions</Text>
          <AdminToggle
            className='app-toggle'
            checked={ Boolean(this.state.enable_order_special_instructions) }
            onChange={ this.toggleOrderEnabled }
          />
        </div>
      </AdminCard>
    );
  }
}

export default toggleSpecialInstructions;
