import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import _ from 'lodash';
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import store from "../../store/store";
import { errorMessage, fieldError, sortHoursByDay, weekdays, getLocations, formatTimeForView } from "../../services/utils";
import AdminCard from '../elements/adminCard';
import AdminToolTip from '../elements/adminToolTip';
import EditDrawer from '../elements/editDrawer';
import RadioLabel from "../elements/radioLabel";
import { Text } from "../elements/text";
import LocationSelection from '../locationSelection';
import PromoHours from "./promoHours";
import ReportsCustomHeader from "../reports/reportsCustomHeader";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import promotionsAPI from '../../services/api/promotionsAPI';
import AdminSelection from '../elements/adminSelection';

const whyCantIEditMsg = "Editing a promo code can cause a bad user experience for customers. If this promo code must be changed due to a mistake, you can delete this promo and create a new one."

const emptyPromoCode = {
    name: '',
    code: '',
    amount_off: { amount: "0", currency: 'USD' },
    percent_off: '',
    expiration: null,
    max_redemptions: '',
    max_redemptions_per_user: '',
    apply_to_minimum: true,
    apply_items_amount: 'ALL',
    is_enabled: true,
    locations: [],
    categories: [],
    items: [],
    users: [],
    coupon_hours: [],
    is_bogo: false
};

class PromoCodeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyToAll: false,
            promoCode: !_.isEmpty(this.props.promoCode) ? this.props.promoCode : _.cloneDeep(emptyPromoCode),
            selectedLocations: !_.isEmpty(this.props.promoCode) && this.props.promoCode.locations.length ? this.props.promoCode.locations : [],
            discountType: !_.isEmpty(this.props.promoCode) && this.props.promoCode.percent_off ? 'percent' : 'amount',
            itemsType: !_.isEmpty(this.props.promoCode) ? this.getItemsType(this.props.promoCode) : 'order',
            apiErrors: {},
            error: '',
            selectedDays: [],
            fromDate: new Date(moment().subtract(1, 'day')),
            toDate: new Date(),
            user_limit: !_.isEmpty(this.props.promoCode) && this.props.promoCode.users.length ? 'specific' : this.props.promoCode.max_redemptions_per_user ? 'limited' : 'unlimited',
            selectedUsers: !_.isEmpty(this.props.promoCode) && this.props.promoCode.users.length ? this.props.promoCode.users : [],
        };
    };

    componentDidMount = () => {
        const { promoCode, selectedDays } = this.state;
        if (!promoCode.coupon_hours) promoCode.coupon_hours = [];
        weekdays.forEach(day => {
            promoCode.coupon_hours.push({
                weekday: day,
                start_time: null,
                end_time: null,
            });
        });
        let availability = promoCode.coupon_hours;
        promoCode.coupon_hours.map((hour) => selectedDays.push(hour.weekday));
        if (promoCode.coupon_hours) {
            weekdays.forEach((weekday) => {
                promoCode.coupon_hours.forEach((coupon_hour) => {
                    if (weekday !== coupon_hour.weekday) {
                        promoCode.coupon_hours.push({
                            weekday: weekday,
                            start_time: null,
                            end_time: null,
                        });
                        promoCode.coupon_hours = _.uniqBy(availability, 'weekday');
                        promoCode.coupon_hours = sortHoursByDay(promoCode.coupon_hours);
                    }
                })
            })
        }
        this.setState({ promoCode, selectedDays });
    };

    toggleRadios = (name, value) => {
        const promoCode = this.state.promoCode;
        switch (name) {
            case 'expires':
                promoCode.expiration = value === 'true' ? new Date() : null;
                this.setState({ promoCode });
                break;
            case 'discountType':
                if (value === 'bogo') {
                    promoCode.is_bogo = true;
                    promoCode.apply_items_amount = 1;
                    this.setState({ discountType: 'percent', itemsType: 'items', promoCode: promoCode });
                } else {
                    promoCode.is_bogo = false;
                    promoCode.apply_items_amount = 1;
                    this.setState({ discountType: value, promoCode: promoCode });
                }
                break;
            case 'user_limit':
                promoCode.max_redemptions = value === 'limited' ? 1 : null;
                this.setState({ promoCode, user_limit: value });
                break;
            case 'max_redemptions_per_user':
                promoCode.max_redemptions_per_user = value === 'true' ? 1 : null;
                this.setState({ promoCode });
                break;
            default:
                promoCode[name] = !this.state.promoCode[name]
                this.setState({ promoCode });
                break;
        }
    }

    toggleItemsType = (value) => {
        const promoCode = this.state.promoCode;
        switch (value) {
            case 'order':
                promoCode.items = []
                promoCode.categories = []
                this.setState({ promoCode, itemsType: value });
                break;

            case 'items':
                promoCode.categories = []
                this.setState({ promoCode, itemsType: value });
                break;

            case 'categories':
                promoCode.items = []
                this.setState({ promoCode, itemsType: value });
                break;

            default:
                break;
        }
    }

    clearStateValues = () => {
        this.setState({
            promoCode: emptyPromoCode,
            locationInFocus: '',
            searchResults: [],
            searchText: '',
            selectedLocation: {},
            selectedLocations: [],
            discountType: 'amount',
            itemsType: 'order',
            apiErrors: {},
            error: '',
        })
    }

    handleClose = updated => {
        this.clearStateValues();
        this.props.onClose(updated);
    };

    validateApplyItemsAmount = (value) => {
        let {promoCode, apiErrors } = this.state;
        value = typeof(value) === 'number' ? value : value.toUpperCase();
        promoCode.apply_items_amount = value;
        if (!value.match(/^[0-9]+$|\W*(ALL)\W*/)) {
            apiErrors.apply_items_amount = [{message: "Must be only digits or ALL", code: 'invalid'}];
        } else {
            delete apiErrors.apply_items_amount;
        }
        this.setState({ promoCode, apiErrors });
    }

    handleChange = (event) => {
        let promoCode = this.state.promoCode;
        let name, value = '';
        if (event.target) {
            name = event.target.name;
            value = event.target.type === 'number' && name !== 'amount_off' ? parseInt(event.target.value) : event.target.value;
            if (name === 'amount_off') {
                value = {
                    amount: value,
                    currency: this.state.selectedLocations[0] ? this.state.selectedLocations[0].currency : store.locations[0].currency
                }
            }
        } else {
            // Datepicker has no event.target
            value = event;
            name = 'expiration';
        }
        promoCode[name] = value;
        this.setState({ promoCode });
    }

    deletePromo = () => {
        promotionsAPI.deletePromoCode(this.state.promoCode.id)
            .then(() => {
                this.handleClose(true);
            });
    };

    getDiscountAmount(promoCode) {
        if (promoCode.percent_off && promoCode.percent_off > 0) return `${promoCode.percent_off}% Off`;
        else if (promoCode.amount_off && promoCode.amount_off.formatted) return `${promoCode.amount_off.formatted} Off`;
        else return null;
    }

    getItemsType(promoCode) {
        if (promoCode.categories && promoCode.categories.length) {
            return 'categories'
        } else if (promoCode.items && promoCode.items.length) {
            return 'items'
        } else {
            return 'order'
        }
    }

    toggleSelectCategory = category => {
        const promoCode = this.state.promoCode;
        if (promoCode.categories.includes(category)) {
            const index = promoCode.categories.indexOf(category);
            promoCode.categories.splice(index, 1);
            this.setState({ promoCode });
        } else {
            promoCode.categories.push(category);
            this.setState({ promoCode });
        }
    };

    toggleSelectItem = item => {
        const promoCode = this.state.promoCode;
        if (promoCode.items.includes(item)) {
            const index = promoCode.items.indexOf(item);
            promoCode.items.splice(index, 1);
            this.setState({ promoCode });
        } else {
            promoCode.items.push(item);
            this.setState({ promoCode });
        }
    };

    handleSubmit = () => {
        if (!this.state.promoCode.id & !this.state.selectedLocations.length) {
            this.setState({ error: 'You must choose at least one location.' });
            return;
        }
        if (this.state.user_limit === 'specific' & !this.state.selectedUsers.length) {
            this.setState({ error: 'You must choose at least one user.' });
            return;
        }
        if (this.state.promoCode.is_bogo) {
            if (this.state.itemsType === 'items' & !this.state.promoCode.items.length) {
                this.setState({ error: 'You must choose at least one item.' });
                return;
            }
            if (this.state.itemsType === 'categories' & !this.state.promoCode.categories.length) {
                this.setState({ error: 'You must choose at least one category.' });
                return;
            }
        }
        let payload = _.cloneDeep(toJS(this.state.promoCode));
        delete payload.id;
        payload.locations = this.state.selectedLocations;
        payload.users = this.state.selectedUsers
        payload.expiration = payload.expiration ? moment(this.state.promoCode.expiration).format("YYYY-MM-DD") : null;
        payload.apply_items_amount = this.state.promoCode.apply_items_amount === 'ALL' ? '' : this.state.promoCode.apply_items_amount;
        payload.coupon_hours = payload.coupon_hours.filter((hour) => Boolean(hour.start_time));

        if (this.state.promoCode.id) {
            delete payload.amount_off;
            delete payload.code;
            promotionsAPI.updatePromoCode(this.state.promoCode.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        } else {
            if (this.state.discountType === 'percent') {
                payload.amount_off.amount = '0';
            } else if (this.state.discountType === 'amount') {
                delete payload.percent_off;
            }
            payload.amount_off.currency = _.find(store.locations, loc => loc.id === this.state.selectedLocations[0]).currency;
            promotionsAPI.createPromoCode(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        }
    };

    updateLocations = locations => {
        this.setState({ selectedLocations: locations });
    };

    updateHours = (coupon_hours=[], selectedDays, timeframe) => {
        let isAllDay = timeframe === 'all';
        let { promoCode } = this.state;
        if (isAllDay) {
            coupon_hours.forEach((hour) => {
                hour.start_time = '00:00';
                hour.end_time = '23:59';
            });
        }
        promoCode.coupon_hours = coupon_hours;
        promoCode.coupon_hours = _.uniqBy(promoCode.coupon_hours, 'weekday');
        promoCode.coupon_hours = sortHoursByDay(promoCode.coupon_hours);
        this.setState({ promoCode, selectedDays });
    }

    updateDates = (type, newDate) => {
        let displayDate = new Date(newDate);
        newDate = moment(newDate).format('MM/DD/YYYY');
        if (type === 'fromDate') {
            this.setState({ fromDate: displayDate });
        } else if (type === 'toDate') {
            this.setState({ toDate: displayDate });
        }
    };

    updateUsers = users => {
        let selectedUsers = [];
        _.forEach(users, (user) => {
            selectedUsers.push(user);
        });
        this.setState({ selectedUsers });
    };

    updateCategories = categories => {
        let { promoCode } = this.state;
        promoCode.categories = categories;
        this.setState({ promoCode });
    };

    updateItems = items => {
        let { promoCode } = this.state;
        promoCode.items = items;
        this.setState({ promoCode });
    };

    render() {
        const {
            apiErrors,
            error,
            discountType,
            itemsType,
            promoCode,
        } = this.state;
        const { isSales } = this.props;
        const addPromo = promoCode.id ? false : true;

        return (
                <EditDrawer
                    title={`${addPromo ? "New" : "Edit"} Promo Code`}
                    onClose={() => this.handleClose(false)}
                    onSubmit={this.handleSubmit}
                    errorMessage={errorMessage(apiErrors, error)}
                    deleteButton={addPromo ? false : true}
                    buttonAction={addPromo ? null : this.deletePromo}
                    buttonText={addPromo ? '' : 'Delete Promotion'}
                >
                    <AdminCard
                        title="Promo Details"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="app-field-wrapper">
                            <label className="app-field-label" htmlFor="name" style={{ marginTop: "15px" }}>Promo Name</label>
                            <Text className='mb-2'>A name for your promotion. (Customers will see this in the app when using this promo.)</Text>
                            <input
                                className={fieldError("name", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Promo Name"
                                value={promoCode.name}
                                onChange={(event) => this.handleChange(event)}
                            />
                            {fieldError('name', apiErrors)}
                        </div>
                        {!isSales ?
                        <div className="app-field-wrapper">
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                Promo Code
                                {!addPromo ? <AdminToolTip title={
                                    <>
                                        <Text weight='bold'>Why can’t I edit a promo code?</Text>
                                        <br />
                                        <Text>{whyCantIEditMsg}</Text>
                                    </>
                                } /> : null}
                            </div>
                            <Text className='mb-2'>The actual code that a customer will input to apply the promotion.</Text>
                            {addPromo ? 
                                <>
                                    <input
                                        className={fieldError("code", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                        id="code"
                                        name="code"
                                        type="text"
                                        placeholder="Promo Code"
                                        value={promoCode.code}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                    {fieldError('code', apiErrors)}
                                </>
                            : <div style={{ display: "flex", alignItems: "center" }}>
                                <FontAwesomeIcon icon="fa-solid fa-star" size="lg" className='tertiaryOrange pr-2 pb-1' />
                                <Text weight="bold">{promoCode.code}</Text>
                            </div>}
                        </div> :
                        <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                Sales Duration
                            </div>
                            <Text className='mb-2'>This sale will run during this time.</Text>
                            <ReportsCustomHeader
                                pickerWidth="30%"
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                handleChange={this.updateDates}
                            />
                        </div>}
                        {isSales ? null :
                        <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                            <label className="app-field-label" htmlFor="expiration" style={{ marginTop: "15px" }}>Promo Expiration</label>
                            <Text className='mb-2'>This promo code will be usable until this date, if one is set. Otherwise, it will never expire.</Text>
                            <RadioGroup
                                aria-label="expires"
                                name="expires"
                                value={Boolean(promoCode.expiration)}
                                onChange={(event) => this.toggleRadios("expires", event.target.value)}
                                className="radio-group mb-2"
                            >
                                <RadioLabel value={false} label="Never" />
                                <RadioLabel value={true} label={<Text>On a specific date. <span className='semi-bold'>Note: Promotion will expire at midnight at the end of the selected day.</span></Text>} />
                            </RadioGroup>
                            {promoCode.expiration ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    style={{ padding: '8px 16px 0 16px' }}
                                    className={`app-input-field indented mt-0 ${fieldError("expiration", this.state.apiErrors) ? 'red-border': ''}`}
                                    value={promoCode.expiration}
                                    variant="inline"
                                    inputVariant="standard"
                                    format="MM/dd/YYYY"
                                    placeholder="MM/DD/YYYY"
                                    size="small"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </MuiPickersUtilsProvider> : null}
                            <div style={{marginLeft: 15}}>{fieldError('expiration', apiErrors)}</div>
                        </div>}
                        {isSales ? null :
                        <div className="app-field-wrapper" style={{ marginTop: "25px" }}>
                            <div className="app-field-label mb-0">Promo Can Be Redeemed By</div>
                            <RadioGroup
                                aria-label="max-redemptions"
                                name="max-redemptions"
                                value={this.state.user_limit}
                                onChange={(event) => this.toggleRadios("user_limit", event.target.value)}
                                className="radio-group"
                            >
                                <RadioLabel value='unlimited' label="Any number of customers" />
                                <RadioLabel value='limited' label="Only a certain amount of customers" />
                                <RadioLabel value='specific' label="Only selected customers" />
                            </RadioGroup>
                            {this.state.user_limit === 'limited' ?
                                <input
                                    className={`app-input-field indented mt-0 ${fieldError("max_redemptions", this.state.apiErrors) ? 'red-border' : ''}`}
                                    id="max_redemptions"
                                    name="max_redemptions"
                                    type="number"
                                    placeholder="Number"
                                    value={promoCode.max_redemptions}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                : null}
                            {this.state.user_limit === 'specific' ?
                                <AdminSelection
                                    list_name='users'
                                    selectedItems={ this.state.selectedUsers }
                                    onUpdateItems={ this.updateUsers }
                                />
                                : null}
                            <div style={{ marginLeft: 15 }}>{fieldError('max_redemptions', apiErrors)}</div>
                        </div>}
                        <div className="app-field-wrapper" style={{ marginTop: "25px" }}>
                            <div className="app-field-label mb-0">Number Of Times A Single Customer Can Redeem</div>
                            <RadioGroup
                                aria-label="max-redemptions-per-user"
                                name="max-redemptions-per-user"
                                value={Boolean(promoCode.max_redemptions_per_user)}
                                onChange={(event) => this.toggleRadios("max_redemptions_per_user", event.target.value)}
                                className="radio-group"
                            >
                                <RadioLabel value={false} label="Unlimited redemptions" />
                                <RadioLabel value={true} label="Only a certain amount of redemptions" />
                            </RadioGroup>
                            {promoCode.max_redemptions_per_user ?
                                <input
                                    className={`app-input-field indented mt-0 ${fieldError("max_redemptions_per_user", this.state.apiErrors) ? 'red-border' : ''}`}
                                    id="max_redemptions_per_user"
                                    name="max_redemptions_per_user"
                                    type="number"
                                    placeholder="Number"
                                    value={promoCode.max_redemptions_per_user}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                : null}
                            <div style={{ marginLeft: 15 }}>{fieldError('max_redemptions_per_user', apiErrors)}</div>
                        </div>
                    </AdminCard>
                    <AdminCard title="Discount Options">
                        {addPromo ?
                            <>
                                <div className='app-field-wrapper'>
                                <div className="app-field-label" style={ { marginTop: "15px" } }>Discount Type</div>
                                <Text className='mb-2'>Buy One, Get One discounts will only be applied to one item per order.</Text>
                                    <RadioGroup
                                        aria-label="discountType"
                                        name="discountType"
                                        value={promoCode.is_bogo ? 'bogo' : discountType}
                                        onChange={(event) => this.toggleRadios("discountType", event.target.value)}
                                        className="radio-group"
                                    >
                                        <RadioLabel value={'bogo'} label="Buy One Get One" />
                                        <RadioLabel value={'amount'} label="Flat Amount" />
                                        <RadioLabel value={'percent'} label="Percentage" />
                                    </RadioGroup>
                                </div>
                                <div className='app-field-wrapper'>
                                    <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Amount</div>
                                    <input
                                        className={`app-input-field mt-0 ${fieldError(`${discountType}_off`, apiErrors) ? "red-border" : ''}`}
                                        id={`${discountType}_off`}
                                        name={`${discountType}_off`}
                                        type="number"
                                        placeholder={`Discount ${discountType === 'amount' ? 'Amount' : 'Percentage'}`}
                                        value={discountType === 'amount' ? promoCode.amount_off.amount : promoCode.percent_off}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                    <div>{fieldError(`${discountType}_off`, apiErrors)}</div>
                                </div>
                            </>
                            :
                        <div>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>
                                Discount Type/Amount
                                <AdminToolTip title={
                                    <>
                                        <Text weight='bold'>Why can’t I edit a promo code?</Text>
                                        <br />
                                        <Text>{whyCantIEditMsg}</Text>
                                    </>
                                } />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FontAwesomeIcon icon="fa-solid fa-star" size="lg" className='tertiaryOrange pr-2 pb-1' />
                                <Text weight="bold">{promoCode.is_bogo ? 'Buy One Get One ' : null}{this.getDiscountAmount(promoCode)}</Text>
                            </div>
                        </div>}
                        <div className="app-field-wrapper" style={{ marginTop: "15px" }}>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Applies To</div>
                            <Text className='mb-2'>Select whether the promotion should apply to an entire order, or to a specific item or item group.</Text>
                            <RadioGroup
                                aria-label="items_type"
                                name="items_type"
                                value={itemsType}
                                onChange={(event) => this.toggleItemsType(event.target.value)}
                                className="radio-group"
                            >
                                {!promoCode.is_bogo ? <RadioLabel value={'order'} label="The entire order" /> : null}
                                <RadioLabel value={'categories'} label="Items in a certain category (or categories)" />
                                    {itemsType === 'categories' ?
                                        <AdminSelection
                                            list_name='categories'
                                            selectedItems={ promoCode.categories }
                                            onUpdateItems={ this.updateCategories }
                                        />
                                    : null}
                                <RadioLabel value={'items'} label="A specific item (or items)" />
                                {itemsType === 'items' ?
                                        <AdminSelection
                                            list_name='items'
                                            selectedItems={ promoCode.items }
                                            onUpdateItems={ this.updateItems }
                                        />
                                    : null}
                            </RadioGroup>
                        </div>
                        {itemsType !== 'order' &! promoCode.is_bogo ? (
                            <>
                                <div className='app-field-wrapper'>
                                    <div className="app-field-label" style={{ marginTop: "15px" }}>
                                        Limit The Number Of Items The Discount Applies To
                                        <AdminToolTip title={
                                            <>
                                                <Text>If you select the "Pizza" category and input "ALL", this discount will be applied to all pizzas in the customer's cart.</Text>
                                                <br />
                                                <Text>If you select the "Hawaiian Pizza" item and input "2", this discount will be applied to only 2 Hawaiian pizzas in the customer's cart.</Text>
                                            </>
                                        } />
                                    </div>
                                    <Text>This amount sets a limit on the number of items the discount applies to. If you wish to allow the discount to be applied to all items that meet your selected items/categories, enter "ALL".</Text>
                                    <input
                                        className={fieldError("apply_items_amount", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-3"}
                                        id="apply_items_amount"
                                        name="apply_items_amount"
                                        type="text"
                                        placeholder="Number"
                                        value={promoCode.apply_items_amount === null ? 'ALL' : promoCode.apply_items_amount}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={(event) => this.validateApplyItemsAmount(event.target.value)}
                                    />
                                    <div style={{ marginLeft: -10 }}>{fieldError('apply_items_amount', apiErrors)}</div>
                                </div>
                                <div className='app-field-wrapper mb-0'>
                                    <div className="app-field-label" style={{ marginTop: "15px" }}>Apply To Least/most Expensive Item In Order</div>
                                    <Text>Determines whether discount is applied to least expensive or most expensive items in cart.</Text>
                                    <RadioGroup
                                        aria-label="apply_to_minimum"
                                        name="apply_to_minimum"
                                        value={Boolean(promoCode.apply_to_minimum)}
                                        onChange={(event) => this.toggleRadios("apply_to_minimum", event.target.value)}
                                        className="radio-group"
                                    >
                                        <RadioLabel value={false} label="Most Expensive Item" />
                                        <RadioLabel value={true} label="Least Expensive Item" />
                                    </RadioGroup>
                                </div>
                            </>
                        ) : null}
                    </AdminCard>
                    {addPromo ?
                        <LocationSelection
                            itemName='promo code'
                            selectedLocations={ this.state.selectedLocations }
                            onUpdateLocations={ this.updateLocations }
                            fieldError={ fieldError('locations', apiErrors) }
                        /> :
                    <AdminCard
                        title="Location Selection"
                        style={ { marginBottom: 25 } }
                        toolTip={
                            <>
                                <Text weight='bold'>Why can’t I edit a promo code?</Text>
                                <br />
                                <Text>{ whyCantIEditMsg }</Text>
                            </>
                        }
                        >
                            <div className='pt-3 pb-3'>
                                { getLocations(promoCode) }
                            </div>
                        </AdminCard>
                    }
                    {addPromo ?
                    <PromoHours
                        apiErrors={ apiErrors }
                        error={ error }
                        hours={ promoCode.coupon_hours }
                        hoursType={ 'Promo Code' }
                        payload={ promoCode.coupon_hours }
                        updateHours={ this.updateHours }
                    /> :
                    <AdminCard
                        title='Promo Code Hours'
                        description='The hours this promo code is active during.'
                        toolTip={
                            <>
                                <Text weight='bold'>Why can’t I edit a promo code?</Text>
                                <br />
                                <Text>{ whyCantIEditMsg }</Text>
                            </>
                        }
                    >
                        {  _.find(promoCode.coupon_hours, 'start_time') ?
                            <Table className='app-table-wrapper' size='small'>
                            <TableHead className='app-table-header'>
                                <TableRow className='app-table-row'>
                                    <TableCell className='app-table-cell'>Day</TableCell>
                                    <TableCell className='app-table-cell'>Start</TableCell>
                                    <TableCell className='app-table-cell'>End</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='app-table-body'>
                                { promoCode.coupon_hours.map(day => (
                                    <TableRow className='app-table-row' key={day.weekday}>
                                        <TableCell className='app-table-cell'>{day.weekday}</TableCell>
                                        <TableCell className='app-table-cell'>{formatTimeForView(day.start_time) || 'Not Active'}</TableCell>
                                        <TableCell className='app-table-cell'>{formatTimeForView(day.end_time)}</TableCell>
                                    </TableRow>
                                )) }
                            </TableBody>
                            </Table>
                            :
                        <Text>This promo code is always active.</Text>}
                    </AdminCard>
                }
                </EditDrawer>
        );
    }
}

export default (PromoCodeEdit);