import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from "moment";

import Drawer from '@material-ui/core/Drawer';
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { errorMessage, fieldError, formatDateForView, formatTimeForView, parseHours} from "../../services/utils";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import HoursInputRow from '../elements/hoursInputRow';
import RadioLabel from "../elements/radioLabel";
import { Text } from "../elements/text";
import tenantAPI from '../../services/api/tenantAPI';

class HolidayHoursEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedHoliday: {},
            name: '',
            date: new Date(),
            openStatus: 'closed',
            store_closed: false,
            store_from: null,
            store_to: null,
            pickup_closed: false,
            pickup_from: null,
            pickup_to: null,
            delivery_closed: false,
            delivery_from: null,
            delivery_to: null,
            apiErrors: {},
            error: ''
        };
    }

    openStatus = holiday => {
        return (holiday.store_from || holiday.pickup_from || holiday.pickup_to
            ? "Special Hours"
            : "Closed");
    }

    selectHoliday = holiday => {
        this.setState({
            selectedHoliday: holiday,
            name: holiday.name,
            date: new Date(moment(holiday.date)),
            store_closed: holiday.store_from ? false : true,
            store_from: holiday.store_from,
            store_to: holiday.store_to,
            pickup_closed: holiday.pickup_from ? false : true,
            pickup_from: holiday.pickup_from,
            pickup_to: holiday.pickup_to,
            delivery_closed: holiday.delivery_from ? false : true,
            delivery_from: holiday.delivery_from,
            delivery_to: holiday.delivery_to,
            openStatus: holiday.store_from || holiday.pickup_from || holiday.delivery_from
                ? "special-hours"
                : "closed"
        });
    }

    handleChange = event => {
        let name, value = '';
        if (event.target) {
            value = event.target.value;
            name = event.target.name;

            // Combine time and am/pm fields into timestamp
            if (name.includes('_timestamp')) {
                // Time was updated
                name = name.replace('_timestamp', '');
                let amPm = name.includes('_from') ? 'AM' : 'PM';
                if (this.state[name] && !this.state[name].includes('x')) {
                    amPm = parseHours(this.state[name], true).toUpperCase();
                }
                if (!value.includes('x')) {
                    value = moment(`${value}:00 ${amPm}}`, 'hh:mm:ss a').format('HH:mm:ss');
                }
            } else if (name.includes('_ampm')) {
                // AM/PM was updated
                name = name.replace('_ampm', '');
                let time = this.state[name] ? parseHours(this.state[name]) : '00:00'
                value = moment(`${time}:00 ${value}}`, 'hh:mm:ss a').format('HH:mm:ss');
            }
        } else {
            // Datepicker has no event.target
            value = event;
            name = 'date';
        }
        this.setState({ [name]: value });
    };

    toggleChecked = value => {
        if (!this.state[`${value}_closed`]) {
            // State is currently open, so we are toggling it to closed. Clear time values.
            this.setState({
                [`${value}_to`]: null,
                [`${value}_from`]: null,
                [`${value}_closed`]: !this.state[`${value}_closed`]
            })
        } else {
            // State is currently closed, so we are toggling it to open. Don't touch the time values.
            this.setState({
                [`${value}_closed`]: !this.state[`${value}_closed`],
            });
        }
    }

    clearStateValues = () => {
        this.setState({
            selectedHoliday: {},
            name: '',
            date: new Date(),
            openStatus: 'closed',
            store_closed: false,
            store_from: null,
            store_to: null,
            pickup_from: null,
            pickup_closed: false,
            pickup_to: null,
            delivery_closed: false,
            delivery_from: null,
            delivery_to: null,
            apiErrors: {},
            error: ''
        })
    }

    handleClose = () => {
        this.clearStateValues();
        this.props.onClose();
    }

    handleSubmit = () => {
        let payload = {
            name: this.state.name,
            date: moment(this.state.date).format("YYYY-MM-DD"),
            store_closed: this.state.store_from,
            store_from: this.state.store_from,
            store_to: this.state.store_to,
            pickup_closed: this.state.pickup_from,
            pickup_from: this.state.pickup_from,
            pickup_to: this.state.pickup_to,
            delivery_closed: this.state.delivery_from,
            delivery_from: this.state.delivery_from,
            delivery_to: this.state.delivery_to,
        }
        if (this.state.selectedHoliday.id) {
            tenantAPI.updateHoliday(this.state.selectedHoliday.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message })
                    }
                    else {
                        this.props.onUpdate();
                        this.handleClose();
                    }
                })
        } else {
            payload.locations = [this.props.location.id];
            tenantAPI.createHoliday(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message })
                    }
                    else {
                        this.props.onUpdate();
                        this.handleClose();
                    }
                })
        }
    }

    render() {
        const { location, open } = this.props;
        const holidays = location.holidays;

        return (
            <Drawer
                anchor="right"
                open={open}
                classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}
                onClose={this.handleClose}
            >
                <EditDrawer
                    title="Edit Holiday Hours"
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmit}
                >
                    <AdminCard
                        title="Scheduled Holiday Hours"
                        style={{ marginTop: "15px" }}
                        description={`You currently have ${holidays && holidays.length > 0 ? holidays.length : 'no'} scheduled holiday${holidays && holidays.length === 1 ? '' : 's'}.`}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{borderBottom: 'none', paddingLeft: 0}}>
                                        <span className="bold">
                                            Date
                                        </span>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: 'none', paddingLeft: 0 }}>
                                        <span className="bold">
                                            Open Status
                                        </span>
                                    </TableCell>
                                    <TableCell style={{borderBottom: 'none', paddingLeft: 10}}>
                                        <span className="bold">
                                            Name
                                        </span>
                                    </TableCell>
                                    <TableCell style={{borderBottom: 'none', paddingLeft: 0}} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holidays && holidays.length ? (
                                    holidays.map(holiday => (
                                        <TableRow key={holiday.id}>
                                            <TableCell className="app-table-cell" style={{ borderBottom: 'none', paddingLeft: 0 }}>
                                                {formatDateForView(holiday.date)}
                                            </TableCell>
                                            <TableCell className="app-table-cell" style={{ borderBottom: 'none', paddingLeft: 0, paddingRight: 5 }}>
                                                {this.openStatus(holiday)}
                                            </TableCell>
                                            <TableCell className="app-table-cell" style={{ borderBottom: 'none', paddingLeft: 10 }}>
                                                {holiday.name}
                                            </TableCell>
                                            <TableCell className="app-table-cell" style={{borderBottom: 'none', paddingLeft: 0}}>
                                                <div className='columns is-mobile'>
                                                    <FontAwesomeIcon
                                                        icon="fa-light fa-pen-circle fa-lg"
                                                        className='column is-one-fifth secondaryBlue'
                                                        onClick={() => this.selectHoliday(holiday)}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon="fa-light fa-circle-xmark fa-lg"
                                                        className='column is-narrow errorRed'
                                                        style={{ paddingLeft: 0 }}
                                                        onClick={() => this.props.deleteHoliday(holiday.id)}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3}>There are no holidays scheduled.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </AdminCard>
                    <AdminCard
                        title={`${_.isEmpty(this.state.selectedHoliday) ? 'Add New' : 'Edit'} Holiday`}
                        style={{ marginTop: "15px" }}
                        description="Holiday hours you set here will override the default hours for the date of the holiday only."
                    >
                        {errorMessage(this.state.apiErrors, this.state.error)}
                        <form>
                            <div className="app-field-wrapper">
                                <label htmlFor="name" className="app-field-label">
                                    Holiday Name
                                </label>
                                <input
                                    className={fieldError("name", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Holiday Name"
                                    value={this.state.name}
                                    onChange={(event) => this.handleChange(event)}
                                />
                                {fieldError('name', this.state.apiErrors)}
                            </div>
                            <div className="app-field-wrapper">
                                <label htmlFor="date" className="app-field-label">
                                    Holiday Date
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        style={{ padding: '8px 16px 0 16px'}}
                                        className={fieldError("date", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                                        value={this.state.date}
                                        variant="inline"
                                        inputVariant="standard"
                                        format="MM/dd/YYYY"
                                        size="small"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </MuiPickersUtilsProvider>
                                {fieldError('date', this.state.apiErrors)}
                            </div>
                            <div className="app-field-wrapper">
                                <label htmlFor="open-status" className="app-field-label">
                                    Open Status
                                </label>
                                <RadioGroup
                                    aria-label="open-status"
                                    name="openStatus"
                                    value={this.state.openStatus}
                                    onChange={event => this.handleChange(event)}
                                    className="radio-group"
                                >
                                    <RadioLabel value="closed" label="Closed" />
                                    <RadioLabel value="special-hours" label="Open for Special Hours" />
                                </RadioGroup>
                            </div>
                            {this.state.openStatus === "special-hours" ?
                                <div className="app-field-wrapper">
                                    <label htmlFor="special-hours" className="app-field-label">
                                        Special Hours
                                    </label>
                                    <HoursInputRow
                                        labelText=""
                                        customLabel={<Text>
                                            Store Hours: {formatTimeForView(this.state.store_from)} - {formatTimeForView(this.state.store_to)}
                                        </Text>}
                                        openTime={this.state.store_from}
                                        closeTime={this.state.store_to}
                                        onChange={this.handleChange}
                                        hoursType="store"
                                        disabled={this.state.store_closed}
                                        toggleChecked={this.toggleChecked}
                                        fromName="store_from"
                                        toName="store_to"
                                    />
                                    {location.pickup_enabled ?
                                        <HoursInputRow
                                            labelText=""
                                            customLabel={<Text>
                                                Pickup Hours: {formatTimeForView(this.state.pickup_from)} - {formatTimeForView(this.state.pickup_to)}
                                            </Text>}
                                            openTime={this.state.pickup_from}
                                            closeTime={this.state.pickup_to}
                                            onChange={this.handleChange}
                                            hoursType="pickup"
                                            disabled={this.state.pickup_closed}
                                            toggleChecked={this.toggleChecked}
                                            fromName="pickup_from"
                                            toName="pickup_to"
                                        />
                                    : null}
                                    {location.delivery_enabled ? 
                                        <HoursInputRow
                                            labelText=""
                                            customLabel={<Text>
                                                Delivery Hours: {formatTimeForView(this.state.delivery_from)} - {formatTimeForView(this.state.delivery_to)}
                                            </Text>}
                                            openTime={this.state.delivery_from}
                                            closeTime={this.state.delivery_to}
                                            onChange={this.handleChange}
                                            hoursType="delivery"
                                            disabled={this.state.delivery_closed}
                                            toggleChecked={this.toggleChecked}
                                            fromName="delivery_from"
                                            toName="delivery_to"
                                        />
                                    : null}
                                </div>
                            : null}
                        </form>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default HolidayHoursEdit;