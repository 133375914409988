import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));

export default class integrationsAPI {
    static createFulfillment(data) {
        store.updateLoading(true);
        const url = `${ base_url }tenants/${ store.tenantId }/fulfillments/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static updateFulfillment(fulfillmentId, data) {
        store.updateLoading(true);
        const url = `${ base_url }tenants/${ store.tenantId }/fulfillments/${ fulfillmentId }/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getFulfillments(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/fulfillments/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveFulfillments(params, data) {
        store.updateLoading(true);
        let response = await this.getFulfillments(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveFulfillments(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateFulfillments(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static deleteFulfillment(fulfillmentId) {
        store.updateLoading(true);
        const url = `${ base_url }tenants/${ store.tenantId }/fulfillments/${ fulfillmentId }/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }
    static getPausedFulfillments(location_id, params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/locations/${ location_id }/pauses/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursivePausedFulfillments(location_id, params, data) {
        store.updateLoading(true);
        let response = await this.getPausedFulfillments(location_id, params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursivePausedFulfillments(location_id, params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updatePausedFulfillments(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static createPause(location_id, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/locations/${ location_id }/pauses/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static deletePause(location_id, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/locations/${ location_id }/pauses/${ body.id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: {}
            })
                .then(() => {
                    store.updateLoading(false);
                    resolve();
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

}