import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import store from '../../store/store';
import {errorMessage} from '../../services/utils';
import EditDrawer from '../elements/editDrawer';
import AdminCard from '../elements/adminCard';
import AdminDropdownField from '../elements/adminDropdownField';
import accountsAPI from '../../services/api/accountsAPI';


class UserAdjustPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            error: '',
            user: this.props.user,
            userLoyalty: {},
            add_or_remove: 'add',
            points: '',
            points_name: this.props.isLoyalty ? `${ store.squareLoyalty.points_name }s` : 'Punches',
            reason: '',
            type: ''
        };
    }

    getReasons = () => {
        return ({
            "add": [
                `Complimentary ${ this.state.points_name }`,
                `Missing ${ this.state.points_name } from Transaction`
            ],
            "remove": [
                `${ this.state.points_name } Accidentally Awarded`,
                "Transaction was Refunded",
                "Suspicious Loyalty Activity"
            ]
        });
    };

    componentDidMount = () => {
        if (store.squareLoyalty && store.squareLoyalty.is_enabled && this.props.user.phone_validated) {
            accountsAPI.getUserRewards(this.props.user.id, store.squareLoyalty.id)
                .then(response => this.setState({ userLoyalty: response["data"], type: 'loyalty' }))
                .catch(err => console.error(err));
        } else if (store.punchCardSettings && store.punchCardSettings.is_enabled) {
            accountsAPI.getPunchCard(this.props.user.id);
            this.setState({ type: 'punchcards' });
        }
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value});
    }

    handleSubmit = () => {
        let {error, points_name} = this.state;
        let payload = {
            'points': parseInt(this.state.points),
            'reason': this.state.reason,
        };
        error = !payload.points ? `You must add ${points_name.toLowerCase()} to save changes` : !payload.reason ? 'You must select a reason to save changes' : '';
        this.setState({ error });
        if (this.state.error) return;

        if (this.state.add_or_remove === 'remove') {
            payload.points = -payload.points;
        }

        if (this.state.type === 'loyalty') {
            accountsAPI.adjustUserPoints(this.props.user.id, store.squareLoyalty.id, payload)
                .then(response => {
                    if (response.errors) {
                        this.setState({apiErrors: response.errors});
                        if (response.errors.__all__) this.setState({error: response.errors.__all__[0].message});
                    }
                    else {
                        this.props.onClose(true);
                    }
                });
        } else {
            accountsAPI.adjustUserPunches(this.props.user.id, payload)
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.props.onClose(true);
                    }
                });
        }
    };

    render() {
        const { points_name, userLoyalty } = this.state;
        const { isLoyalty } = this.props;

        return (
            <EditDrawer
                title="Adjust Current Balance"
                onClose={ () => this.props.openSecondaryDrawer('edit') }
                isSubmitting={false}
                onSubmit={this.handleSubmit}
                errorMessage={errorMessage(this.state.apiErrors, this.state.error)}
            >
                <AdminCard
                    title='Current Balance'
                    style={{marginTop: '15px'}}
                    >
                    <div className="rewards-container pt-2">
                        <div style={ { marginRight: 10 } }>
                            <FontAwesomeIcon
                                className={ isLoyalty ? 'tertiaryOrange' : 'brightGreen' }
                                icon={ isLoyalty ? "fa-solid fa-star" : "fa-solid fa-trophy-star" }
                            />
                        </div>
                        <div>
                            <p className="loyalty-reward-text">{ isLoyalty ? userLoyalty.points : store.current_punch_card.points } { points_name }</p>
                        </div>
                    </div>
                    <form className='pt-3'>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label'>
                                Add/Remove { points_name }
                            </label>
                            <AdminDropdownField
                                id='add_or_remove'
                                name='add_or_remove'
                                onChange={ (event) => this.handleChange(event) }
                                value={ this.state.add_or_remove }
                                style={ { width: '100%' } }
                            >
                                <option value='add'>{ `Add ${ points_name }` }</option>
                                <option value='remove'>{ `Remove ${ points_name }` }</option>
                            </AdminDropdownField>
                        </div>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label'>
                                { this.state.add_or_remove[0].toUpperCase() }{ this.state.add_or_remove.slice(1)} {points_name}
                            </label>
                            <input
                                className='app-input-field mt-0'
                                id='points'
                                name='points'
                                type='number'
                                placeholder='0'
                                value={this.state.points}
                                onChange={(event) => this.handleChange(event)}
                            />
                        </div>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label'>
                                Reason
                            </label>
                            <AdminDropdownField
                                id='reason'
                                name='reason'
                                onChange={ (event) => this.handleChange(event) }
                                value={ this.state.reason }
                                style={ { width: '100%' } }
                            >
                                <option defaultValue>Select Reason</option>
                                { this.getReasons()[this.state.add_or_remove].map((option, i) => (
                                    <option key={ i } value={ option }>
                                        { option }
                                    </option>
                                )) }
                            </AdminDropdownField>
                        </div>
                    </form>
                </AdminCard>
            </EditDrawer>
        );
    }
}

export default observer(UserAdjustPoints);
