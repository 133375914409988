import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import withNav from '../components/withNav';

import accountsAPI from '../services/api/accountsAPI';
import store from '../store/store';
import { formatDateTimeForView, formatPhone, formatUserRoleForView } from '../services/utils';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import UserDrawer from '../components/user/userDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../components/elements/text';


class YourProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerType: 'none',
            open: false,
            user: store.user
        };
    }

    handleDialogClose = () => {
        this.toggleDrawer('none');
        this.getUser();
    };

    getUser = () => {
        accountsAPI.getUserById(store.user.id)
            .then(response => store.updateUser(response.data));
    };

    toggleDrawer = drawerType => {
        this.setState({ drawerType: drawerType });
        this.setState({ open: !this.state.open });
    };

    render() {
        const user = store.user;
        return (
            <div>
                <div className='admin-page-wrapper'>
                    <AdminPageHeader
                        title='Your Profile'
                        mainNav='Marketing'
                        subNav='Users'
                        description='All of your information in one place.'
                    />
                    <div>
                        <AdminCard
                            largeTitle
                            title='Personal Information'
                            onHeaderActionClick={ () => this.toggleDrawer('edit') }
                        >
                            <div className='app-field-wrapper pt-4 mb-2'>
                                <div className='app-field-label'>
                                    Name
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ user.full_name }</Text>
                                </div>
                            </div>
                            <div className='app-field-wrapper mb-2'>
                                <div className='app-field-label'>
                                    Phone
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ formatPhone(user.phone) }</Text>
                                </div>
                            </div>
                            <div className='app-field-wrapper mb-2'>
                                <div className='app-field-label'>
                                    Email
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ user.email }</Text>
                                </div>
                            </div>
                        </AdminCard>
                        <AdminCard
                            largeTitle
                            title='Account Management'
                            onHeaderActionClick={ () => this.toggleDrawer('edit') }
                        >
                            <div className='app-field-wrapper pt-4 mb-2'>
                                <div className='app-field-label'>
                                    User Role
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ formatUserRoleForView(user.role) }</Text>
                                </div>
                            </div>
                            <div className='app-field-wrapper mb-2'>
                                <div className='app-field-label'>
                                    Date Joined
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ formatDateTimeForView(user.date_joined) }</Text>
                                </div>
                            </div>
                            <div className='app-field-wrapper mb-2'>
                                <div className='app-field-label'>
                                    Recent Order
                                </div>
                                <div className='profile-container'>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-arrow-right' className='mr-2 secondaryBlue'/>
                                    <Text>{ user.recent_order_date ? formatDateTimeForView(user.recent_order_date) : 'No Orders Placed' }</Text>
                                </div>
                            </div>
                            <div className='app-field-wrapper mb-2'>
                                <div className='app-field-label'>
                                    Password
                                </div>
                                <div className='profile-container'>
                                    <Text>********</Text>
                                    <div className='pl-3 secondaryBlue semi-bold medium-text cursor-pointer' onClick={ () => this.toggleDrawer('password') }>
                                        Reset Password
                                    </div>
                                </div>
                            </div>
                        </AdminCard>
                    </div>
                </div>
                <UserDrawer
                    open={ this.state.open }
                    user={ store.user }
                    onClose={ this.handleDialogClose }
                    profile={ true }
                    drawerType={ this.state.drawerType }
                />
            </div>
        );
    }
}

export default withNav(withRouter(observer(YourProfile)));
