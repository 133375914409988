import styled from "styled-components";
import { colors } from "../../styles/global";

export const Link = styled.div`
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'SofiaPro-Bold', sans-serif;
    text-decoration: underline;
    color: ${colors.ordrslipRed};
    opacity: 0.9;
    cursor: pointer;
`