import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../store/store";
import LoadingBox from "./loadingBox";
import _ from "lodash";

class LoadingBoxWrapper extends Component {

  render() {
    return (
      <LoadingBox 
        open={_.isBoolean(store.loading) ? store.loading : false}
        disableEscapeKeyDown={true}
        hideBackdrop={true}
        transitionDuration={200}
        message={store.loadingMessage}
      />
    );
  }
}

export default observer(LoadingBoxWrapper);
