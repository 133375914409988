import React from "react";
import NavBar from "./navBar";
import { SideNavAndContentContainer, SideNavContainer, ContentContainer } from './elements/navs';

const withNav = WrappedComponent => props => {
  return (
    <SideNavAndContentContainer>
      <SideNavContainer>
        <NavBar />
      </SideNavContainer>
      <ContentContainer>
        <WrappedComponent />
      </ContentContainer>
    </SideNavAndContentContainer>
  );
};

export default withNav;
