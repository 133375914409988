import React, { Component } from "react";
import tenantAPI from '../../services/api/tenantAPI';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';

class ToggleTipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      enable_tipping: this.props.location.enable_tipping
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location)
      this.setState({
        location: nextProps.location,
        enable_tipping: nextProps.location.enable_tipping
      });
  }

  toggleEnabled = () => {
    const location = this.state.location;
    let enable_tipping = this.state.enable_tipping === true ? false : true;
    const values = JSON.stringify({ enable_tipping: enable_tipping });
    tenantAPI.updateLocation(location.id, values)
      .then(response => this.props.onUpdate(response))
  };

  render() {
    return (
      <AdminCard
        largeTitle
        title="Enable/Disable Tipping"
        description="When enabled, customers will be able to add a tip to orders placed at this location. (This setting will not affect other locations.)"
      >
        <AdminToggle
          className='app-toggle'
          checked={ Boolean(this.state.enable_tipping) }
          onChange={ this.toggleEnabled }
        />
      </AdminCard>
    );
  }
}

export default ToggleTipping;
