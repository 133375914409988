import React, { Component } from "react";
import store from "../store/store";

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { autorun, toJS } from "mobx";
import { observer } from "mobx-react";
import ls from 'local-storage';
import { WideButton } from './elements/buttons';
import { colors } from "../styles/global";
import tenantAPI from '../services/api/tenantAPI';
import menuAPI from '../services/api/menuAPI';
import reportsAPI from '../services/api/reportsAPI';


class SyncButton extends Component {
  state = {
    isSyncStatusOpen: false,
    variant: null,
    message: "",
    duration: null,
    vertical: 'bottom',
    horizontal: 'right',
  }

  dispose = autorun(() => {
    if (store.sync_task_id.get()) this.handleSync(store.sync_task_id.get());
  });

  handleSync = (taskId) => {
    this.waitForSuccessfulSync(taskId)
      .then(() => {
        this.setAndShowSyncStatus("success");
        menuAPI.getRecursiveCategories({ page: 1 });
        menuAPI.getRecursiveItems({ page: 1 });
      })
      .catch(err => {
        console.error(err);
        store.updateSyncTaskId(null);
        store.updateSyncStatus('error');
        store.updateIsSyncing(false);
        this.setAndShowSyncStatus("error");
      })
  };

  componentDidMount() {
    let taskId = ls.get('sync_task_id');
    if (taskId) {
      this.handleSync(taskId);
    }
  }

  onSync = () => {
    tenantAPI.initiateSyncTenant()
      .then(data => {
        let { task_id } = data;
        if (task_id) {
          store.updateSyncTaskId(task_id);
          store.updateIsSyncing(true);
          store.updateShowSecondaryButton(false);
          store.updateSyncErrorMessage(null);
          store.updateShowSyncErrorDialog(false);
          this.setAndShowSyncStatus("inProgress");
          this.waitForSuccessfulSync(task_id)
            .then(() => {
              this.setAndShowSyncStatus("success");
              menuAPI.getRecursiveCategories({ page: 1 });
              menuAPI.getRecursiveItems({ page: 1 });
            })
            .catch(err => {
              console.error(err);
              store.updateSyncTaskId(null);
              store.updateSyncStatus('error');
              store.updateIsSyncing(false);
              this.setAndShowSyncStatus("error");
            })
        } else {
          store.updateSyncTaskId(null);
          store.updateSyncStatus('error');
          store.updateIsSyncing(false);
          this.setAndShowSyncStatus("error");
        }
      })
      .catch(err => {
        console.log('error',err.result);
        this.setAndShowSyncStatus("error");


      })
  };

  waitForSuccessfulSync = task_id => {
    return new Promise((resolve, reject) => {
      // Check every second to see if sync has completed.
      const intervalId = setInterval(() => {
        reportsAPI.getTaskStatus(task_id)
          .then(response => {
            let count;
            if (store.sync_counter.get() >= 100) {
              count = 10;
            } else {
              count = store.sync_counter.get() + 1;
            }
            store.updateSyncCounter(count);
            if (response["result"]) {
              // A sync is already running, so follow it's progress.
              if (response.result.error && response.result.error.includes('in progress')) {
                clearInterval(intervalId);
                tenantAPI.getSyncTaskId();
                return resolve();
              }
              // Sync has finished.
              const { success } = response["result"];
              if (success === true) {
                store.updateSyncTaskId(null);
                store.updateSyncStatus('success');
                store.updateIsSyncing(false);
                store.updateSyncCounter(null);
                setTimeout(() => {
                  store.updateSyncStatus(null);
                  store.updateSyncCounter(null);
                }, 60000);
                // Sync has finished successfully.
                clearInterval(intervalId);
                resolve(success);
              } else {
                store.updateSyncTaskId(null);
                store.updateIsSyncing(false);
                store.updateSyncCounter(null);
                clearInterval(intervalId);

                if (response.result.error && response.result.error.includes('permissions') && store.tenant.pos_name.toLowerCase() === 'square') {
                  store.updateShowSecondaryButton(true);
                  store.updateSyncErrorMessage('STOP! You need to accept new permissions in Square. Please log out of all Square accounts not associated with this dashboard before proceeding. Hitting "Continue" will redirect to your Square login to accept these permissions. Please make sure that you log into the correct Square account to avoid data loss.');
                  store.updateShowSyncErrorDialog(true);
                  store.updateSyncStatus('error');
                  store.updateIsSyncing(false);
                } else {
                  // Sync has finished, but with an error.
                  if (!response.result.success) {
                    store.updateShowSecondaryButton(false);
                    store.updateSyncErrorMessage(response.result.error);
                  }
                  store.updateShowSecondaryButton(false);
                  store.updateSyncStatus('error');
                  store.updateShowSyncErrorDialog(true);
                  reject(success);
                }
              }
            } else if (response["result"] === null) {
              // Sync is still in progress, so keep interval going.
              store.updateSyncStatus('inProgress');
              store.updateIsSyncing(true);
              console.log('Sync in progress...');
            } else {
              // We received a malformed response.
              clearInterval(intervalId);
              reject(response);
            }

          })
          .catch(err => {
            console.error(err);
            reject(err);
            clearInterval(intervalId);
          });
      }, 2000);
    });
  }
  handleClick = () => {
    if (this.state.variant !== "inProgress") {
      this.onSync();
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      isSyncStatusOpen: false
    });
  };

  setAndShowSyncStatus = status => {
    if (this.state.isSyncStatusOpen) {
      this.setState({ isSyncStatusOpen: false });
    }
    setTimeout(() => {
      if (status === "inProgress") {
        this.setState({
          variant: status,
          message: "Syncing...",
          duration: null
        })
      }
      if (status === "success") {
        this.setState({
          variant: status,
          message: "Sync complete!",
          duration: 6000
        })
      }
      if (status === "error") {
        this.setState({
          variant: status,
          message: "Sync failed. Please try again.",
          duration: 6000
        })
      }
      this.setState({ isSyncStatusOpen: true })
    }, 250);
  }

  syncingButton() {
    const { classes } = this.props;

    if (store.isSyncing.get()) {
      return (
        <div style={{padding: 10, display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <WideButton
            disabled={store.isSyncing.get()}
            onClick={()=> {this.handleClick()}}
          >
            {store.isSyncing.get() ? "Sync In Progress" : "Sync POS"}
          </WideButton>
          <div className="top-margin-20">
            <LinearProgress
              {...this.props}
              classes={{colorPrimary: classes.loading, barColorPrimary: classes.barLoading}}
              variant="determinate"
              value={parseInt(toJS(store.sync_counter.get()))}
            />
            <div className="sync-text">
              Syncing Catalog...
            </div>
          </div>
        </div>
      )
    } else if (store.sync_status.get() === 'success') {
        return (
          <div style={{padding: 10, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <WideButton
              success
              disabled={store.isSyncing.get()}
              onClick={()=> {this.handleClick()}}
            >
              Success
            </WideButton>
            <div className="top-margin-20">
              <span>
                <LinearProgress
                  {...this.props}
                  classes={{colorPrimary: classes.success, barColorPrimary: classes.barSuccess}}
                  variant="determinate"
                  value={100}
                />
              </span>
              <div className="sync-text">
                Syncing Complete
              </div>
            </div>
          </div>
        )
      } else if (store.sync_status.get() === 'error') {
        return (
          <div style={{padding: 10, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <WideButton
              disabled={store.isSyncing.get()}
              onClick={()=> {this.handleClick()}}
              >
                Sync POS
            </WideButton>
            <div className="top-margin-20">
              <LinearProgress
                {...this.props}
                classes={{colorPrimary: classes.error, barColorPrimary: classes.barError}}
                variant="determinate"
                value={100}
              />
              <div className="sync-text">
                Sync Failed. Try Again
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div style={{padding: 10, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <WideButton
              disabled={store.isSyncing.get()}
              onClick={()=> {this.handleClick()}}
            >
              Sync POS
            </WideButton>
          </div>
        )
      }
  }

  render() {
    return (
      <div>
        {this.syncingButton()}
      </div>
    );
  }
}

const styles = props => ({
  loading: {
    backgroundColor: '#CDEDF8',
  },
  barLoading: {
    backgroundColor: '#15C0FF',
  }, 
  success: {
    backgroundColor: colors.brightGreen,
  },
  barSuccess: {
    backgroundColor: colors.brightGreen,
  },
  error: {
    backgroundColor: colors.errorRed,
  },
  barError: {
    backgroundColor: colors.errorRed,
  }
});

export default withStyles(styles)(observer(SyncButton));
