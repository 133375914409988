import React from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import store from "../store/store";
import { observer } from "mobx-react";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        store.user && store.user.id ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

export default observer(PrivateRoute);
