import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./buttons";


class EditDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    closeDrawer = (isHeader) => {
        this.props.onClose(isHeader);
    };

    render() {
        const {
            cancelText,
            buttonAction,
            buttonText,
            deleteButton,
            errorMessage,
            isSubmitting,
            noFooter,
            noSubmitButton,
            onSubmit,
            submitText,
            successMessage,
            title,
            topButton
        } = this.props;
        return (
            <div>
                <div className="drawer-header-title">
                    {title}
                    <p><FontAwesomeIcon color="#D9D9D9" icon={['far', 'times']} style={{ float: "right" }} onClick={() => this.closeDrawer(true)} /></p>
                </div>
                <div className="drawer-info-container">
                    { errorMessage }
                    { successMessage }

                    {topButton || deleteButton ? 
                        <div className='has-text-right' style={{ paddingTop: "5px" }}>
                            <Button
                                delete={Boolean(deleteButton)}
                                edit={!Boolean(deleteButton)}
                                onClick={buttonAction}
                            >
                                {buttonText}
                            </Button>
                        </div> : null}
                    {this.props.children}
                </div>
                {noFooter ? null :
                <div className={"drawer-footer columns is-mobile is-centered"}>
                    <div className="column is-narrow">
                        <Button
                            secondary
                            type="button"
                            onClick={this.closeDrawer}
                        >
                            {cancelText ? cancelText : 'Cancel'}
                        </Button>
                            { noSubmitButton ? null :
                            <Button
                                primary
                                type="submit"
                                disabled={isSubmitting}
                                onClick={onSubmit}
                            >
                                {submitText ? submitText : 'Save Changes'}
                            </Button>}
                    </div>
                </div>}
            </div>
        );
    }
}

export default observer(EditDrawer);
