import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from 'lodash';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import HoursEdit from "../hoursEdit";
import AdminCard from '../elements/adminCard';
import AdminTable from '../elements/adminTable';
import AdminSectionHeader from "../elements/adminSectionHeader";
import AdminToggle from '../elements/adminToggle';
import { Text } from "../elements/text";
import integrationsAPI from "../../services/api/integrationsAPI";
import store from '../../store/store';
import { getAvailabilityOptions, getLocationNameById, formatTimeForView } from "../../services/utils";
import { Button } from '../elements/buttons';
import LocationFulfillmentEdit from './locationFulfillmentEdit';


const tableColumns = [
    { field: "weekday", headerName: "Day", width: 200 },
    { field: "start_time", headerName: "Open", width: 120 },
    { field: "end_time", headerName: "Close", width: 120 }
];

class LocationIntegrationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editHours: false,
            editSettings: false
        };
    }

    toggleEnabled = () => {
        integrationsAPI.updateFulfillment(this.props.fulfillment.id, JSON.stringify({ is_enabled: !this.props.fulfillment.is_enabled }));
        this.props.handleUpdate();
    };

    handleDialogOpen = (type) => {
        this.setState({ [type]: true });
    };

    handleDialogClose = type => {
        this.setState({ [type]: false });
    };

    getTitle = () => {
        switch (this.props.hoursType) {
            case 'Pickup':
                return 'Enable/Disable Pickup and Set Pickup Hours'

            case 'Curbside':
                return 'Enable/Disable Curbside'

            case 'Delivery':
                return 'Enable/Disable In-House Delivery and Set In-House Delivery Hours'

            default:
                return '';
        }
    }

    getDescription = () => {
        if (this.props.hoursType === 'Delivery') {
            return (
                <Text>
                    Customize in-house delivery for your location by delivery type, minimum subtotal, and more.
                    <br />
                    <span className='bold'>NOTE: This is for in-house delivery. To set up third-party delivery, visit Configuration { '>' } Integrations.</span>
                </Text>
            )
        } else {
            return `Customize ${ this.props.hoursType.toLowerCase() } for your location.`
        }
    }

    getHoursText = () => {
        const { hoursType } = this.props;
        if (hoursType === 'Delivery') {
            return (
                'Customers will only be able to request that their order be delivered within these set hours.'
            )
        } else {
            return (
                `Customers will only be able to place orders for immediate ${ hoursType.toLowerCase() } when their order would be ready within these set hours.`
            )
        }
    }

    prepareTableData = hours => {
        let tableData = _.cloneDeep(hours);
        _.map(tableData, (availability, index) => {
            availability.id = index;
            availability.start_time = formatTimeForView(availability.start_time);
            availability.end_time = formatTimeForView(availability.end_time);
        });
        return tableData;
    };

    render() {
        const { fulfillment, hours, hoursType, hoursPropName, location } = this.props;
        const availabilityOptions = getAvailabilityOptions(hours);
        const tableData = this.prepareTableData(hours);

        return (
            <div>
                <AdminCard
                    largeTitle
                    title={this.getTitle()}
                    description={this.getDescription()}
                    onHeaderActionClick={ () => this.handleDialogOpen('editSettings') }
                >
                    <div>
                        <AdminSectionHeader
                            title={`${ hoursType === 'Delivery' ? 'In-House': ''} ${hoursType} Fulfillment`}
                        />
                        <Text>
                            <span className='bold'>Note: </span>
                            {`Disabling this fulfillment will disable ${hoursType.toLowerCase()} for all connected locations.`}
                        </Text>
                        <AdminToggle
                            className="app-toggle"
                            checked={ fulfillment ? fulfillment.is_enabled : false }
                            onChange={ this.toggleEnabled }
                        />
                        { fulfillment ?
                            <Table className='app-table-wrapper'>
                                <TableHead className='app-table-header'>
                                    <TableRow className='app-table-row'>
                                        <TableCell className='app-table-cell' style={ { width: 150, paddingLeft: 0 } }>
                                                Location
                                        </TableCell>
                                        { hoursType === 'Delivery' ? 
                                                <TableCell className='app-table-cell' style={ { width: 150, paddingLeft: 0 } }>
                                                    Type
                                                </TableCell>
                                        : null }
                                        { hoursType === 'Delivery' ? 
                                                <TableCell className='app-table-cell' style={ { width: 150, paddingLeft: 0 } }>
                                                    Fee
                                                </TableCell>
                                            : null }
                                        <TableCell className='app-table-cell'>
                                                Min. Subtotal
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className='app-table-body'>
                                    { !_.isEmpty(fulfillment) && fulfillment.locations.map(locationID => (
                                        <TableRow key={locationID} className='app-table-row'>
                                            <TableCell className='app-table-cell pl-0'>
                                                { getLocationNameById(locationID, store.locations) }
                                            </TableCell>
                                            { hoursType === 'Delivery' ?
                                                    <TableCell className='app-table-cell' style={ { width: 150, paddingLeft: 0 } }>
                                                        { fulfillment.delivery_area_method === 'radius' ? 'Radius' : 'Postal Codes' }
                                                    </TableCell>
                                                : null }
                                            { hoursType === 'Delivery' ?
                                                    <TableCell className='app-table-cell' style={ { width: 150, paddingLeft: 0 } }>
                                                        {fulfillment.delivery_fee.formatted}
                                                    </TableCell>
                                                : null }
                                            <TableCell className='app-table-cell'>
                                                { fulfillment.minimum_subtotal.formatted }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        : null }
                    </div>
                    { hoursType !== 'Curbside' ?
                        <div className='mt-5'>
                            <div className="columns is-mobile mb-0">
                                <div className='column is-three-quarters pb-0'>
                                    <AdminSectionHeader
                                        title={`${ hoursType === 'Delivery' ? 'In-House': ''} ${hoursType} Hours`}
                                    />
                                    <Text>{this.getHoursText()}</Text>
                                </div>
                                <div className="column is-one-quarter has-text-right pb-0">
                                    <Button edit onClick={ () => this.handleDialogOpen('editHours') }>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        { hours &&
                            hours.length > 0 && (
                                <AdminTable
                                    columnData={ tableColumns }
                                    rowData={ tableData }
                                    noCardBorder
                                />
                            ) }
                        { !(hours &&
                            hours.length > 0) && (
                                <div className="app-card-content-wrapper">
                                    <div className="no-data-for-view in-card">
                                        { hoursType } hours haven't been set yet.
                                    </div>
                                </div>
                            ) }
                    </div> : null}
                </AdminCard>
                <HoursEdit
                    open={ this.state.editHours }
                    onClose={ () => this.handleDialogClose('editHours') }
                    onUpdate={ this.props.handleUpdate }
                    hours={ hours }
                    hoursType={ hoursType }
                    hoursPropName={ hoursPropName }
                    availabilityOptions={ availabilityOptions }
                    locationId={ location.id }
                />
                <LocationFulfillmentEdit
                    open={ this.state.editSettings }
                    onClose={ () => this.handleDialogClose('editSettings') }
                    onUpdate={ this.props.handleUpdate }
                    fulfillment={ fulfillment }
                    location={ location }
                    fulfillmentType={ this.props.fulfillmentType }
                />
            </div>
        );
    }
}

export default observer(LocationIntegrationCard);
