import React, { Component } from 'react';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { FormControlLabel } from '@material-ui/core';
import AdminCheckbox from '../elements/adminCheckbox';


class UberTOU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
    };

    handleChecked = () => {
        let checked = !this.state.checked;
        this.setState({ checked });
    };

    render() {
        return (
            <EditDrawer
                title='Add Uber Integration'
                onClose={ this.props.onClose }
                isSubmitting={ false }
                onSubmit={ this.props.acceptUberTOU }
                submitText='Accept TOU'
            >
                <AdminCard
                    title='Accept Uber Terms of Use'
                    description='By enabling Uber Direct, you agree to their Terms of Use.'
                >
                    <div>
                        <a href="https://www.uber.com/legal/en/document/?name=uber-delivery-integration-api-terms-of-use" target="_blank" rel="noopener noreferrer">https://www.uber.com/legal/en/document/?name=uber-delivery-integration-api-terms-of-use</a>
                    </div>
                    <FormControlLabel
                        control={
                            <AdminCheckbox
                                checked={ this.state.checked }
                                onChange={ this.handleChecked }
                            />
                        }
                        label="Accept Uber Terms of Use"
                    />
                </AdminCard>
            </EditDrawer>
        );
    }
}

export default UberTOU;