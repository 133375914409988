import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../store/store";
import IntegrationsDetails from "../components/integrations/integrationsDetails";
import { Text } from "../components/elements/text";
import AdminPageHeader from "../components/elements/adminPageHeader";
import _ from 'lodash';
import { getFulfillment } from "../services/utils";
import { toJS } from 'mobx';
import integrationsAPI from '../services/api/integrationsAPI';
import tenantAPI from '../services/api/tenantAPI';

class IntegrationsDoordash extends Component {
  state = {
    open: false,
    fulfillments: [],
    drawerType: 'create',
    searchedItems: false,
    typing: false,
    typingTimeout: 0,
  };

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(() => this.getFulfillments());
  };

  getFulfillments = () => {
    integrationsAPI.getRecursiveFulfillments({ page: 1 })
      .then(response => {
        let fulfillments = getFulfillment(response, 'doordash');
        this.setState({ fulfillments });
      });
  };

  searchFulfillments = (query) => {
    let self = this;
    store.updateFulfillmentSearch(query);
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getSearchResult(query);
      }, 500),
      searchedItems: query !== "" ? true : false,
    });
  };

  getSearchResult = (query) => {
    if (query === "") {
      let fulfillments = getFulfillment(store.fulfillments, 'doordash');
      this.setState({ fulfillments });
    } else {
      let fulfillments = _.filter(this.state.fulfillments, (fulfillment) => {
        let names = toJS(fulfillment).location_names;
        return _.find(names, name => name.includes(query));
      });
      this.setState({ fulfillments });
    }
  };

  handleDialogOpen = drawerType => {
    this.setState({ drawerType });
    this.setState({ open: true });
  };

  handleDialogClose = updated => {
    this.setState({ open: false });
    if (updated === true) this.getFulfillments();
  };

  onDelete = integrationID => {
    integrationsAPI.deleteFulfillment(integrationID)
      .then(() => {
        this.handleDialogClose(true);
      });
  };

  render() {
    const { fulfillments } = this.state;
    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="DoorDash"
          mainNav="Configuration"
          subNav="Integrations"
          description={<Text large>
            To get started with DoorDash Drive please complete the following form by visiting this link for U.S.: <a href="https://doordash.workflowcloud.com/forms/8f1b2fc0-45d0-41b1-b6ac-ece88745fc74" target="_blank" rel="noopener noreferrer" className='ordrslipRed'> https://doordash.workflowcloud.com/forms/8f1b2fc0-45d0-41b1-b6ac-ece88745fc74</a> or this link for Canada: <a href="https://doordash.workflowcloud.com/forms/d9d9fba7-440f-4d49-ae42-2becf5fb5b8a" target="_blank" rel="noopener noreferrer" className='ordrslipRed'> https://doordash.workflowcloud.com/forms/d9d9fba7-440f-4d49-ae42-2becf5fb5b8a</a>
          </Text>}
          headerActionText="Add New"
          onHeaderActionClick={() => this.handleDialogOpen('create')}
        />
        <IntegrationsDetails
          open={this.state.open}
          drawerType={this.state.drawerType}
          fulfillments={fulfillments}
          handleDialogOpen={ () => this.handleDialogOpen('edit') }
          integration={"doordash"}
          onClose={this.handleDialogClose}
          onSearch={this.searchFulfillments}
          onDelete={ this.onDelete }
        />
      </div>
    );
  }
}

export default observer(IntegrationsDoordash);
