import React, { Component } from "react";
import InputMask from 'react-input-mask';


class HoursInput extends Component {
    render() {
        const {
            disabled,
            hasError,
            id,
            name,
            onChange,
            placeholder,
            style,
            value
        } = this.props;

        return (
            <InputMask
                className={hasError ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={(event) => event.target.select()}
                mask="**:**"
                maskChar='x'
                placeholder={placeholder}
                style={style}
                disabled={disabled}
            />
        )
    }
}

export default HoursInput
