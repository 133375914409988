import Home from "./screens/home";
import UserList from "./screens/userList";
import MenuDetails from "./screens/menuDetails";
import Catalog from "./screens/catalog";
import Messages from "./screens/messages"
import LocationDetails from "./screens/locationDetails";
import Reports from "./screens/reports";
import Rewards from "./screens/rewards";
import AppCustomization from "./screens/appCustomization";
import Integrations from "./screens/integrations";
import YourProfile from './screens/yourProfile';
import Promotions from './screens/promotions';
import Referrals from './screens/referrals';

export const routes = [
  {
    path: "/users/all-users",
    component: UserList
  },
  {
    path: "/users/profile",
    component: YourProfile
  },
  {
    component: MenuDetails,
    path: "/menu/:menuId"
  },
  {
    path: "/messages",
    component: Messages
  },
  {
    component: LocationDetails,
    path: "/locations/:locationId"
  },
  {
    path: "/catalog",
    component: Catalog
  },
  {
    path: "/reports",
    component: Reports
  },
  {
    path: "/app-settings",
    component: AppCustomization
  },
  {
    path: "/referrals",
    component: Referrals,
    exact: true
  },
  {
    path: "/rewards",
    component: Rewards
  },
  {
    path: "/promotions",
    component: Promotions
  },
  {
    path: "/integrations",
    component: Integrations
  },
  {
    path: "/",
    component: Home,
    exact: true
  }
];
