import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';

import { errorMessage, fieldError } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminSectionHeader from "../elements/adminSectionHeader";
import AdminToggle from '../elements/adminToggle';
import DraggableTable from '../elements/draggableTable';
import EditDrawer from '../elements/editDrawer';
import { Text } from '../elements/text';
import ImageCard from '../imageCard';
import menuAPI from '../../services/api/menuAPI';


class CategoryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            category: _.cloneDeep(this.props.category),
            delete_image: false,
            fileUrl: '',
            file: null,
            reordered: false
        };
    };

    updateImage = (fileUrl, file) => {
        this.setState({ fileUrl, file, delete_image: false });
    };

    deleteImage = () => {
        this.setState({ fileUrl: '', file: null, delete_image: true });
    };

    toggleEnabled = () => {
        let { category } = this.state;
        category.is_enabled = !category.is_enabled;
        this.setState({ category });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.category !== this.props.category) {
            this.setState({ category: nextProps.category});
        }
    };

    handleChange(event) {
        let { category } = this.state;
        const value = event.target.value;
        const name = event.target.name;
        category[name] = value
        this.setState({ category });
    };

    handleSubmit = () => {
        const { category, reordered, delete_image } = this.state;
        let payload = {
            is_enabled: category.is_enabled,
            minimum_selected_items: category.minimum_selected_items,
            maximum_selected_items: category.maximum_selected_items
        };
        if (delete_image) payload.delete_image = true;
        if (reordered) {
            // Bulk update items
            let itemPayload = { 'objects': [] };
            category.items.forEach((item, index) => {
                itemPayload.objects.push({ 'id': item.id, 'order': item.order });
            });
            menuAPI.bulkUpdate('items', JSON.stringify(itemPayload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        return;
                    } else {
                        this.setState({ apiErrors: {} });
                    }
                })
        }
        menuAPI.updateCategory(category.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    if (this.state.fileUrl) {
                        let data = new FormData();
                        data.append('image', this.state.file);
                        menuAPI.updateCategoryImage(category.id, data)
                            .then(response => {
                                if (response.errors) {
                                    this.setState({ apiErrors: response.errors });
                                } else {
                                    this.handleClose(true);
                                }
                            });
                    } else {
                        this.handleClose(true);
                    }
                }
            })
    };

    handleSaveImage = data => {
        const category_id = this.state.category.id;
        menuAPI.updateCategoryImage(category_id, data)
            .then(response => this.setState({ category: response }));
    };

    handleClose = updated => {
        this.setState({
            fileUrl: '',
            file: null,
            apiErrors: {},
            reordered: false,
            delete_image: false
        });
        this.props.onClose(updated);
    };

    updateDragged = list => {
        let { category } = this.state;
        category.items = list;
        this.setState({ category, reordered: true });
    };

    render() {
        const { apiErrors, category } = this.state;
        if (_.isEmpty(category)) return <div />;
        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ () => this.handleClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={`Edit ${category.name} Category`}
                    onSubmit={ this.handleSubmit }
                    onClose={ () => this.handleClose(false) }
                    errorMessage={errorMessage(this.state.apiErrors)}
                >
                    <form>
                        <AdminCard
                            noDescription
                            title='Enable/Disable Category'
                            style={ { marginTop: '15px' } }
                            description='When enabled, this category will appear on your mobile app and/or online ordering for this menu.'
                        >
                            <AdminToggle
                                className='app-toggle'
                                checked={ category.is_enabled }
                                onChange={ this.toggleEnabled }
                            />
                            <div className='mt-3'>
                                <AdminSectionHeader
                                    title='Category Image'
                                />
                                <Text>
                                    Upload your category image. If no image is selected, an image will not show on your app an/or online ordering.
                                </Text>
                                <ImageCard
                                    imgUrl={ !this.state.delete_image ? category.image : null }
                                    file={ this.state.file }
                                    fileUrl={this.state.fileUrl}
                                    altText={ category.name }
                                    item={ category }
                                    updateImage={ this.updateImage }
                                    deleteImage={ this.deleteImage }
                                    delete_image={ this.state.delete_image }
                                />
                            </div>
                        </AdminCard>
                        <AdminCard
                            title='Minimum/Maximum Items Ordered from Category'
                            description='Setting a minimum/maximum will limit users to the amount of items they can order from this category. (Optional)'
                        >
                            <div className='app-field-wrapper'>
                                <div className='app-field-label' style={ { marginTop: '15px' } }>Minimum</div>
                                <input
                                    className={ `app-input-field mt-0 ${ fieldError('minimum_selected_items', apiErrors) ? 'red-border' : '' }` }
                                    name='minimum_selected_items'
                                    type='number'
                                    placeholder='Minimum'
                                    value={ category.minimum_selected_items }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                <div>{ fieldError('minimum_selected_items', apiErrors) }</div>
                            </div>
                            <div className='app-field-wrapper'>
                                <div className='app-field-label' style={ { marginTop: '15px' } }>Maximum</div>
                                <input
                                    className={ `app-input-field mt-0 ${ fieldError('maximum_selected_items', apiErrors) ? 'red-border' : '' }` }
                                    name='maximum_selected_items'
                                    type='number'
                                    placeholder='Maximum'
                                    value={ category.maximum_selected_items ? category.maximum_selected_items : '' }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                <div>{ fieldError('maximum_selected_items', apiErrors) }</div>
                            </div>
                        </AdminCard>
                        <AdminCard
                            title='Organize Items Within Category'
                            description='Organize your items the way they will display in your app and/or online ordering.'
                        >
                            { category.items.length ?
                                <DraggableTable
                                    list={ category.items }
                                    headerData={ ['Item Name', 'Enabled'] }
                                    fieldData={ ['name', 'is_available'] }
                                    imageIcon={ true }
                                    updateParentState={this.updateDragged}
                                />
                                : <Text>There are no items in this category.</Text>
                            }
                        </AdminCard>
                    </form>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default CategoryEdit;
