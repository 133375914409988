import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from "@material-ui/core";

import store from "../../store/store";
import { showLocations } from "../../services/utils";
import { Button } from '../elements/buttons';
import AdminSearchInput from '../elements/adminSearchInput';
import IntegrationsDrawer from './integrationsDrawer';


class IntegrationsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      intInFocus: '',
      selectedFulfillment: {},
      rowsPerPage: 10,
      currentPage: 0,
      fulfillments: this.props.fulfillments,
      orderBy: 'location',
      order: 'desc'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fulfillments !== this.props.fulfillments) {
      this.setState({ fulfillments: nextProps.fulfillments });
    }
  };

  editFulfillment(fulfillment) {
    this.setState({ selectedFulfillment: fulfillment });
    this.props.handleDialogOpen();
  }

  maskKey = (key) => {
    let first3 = key.substring(0, 3);
    let last2 = key.substring(key.length - 2, key.length);
    let middle = key.substring(3, key.length - 2);
    let starred = '*'.repeat(middle.length);
    let masked = first3 + starred + last2;
    return masked;
  }

  getLocationCurrency = (id) => {
    return _.find(store.locations, { id: id }).currency;
  }

  showDeliveryType = (fulfillment) => {
    if (fulfillment.delivery_area_method === "postal_codes") {
      return 'Zip Code(s)';
    } else {
      return 'Radius';
    }
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({currentPage: newPage})
  }

  handleSort(value) {
    let fulfillments = this.state.fulfillments;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'business_name') {
      fulfillments = _.orderBy(fulfillments, fulfillment => fulfillment[value].toLowerCase(), [order]);
    } else {
      if (["delivery_fee", "minimum_subtotal"].includes(value)) value = `${value}.amount`;
      fulfillments = _.orderBy(fulfillments, [value], [order]);
    } 
    this.setState({fulfillments, order, orderBy, currentPage: 0})
  }

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={['fas', 'caret-up']} />
      :
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={['fas', 'caret-down']} />
    return sortIcon;
  }

  render() {
    const { onSearch, integration } = this.props;
    const { rowsPerPage, currentPage, fulfillments, orderBy, order } = this.state;

    return (
      <div>
        <div className="table-wrapper">
          <div className="inside-table-padding table-height">
            <div className='columns is-mobile mb-0'>
              <div className="column pb-0 is-one-fifth is-offset-four-fifths">
                <AdminSearchInput
                  value={ store.fulfillmentSearch }
                  onChange={ onSearch }
                  style={ { paddingBottom: 12 } }
                />
              </div>
            </div>
          <div>
            <div className='divider' />
            {fulfillments && fulfillments.length ? 
            <div style={{ width: '100%', flex: 1, overflowX: "auto" }}>
              <Table className="app-table-wrapper">
                <TableHead className="app-table-header">
                  <TableRow className="app-table-row">
                    <TableCell className="app-table-cell">
                        Locations
                    </TableCell>
                    <TableCell className="app-table-cell">
                      <TableSortLabel
                        active={orderBy === "is_enabled"}
                        direction={orderBy === "is_enabled" ? order : 'asc'}
                        onClick={() => this.handleSort("is_enabled")}
                        IconComponent={() => this.getSortIcon("is_enabled")}
                      >
                          Enabled
                        {orderBy === "is_enabled" ? (
                          <span className='is-hidden'>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    {integration === "uber" ? <TableCell className="app-table-cell">
                          Customer ID
                    </TableCell> : null}
                    {integration === "doordash" ?
                    <React.Fragment>
                      <TableCell className="app-table-cell">
                          <TableSortLabel
                            active={orderBy === "business_name"}
                            direction={orderBy === "business_name" ? order : 'asc'}
                            onClick={() => this.handleSort("business_name")}
                            IconComponent={() => this.getSortIcon("business_name")}
                          >
                              Business Name
                            {orderBy === "business_name" ? (
                              <span className='is-hidden'>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                      </TableCell> 
                      <TableCell className="app-table-cell">
                          <TableSortLabel
                            active={orderBy === "delivery_fee"}
                            direction={orderBy === "delivery_fee" ? order : 'asc'}
                            onClick={() => this.handleSort("delivery_fee")}
                            IconComponent={() => this.getSortIcon("delivery_fee")}
                          >
                              Delivery Fee
                            {orderBy === "delivery_fee" ? (
                              <span className='is-hidden'>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                      </TableCell>
                    </React.Fragment>
                    : null}
                    <TableCell className="app-table-cell">
                        <TableSortLabel
                          active={orderBy === "minimum_subtotal"}
                          direction={orderBy === "minimum_subtotal" ? order : 'asc'}
                          onClick={() => this.handleSort("minimum_subtotal")}
                          IconComponent={() => this.getSortIcon("minimum_subtotal")}
                        >
                            Min. Subtotal
                          {orderBy === "minimum_subtotal" ? (
                            <span className='is-hidden'>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="set-width app-table-cell" />
                  </TableRow>
                </TableHead>
                <TableBody className="app-table-body">
                  {(rowsPerPage > 0 ? fulfillments.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : fulfillments).map((fulfillment, index) => (
                    <TableRow className={this.state.intInFocus === fulfillment.id ? "highlighted-row app-table-row" : " app-table-row"} key={index} onMouseOver={() => this.setState({intInFocus: fulfillment.id})}>
                      <TableCell className="app-table-cell">
                        { showLocations(fulfillment) }
                      </TableCell>
                      <TableCell className="app-table-cell">
                        {fulfillment.is_enabled ? 
                        <span className="brightGreen semi-bold">Enabled</span> : <span className="ordrslipRed semi-bold">Disabled</span>}
                      </TableCell>
                      {fulfillment.customer_id ? 
                      <TableCell className="app-table-cell">
                          {fulfillment.customer_id}
                      </TableCell> : null}
                      { integration === "doordash" ?
                        <React.Fragment>
                          <TableCell className="app-table-cell">
                            {fulfillment.business_name}
                          </TableCell>
                          <TableCell className="app-table-cell">
                            {fulfillment.delivery_fee.formatted}
                          </TableCell>
                        </React.Fragment>
                      : null}
                      <TableCell className="app-table-cell">
                        {fulfillment.minimum_subtotal.formatted}
                      </TableCell>
                      <TableCell className="set-width app-table-cell">
                        {this.state.intInFocus === fulfillment.id ? 
                          <Button edit onClick={() => this.editFulfillment(fulfillment)}>
                            Edit
                          </Button>
                        : null}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={this.props.fulfillments && this.props.fulfillments.length ? this.props.fulfillments.length : 0}
                      rowsPerPage={this.state.rowsPerPage}
                      onRowsPerPageChange={(event) => this.updateRows(event)}
                      page={this.state.currentPage}
                      onPageChange={(event, newPage) => this.updatePage(event, newPage)}
                      style={{ borderBottom: 'none' }}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </div> : 
            <div className="text-center">
              No integrations were found.
            </div>}
          </div>
        </div>
        </div>
        <IntegrationsDrawer
          fulfillment={this.props.drawerType === 'edit' ? _.cloneDeep(this.state.selectedFulfillment) : {}}
          integration={this.props.integration}
          open={this.props.open}
          onClose={this.props.onClose}
          deleteIntegration={this.props.onDelete}
        />
      </div>
    );
  }
}

export default observer(IntegrationsDetails);
