import React, { Component } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { colors } from "../../styles/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledDropdownField = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: props => props.hasError ? `2px solid ${colors.errorRed}` : `1px solid ${colors.disabledGrey}`,
        fontSize: 14,
        height: 32,
        padding: '0px 14px',
        '&:focus': {
            borderRadius: 4,
        },
    },
}))(({ hasError, ...props }) => {
    return (
        <InputBase {...props} />
    )
})

class AdminDropdownField extends Component {
    render() {
        let { field } = this.props;

        return (
            <NativeSelect
                input={<StyledDropdownField />}
                IconComponent={() => <FontAwesomeIcon 
                    size="sm" 
                    color={colors.ordrslipRed} 
                    icon={['fas', 'sort-down']}
                    style={{ position: 'absolute', top: 5, right: 8, pointerEvents: 'none' }}
                    />}
                {...field} 
                {...this.props}
            >
                {this.props.children}
            </NativeSelect>
        )
    }
}

export default AdminDropdownField;