import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../store/store";
import Collapse from '@material-ui/core/Collapse';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Currency from 'react-currency-formatter';
import moment from "moment";
import _ from "lodash";
import { getStartOfDayByDate, getEndOfDayByDate, EXTERNAL_FULFILLMENT_NAMES } from "../services/utils";
import AdminPageHeader from "../components/elements/adminPageHeader";
import AdminCard from '../components/elements/adminCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TablePagination } from "@material-ui/core";
import ReportsCustomHeader from "../components/reports/reportsCustomHeader";
import AutocompleteVerbiage from '../components/reports/autcompleteVerbiage';
import tenantAPI from '../services/api/tenantAPI';
import reportsAPI from '../services/api/reportsAPI';

class SalesSummary extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      salesReport: {},
      rowCollapsed: {},
      rowsPerPage: 10,
      currentPage: 0,
    };
  }

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({page: 1})
      .then(locations => {
        this.setState({ locations });
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format('MM/DD/YYYY');
        this.formatReports(locations, monthAgo, todaysDate);
      });
  }

  formatReports(locations, fromDate, toDate) {
    return new Promise(resolve => {
      let formattedReports = [];
      _.forEach(locations, (location, index) => {
        reportsAPI.getSalesSummary(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), location.id)
          .then(data => {
            let locReport = {
              'location': {
                name: location.name,
                id: location.id
              },
              'reports': data
            };
            formattedReports.push(locReport);
            this.setState({salesReport: formattedReports});
          })
          .catch(err => console.error(err));
      });
      resolve(formattedReports);
    });
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format('MM/DD/YYYY');
    if (type === 'fromDate') {
      this.setState({fromDate: displayDate}, () => {
        this.formatReports(this.state.locations, newDate, this.state.toDate);
      });
    } else if (type === 'toDate') {
      this.setState({toDate: displayDate}, () => {
        this.formatReports(this.state.locations, this.state.fromDate, newDate);
      });
    }
  }

  openOrClosedIcon(reportId) {
    if (this.state.rowCollapsed === reportId) {
      return <FontAwesomeIcon size="1x" className="ordrslipRed" icon={['fas', 'caret-down']} />;
    } else {
      return <FontAwesomeIcon size="1x" className="ordrslipRed" icon={['fas', 'caret-right']} />;
    }
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  render() {
    if (store.loading || !this.state.salesReport.length) return <div />;
    const { rowsPerPage, currentPage } = this.state; 

    return (
      <div className="admin-page-wrapper">
          <AdminPageHeader
            title="Sales Summary"
            mainNav="Reports"
            subNav="Location Reports"
            description="View the overall sales breakdown for each of your locations."
        />
          <AutocompleteVerbiage />
          <AdminCard
            title=""
            bottomBorder={true}
            customHeader={
              <ReportsCustomHeader
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                pickerWidth="30%"
                handleChange={this.handleChange}
            />}
          >
            <Table className="app-table-wrapper collapsible mt-0">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell set-thin-width">Locations</TableCell>
                  <TableCell className="app-table-cell" align="left">QTY Orders</TableCell>
                  <TableCell className="app-table-cell" align="left">Discounts</TableCell>
                  <TableCell className="app-table-cell" align="left">Refunds</TableCell>
                  <TableCell className="app-table-cell" align="left">Total Order Sales</TableCell>
                  <TableCell className="app-table-cell" align="left">Avg Order Sales</TableCell>
                  <TableCell className="app-table-cell" align="left">Taxes</TableCell>
                  <TableCell className="app-table-cell" align="left">Tips</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
                {rowsPerPage > 0 && this.state.salesReport &&
                  this.state.salesReport.length > 0 &&
                  this.state.salesReport.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) &&
                  this.state.salesReport.map((report, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={report.location.id} className="app-table-row">
                        <TableCell className="app-table-cell pl-0 cursor-pointer" onClick={() => {
                          const rowCollapsed = report.location.id === this.state.rowCollapsed ? {} : report.location.id;
                          this.setState({ rowCollapsed });
                        }}>
                          <span className="pr-2">
                            {this.openOrClosedIcon(report.location.id)}
                          </span>
                          {report.location.name}
                        </TableCell>
                        <TableCell className="app-table-cell" align="left">
                          {report.reports.number_of_orders}
                        </TableCell>
                        <TableCell className="app-table-cell" align="left">
                          <Currency
                            quantity={report.reports.discounts.amount.amount}
                            currency={report.reports.discounts.amount.currency}
                          />
                        </TableCell>
                        <TableCell className="app-table-cell" align="left">
                          <Currency
                            quantity={report.reports.refunds.amount.amount}
                            currency={report.reports.refunds.amount.currency}
                          />
                        </TableCell>
                        <TableCell className="app-table-cell" align="left">
                          <Currency
                            quantity={report.reports.total_order_sales.amount}
                            currency={report.reports.total_order_sales.currency}
                          />
                        </TableCell>
                        <TableCell className="app-table-cell" align="left">
                          <Currency
                            quantity={report.reports.average_order_amount.amount}
                            currency={report.reports.average_order_amount.currency}
                          />
                        </TableCell>
                        <TableCell className="app-table-cell">
                          <Currency
                            quantity={report.reports.taxes.amount}
                            currency={report.reports.taxes.currency}
                          />
                        </TableCell>
                        <TableCell className="app-table-cell">
                          <Currency
                            quantity={report.reports.tips.amount.amount}
                            currency={report.reports.tips.amount.currency}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow key={`${report.location.id}-fulfillments-summary`} className="app-table-row">
                        <TableCell className="app-table-cell" style={{ padding: 0 }} colSpan={9}>
                          <Collapse in={this.state.rowCollapsed === report.location.id} timeout="auto" unmountOnExit>
                            <Table className="app-table-wrapper sub-table mb-2">
                              <TableHead className="app-table-header">
                                <TableRow className="app-table-row divider">
                                  <TableCell className="app-table-cell set-thin-width">Fulfillment Type</TableCell>
                                  <TableCell className="app-table-cell" align="left">QTY Orders</TableCell>
                                  <TableCell className="app-table-cell" align="left">Discounts</TableCell>
                                  <TableCell className="app-table-cell" align="left">Refunds</TableCell>
                                  <TableCell className="app-table-cell" align="left">Total Order Sales</TableCell>
                                  <TableCell className="app-table-cell" align="left">Avg Order Sales</TableCell>
                                  <TableCell className="app-table-cell" align="left">Taxes</TableCell>
                                  <TableCell className="app-table-cell" align="left">Tips</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className="app-table-body">
                                {Object.entries(report.reports.fulfillments).sort().map(([name, fulfillment]) => (
                                  <TableRow className="app-table-row" key={`${report.location.id}-${name}`}>
                                    <TableCell className="app-table-cell">
                                      {EXTERNAL_FULFILLMENT_NAMES[name]}
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      {fulfillment.number_of_orders}
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.discounts.amount.amount}
                                        currency={fulfillment.discounts.amount.currency}
                                      />
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.refunds.amount.amount}
                                        currency={fulfillment.refunds.amount.currency}
                                      />
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.total_order_sales.amount}
                                        currency={fulfillment.total_order_sales.currency}
                                      />
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.average_order_amount.amount}
                                        currency={fulfillment.average_order_amount.currency}
                                      />
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.taxes.amount}
                                        currency={fulfillment.taxes.currency}
                                      />
                                    </TableCell>
                                    <TableCell className="app-table-cell" align="left">
                                      <Currency
                                        quantity={fulfillment.tips.amount.amount}
                                        currency={fulfillment.tips.amount.amount.currency}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={ [10, 25, 50] }
                    count={ this.state.salesReport && this.state.salesReport.length ? this.state.salesReport.length : 0 }
                    rowsPerPage={ this.state.rowsPerPage }
                    onRowsPerPageChange={ (event) => this.updateRows(event) }
                    page={ this.state.currentPage }
                    onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                    style={ { borderBottom: 'none' } }
                  />
                </TableRow>
              </TableBody>
            </Table>
          </AdminCard>
      </div>
    );
  }
}

export default observer(SalesSummary);
