import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import { Text } from '../elements/text';
import SupportEmailEdit from './supportEmailEdit';

class LocationSupportEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: this.props.location,
            open: false
        };
    };

    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    componentWillReceiveProps(nextProps) {
        if (this.state.location && nextProps.location.support_email !== this.state.support_email)
            this.setState({
                location: nextProps.location,
            });
    };

    render() {

        return (
            <div>
                <AdminCard
                    largeTitle
                    title="Location Support Email Address"
                    description="This email address will receive support request emails sent from your mobile app and online ordering page for this specific location."
                    onHeaderActionClick={ this.toggleDrawer }
                >
                    <Text>{this.state.location.support_email ? this.state.location.support_email : "No email has been selected."}</Text>
                </AdminCard>
                <SupportEmailEdit
                    open={ this.state.open }
                    onClose={ this.toggleDrawer }
                    support_email={ this.state.location.support_email }
                    onUpdate={ this.props.onUpdate }
                    location={ this.state.location }
                />
            </div>
        );
    }
}

export default LocationSupportEmail;
