import React, { Component } from "react";
import { observer } from "mobx-react";
import AdminPageHeader from "../components/elements/adminPageHeader";
import ReportsDiscountActivity from "../components/reports/reportsDiscountActivity";
import ReportsTopMenuItems from "../components/reports/reportsTopMenuItems";
import ReportsDailySales from "../components/reports/reportsDailySales";
import ReportsAppReporting from "../components/reports/reportsAppReporting";
import ReportsTopCategories from '../components/reports/reportsTopCategories';

class ReportsAnalytics extends Component {

  render() {
    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="Analytics"
          mainNav="Reports"
          description="Highlights from your location(s) using your mobile app and/or online ordering."
        />
        <ReportsAppReporting />
        <ReportsDailySales />
        <ReportsTopMenuItems />
        <ReportsTopCategories />
        <ReportsDiscountActivity />
        </div>
    );
  }
}

export default observer(ReportsAnalytics);
