import React, { Component } from "react";
import { observer } from "mobx-react";
import { parseHours } from "../services/utils";
import AdminCard from './elements/adminCard';
import HoursInputRow from './elements/hoursInputRow';
import _ from 'lodash';
import moment from "moment";
import store from '../store/store';


class HoursEditCard extends Component {
    componentDidMount = () => {
        store.updateSelectedHours(this.props.hours);
    }

    toggleChecked = weekday => {
        let hours = store.selectedHours;
        let day = _.find(hours, { weekday: weekday });
        if (day.closed) {
            // State is currently closed, so we are toggling it to open. Don't touch the time values.
            day.closed = false;
        } else {
            // State is currently open, so we are toggling it to closed. Clear time values.
            day.start_time = null;
            day.end_time = null;
            day.closed = true;
        }
        store.updateSelectedHours(hours);
    };

    handleChange = event => {
        // Combine time and am/pm fields into timestamp
        let hours = store.selectedHours;
        let value = event.target.value;
        let name = event.target.name;
        let id = event.target.id;
        let day = _.find(hours, { weekday: id });

        if (name.includes('_timestamp')) {
            // Time was updated
            name = name.replace('_timestamp', '');
            let amPm = name.includes('start') ? 'AM' : 'PM';
            if (day[name] && !day[name].includes('x')) {
                amPm = parseHours(day[name], true).toUpperCase();
            }
            if (!value.includes('x')) {
                value = moment(`${ value }:00 ${ amPm }}`, 'hh:mm:ss a').format('HH:mm:ss');
            }

        } else if (name.includes('_ampm')) {
            // AM/PM was updated
            name = name.replace('_ampm', '');
            let time = day[name] ? parseHours(day[name]) : '00:00';
            value = moment(`${ time }:00 ${ value }}`, 'hh:mm:ss a').format('HH:mm:ss');
        }
        day[name] = value;
        store.updateSelectedHours(hours);
    };

    fieldError = (day) => {
        // Errors are returned in an array, we must match the index to the index
        // of the day in the saved payload to show the error message on the correct row
        if (this.props.apiErrors.hasOwnProperty(`${ (this.props.hoursType).toLowerCase() }_hours`)) {
            if (this.props.payload.length > 0) {
                let dayIndex = this.props.payload.findIndex(item => {
                    return item.weekday === day;
                });
                if (dayIndex > -1) {
                    let error = this.props.apiErrors.store_hours[dayIndex];
                    if (error.message) {
                        let msg = JSON.parse(error.message).__all__;
                        if (msg) {
                            return (
                                <div className="red-error-text" style={ { marginTop: -12 } }>
                                    { msg[0].message }
                                </div>
                            );
                        }
                    }
                }
            }
        }
        return null;
    };

    render() {
        const { hoursType } = this.props;
        const hours = store.selectedHours;

        return (
            <AdminCard
                title={ `${ hoursType } Hours` }
                style={ { marginTop: 15 } }
            >
                { hours.length && hours.map((day, index) => (
                    <React.Fragment key={ index }>
                        <HoursInputRow
                            index={ day.weekday }
                            labelText={ day.weekday }
                            openTime={ day.start_time }
                            closeTime={ day.end_time }
                            onChange={ this.handleChange }
                            hoursType={ day.weekday }
                            disabled={ !!day.closed }
                            toggleChecked={ this.toggleChecked }
                            fromName="start_time"
                            toName="end_time"
                            hasError={ Boolean(this.fieldError(day.weekday)) }
                        >
                            { this.fieldError(day.weekday) }
                        </HoursInputRow>
                    </React.Fragment>
                )) }
            </AdminCard>
        );
    }
}

export default observer(HoursEditCard);
