import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import Drawer from '@material-ui/core/Drawer';
import { errorMessage, fieldError } from "../../services/utils";
import tenantAPI from '../../services/api/tenantAPI';

class SupportEmailEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            support_email: this.props.support_email,
            apiErrors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.support_email !== nextProps.support_email) {
            this.setState({ support_email: nextProps.support_email });
        }
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let payload = { support_email: this.state.support_email };
        if (this.props.location) {
            // Update location support email
            tenantAPI.updateLocation(this.props.location.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        this.setState({ apiErrors: {} });
                        this.props.onUpdate(response);
                        this.handleClose(true);
                    }
                })
                .catch(err => console.error(err));
        } else {
            // Update tenant support email
            tenantAPI.updateTenant(payload)
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        this.setState({ apiErrors: {} });
                        this.handleClose(true);
                    }
                })
                .catch(err => console.error(err));
        }
    };

    handleClose = updated => {
        this.setState({ support_email: '' });
        this.props.onClose(updated);
    };

    render() {
        return (
            <Drawer anchor="right" open={ this.props.open } onClose={ () => this.handleClose(false) } classes={ { paper: "app-drawer", paperAnchorRight: "app-drawer" } }>
                <EditDrawer title={`Edit ${this.props.location ? 'Location' : ''} Support Email Address`} onSubmit={ this.handleSubmit } onClose={ () => this.handleClose(false) }>
                    <AdminCard
                        title={`${this.props.location ? "Location" : ''} Support Email Address`}
                        style={ { marginTop: "15px" } }
                        description={`Set the email address where you would like to receive support request emails sent from your mobile app and online ordering page ${this.props.location ? "for this location." : "for all locations."}`}
                    >
                        { errorMessage(this.state.apiErrors) }
                        <form>
                            <div className='app-field-wrapper'>
                                <label htmlFor='support_email' className='app-field-label'>
                                    Email
                                </label>
                                <input
                                    className={ fieldError('support_email', this.state.apiErrors) ? 'app-input-field red-border mt-0' : 'app-input-field mt-0' }
                                    id='support_email'
                                    name='support_email'
                                    type='email'
                                    value={ this.state.support_email }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                { fieldError('support_email', this.state.apiErrors) }
                            </div>
                        </form>
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default SupportEmailEdit;
