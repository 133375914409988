import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors } from '../../styles/global';

class DraggableTable extends Component {

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let { list } = this.props;
        list = this.reorder(
            list,
            result.source.index,
            result.destination.index
        );

        list.forEach((item, index) => {
            item.order = index + 1;
        });
        this.props.updateParentState(list);
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: colors.white,
        border: isDragging ? `2px solid ${ colors.secondaryBlue }` : 'none',
        display: isDragging ? 'table' : '',
        ...draggableStyle
    });

    getImageIcon = (field, item) => {
        if (field === 'name' && this.props.imageIcon) {
            if (item.image) {
                return <FontAwesomeIcon icon='fa-solid fa-image' className='pr-2 secondaryBlue' />;
            } else {
                return <FontAwesomeIcon icon='fa-regular fa-image-slash' className='pr-2 disabledGrey' />;
            }
        } else {
            return null;
        }
    };

    renderTableCell = (field, item) => {
        switch (field) {
            case 'is_enabled':
            case 'is_available':
                return (
                    <span className={ `semi-bold ${ item[field] ? 'brightGreen' : 'ordrslipRed' }` }>
                        { item[field] ? 'Enabled' : 'Disabled' }
                    </span>
                );

            case 'pricerange':
                let max = item.pricerange.max.formatted;
                let min = item.pricerange.min.formatted;
                if (max === min) return <span>{ max }</span>;
                else return <span>{ min } - { max }</span>;

            default:
                return <span>{ this.getImageIcon(field, item) }{ item[field] }</span>;
        }
    };

    render() {
        const {
            fieldData,
            headerData,
            list
        } = this.props;
        return (
            <Table className='app-table-wrapper' size='small'>
                <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                        { headerData.map((item, index) => (
                            <TableCell key={ index } className={`app-table-cell pl-0 ${index > 0 ? 'fourth-table-width' : 'half-table-width'}`}>
                                {item}
                            </TableCell>
                        )) }
                        </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={ this.onDragEnd }>
                    <Droppable droppableId='droppable'>
                        { (provided, snapshot) => (
                            <TableBody
                                ref={ provided.innerRef }
                                { ...provided.droppableProps }
                            >
                                { list.map((item, index) => (
                                    <Draggable key={ item.id } draggableId={ item.id } index={ index }>
                                        { (provided, snapshot) => (
                                            <TableRow
                                                className='table-row'
                                                ref={ provided.innerRef }
                                                { ...provided.draggableProps }
                                                { ...provided.dragHandleProps }
                                                style={ this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                ) }
                                            >
                                                { fieldData.map((field, index) => (
                                                    <TableCell key={ index } className={ `app-table-cell pl-0 ${ index > 0 ? 'fourth-table-width' : 'half-table-width' }` }>
                                                        { index === 0 ? <FontAwesomeIcon icon='fa-solid fa-grip-dots-vertical' size='1x' className='pr-3 pt-2' /> : null }
                                                        { this.renderTableCell(field, item) }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ) }
                                    </Draggable>
                                )) }
                                { provided.placeholder }
                            </TableBody>
                        ) }
                    </Droppable>
                </DragDropContext>
            </Table>
        );
    }
}

export default DraggableTable;
