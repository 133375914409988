import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));


export default class promotionsAPI {
    static getPromoCodes(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/coupons/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursivePromoCodes(params, data) {
        store.updateLoading(true);
        let response = await this.getPromoCodes(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursivePromoCodes(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updatePromoCodeList(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static updatePromoCode(promo_code_id, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/coupons/" + promo_code_id + "/", {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updatePromoCode(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static createPromoCode(data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/coupons/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    this.getRecursivePromoCodes({ "page": 1 }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static deletePromoCode(promo_code_id) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/coupons/" + promo_code_id + "/", {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(response => {
                    _.remove(store.currentPromoCodes, { 'id': promo_code_id });
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getSales(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/sales/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveSales(params, data) {
        store.updateLoading(true);
        let response = await this.getSales(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveSales(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateSalesList(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static updateSale(sale_id, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/sales/" + sale_id + "/", {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static postSale(data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/sales/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

}