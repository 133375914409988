import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';

import { errorMessage, fieldError } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import tenantAPI from '../../services/api/tenantAPI';
import AdminToggle from '../elements/adminToggle';
import ImageCard from '../imageCard';
import AdminSectionHeader from '../elements/adminSectionHeader';
import AdminColorPicker from '../elements/adminColorPicker';
import AdminDropdownField from '../elements/adminDropdownField';
import { Text } from '../elements/text';


class ThemeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            apiErrors: {},
            error: '',
            theme: this.props.theme,
            logo: {
                fileUrl: '',
                file: null
            },
            icon: {
                fileUrl: '',
                file: null
            },
            splash: {
                fileUrl: '',
                file: null
            },
        }
    };

    componentWillReceiveProps = nextProps => {
        if (this.props.theme.id !== nextProps.theme.id) {
            let theme = _.cloneDeep(nextProps.theme);
            this.setState({ theme });
        }
    };

    handleChange = event => {
        let { theme } = this.state;
        let value = event.target.value;
        let name = event.target.name;

        theme[name] = value;
        this.setState({ theme });
    };

    handleChangeColors = (event, color) => {
        let { theme } = this.state;
        let name = color;
        let value = event.hex;

        theme[name] = value;
        this.setState({ theme });
    };

    handleSubmit = event => {
        event.preventDefault();
        const { theme, logo, icon, splash } = this.state;
        if (!theme.id) {
            if (!logo.fileUrl || !icon.fileUrl || !splash.fileUrl) {
                this.setState({ error: 'All images are required.' });
                return;
            }
        }
        let payload = {
            name: theme.name,
            is_default: Boolean(theme.is_default),
            is_active: Boolean(theme.is_active),
            is_online: Boolean(theme.is_online),
            header_text_opacity: theme.header_text_opacity || 100,
            primary_color: theme.primary_color || '#000000',
            header_text_color: theme.header_text_color || '#000000',
            text_color: theme.text_color || '#000000',
            heading_text_color: theme.heading_text_color || '#000000',
            primary_button_color: theme.primary_button_color || '#000000',
            primary_button_text_color: theme.primary_button_text_color || '#000000',
            secondary_button_color: theme.secondary_button_color || '#000000',
            secondary_button_text_color: theme.secondary_button_text_color || '#000000'
        }
        let data = new FormData();
        for (var key in payload) {
            data.append(key, payload[key]);
        };
        if (logo.fileUrl) {
            data.append('splash_logo', logo.file);
        };
        if (icon.fileUrl) {
            data.append('app_icon', icon.file);
        };
        if (splash.fileUrl) {
            data.append('splash_background', splash.file);
        };
        if (theme.id) {
            tenantAPI.updateTheme(theme.id, data)
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.props.onClose(true);
                    }
                });
        } else {
            tenantAPI.createTheme(data)
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.props.onClose(true);
                    }
                });
        }
    };

    toggleSettings = name => {
        let { theme } = this.state;
        theme[name] = !theme[name];
        this.setState({ theme });
    };

    handleClose = updated => {
        this.setState({
            apiErrors: {},
            error: '',
            logo: {
                delete_image: false,
                fileUrl: '',
                file: null
            },
            icon: {
                delete_image: false,
                fileUrl: '',
                file: null
            },
            splash: {
                delete_image: false,
                fileUrl: '',
                file: null
            }
        });
        this.props.onClose(updated);
    };

    deleteTheme = themeID => {
        tenantAPI.deleteTheme(themeID)
            .then(() => {
                this.handleClose(true);
            });
    };

    updateLogo = (fileUrl, file) => {
        let logo = {
            fileUrl: fileUrl,
            file: file,
        };
        this.setState({ logo });
    };

    updateIcon = (fileUrl, file) => {
        let icon = {
            fileUrl: fileUrl,
            file: file,
        };
        this.setState({ icon });
    };

    updateSplash = (fileUrl, file) => {
        let splash = {
            fileUrl: fileUrl,
            file: file,
        };
        this.setState({ splash });
    };

    render() {
        const { theme, apiErrors, error, logo, icon, splash } = this.state;
        const edit = Boolean(theme.id);

        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ this.handleClose }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={`${edit ? 'Edit' : 'Add'} Theme` }
                    onClose={ this.handleClose }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(apiErrors, error) }
                    deleteButton={ edit }
                    buttonAction={ () => this.deleteTheme(theme.id) }
                    buttonText='Delete Theme'
                >
                    <AdminCard
                        style={ { marginTop: "15px" } }
                        title='Theme Name'
                    >
                        <input
                            className={ `app-input-field mt-0 ${ fieldError('name', apiErrors) ? 'red-border' : '' }` }
                            id='name'
                            name='name'
                            type='text'
                            value={ theme.name || '' }
                            onChange={ (event) => this.handleChange(event) }
                        />
                        { fieldError('name', apiErrors) }
                    </AdminCard>
                    <AdminCard
                        style={ { marginTop: "15px" } }
                        title='Set As Default Theme'
                        description='You can only have one default theme. The default theme will be used if no Mobile App theme is available.'
                    >
                        <AdminToggle
                            className='app-toggle'
                            checked={ Boolean(theme.is_default) }
                            checkedLabel='Default'
                            unCheckedLabel='Not Default'
                            onChange={ () => this.toggleSettings('is_default') }
                        />
                    </AdminCard>
                    <AdminCard
                        style={ { marginTop: "15px" } }
                        title='Set As Active Mobile App Theme'
                        description={ <span>You can only have one active mobile app theme at a time. <span className='bold'>NOTE: Mobile app themes will not be active immediately. To activate your new mobile app theme, please contact Customer Support at support@ordrslip.com.</span></span> }

                    >
                        <AdminToggle
                            className='app-toggle'
                            checked={ Boolean(theme.is_active) }
                            checkedLabel='Active'
                            unCheckedLabel='Not Active'
                            onChange={ () => this.toggleSettings('is_active') }
                        />
                    </AdminCard>
                    <AdminCard
                        style={ { marginTop: "15px" } }
                        title='Set As Online Ordering Theme'
                        description='To use this theme for your online ordering page, enable this setting. You can only have one online ordering theme. The active mobile app theme will be used if no online ordering theme is available.'
                    >
                        <AdminToggle
                            className='app-toggle'
                            checked={ Boolean(theme.is_online) }
                            onChange={ () => this.toggleSettings('is_online') }
                        />
                    </AdminCard>
                    <AdminCard
                        style={ { marginTop: "15px" } }
                        title='Theme Images'
                        description='Select the images for your theme.'
                    >
                        <div>
                            <AdminSectionHeader
                                title='Logo'
                            />
                            <Text>File Format: png</Text>
                            <Text>Dimensions: 300px by 300px</Text>
                            <ImageCard
                                imgUrl={ !logo.delete_image ? theme.splash_logo : null }
                                inputID='logo'
                                file={ logo.file }
                                fileUrl={ logo.fileUrl }
                                altText='Theme Logo'
                                item={ logo }
                                updateImage={ this.updateLogo }
                                disable_delete
                            />
                        </div>
                        <div style={ { paddingTop: 20 } }>
                            <AdminSectionHeader
                                title='App Icon'
                            />
                            <Text>File Format: png</Text>
                            <Text>Dimensions: 1024px by 1024px</Text>
                            <ImageCard
                                imgUrl={ !icon.delete_image ? theme.app_icon : null }
                                inputID='icon'
                                file={ icon.file }
                                fileUrl={ icon.fileUrl }
                                altText='App Icon'
                                item={ icon }
                                updateImage={ this.updateIcon }
                                disable_delete
                            />
                        </div>
                        <div style={ { paddingTop: 20 } }>
                            <AdminSectionHeader
                                title='Background'
                            />
                            <Text>File Format: png</Text>
                            <Text>Dimensions: 2732px by 2732px</Text>
                            <ImageCard
                                imgUrl={ !splash.delete_image ? theme.splash_background : null }
                                inputID='splash'
                                file={ splash.file }
                                fileUrl={ splash.fileUrl }
                                altText='Theme Background'
                                item={ splash }
                                updateImage={ this.updateSplash }
                                disable_delete
                            />
                        </div>
                    </AdminCard>
                    <AdminCard
                        style={ { marginTop: '15px' } }
                        title='Theme Colors'
                        description='Select the colors for your theme.'
                    >
                        <div>
                            <label className='inputLabel is-uppercase'>Header Text Opacity</label>
                            <AdminDropdownField
                                id='header_text_opacity'
                                name='header_text_opacity'
                                onChange={ (event) => this.handleChange(event) }
                                value={ theme.header_text_opacity || 100 }
                                style={ { width: '100%' } }
                            >
                                <option value={80}>80</option>
                                <option value={85}>85</option>
                                <option value={90}>90</option>
                                <option value={95}>95</option>
                                <option value={100}>100</option>
                            </AdminDropdownField>
                            { fieldError('header_text_opacity', apiErrors) }
                        </div>
                        <AdminColorPicker
                            title='Primary Color'
                            description='Color of the header and footer background.'
                            colorValue={ theme.primary_color }
                            // colorValue={ this.getHexValue(theme.primary_color) }
                            onChange={ (event) => this.handleChangeColors(event, 'primary_color') }
                            fieldError={ fieldError('primary_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Header Text Color'
                            description='Color of the header and footer text.'
                            colorValue={ theme.header_text_color }
                            onChange={ (event) => this.handleChangeColors(event, 'header_text_color') }
                            fieldError={ fieldError('header_text_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Text Color'
                            description='Color of the text.'
                            colorValue={ theme.text_color }
                            onChange={ (event) => this.handleChangeColors(event, 'text_color') }
                            fieldError={ fieldError('text_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Heading Text Color'
                            description='Color of the title text.'
                            colorValue={ theme.heading_text_color }
                            onChange={ (event) => this.handleChangeColors(event, 'heading_text_color') }
                            fieldError={ fieldError('heading_text_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Primary Button Color'
                            description='Color of the large buttons.'
                            colorValue={ theme.primary_button_color }
                            onChange={ (event) => this.handleChangeColors(event, 'primary_button_color') }
                            fieldError={ fieldError('primary_button_text_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Primary Button Text Color'
                            description='Color of the text on large buttons.'
                            colorValue={ theme.primary_button_text_color }
                            onChange={ (event) => this.handleChangeColors(event, 'primary_button_text_color') }
                            fieldError={ fieldError('primary_button_text_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Secondary Button Color'
                            description='Color of the small buttons.'
                            colorValue={ theme.secondary_button_color }
                            onChange={ (event) => this.handleChangeColors(event, 'secondary_button_color') }
                            fieldError={ fieldError('secondary_button_color', this.state.apiErrors) }
                        />
                        <AdminColorPicker
                            title='Secondary Button Text Color'
                            description='Color of the text on small buttons.'
                            colorValue={ theme.secondary_button_text_color }
                            onChange={ (event) => this.handleChangeColors(event, 'secondary_button_text_color') }
                            fieldError={ fieldError('secondary_button_text_color', this.state.apiErrors) }
                        />
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        )
    }
}

export default observer(ThemeEdit);