import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';

import store from '../../store/store';
import {logout} from "../../services/utils";
import integrationsAPI from '../../services/api/integrationsAPI';
import tenantAPI from '../../services/api/tenantAPI';
import websocketClient from "../../services/websocketClient"
import AdminSearchInput from "../elements/adminSearchInput";
import EditDrawer from '../elements/editDrawer';
import accountsAPI from '../../services/api/accountsAPI';


class TenantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenants: store.tenants,
            selectedTenant: null,
            searchedTenants: false,
            typing: false,
            typingTimeout: 0
        };
    }

    componentDidMount = () => {
        this.getTenants();
    };
    
    getTenants = () => {
        store.updateTenantSearch('');
        tenantAPI.getRecursiveTenants({ page: 1 })
        .then(() => {
            this.setState({ tenants: store.tenants });
        });
    }

    handleSubmit = () => {
        const selectedTenant = this.state.selectedTenant;
        if (selectedTenant) {
            logout(this.props, false);
            tenantAPI.getTenant(selectedTenant.id);
            store.updateTenantId(selectedTenant.id);
            tenantAPI.getPosStatus().then(
              data => {
                store.updatePosStatus(data.status);
              });
            tenantAPI.getRecursiveLocations({ page: 1 });
            accountsAPI.getRecursiveUsers({ page: 1 });
            integrationsAPI.getRecursiveFulfillments({ page: 1 });
            // Open a new websocket
            store.updatewebsocketShouldReopen(true);
            websocketClient.openWebsocket();
            store.updateShowTenantList(false);
            store.updateLoggedIn(true);
        }
    }

    handleClose = () => {
        const loggedIn = store.loggedIn.get();
        if (!loggedIn) {
            logout(this.props);
        }
        store.updateShowTenantList(false);

    }

    getApiSearch(query) {
        if (!this.state.searchedTenants) {
          this.getTenants();
        } else {
            tenantAPI.searchTenants(query)
                .then(response => {
                this.setState({ tenants: response.data, currentPage: 0 });
                });
        }
      }

    searchTenants = query => {
        store.updateTenantSearch(query);
        let self = this;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            typing: false,
            typingTimeout: setTimeout(function () {
                self.getApiSearch(query);
            }, 500),
            searchedTenants: query !== '',
        });
    }

    render () {
        const {
            tenants,
            selectedTenant,
          } = this.state;

        console.log(selectedTenant)
        return (
            <Drawer
                anchor='right'
                open={ true }
                onClose={ () => this.handleClose }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={`Select a Tenant`}
                    onSubmit={ this.handleSubmit }
                    onClose={ this.handleClose }
                    submitText={"Select Tenant"}
                >
                <AdminSearchInput
                    value={ store.tenantSearch }
                    onChange={ this.searchTenants }
                    style={ { paddingBottom: 12 } }
                />
                    {tenants.length ?
                        <List>
                            {tenants.map((tenant) => {
                                return (
                                    <div
                                        key={tenant.id}
                                        onClick={() => {this.setState({selectedTenant: tenant})}}
                                        className={`paragraph cat-items ${selectedTenant && selectedTenant.id == tenant.id ? 'category_active': ''}`}>
                                        {tenant.name}
                                    </div>
                                )
                            })}
                        </List>
                    : null}
                </EditDrawer> 
            </Drawer>
        );
    }
}


export default withRouter(observer(TenantList));
