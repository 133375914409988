import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store/store";
import ls from "local-storage";
import websocketClient from "./services/websocketClient"
import integrationsAPI from './services/api/integrationsAPI';
import tenantAPI from './services/api/tenantAPI';
import accountsAPI from './services/api/accountsAPI';

//   Update user from local storage
if (!store.user.id && ls.get("user") && ls.get("token")) {
  let token = ls.get("token");
  let user = JSON.parse(ls.get("user"));
  store.updateUserToken(token);
  store.updateUser(user[0]);
  // Update tenant from local storage
  let tenant_id = ls.get("tenant_id");
  store.updateTenantId(tenant_id)
  tenantAPI.getTenants().then((data) => {
    if (!data["errors"]){
      store.updateIsAdmin(true);
    }
  })
  if (tenant_id) {
    store.updateLoading(true);
    tenantAPI.getTenant(tenant_id).then(() => {
      accountsAPI.getRecursiveUsers({ page: 1 });
      tenantAPI.getRecursiveLocations({ page: 1 })
        .then(integrationsAPI.getRecursiveFulfillments({ page: 1 })
          .then(() => store.updateLoading(false)))
      // Open a new websocket
      websocketClient.openWebsocket();
    });
  }
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
