import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from 'lodash';

import store from "../store/store";
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from "../components/elements/adminPageHeader";
import { Button } from '../components/elements/buttons';
import PromoCodeDrawer from "../components/promo/promoCodeDrawer";
import tenantAPI from '../services/api/tenantAPI';
import menuAPI from '../services/api/menuAPI';
import promotionsAPI from '../services/api/promotionsAPI';
import accountsAPI from '../services/api/accountsAPI';


class PromoCodeList extends Component {
  state = {
    open: false,
    inFocusPromo: {},
    drawerType: ''
  };

  componentWillMount = () => {
    if (!store.promoCodeList.length) promotionsAPI.getRecursivePromoCodes({ page: 1 });
    if (!store.locations.length) tenantAPI.getRecursiveLocations({ page: 1 });
    if (!store.categories.length) menuAPI.getRecursiveCategories({ page: 1 });
    if (!store.items.length) menuAPI.getRecursiveItems({ page: 1 });
    if (!store.users.length) accountsAPI.getRecursiveUsers({ page: 1 });
  };

  toggleDrawer(drawerType) {
    this.setState({ drawerType, open: !this.state.open });
  };

  handleDialogClose = updated => {
    this.toggleDrawer('');
    if (updated) {
      promotionsAPI.getRecursivePromoCodes({ page: 1 });
    }
  }

  getDiscountAmount(promoCode) {
    let discount = promoCode.percent_off && promoCode.percent_off > 0 ? `${promoCode.percent_off}%` : promoCode.amount_off.formatted;
    return `${discount} Off`;
  }

  render() {
    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="Promo Codes"
          mainNav="Marketing"
          subNav="Promotions"
          description="All your promo codes are shown here."
          headerActionText="Add New"
          onHeaderActionClick={() => this.toggleDrawer('create')}
        />
        <AdminCard
          title="Active Promo Codes"
          description="Promo codes that are currently active in your app and/or online ordering."
        >
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0" style={{ width: 250 }}>Code Name</TableCell>
                  <TableCell className="app-table-cell" style={{ width: 250 }}>Code</TableCell>
                  <TableCell className="app-table-cell">Discount Amount</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
              { store.currentPromoCodes.length ? store.currentPromoCodes.map(promoCode => (
                  <TableRow
                    key={ promoCode.id }
                    className={ `app-table-row ${ this.state.inFocusPromo.id === promoCode.id ? "highlighted-row" : "" }` }
                    onMouseOver={ () => this.setState({ inFocusPromo: promoCode }) }
                  >
                    <TableCell className="app-table-cell pl-0" style={ { width: 250 } }>
                      {promoCode.name}
                    </TableCell>
                    <TableCell className="app-table-cell" style={ { width: 250 } }>
                      {promoCode.code}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {this.getDiscountAmount(promoCode)}
                    </TableCell>
                    <TableCell className="app-table-cell set-width">
                      {this.state.inFocusPromo.id === promoCode.id ?
                        <Button
                          edit
                          onClick={() => this.toggleDrawer('edit')}
                        >
                          Edit
                        </Button>
                        : null}
                    </TableCell>
                    </TableRow>
              )) :
                <TableRow>
                  <TableCell colSpan={ 4 }>There aren't any promo codes yet.</TableCell>
                </TableRow>
              }
              </TableBody>
            </Table>
        </AdminCard>
        <AdminCard
          title="Expired Promo Codes"
          description="Promo codes that have expired in your app and/or online ordering. (These are not editable.)"
        >
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0" style={ { width: 250 } }>Code Name</TableCell>
                  <TableCell className="app-table-cell" style={ { width: 250 } }>Code</TableCell>
                  <TableCell className="app-table-cell">Discount Amount</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
              { store.expiredPromoCodes.length ? store.expiredPromoCodes.map(promoCode => (
                <TableRow
                  key={ promoCode.id }
                  className={ `app-table-row ${ this.state.inFocusPromo.id === promoCode.id ? "highlighted-row" : "" }` }
                  onMouseOver={ () => this.setState({ inFocusPromo: promoCode }) }
                >
                  <TableCell className="app-table-cell pl-0" style={ { width: 250 } }>
                    { promoCode.name }
                  </TableCell>
                  <TableCell className="app-table-cell" style={ { width: 250 } }>
                    { promoCode.code }
                  </TableCell>
                  <TableCell className="app-table-cell">
                    { this.getDiscountAmount(promoCode) }
                  </TableCell>
                  <TableCell className="app-table-cell set-width">
                    { this.state.inFocusPromo.id === promoCode.id ?
                      <Button
                        edit
                        onClick={ () => this.toggleDrawer('view') }
                      >
                        View
                      </Button>
                      : null }
                  </TableCell>
                </TableRow>
              )) :
                <TableRow>
                  <TableCell colSpan={ 4 }>There aren't any expired promo codes yet.</TableCell>
                </TableRow>
              }
              </TableBody>
            </Table>
        </AdminCard>
        <PromoCodeDrawer
          open={this.state.open}
          onClose={this.handleDialogClose}
          promoCode={_.cloneDeep(this.state.inFocusPromo)}
          drawerType={this.state.drawerType}
        />
      </div>
    );
  }
}

export default withRouter(observer(PromoCodeList));