import React, { Component } from "react";
import { observer } from "mobx-react";
import AdminCard from '../elements/adminCard';
import HoursInputRow from '../elements/hoursInputRow';
import _ from 'lodash';
import moment from "moment";
import { parseHours } from "../../services/utils"
import AdminCheckbox from "../elements/adminCheckbox";
import { RadioGroup } from "@material-ui/core";
import RadioLabel from "../elements/radioLabel";
import { ErrorOutline } from "@material-ui/icons";

class PromoHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDays: [],
            timeframe: 'all',
            hours: props.hours,
        };
    };

    componentDidMount = () => {
        const { hours } = this.props;
        let selectedDays = [];
        let timeframe = 'all';
        if (hours) {
            hours.map((hour) => selectedDays.push(hour.weekday));
            if (hours.length) timeframe = 'custom';
            this.setState({
                selectedDays,
                timeframe,
            });
        }
    }

    handleChange = event => {
        // Combine time and am/pm fields into timestamp
        let hours = this.props.hours;
        let value = event.target.value;
        let name = event.target.name;
        let id = event.target.id;
        let day = _.find(hours, { weekday: id });
        if (name.includes('_timestamp')) {
            // Time was updated
            name = name.replace('_timestamp', '');
            let amPm = name.includes('start') ? 'AM' : 'PM';
            if (day[name] && !day[name].includes('x')) {
                amPm = parseHours(day[name], true).toUpperCase();
            }
            if (!value.includes('x')) {
                value = moment(`${ value }:00 ${ amPm }}`, 'hh:mm:ss a').format('HH:mm:ss');
            }

        } else if (name.includes('_ampm')) {
            // AM/PM was updated
            name = name.replace('_ampm', '');
            let time = day[name] ? parseHours(day[name]) : '00:00';
            value = moment(`${ time }:00 ${ value }}`, 'hh:mm:ss a').format('HH:mm:ss');
        }
        day[name] = value;
        this.setState({ hours });
        this.props.updateHours(hours, this.state.selectedDays, this.state.timeframe);
    };

    fieldError = (day) => {
        // Errors are returned in an array, we must match the index to the index
        // of the day in the saved payload to show the error message on the correct row
        if (this.props.apiErrors.hasOwnProperty(`${ (this.props.hoursType).toLowerCase() }_hours`)) {
            if (this.props.payload.length > 0) {
                let dayIndex = this.props.payload.findIndex(item => {
                    return item.weekday === day;
                });
                if (dayIndex > -1) {
                    let error = this.props.apiErrors.store_hours[dayIndex];
                    if (error.message) {
                        let msg = JSON.parse(error.message).__all__;
                        if (msg) {
                            return (
                                <div className="red-error-text" style={ { marginTop: -12 } }>
                                    { msg[0].message }
                                </div>
                            );
                        }
                    }
                }
            }
        }
        return null;
    };

    toggleSelected = (day) => {
        const { selectedDays } = this.state;
        const { hours, updateHours } = this.props;
        if (selectedDays.includes(day)) {
            selectedDays.splice(selectedDays.indexOf(day), 1);
            this.setState({ selectedDays });
            updateHours(hours, selectedDays, this.state.timeframe);
        } else {
            let match = hours.map(e => e.weekday).indexOf(day);
            hours[match]['start_time'] = '00:00';
            hours[match]['end_time'] = '23:59';
            selectedDays.push(day);
            this.setState({ selectedDays });
            updateHours(hours, selectedDays, this.state.timeframe);
        }
    }

    updateTimeFrame = (event) => {
        let { hours } = this.state;
        let timeframe = event.target.value;
        this.setState({ timeframe });
        if (timeframe === 'all') {
            hours.forEach((hour) => {
                hour.start_time = '00:00';
                hour.end_time = '23:59';
            });
        }
        this.props.updateHours(hours, this.state.selectedDays, timeframe);
    }

    toggleAllDay = (day) => {
        let { hours } = this.props;
        hours = _.uniqBy(hours, 'weekday');
        let match = hours.map(e => e.weekday).indexOf(day);
        if (this.isChecked(hours[match])) {
            hours[match]['start_time'] = hours[match]['end_time'] = null;
        } else {
            hours[match]['start_time'] = '00:00';
            hours[match]['end_time'] = '23:59';
        }
        this.props.updateHours(hours, this.state.selectedDays, this.state.timeframe);
    }

    isChecked = (day) => {
        return ["00:00:00", "00:00"].includes(day.start_time) && ["23:59:00", "23:59"].includes(day.end_time);
    }

    render() {
        const { apiErrors, hours, isSale } = this.props;
        const { selectedDays } = this.state;
        const promoWord = isSale ? "sale" : "promo code";

        return (
            <AdminCard
                title={isSale ? "Sale Availability" : "Promo Code Availability"}
                description={`Choose the day(s) and hours when this ${promoWord} will be valid. This ${promoWord} will only apply to orders placed during the selected hours.`}
                style={{ marginTop: -40 }}
            >
                {apiErrors['coupon_hours'] && (
                  <div className="form-error-message display-in-line center-items">
                    <ErrorOutline className="error-icon" />
                    Please check your hours
                  </div>
                )}
                <div className="app-field-label" style={{ marginTop: "15px" }}>
                    Choose what time the {promoWord} will apply to
                </div>
                <RadioGroup
                    name="time"
                    value={ this.state.timeframe }
                    onChange={ (event) => this.updateTimeFrame(event) }
                    className="radio-group"
                >
                    <RadioLabel
                        value='all'
                        label='Anytime'
                    />
                    <RadioLabel
                        value='custom'
                        label='Custom'
                    />
                </RadioGroup>
                {this.state.timeframe === 'custom' ? 
                <div>
                    <div className="app-field-label" style={{ marginTop: "15px" }}>
                        Choose what days the {promoWord} will apply to
                    </div>
                    {hours.map((day, index) => 
                    <div style={{ display: 'flex', flexDirection: 'column' }} key={ index }>
                        <div className='columns is-mobile is-vcentered mb-0' >
                            <div className='column is-narrow p-0'>
                                <AdminCheckbox
                                    checked={ selectedDays.includes(day.weekday) }
                                    onChange={ () => this.toggleSelected(day.weekday) }
                                    style={ { padding: '5px 12px' } }
                                />
                            </div>
                            <div className='column is-narrow pt-0 pb-0'>
                                { day.weekday }
                            </div>
                        </div>
                        {selectedDays.includes(day.weekday) ?
                            <React.Fragment key={ index }>
                                <HoursInputRow
                                    index={ day.weekday }
                                    openTime={ day.start_time }
                                    closeTime={ day.end_time }
                                    onChange={ this.handleChange }
                                    hoursType={ day.weekday }
                                    fromName="start_time"
                                    toName="end_time"
                                    closedTitle="All Day"
                                    toggleChecked={ this.toggleAllDay }
                                    closedPlaceHolder="End"
                                    openPlaceHolder="Start"
                                    hasError={ Boolean(this.fieldError(day.weekday)) }
                                    checkedValue={ this.isChecked(day) }
                                >
                                    { this.fieldError(day.weekday) }
                                </HoursInputRow>
                            </React.Fragment>
                        : null}
                    </div>
                    )}
                </div> : null}
            </AdminCard>
        );
    }
}

export default observer(PromoHours);
