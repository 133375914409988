import styled from "styled-components";
import { colors } from "../../styles/global";

export const Button = styled.button`
    margin: ${props => 
        props.noPadding || props.delete || props.edit ? "0" : 
        "0 15px 0 0"
    };
    padding: ${props =>
        props.primary || props.secondary ? "5px 15px" :
        props.noPadding || props.delete ? "5px 0 2px 0" :
        props.edit ? "0" : "5px 16px 2px 16px;"
    };
    border-radius: 100px;
    font-size: 14px;
    font-family: 'MulishBold', sans-serif;
    cursor: pointer;
    background-color: ${props => 
    props.primary ? colors.ordrslipRed : props.delete || props.edit ? 'transparent' : colors.white
    };
    color: ${props => 
        props.primary ? colors.white : 
        props.edit ? colors.secondaryBlue : colors.ordrslipRed
    };
    border: 2px solid ${props => 
        props.noBorder || props.delete || props.edit ? 'transparent' : 
        colors.ordrslipRed
    };
    cursor: pointer;
`

export const WideButton = styled(Button)`
    margin: 0;
    padding: 8px 60px;
    font-size: 13px;
    background-color: ${props => 
        props.success ? colors.brightGreen : 
        colors.white
    };
    color: ${props => 
        props.success ? colors.white : 
        props.disabled ? colors.disabledGrey :
        colors.ordrslipRed
    };
    border: none;
`

export const TableRowButton = styled.button`
    color: ${colors.secondaryBlue};
    font-size: 12px;
    font-family: 'SofiaProBlack', sans-serif;
    background-color: transparent;
    border: transparent;
`
