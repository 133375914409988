import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { observer } from "mobx-react";

class PosConnectionSuccess extends Component {
  state = {
  };
  
  handleDialogClose = response => {
    this.props.onClose(response);
  };

  render() {
    const { classes, onClose, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleDialogClose}
        aria-labelledby="pos-connection-error"
        {...other}
      >
        <div className="app-card" style={{ marginTop: 0 }}>
          <div className="app-card-header">
            <div className="app-card-header-text">
              <div className="app-card-header-title">POS Connection Successful</div>
            </div>
          </div>
          <div className="app-card-content-wrapper">
            <div className="app-card-red-text" style={{ marginTop: 10 }}><span style={{ fontWeight: 700 }}>Success!</span> Ordrslip can now access your POS data and customers can place orders.</div>
          </div>
          <div className="app-card-actions-wrapper center">
            <button className="app-button primary" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default observer(PosConnectionSuccess);
