import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colors } from '../styles/global';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    color: colors.errorRed
  }
});

function LoadingSpinner(props) {
  const { classes, size, thickness } = props;
  return (
    <div>
      <CircularProgress className={classes.progress} size={size} thickness={thickness} />
    </div>
  );
}

LoadingSpinner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingSpinner);
