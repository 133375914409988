import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { observer } from "mobx-react";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import RadioGroup from "@material-ui/core/RadioGroup";
import _ from "lodash";
import moment from "moment";

import store from "../../store/store";
import { errorMessage, fieldError, parseHours } from "../../services/utils";
import AdminCard from "../elements/adminCard";
import AdminDropdownField from "../elements/adminDropdownField";
import AdminSearchInput from "../elements/adminSearchInput";
import EditDrawer from '../elements/editDrawer';
import HoursInput from "../elements/hoursInput";
import RadioLabel from "../elements/radioLabel";
import { HeaderText, Text } from "../elements/text";
import messagesAPI from '../../services/api/messagesAPI';

const reachOptions = [
  {
    value: '{"user_type":"active","user_days":"365"}',
    label: "All Customers of a Location",
  },
  {
    value: '{"user_type":"active","user_days":"90"}',
    label: "Recent customers of a Location (made a purchase in the last 90 days)",
  },
  // {
  //   value: '{"user_type":"registered","user_days":"All"}',
  //   label: "All signups",
  // },
  {
    value: '{"user_type":"registered","user_days":"90"}',
    label: "Recent signups (signed up in the last 90 days)",
  },
]

class BroadcastsEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: this.props.message,
      apiErrors: {},
      error: '',
      broadcast_date: this.props.message.broadcast_date || moment().add(10, "m").toDate(),
      searchText: "",
      selectedLocation: this.props.message.location || "",
      searchResults: [],
      selected: "",
      reach: this.props.message.user_type ? JSON.stringify({
        user_type: this.props.message.user_type,
        user_days: this.props.message.user_days
      }) : reachOptions[0].value,
      broadcast: moment(this.props.message.broadcast_date || moment().add(10, "m")).format('HH:mm'),
      sms_message: this.props.message.message
    };
  }

  deleteMessage = () => {
    messagesAPI.deleteBroadcastMessage(this.props.message.id)
      .then(() => this.props.onClose(true));
  };

  getLocationOptions = () => {
    let locationOptions = [];
    _.map(store.locations, location => {
      if (location.is_enabled)
        locationOptions.push({ value: location.id, label: location.name });
    });
    return locationOptions;
  };

  addOrUpdateMessage = () => {
    const { message } = this.props;
    const { broadcast_date, broadcast, sms_message, selectedLocation, reach } = this.state;
    let date = moment(broadcast_date).format('YYYY-MM-DD');
    let body = {
      broadcast_date: _.replace(moment(`${date} ${broadcast}`).utc().format(), 'Z', ''),
      message: sms_message,
    };
    if (selectedLocation) {
      body['location'] = selectedLocation;
    } else if (!selectedLocation && JSON.parse(reach).user_type === "active") {
      let apiErrors = {
        'location': [{ message: 'You must choose at least one location.' }]
      };
      this.setState({ apiErrors });
      return;
    } else {
      body['location'] = "";
    }
    _.assign(body, JSON.parse(reach));

    if (message.id) {
      messagesAPI.updateBroadcastMessage(message.id, JSON.stringify(body))
        .then(response => {
          if (!response.non_field_errors && !response.errors) {
            this.props.onClose(true);
          } else {
            this.setState({ apiErrors: response.errors });
          }
        })
    } else {
      body['broadcast_type'] = "later";
      messagesAPI.createBroadcastMessage(JSON.stringify(body))
        .then(response => {
          if (!response.errors) {
            this.props.onClose(true);
          } else {
            this.setState({ apiErrors: response.errors });
          }
        })
    }
  };

  updateState = (type, value) => {
    let body = {};
    body[type] = value;
    if (type === 'sms_message') {
      if (value.length > 160) {
        this.setState({
          apiErrors:
          {
            message: [{
              message: "You have exceeded the 160 character limit.",
              code: "message"
            }]
          }
        });
        return;
      } else {
        this.setState({ apiErrors: {} });
      }
    }
    this.setState({ apiErrors: {} });
    this.setState(body);
  };

  handleChange = event => {
    let name, value = '';
    this.setState({ apiErrors: {} });
    if (event.target) {
      value = event.target.value;
      name = event.target.name;
      // Combine time and am/pm fields into timestamp
      if (name.includes('_time')) {
        // Time was updated
        name = name.replace('_time', '');
        let amPm = 'AM';
        if (this.state[name] && !this.state[name].includes('x')) {
          amPm = parseHours(this.state[name], true).toUpperCase();
        }
        if (!value.includes('x')) {
          value = moment(`${value}:00 ${amPm}}`, 'hh:mm:ss a').format('HH:mm:ss');
        }
      } else if (name.includes('_am')) {
        // AM/PM was updated
        name = name.replace('_am', '');
        let time = this.state[name] ? parseHours(this.state[name]) : '00:00'
        value = moment(`${time}:00 ${value}}`, 'hh:mm:ss a').format('HH:mm:ss');
      }
    } else {
      // Datepicker has no event.target
      value = event;
      name = 'date';
    }
    this.setState({ [name]: value });
  };

  searchLocations = (text) => {
    this.setState({ searchText: text });
    if (text !== '') {
      const { locations } = store;
      const results = locations.filter(obj => {
        return obj.name.toLowerCase().includes(text.toLowerCase());
      });
      this.setState({ searchResults: results });
    } else {
      this.setState({ searchResults: [] });
    }
  };

  updateSelected = (result) => {
    this.setState({
      searchText: '',
      searchResults: [],
      selectedLocation: result.id,
      apiErrors: {},
    });
  };

  updateSearchState = (state) => {
    this.setState(state);
  };

  removeSelectedLocation = () => {
    this.setState({ selectedLocation: "" });
  };

  updateReach = value => {
    if (JSON.parse(value).user_type === "registered") {
      this.setState({ reach: value, selectedLocation: "" });
    } else {
      this.setState({ reach: value });
    }
  };

  render() {
    const { message, onClose } = this.props;
    const {
      apiErrors,
      broadcast,
      broadcast_date,
      error,
      reach,
      searchResults,
      searchText,
      selected,
      selectedLocation,
      sms_message
    } = this.state;
    const editing = Boolean(message && message.id);

    return (
      <EditDrawer
        fixedFooter={true}
        title={editing ? "Edit SMS Message" : "New SMS Message"}
        onClose={ () => onClose(false) }
        isSubmitting={false}
        onSubmit={ this.addOrUpdateMessage }
        deleteButton={ editing }
        buttonAction={ editing ? this.deleteMessage : null }
        buttonText={ editing ? 'Delete SMS Message' : '' }
        errorMessage={ errorMessage(apiErrors, error) }
      >
        <AdminCard
          title="SMS Message Details"
          description="Limited to one sms message per day."
        >
          <div className="app-field-wrapper">
            <label htmlFor="broadcast_date" className="app-field-label">
              Date and Time
            </label>
            <div className="columns is-mobile display-in-line center-items mb-0">
              <div className="column pb-0">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    disablePast
                    style={{ padding: '8px 16px 0 16px'}}
                    variant="inline"
                    inputVariant="standard"
                    className={ `app-input-field no-padding ${ fieldError("broadcast_date", apiErrors) ? 'red-border' : ''}`}
                    value={broadcast_date}
                    format="MM/dd/YYYY"
                    size="small"
                    InputProps={{
                      disableUnderline: true
                    }}
                    onChange={(event) => this.updateState('broadcast_date', event)}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="is-align-self-flex-end pb-1">at</div>
              <div className="column is-3 pb-0">
                <HoursInput
                  hasError={fieldError("broadcast_date", apiErrors)}
                  id="broadcast_time"
                  name="broadcast_time"
                  value={parseHours(broadcast)}
                  onChange={(event) => this.handleChange(event)}
                  style={{ width: 75, marginTop: 0 }}
                />
              </div>
              <div className="column pl-2 is-3 pb-0">
                <AdminDropdownField
                  id="broadcast_am"
                  name="broadcast_am"
                  onChange={(event) => this.handleChange(event)}
                  value={parseHours(broadcast, true)}
                  className={fieldError("broadcast_date", apiErrors) ? "red-border" : ""}
                >
                  <option>AM</option>
                  <option>PM</option>
                </AdminDropdownField>
              </div>
            </div>
            {fieldError("broadcast_date", apiErrors)}
            <label htmlFor="message" className="app-field-label pt-4">
              Message
            </label>
            <textarea
              rows="2"
              style={{ width: '100%', padding: 10 }}
              className={fieldError("message", apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
              placeholder="160 character limit"
              value={sms_message}
              onChange={(event) => this.updateState('sms_message', event.target.value)}
            />
            {fieldError("message", apiErrors)}
          </div>
        </AdminCard>
        <AdminCard
          title="User Selection (Select One)"
          style={{ marginTop: "15px" }}
          description={""}
        >
          <RadioGroup
            name="reach"
            value={reach}
            onChange={ e => this.updateReach(e.target.value)}
            className="radio-group"
          >
          {reachOptions.map(option => (
            <RadioLabel
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ))}
            { reach && JSON.parse(reach).user_type === "active" ?
            <div className="app-card-section-wrapper pt-4">
              <HeaderText small>
                Location Selection (Select One)
              </HeaderText>
              <Text medium>
                Select the location you wish to send a SMS message for your selected user group.
              </Text>
              <div className="app-field-wrapper pt-2">
                <AdminSearchInput
                  fieldError={fieldError('location', apiErrors)}
                  items={store.locations}
                  onChange={this.searchLocations}
                  removeSelected={this.removeSelectedLocation}
                  searchResults={searchResults}
                  selected={selected}
                  selectedItems={selectedLocation ? [selectedLocation] : []}
                  showResults
                  style={{ paddingBottom: 12 }}
                  updateSelected={this.updateSelected}
                  updateState={this.updateSearchState}
                  value={searchText}
                  singleOption
                />
              </div>
            </div> : null}
          </RadioGroup>
        </AdminCard>
      </EditDrawer>
    )
  }
}

export default observer(BroadcastsEdit);
