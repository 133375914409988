import React, { Component } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { formatDateTimeForView, getLocationNameById } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { Text } from '../elements/text';
import PushNotification from './pushNotification';

class PushNotificationPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  getUserType = () => {
    const { notification, userTypes } = this.props;
    let user_type = _.find(userTypes, (type) => notification.user_type === type.option);
    return user_type.label;
  };

  render() {
    const { notification, onClose, editNotification, sendTest } = this.props;
    const viewOnly = notification.status === 'sent';

    return (
      <EditDrawer
        title='Preview Push Notification'
        onClose={ viewOnly ? onClose : editNotification }
        onSubmit={onClose}
        submitText='Done'
        cancelText='Back'
        noSubmitButton={viewOnly}
      >
        <AdminCard
          title='Push Notification Details'
          style={{ marginTop: '15px' }}
          description=''
        >
          <div className='app-field-wrapper'>
            <label htmlFor='date' className='app-field-label'>
              Date and Time
            </label>
            <Text>{formatDateTimeForView(notification.notification_date)}</Text>
          </div>
          <div className='app-field-wrapper'>
            <label htmlFor='users' className='app-field-label'>
              Users
            </label>
            <Text>{this.getUserType()}</Text>
          </div>
          {notification.locations && notification.locations.length ?
          <div className='app-field-wrapper'>
            <label htmlFor='location' className='app-field-label'>
              Locations
            </label>
              { notification.locations.map(location => (
                <Text key={location}>{getLocationNameById(location)}</Text>
            )) }
          </div> : null}
        </AdminCard>
        <AdminCard
          title='Push Notification Preview'
          style={{ marginTop: '15px' }}
          description=''
          onHeaderActionClick={ viewOnly ? null : sendTest }
          linkText={viewOnly ? '': 'Send Test'}
        >
          <PushNotification notification={ notification } />
        </AdminCard>
      </EditDrawer>
    );
  }
}

export default observer(PushNotificationPreview);
