import React, { Component } from "react";
import withNav from "../components/withNav";
import { Route } from "react-router-dom";
import PromoCodeList from "./promoCodeList";
import { SideNavAndContentContainer } from '../components/elements/navs';
import Sales from "./sales";

class Promotions extends Component {
    render() {
        return (
            <div>
                <SideNavAndContentContainer>
                    <Route path="/promotions/sales" component={ Sales } />
                    <Route path="/promotions/promo-codes" component={ PromoCodeList } />
                </SideNavAndContentContainer>
            </div>
        );
    }
}

export default withNav(Promotions);
