import React, { Component } from "react";
import withNav from "../components/withNav";
import { Route } from "react-router-dom";
import Broadcasts from "./broadcasts";
import Popup from "./popup";
import { SideNavAndContentContainer } from '../components/elements/navs';
import PushNotifications from './pushNotifications';

class Messages extends Component {
  render() {
    return (
      <div>
        <SideNavAndContentContainer>
          <Route path="/messages/broadcast-messages" component={Broadcasts} />
          <Route path="/messages/popup-messages" component={Popup} />
          <Route path="/messages/push-notifications" component={PushNotifications} />
        </SideNavAndContentContainer>
      </div>
    );
  }
}

export default withNav(Messages);
