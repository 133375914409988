import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { errorMessage, fieldError, validatePassword } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import accountsAPI from '../../services/api/accountsAPI';

class UserResestPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            error: '',
            current_password: '',
            new_password: '',
            confirm_password: '',
            passwordError: '',
            confirmPasswordError: ''
        };
    }

    handleChange = event => {
        const value = event.target.value;
        const name = event.target.name;

        // Validate Password
        this.setState({ [name]: value });
        if (name === 'new_password') {
            let { passwordError, lower, upper, number } = validatePassword(value);
            this.setState({
                passwordError: passwordError,
                lower: lower,
                upper: upper,
                number: number,
            });
        } else if (name === 'confirm_password') {
            if (value === this.state.new_password) {
                this.setState({ confirmPasswordError: '' });
            } else {
                this.setState({ confirmPasswordError: "Passwords do not match." });
            }
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        let payload = {
                'current_password': this.state.current_password,
                'password': this.state.new_password
            };
        accountsAPI.updateUser(this.props.user.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                    if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                }
                else {
                    this.props.onClose(true);
                }
            });
    };

    render() {
        const { onClose } = this.props;

        return (
            <EditDrawer
                title='Resest Password'
                onClose={ () => onClose(false) }
                isSubmitting={ false }
                onSubmit={ this.handleSubmit }
                errorMessage={ errorMessage(this.state.apiErrors, this.state.error) }
            >
                <form>
                    <AdminCard title='Reset Password'>
                        <div className='app-field-wrapper'>
                            <label className='app-field-label'>
                                Password
                            </label>
                            <input
                                className={ `app-input-field mt-3 ${ fieldError('password', this.state.apiErrors) ? 'red-border' : '' }` }
                                id='current_password'
                                name='current_password'
                                type='password'
                                value={ this.state.current_password }
                                placeholder='Current Password'
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('password', this.state.apiErrors) }
                            <input
                                className='app-input-field mt-3'
                                id='new_password'
                                name='new_password'
                                type='password'
                                value={ this.state.new_password }
                                placeholder='New Password'
                                onChange={ (event) => this.handleChange(event) }
                            />
                            <div className="password-check">
                                AAA
                                { this.state.upper ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={ ['fal', 'check'] } /> : null }
                                | aaa
                                { this.state.lower ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={ ['fal', 'check'] } /> : null }
                                | 123
                                { this.state.number ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={ ['fal', 'check'] } /> : null }
                            </div>
                            <div className="red-error-text smaller-left-spacing">
                                { this.state.passwordError }
                            </div>
                            <input
                                className='app-input-field mt-3'
                                id='confirm_password'
                                name='confirm_password'
                                type='password'
                                value={ this.state.confirm_password }
                                placeholder='Confirm New Password'
                                onChange={ (event) => this.handleChange(event) }
                            />
                            <div className="red-error-text smaller-left-spacing">
                                { this.state.confirmPasswordError }
                            </div>
                        </div>
                    </AdminCard>
                </form>
            </EditDrawer>
        );
    }
}

export default observer(UserResestPassword);
