import React, { Component } from "react";
import SocialMediaEdit from "./socialMediaEdit";
import AdminCard from '../elements/adminCard';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class SocialMedia extends Component {
  state = {
    open: false
  };

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { location } = this.props;
    return (
      <div>
        <AdminCard
          title="Location Social Media"
          description="Set your location’s social media accounts here."
          onHeaderActionClick={ this.toggleDrawer }
          largeTitle
        >
          <div>
            <Table className="app-table-wrapper">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0" style={{width: 150}}>
                      Platform
                  </TableCell>
                  <TableCell className="app-table-cell">
                      Social Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
                <TableRow className="app-table-row">
                  <TableCell className='app-table-cell pl-0'>Facebook</TableCell>
                  <TableCell>{location.facebook_url}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='app-table-cell pl-0'>Twitter</TableCell>
                  <TableCell>{location.twitter_url}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='app-table-cell pl-0'>Instagram</TableCell>
                  <TableCell>{location.instagram_url}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='app-table-cell pl-0'>Yelp</TableCell>
                  <TableCell>{location.yelp_url}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </AdminCard>
        <SocialMediaEdit
          open={this.state.open}
          onClose={this.toggleDrawer}
          location={ location }
          onUpdate={ this.props.onUpdate }
        />
      </div>
    );
  }
}

export default SocialMedia;
