import styled from "styled-components";
import { colors } from "../../styles/global";

export const Card = styled.div`
    margin-top: 30px;
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 20px;
`

export const CardHeader = styled.div`
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    margin-bottom: ${props => 
        props.noDescription ? "0px" : "18px"
    };
    border-bottom: 1px solid ${props => props.bottomBorder ? 
        colors.disabledGrey : 'transparent'};
`