import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import store from '../store/store';
import AppAppearanceEdit from '../components/appAppearanceEdit';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import CustomTextMsgEdit from '../components/customTextMsgEdit';
import { Text } from '../components/elements/text';
import AdminToggle from '../components/elements/adminToggle';
import tenantAPI from '../services/api/tenantAPI';


class AppAppearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      appTextOpen: false,
      textMessagesOpen: false,
      content: {},
    };
  }

  componentDidMount() {
    this.getContent();
  };

  getContent = () => {
    tenantAPI.getCustomTextFieldData()
      .then(response => {
        this.setState({ content: response, loading: false });
      });
  }

  handleToggle = () => {
    tenantAPI.updateTenant({ login_home_button_enabled: !store.tenant.login_home_button_enabled });
  };

  handleDialogOpen = value => {
    this.setState({ [value]: true });
  };

  handleDialogClose = (updated, value) => {
    this.setState({ [value]: false });
    if (updated) this.getContent();
  };

  getValue(value) {
    let item = _.find(store.customTextOptions, { property: value });
    if (item) {
      const text = item.value.split(' ');
      return (
        <Text>
          { text.map((text) => {
            if (text.startsWith('$')) {
              return (
                <span key={ text } className='bold ordrslipRed'>
                  { text }
                </span>
              );
            }
            return ` ${ text } `;
          }) }
        </Text>
      );
    }
  };

  getSMSCustomText = () => {
    let items = [];
    const properties = [
      'order_confirmation_immediate_pickup_sms',
      'order_confirmation_future_pickup_sms',
      'order_confirmation_immediate_delivery_sms',
      'order_confirmation_future_delivery_sms',
      'order_reminder_pickup_sms',
      'order_reminder_delivery_sms',
      'order_ready_for_pickup_sms',
      'order_out_for_delivery_sms',
      'order_completed_sms',
      'order_cancelled_sms'
    ];
    properties.forEach(prop => {
      let item = _.find(store.customTextOptions, { property: prop });
      items.push(item);
    });

    return items;
  }

  renderTextFields = () => {
    let items = this.getSMSCustomText();
    return (
      items.map(item => (
        <div key={item.property} className='column is-half app-card-section-wrapper'>
          <div className='has-text-weight-bold app-card-section-title'>{ item.name }</div>
          <div className='divider' />
          <div className='pt-2'>{ this.getValue(item.property) }</div>
        </div>
      ))
    );
  };

  getSMSDescription = () => {
    return (
      <div>
        <Text>The text you set here will override the default text for order status messages.</Text>
        <Text><span className='bold ordrslipRed'>$ready_time</span> will show the time that the order is scheduled for.</Text>
        <Text><span className='bold ordrslipRed'>$ready_date</span> will show the date that the order is scheduled for.</Text>
        <Text><span className='bold ordrslipRed'>$contact</span> will show the phone number for the location the order was placed at.</Text>
      </div>
    );
  };

  render() {
    if (store.loading || this.state.loading) return <div />;
    return (
      <div>
        <div className='admin-page-wrapper'>
          <AdminPageHeader
            title='Content'
            mainNav='Configuration'
            subNav='App Customization'
            description='Customize the content of your app to provide a better experience for your customers.'
          />
          <div>
            <div>
              <AdminCard
                title='Show/Hide Login Button on Home screen'
                description={ `Customers will see a more prominent 'Login/Register' button on the home screen of the app. The profile icon in the top-right will still be shown either way.` }
                largeTitle
              >
                <AdminToggle
                  weight='bold'
                  className='app-toggle'
                  checked={ Boolean(store.tenant.login_home_button_enabled) }
                  onChange={ this.handleToggle }
                />
              </AdminCard>
              <AdminCard
                title='Customize In-App Text'
                description='The text you set here will override the corresponding default text in the mobile app.'
                largeTitle
                onHeaderActionClick={ () => this.handleDialogOpen('appTextOpen')}
              >
                <div className='app-card-content-wrapper'>
                  <div className='columns is-mobile is-multiline'>
                    <div className='column is-half app-card-section-wrapper'>
                      <div className='has-text-weight-bold app-card-section-title'>Pickup Order Instructions (Label)</div>
                      <div className='app-card-header-subtext'>
                        Displayed above the Order Instructions text box on the cart screen.
                      </div>
                      <div className='divider' />
                      <div className='pt-2'>{this.getValue('pickup_order_instructions_label')}</div>
                    </div>
                    <div className='column is-half app-card-section-wrapper'>
                      <div className='has-text-weight-bold app-card-section-title'>Order Instructions (Description)</div>
                      <div className='app-card-header-subtext'>
                        Displayed below the Order Instructions text box on the cart screen.
                      </div>
                      <div className='divider' />
                      <div className='pt-2'>{this.getValue('order_instructions_description')}</div>
                    </div>
                    <div className='column is-full app-card-section-wrapper'>
                      <div className='has-text-weight-bold app-card-section-title'>Delivery Order Instructions (Label)</div>
                      <div className='app-card-header-subtext'>
                        Displayed above the Delivery Instructions text box on the cart screen.
                      </div>
                      <div className='divider' />
                      <div className='pt-2'>{this.getValue('delivery_order_instructions_label')}</div>
                    </div>
                    <div className='column is-half app-card-section-wrapper'>
                      <div className='has-text-weight-bold app-card-section-title'>Item Instructions (Label)</div>
                      <div className='app-card-header-subtext'>
                        Displayed above the Item Instructions text box on the item detail screen.
                      </div>
                      <div className='divider' />
                      <div className='pt-2'>{this.getValue('item_instructions_label')}</div>
                    </div>
                    <div className='column is-half app-card-section-wrapper'>
                      <div className='has-text-weight-bold app-card-section-title'>Item Instructions (Description)</div>
                      <div className='app-card-header-subtext'>
                        Displayed below the Item Instructions text box on the item detail screen.
                      </div>
                      <div className='divider' />
                      <div className='pt-2'>{this.getValue('item_instructions_description')}</div>
                    </div>
                  </div>
                </div>
              </AdminCard>
              <AdminCard
                title='Customize Order Status Text Messages'
                description={ this.getSMSDescription() }
                largeTitle
                onHeaderActionClick={ () => this.handleDialogOpen('textMessagesOpen')}
              >
                <div className='app-card-content-wrapper'>
                  <div className='columns is-mobile is-multiline'>
                    {this.renderTextFields()}
                  </div>
                </div>
              </AdminCard>
              <div>
                <AppAppearanceEdit
                  open={ this.state.appTextOpen }
                  content={ this.state.content }
                  onClose={ (updated) => this.handleDialogClose(updated, 'appTextOpen')}
                />
                <CustomTextMsgEdit
                  open={ this.state.textMessagesOpen }
                  content={ this.state.content }
                  onClose={ (updated) => this.handleDialogClose(updated, 'textMessagesOpen') }
                  description={ this.getSMSDescription() }
                  items={ this.getSMSCustomText() }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(AppAppearance);
