import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));

export default class tenantAPI {
    static getTenant(tenant_id) {
        let url = `${ base_url }tenants/${ tenant_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateTenant(data);
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getTenants(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveTenants(params, data) {
        let response = await this.getTenants(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveTenants(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateTenants(new_data);
        }
        return new_data;
    }

    static updateTenant(data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: JSON.stringify((data))
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.errors) {
                        store.updateLoading(false);
                        resolve(response);
                    }
                    else {
                        let data = response.data;
                        store.updateTenant(data);
                        store.updateLoading(false);
                        resolve(response);
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getLocations(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/locations/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveLocations(params, data) {
        store.updateLoading(true);
        let response = await this.getLocations(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveLocations(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateLocations(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static updateLocation(locationId, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/locations/${ locationId }/`;

        return new Promise((resolve, reject) => {
            fetch(url,
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + store.token
                    },
                    body: data
                }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.data) {
                        store.updateSingleLocation(response.data);
                        store.updateLoading(false);
                        resolve(response.data);
                    } else {
                        store.updateLoading(false);
                        resolve(response);
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static searchTenants(searchQuery, page) {
        let url = `${ base_url }tenants/search/?search=${ searchQuery }`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateMenus(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static initiateSyncTenant() {
        return new Promise((resolve, reject) => {
            let url = `${ base_url }tenants/${ store.tenantId }/pos/sync/`;
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: JSON.stringify({})
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    static getSyncTaskId() {
        return new Promise((resolve, reject) => {
            let url = `${ base_url }tenants/${ store.tenantId }/pos/syncs/?inprogress`;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    if (data.length > 0) {
                        store.updateSyncTaskId(data[0].task_id);
                    }
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });

        });
    }

    static getCustomTextFieldData() {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/content/en/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateContent(data);
                    store.updateLoading(false);
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static postCustomTextFieldData(payload) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/content/en/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: payload
            })
                .then(raw_response => {
                    return raw_response.json();
                })
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getTaxes(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/taxes/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateTaxes(response.data);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveTaxes(params, data) {
        store.updateLoading(true);
        let response = await this.getTaxes(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveTaxes(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateTaxes(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static updateTax(taxId, payload) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/taxes/${ taxId }/`;

        return new Promise((resolve, reject) => {
            fetch(url,
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + store.token
                    },
                    body: JSON.stringify(payload)
                }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static squareOAuth() {
        store.updateShowSyncErrorDialog(false);
        store.updateShowSecondaryButton(false);
        store.updateSyncStatus(null);
        store.updateIsSyncing(false);
        window.location.href = `${ config.base_url }tenants/${ store.tenant.id }/pos/${ store.tenant.pos_name }/authorize?redirect=${ config.link_url }rewards/square-loyalty`;
    }

    static getArticles() {
        store.updateLoading(true);
        let url = `${ base_url }articles/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getFees(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/fees/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateFees(response.data);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveFees(params, data) {
        store.updateLoading(true);
        let response = await this.getFees(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveFees(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateFees(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static async updateFee(fee_id, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/fees/${ fee_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async createFee(body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/fees/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getPosStatus() {
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/pos/auth-status/", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static createHoliday(data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/holidays/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    if (response.errors) {
                        let code = Object.keys(response.errors)[0];
                        let error = response.errors[code][0].message;
                        store.updateError(error);
                        store.updateLoading(false);
                        resolve(response);
                    } else {
                        store.updateError(null);
                        store.updateSuccess(true);
                        store.updateLoading(false);
                        resolve(data);
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateHoliday(holiday_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/holidays/${ holiday_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    if (response.errors) {
                        let code = Object.keys(response.errors)[0];
                        let error = response.errors[code][0].message;
                        store.updateError(error);
                        store.updateLoading(false);
                        resolve(response);
                    } else {
                        store.updateError(null);
                        store.updateSuccess(true);
                        store.updateLoading(false);
                        resolve(data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static deleteHoliday(holiday_id) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/holidays/${ holiday_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            }
            )
                .then(response => {
                    if (response.errors) {
                        let code = Object.keys(response.errors)[0];
                        let error = response.errors[code][0].message;
                        store.updateError(error);
                        store.updateLoading(false);
                        resolve(response);
                    } else {
                        store.updateError(null);
                        store.updateSuccess(true);
                        store.updateLoading(false);
                        resolve(response.data);
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getPunchCardSettings() {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/rewards/`;


        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updatePunchCardSettings(data);
                    store.updateLoading(false);
                    resolve(data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updatePunchCardSettings(data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/rewards/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                    this.getPunchCardSettings().then(() => store.updateLoading(false));
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getSquareLoyalty() {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/reward-programs/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    let sq_loyalty = _.find(data, { 'type': 'square_loyalty' });
                    store.updateSquareLoyalty(sq_loyalty);
                    store.updateLoading(false);
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static updateSquareLoyalty(data, rewards_id) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/reward-programs/${ rewards_id }/`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateSquareLoyalty(data);
                    store.updateLoading(false);
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static sendSupportEmail(body) {
        let url = `${ base_url }tenants/${ store.tenantId }/support-emails/`;
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: "POST",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": "Token " + store.token
            },
            body: JSON.stringify(body)
          })
            .then(raw_response => raw_response.json())
            .then(response => {
              resolve(response);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });
      }

    static getReferrals(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/referrals/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateReferrals(response.data);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveReferrals(params, data) {
        store.updateLoading(true);
        let response = await this.getReferrals(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveReferrals(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateReferrals(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static createReferral(data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/referrals/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    this.getRecursiveReferrals({ page: 1 }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static updateReferral(referral_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/referrals/${ referral_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    this.getRecursiveReferrals({ page: 1 }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static deleteReferral(referral_id) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/referrals/${ referral_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            }
            )
                .then(response => {
                    if (response.errors) {
                        let code = Object.keys(response.errors)[0];
                        let error = response.errors[code][0].message;
                        store.updateError(error);
                        store.updateLoading(false);
                        resolve(response);
                    } else {
                        store.updateError(null);
                        store.updateSuccess(true);
                        store.updateLoading(false);
                        resolve(response.data);
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getActiveTheme() {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/themes/active`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateActiveTheme(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static getThemes(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/themes/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveThemes(params, data) {
        store.updateLoading(true);
        let response = await this.getThemes(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveThemes(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateThemes(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static deleteTheme(theme_id) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/themes/${ theme_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(response => {
                    if (response.errors) {
                        let code = Object.keys(response.errors)[0];
                        let error = response.errors[code][0].message;
                        store.updateError(error);
                        store.updateLoading(false);
                        resolve(response);
                    } else {
                        this.getRecursiveThemes({ page: 1 }).then(() => {
                            store.updateError(null);
                            store.updateSuccess(true);
                            store.updateLoading(false);
                            resolve(response);
                        });
                    }
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static createTheme(data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/themes/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static updateTheme(theme_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/themes/${ theme_id }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Authorization: "Token " + store.token
                },
                body: data
            }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }
}