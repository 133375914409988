import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';

import store from './../store/store';
import { errorMessage, fieldError } from './../services/utils';
import AdminCard from './elements/adminCard';
import EditDrawer from './elements/editDrawer';
import LocationSelection from './locationSelection';
import AdminToggle from './elements/adminToggle';
import tenantAPI from '../services/api/tenantAPI';


class FeeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            apiErrors: {},
            error: '',
            fee: this.props.fee,
            selectedLocations: this.props.fee.locations || []
        };
    };

    componentWillReceiveProps = nextProps => {
        if (this.props.fee.id !== nextProps.fee.id) {
            let fee = _.cloneDeep(nextProps.fee)
            this.setState({ fee, selectedLocations: fee.locations || [] });
        }
    };

    updateLocations = locations => {
        this.setState({ selectedLocations: locations });
    };

    handleChange = event => {
        let { fee } = this.state;
        let value = event.target.value;
        let name = event.target.name;
        if (name === 'amount') { value = {
                amount: value,
                currency: store.locations[0].currency
            };
        }

        fee[name] = value;
        this.setState({ fee });
    };

    handleSubmit = event => {
        const { fee, selectedLocations } = this.state;
        const amount = fee.amount ? (fee.amount.amount).toString() : '0';
        if (!selectedLocations.length) {
            this.setState({ error: 'You must choose at least one location.' });
            return;
        }
        if (amount === '0') {
            let { apiErrors } = this.state;
            apiErrors.amount = [{ message: "Amount must be greater than 0", code: 'invalid' }];
            this.setState({ apiErrors });
            return;
        }
        const payload = {
            'name': fee.name,
            'is_enabled': fee.is_enabled || false,
            'locations': selectedLocations,
            'amount': {'amount': amount, 'currency': store.locations[0].currency}
        };
        if (fee.id) {
            tenantAPI.updateFee(this.props.fee.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.props.onClose(true);
                    }
                });
            } else {
                tenantAPI.createFee(JSON.stringify(payload))
                    .then(response => {
                        if (response.errors) {
                            this.setState({ apiErrors: response.errors });
                            if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                        }
                        else {
                            this.props.onClose(true);
                        }
                    });
        }
    };

    toggleEnabled = () => {
        let { fee } = this.state;
        fee.is_enabled = !fee.is_enabled;
        this.setState({ fee });
    };

    handleClose = () => {
        this.setState({ apiErrors: {}, error: '' });
        this.props.onClose(false);
    }

    render() {
        const { apiErrors, error, fee } = this.state;

        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ this.handleClose }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title='Edit Fee'
                    onClose={ this.handleClose }
                    isSubmitting={ false }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(apiErrors, error) }
                >
                    <AdminCard
                        title='Fee Details'
                        style={ { marginTop: '15px' } }
                    >
                        <AdminToggle
                            className='app-toggle'
                            checked={ fee.is_enabled || false }
                            onChange={ this.toggleEnabled }
                        />
                        <div className='app-field-wrapper'>
                            <label htmlFor='full_name' className='app-field-label mt-3'>
                                Fee Name
                            </label>
                            <input
                                className={ `app-input-field mt-0 ${ fieldError('name', apiErrors) ? 'red-border' : '' }` }
                                id='name'
                                name='name'
                                type='text'
                                value={ fee.name || '' }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('name', apiErrors) }
                        </div>
                        <div className='app-field-wrapper'>
                            <div className="app-field-label" style={ { marginTop: "15px" } }>Fee Amount</div>
                            <input
                                className={ `app-input-field mt-0 ${ fieldError(`amount`, apiErrors) ? "red-border" : '' }` }
                                id='amount'
                                name='amount'
                                type="number"
                                placeholder='Fee Amount'
                                step='0.25'
                                value={ fee.amount ? fee.amount.amount : 0 }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            <div>{ fieldError('amount', apiErrors) }</div>
                        </div>
                    </AdminCard>
                    <LocationSelection
                        selectedLocations={ this.state.selectedLocations }
                        itemName={ 'fee' }
                        onUpdateLocations={ this.updateLocations }
                        fieldError={ fieldError('locations', apiErrors) }
                    />
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(FeeEdit);
