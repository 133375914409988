import React, { Component } from "react";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Chart from 'react-apexcharts'
import getSymbolFromCurrency from 'currency-symbol-map';
import _ from "lodash";

import { getStartOfDayByDate, getEndOfDayByDate } from "../../services/utils";
import AdminCard from "../elements/adminCard";
import ReportsCustomHeader from "./reportsCustomHeader";
import { HeaderText } from "../elements/text";
import store from "../../store/store";
import { colors } from "../../styles/global";
import tenantAPI from '../../services/api/tenantAPI';
import reportsAPI from '../../services/api/reportsAPI';

class ReportDailySales extends Component {
  constructor() {
    super();
    this.state = {
      fromDate: new Date(moment().subtract(1, 'month')),
      toDate: new Date(),
      locations: store.locations,
      categorySales: [],
      selectedLocation: 'All',
      series: [{
        name: 'Daily Sales',
        type: 'column',
        data: []
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false,
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            show: true,
            format: 'MMM d'
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: "MMM d"
          },
        },
        yaxis: {
          showForNullSeries: false,
          seriesName: 'Daily Sales',
        },
        fill: {
          colors: colors.secondaryBlue
        }
      },
    };
  }

  componentDidMount = () => {
    tenantAPI.getRecursiveLocations({ page: 1 })
      .then(locations => {
        this.setState({locations});
        let monthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
        let todaysDate = moment().format('MM/DD/YYYY');
        this.formatReports(monthAgo, todaysDate);
      });
  }

  formatReports(fromDate, toDate) {
    const { selectedLocation } = this.state;
    reportsAPI.getDailySales(getStartOfDayByDate(fromDate), getEndOfDayByDate(toDate), selectedLocation === 'All' ? undefined : selectedLocation)
    .then(data => {
      let labels = Object.keys(data.daily_order_stats);
      let values = Object.values(data.daily_order_stats);
      let tableData = [];
      _.forEach(values, (value) => {
        tableData.push(value.subtotal.amount);
      });
      this.setState({
        categorySales: data.categories,
        dailySales: data.daily_order_stats,
        series: [{
          data: tableData,
        }],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: labels
          },
          yaxis: {
            ...this.state.options.yaxis,
            labels:
            {
              formatter: (value) =>
              getSymbolFromCurrency(this.state.locations[0].currency) + value
            }
          }
        }
      });
    })
    .catch(err => console.error(err));
  }

  handleChange = (type, newDate) => {
    let displayDate = new Date(newDate);
    newDate = moment(newDate).format('MM/DD/YYYY');
    if (type === 'fromDate') {
      this.setState({fromDate: displayDate}, () => {
        this.formatReports(newDate, this.state.toDate);
      });
    } else if (type === 'toDate') {
      this.setState({toDate: displayDate}, () => {
        this.formatReports(this.state.fromDate, newDate);
      });
    }
  }

  handleSelectLocation = (value) => {
    this.setState({ selectedLocation: value }, () => {
      this.formatReports(this.state.fromDate, this.state.toDate);
    });
  }

  render() {
    return (
      <div>
        <AdminCard
          customHeader={
            <ReportsCustomHeader
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            selectedLocation={this.state.selectedLocation}
            handleChange={this.handleChange}
            handleSelectLocation={this.handleSelectLocation}
          />}
        >
          <HeaderText medium className="pt-2">
            Daily Sales (MTD)
          </HeaderText>
          <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
          {store.loading ?
          <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
            Loading...
          </div> : this.state.categorySales && !this.state.categorySales.length ?
          <div className="admin-page-header-subtext" style={{ textAlign: 'center', padding: 20 }}>
            No category sales for the selected days or location.
          </div> :
          <div>
            <HeaderText medium className="pt-2">
              Sales Breakdown
            </HeaderText>
            <Table className="app-table-wrapper mt-0">
              <TableHead className="app-table-header">
                <TableRow className="app-table-row">
                  <TableCell className="app-table-cell pl-0 set-thin-width">
                    Category
                  </TableCell>
                  <TableCell className="app-table-cell">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="app-table-body">
                {this.state.categorySales &&
                this.state.categorySales.length > 0 &&
                this.state.categorySales.map((report, index) => (
                  <TableRow key={index} className="app-table-row">
                    <TableCell className="app-table-cell pl-0 set-thin-width">
                      {report.category_name}
                    </TableCell>
                    <TableCell className="app-table-cell">
                      {getSymbolFromCurrency(this.state.locations[0].currency) + report.total_sales}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>}
        </AdminCard>
      </div>
    );
  }
}

export default observer(ReportDailySales);
