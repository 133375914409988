import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSearchInput from '../components/elements/adminSearchInput';
import { Button } from '../components/elements/buttons';
import ModifierEdit from '../components/catalog/modifierEdit';
import menuAPI from '../services/api/menuAPI';


class ModifierList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      inFocus: '',
      modifiers: store.modifiers,
      open: false,
      orderBy: 'name',
      order: 'desc',
      rowsPerPage: 10,
      searchedModifiers: false,
      selectedModifier: {},
      typing: false,
      typingTimeout: 0
    }
  };

  componentDidMount = () => {
    this.getModifierLists();
  };

  getModifiers = () => {
    store.updateModifierSearch('');
    menuAPI.getRecursiveModifiers({ page: 1 })
      .then(() => {
        store.updateLoading(false);
        this.setState({ modifiers: store.modifiers });
      });
  };

  getModifierLists = () => {
    store.updateModifierListSearch('');
    store.updateLoading(true);
    menuAPI.getRecursiveModifierLists({page: 1})
      .then(() => {
        this.getModifiers();
      });
  };

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  getApiSearch = query => {
    store.updateLoading(true);
    if (!this.state.searchedModifiers) {
      menuAPI.getRecursiveModifiers({'page': 1})
        .then(response => {
          this.setState({ modifiers: response.data, currentPage: 0 });
          store.updateLoading(false);
        });
    } else {
      menuAPI.searchModifiers(query)
        .then(response => {
          this.setState({ modifiers: response.data, currentPage: 0 });
          store.updateLoading(false);
        });
    }
  };

  searchModifiers = query => {
    store.updateModifierSearch(query);
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getApiSearch(query);
      }, 500),
      searchedModifiers: query !== '',
    });
  };

  modifierListName(modifier) {
    return _.find(store.modifierLists, { id: modifier.modifier_list }) ? _.find(store.modifierLists, { id: modifier.modifier_list }).name : 'missing modifier list';
  };

  filterModifiers = enabledType => {
    let modifiers = this.state.modifiers;
    switch (enabledType) {
      case 'All Modifiers':
        modifiers = store.modifiers;
        break;

      case 'Enabled':
        modifiers = _.filter(store.modifiers, ['is_enabled', true]);
        break;

      case 'Disabled':
        modifiers = _.filter(store.modifiers, ['is_enabled', false]);
        break;

      default:
        modifiers = store.modifiers;
        break;
    }
    this.setState({ modifiers, currentPage: 0 });
  };

  renderFilter = enabledType => {
    const modifierCount = _.countBy(toJS(store.modifiers), 'is_enabled');
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterModifiers(enabledType) }>
          { enabledType }
        </Button>
        ({ enabledType === 'All Modifiers' ? store.modifiers.length : enabledType === 'Enabled' ? modifierCount.true || 0 : modifierCount.false || 0 })
      </React.Fragment>
    );
  };

  handleSort(value) {
    let modifiers = this.state.modifiers;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (['name', 'modifier_list'].includes(value)) modifiers = _.orderBy(modifiers, [modifier => modifier[value].toLowerCase()], [order]);
    else modifiers = _.orderBy(modifiers, [modifier => modifier[value]], [order]);
    this.setState({ modifiers, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  editModifier = modifier => {
    this.setState({ selectedModifier: modifier });
    this.setState({ open: true });
  };

  handleClose = updated => {
    if (updated) this.setState({ modifiers: store.modifiers });
    this.setState({ open: false });
  };

  render() {
    const {
      currentPage,
      modifiers,
      order,
      orderBy,
      rowsPerPage
    } = this.state;

    return (
      <div className='admin-page-wrapper'>
          <AdminPageHeader
            title='Modifiers'
            mainNav='Configuration'
            subNav='Item Library'
            description='Edit a modifier’s settings, such as if it’s currently available for purchase within the app.'
          />
        <AdminCard>
          <div className='columns is-mobile is-vcentered mb-0'>
            <div className='column pb-0 is-two-fifths'>
              <div className='medium-text'>
                { this.renderFilter('All Modifiers') }<span className='pl-1 pr-1'> | </span>
                { this.renderFilter('Enabled') }<span className='pl-1 pr-1'> | </span>
                { this.renderFilter('Disabled') }<span className='pl-1 pr-1'> | </span>
              </div>
            </div>
            <div className='column pb-0 is-one-fifth is-offset-two-fifths'>
              <AdminSearchInput
                value={ store.modifierSearch }
                onChange={ this.searchModifiers }
                style={ { paddingBottom: 12 } }
              />
            </div>
          </div>
          <div className='divider' />
          <Table className='app-table-wrapper'>
            <TableHead className='app-table-header'>
              <TableRow className='app-table-row'>
                <TableCell className='app-table-cell'>
                  <TableSortLabel
                    active={ orderBy === 'name' }
                    direction={ orderBy === 'name' ? order : 'asc' }
                    onClick={ () => this.handleSort('name') }
                    IconComponent={ () => this.getSortIcon('name') }
                  >
                    Modifier Name
                    { orderBy === 'name' ? (
                      <span className='is-hidden'>
                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell className='app-table-cell'>
                  <TableSortLabel
                    active={ orderBy === 'modifier_list' }
                    direction={ orderBy === 'modifier_list' ? order : 'asc' }
                    onClick={ () => this.handleSort('modifier_list') }
                    IconComponent={ () => this.getSortIcon('modifier_list') }
                  >
                    Modifier List
                    { orderBy === 'modifier_list' ? (
                      <span className='is-hidden'>
                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell className='app-table-cell'>
                  <TableSortLabel
                    active={ orderBy === 'is_enabled' }
                    direction={ orderBy === 'is_enabled' ? order : 'asc' }
                    onClick={ () => this.handleSort('is_enabled') }
                    IconComponent={ () => this.getSortIcon('is_enabled') }
                  >
                    Enabled
                    { orderBy === 'is_enabled' ? (
                      <span className='is-hidden'>
                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell className='app-table-cell' style={{width: 107}}/>
              </TableRow>
            </TableHead>
            <TableBody className='app-table-body'>
              { modifiers.length ?
                <>
                  { (rowsPerPage > 0 ? this.state.modifiers.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : this.state.modifiers).map(modifier => (
                    <TableRow
                      key={ modifier.id }
                      className={ `app-table-row ${ this.state.inFocus === modifier.id ? 'highlighted-row' : '' }` }
                      onMouseOver={ () => this.setState({ inFocus: modifier.id }) }
                    >
                      <TableCell className='app-table-cell'>
                        { modifier.name }
                      </TableCell>
                      <TableCell className='app-table-cell'>{ this.modifierListName(modifier) }</TableCell>
                      <TableCell className='app-table-cell'>
                        { modifier.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                      </TableCell>
                      <TableCell className='app-table-cell has-text-right'>
                      { this.state.inFocus === modifier.id  ? <Button
                          edit
                          onClick={ () => this.editModifier(modifier) }
                        >
                          Edit
                        </Button> : null}
                      </TableCell>
                    </TableRow>
                )) }
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={ [10, 25, 50] }
                    count={ this.state.modifiers && this.state.modifiers.length ? this.state.modifiers.length : 0 }
                    rowsPerPage={ this.state.rowsPerPage }
                    onRowsPerPageChange={ (event) => this.updateRows(event) }
                    page={ this.state.currentPage }
                    onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                    style={ { borderBottom: 'none' } }
                  />
                </TableRow>
              </>
            : <TableRow className='app-table-row'>
                <TableCell className='app-table-cell'>
                  No Modifiers matched your search
                </TableCell>
              </TableRow> }
          </TableBody>
          </Table>
        </AdminCard>
        <ModifierEdit
          modifier={ this.state.selectedModifier }
          onClose={this.handleClose}
          open={this.state.open}
        />
      </div>
    );
  }
}

export default observer(ModifierList);
