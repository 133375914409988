import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSearchInput from '../components/elements/adminSearchInput';
import { Button } from '../components/elements/buttons';
import ModifierEdit from '../components/catalog/modifierEdit';
import ModifierListEdit from '../components/catalog/modifierListEdit';
import menuAPI from '../services/api/menuAPI';


class ModifierListList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modifier_lists: store.modifier_lists,
            currentPage: 0,
            inFocusModList: '',
            inFocusMod: '',
            openModListEdit: false,
            openModEdit: false,
            orderBy: 'name',
            order: 'desc',
            rowCollapsed: {},
            rowsPerPage: 10,
            searchedModifierLists: false,
            selectedModifierList: {},
            selectedModifier: {},
            typing: false,
            typingTimeout: 0
        };
    };

    toggleRow(modifierList) {
        const rowCollapsed = modifierList.id === this.state.rowCollapsed ? {} : modifierList.id;
        this.setState({ rowCollapsed });
    };

    handleClose = (updated) => {
        this.setState({ openModEdit: false, openModListEdit: false });
        if (updated) this.getModifierLists();
    };

    getModifierLists = () => {
        store.updateModifierListSearch('');
        store.updateLoading(true);
        menuAPI.getRecursiveModifierLists({page: 1})
            .then(() => {
                this.setState({ modifier_lists: store.modifierLists });
                store.updateLoading(false);
            });
    };

    getApiSearch = query => {
        if (!this.state.searchedModifierLists) {
            store.updateLoading(true);
            menuAPI.getRecursiveModifierLists({ page: 1 })
                .then(() => {
                    this.setState({ modifier_lists: store.modifierLists, currentPage: 0 });
                });
        } else {
            menuAPI.searchModifierLists(query)
                .then(response => {
                    this.setState({ modifier_lists: response.data, currentPage: 0 });
                });
        }
    };

    searchModifierLists = query => {
        store.updateModifierListSearch(query);
        let self = this;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            typing: false,
            typingTimeout: setTimeout(function () {
                self.getApiSearch(query);
            }, 500),
            searchedModifierLists: query !== ''
        });
    };

    componentDidMount = () => {
        this.getModifierLists();
    };

    filterModifierLists = enabledType => {
        let modifier_lists = this.state.modifier_lists;
        switch (enabledType) {
            case 'All Modifier Lists':
                modifier_lists = store.modifierLists;
                break;

            case 'Enabled':
                modifier_lists = _.filter(store.modifierLists, ['is_enabled', true]);
                break;

            case 'Disabled':
                modifier_lists = _.filter(store.modifierLists, ['is_enabled', false]);
                break;

            default:
                modifier_lists = store.modifierLists;
                break;
        }
        this.setState({ modifier_lists, currentPage: 0 });
    };

    renderFilter = enabledType => {
        const modifierListCount = _.countBy(store.modifierLists, 'is_enabled');
        return (
            <React.Fragment>
                <Button edit onClick={ () => this.filterModifierLists(enabledType) }>
                    { enabledType }
                </Button>
                ({ enabledType === 'All Modifier Lists' ? store.modifierLists.length : enabledType === 'Enabled' ? modifierListCount.true || 0 : modifierListCount.false || 0 })
            </React.Fragment>
        );
    };

    handleSort(value) {
        let modifier_lists = this.state.modifier_lists;
        let orderBy = value;
        let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
        if (value === 'name') modifier_lists = _.orderBy(modifier_lists, [modifier_list => modifier_list[value].toLowerCase()], [order]);
        else modifier_lists = _.orderBy(modifier_lists, [modifier_list => modifier_list[value]], [order]);
        this.setState({ modifier_lists, order, orderBy, currentPage: 0 });
    };

    getSortIcon(orderBy) {
        let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
            :
            <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
        return sortIcon;
    };

    updateRows(event) {
        this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
    };

    updatePage(event, newPage) {
        this.setState({ currentPage: newPage });
    };

    editModifierList = modifierList => {
        this.setState({ selectedModifierList: modifierList });
        this.setState({ openModListEdit: !this.state.openModListEdit });
    };

    editModifier = modifier => {
        this.setState({ selectedModifier: modifier });
        this.setState({ openModEdit: !this.state.openModEdit });
    };

    render() {
        const {
            inFocusModList,
            inFocusMod,
            modifier_lists,
            order,
            orderBy,
            rowsPerPage,
            currentPage
        } = this.state;

        return (
            <div className='admin-page-wrapper'>
                <AdminPageHeader
                    title='Modifier Lists'
                    mainNav='Configuration'
                    subNav='Item Library'
                    description='Update your modifier list settings.'
                />
                <AdminCard>
                    <div className='columns is-mobile is-vcentered mb-0'>
                        <div className='column pb-0 is-two-fifths'>
                            <div className='medium-text'>
                                { this.renderFilter('All Modifier Lists') }<span className='pl-1 pr-1'> | </span>
                                { this.renderFilter('Enabled') }<span className='pl-1 pr-1'> | </span>
                                { this.renderFilter('Disabled') }<span className='pl-1 pr-1'> | </span>
                            </div>
                        </div>
                        <div className='column pb-0 is-one-fifth is-offset-two-fifths'>
                            <AdminSearchInput
                                value={ store.modifierListSearch }
                                onChange={ this.searchModifierLists }
                                style={ { paddingBottom: 12 } }
                            />
                        </div>
                    </div>
                    <div className='divider' />
                    <Table size="small" className='app-table-wrapper'>
                        <TableHead className='app-table-header'>
                            <TableRow className='app-table-row'>
                                <TableCell className='app-table-cell set-width-column-350'>
                                    <TableSortLabel
                                        active={ orderBy === 'name' }
                                        direction={ orderBy === 'name' ? order : 'asc' }
                                        onClick={ () => this.handleSort('name') }
                                        IconComponent={ () => this.getSortIcon('name') }
                                    >
                                        Modifier List
                                        { orderBy === 'name' ? (
                                            <span className='is-hidden'>
                                                { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                            </span>
                                        ) : null }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className='app-table-cell'>
                                    <TableSortLabel
                                        active={ orderBy === 'is_enabled' }
                                        direction={ orderBy === 'is_enabled' ? order : 'asc' }
                                        onClick={ () => this.handleSort('is_enabled') }
                                        IconComponent={ () => this.getSortIcon('is_enabled') }
                                    >
                                        Enabled
                                        { orderBy === 'is_enabled' ? (
                                            <span className='is-hidden'>
                                                { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                            </span>
                                        ) : null }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className='app-table-cell' style={ { width: '20%' } } />
                            </TableRow>
                        </TableHead>
                        <TableBody className='app-table-body'>
                            { modifier_lists && modifier_lists.length ?
                                <React.Fragment>
                                    { (rowsPerPage > 0 ? modifier_lists.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : modifier_lists).map(modifierList => (
                                        <React.Fragment key={ modifierList.id }>
                                            <TableRow
                                                className={ `app-table-row ${ inFocusModList === modifierList.id ? 'highlighted-row ' : '' }` }
                                                onMouseOver={ () => this.setState({ inFocusModList: modifierList.id }) }
                                            >
                                                <TableCell className='app-table-cell pl-0'>
                                                    <IconButton aria-label="expand row" onClick={ () => {
                                                        this.toggleRow(modifierList);
                                                    } }>
                                                        { this.state.rowCollapsed === modifierList.id ?
                                                            <FontAwesomeIcon size='xs' className='ordrslipRed' icon={ ['fas', 'caret-down'] } /> :
                                                            <FontAwesomeIcon size='xs' className='ordrslipRed' icon={ ['fas', 'caret-right'] } /> }
                                                    </IconButton>
                                                    { modifierList.name }
                                                </TableCell>
                                                <TableCell className='app-table-cell'>
                                                    { modifierList.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                                                </TableCell>
                                                <TableCell className='app-table-cell has-text-right'>
                                                    { inFocusModList === modifierList.id ?
                                                        <Button
                                                            edit
                                                            onClick={ () => this.editModifierList(modifierList) }
                                                        >
                                                            Edit
                                                        </Button> : null }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="app-table-row">
                                                <TableCell className="app-table-cell pb-0 pt-0" colSpan={ 9 }>
                                                    <Collapse in={ this.state.rowCollapsed === modifierList.id } timeout="auto" unmountOnExit>
                                                        <div style={ { padding: 10, width: '100%', flex: 1, overflowX: "auto" } }>
                                                            <Table size="small" className="app-table-wrapper mt-0">
                                                                <TableHead className='app-table-header'>
                                                                    <TableRow className='app-table-row'>
                                                                        <TableCell className='app-table-cell set-width-column-350' style={ { padding: 0}} />
                                                                        <TableCell className='app-table-cell p-0' />
                                                                        <TableCell className='app-table-cell p-0' />
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody className='app-table-body'>
                                                                    { modifierList.modifiers && modifierList.modifiers.length ?
                                                                        modifierList.modifiers.map(modifier =>
                                                                            <TableRow
                                                                                key={ modifier.id }
                                                                                className={ `app-table-row ${ inFocusMod === modifier.id ? 'highlighted-row ' : '' }` }
                                                                                onMouseOver={ () => this.setState({ inFocusMod: modifier.id }) }
                                                                            >
                                                                                <TableCell className="app-table-cell">{ modifier.name }</TableCell>
                                                                                <TableCell className="app-table-cell" style={ { paddingLeft: 10}}>
                                                                                    { modifier.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                                                                                </TableCell>
                                                                                <TableCell className='app-table-cell has-text-right'>
                                                                                    { inFocusMod === modifier.id ?
                                                                                        <Button
                                                                                            edit
                                                                                            onClick={ () => this.editModifier(modifier) }
                                                                                        >
                                                                                            Edit
                                                                                        </Button> : null }
                                                                                </TableCell>
                                                                        </TableRow>) : null }
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )) }
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={ [10, 25, 50] }
                                            count={ modifier_lists && modifier_lists.length ? modifier_lists.length : 0 }
                                            rowsPerPage={ rowsPerPage }
                                            onRowsPerPageChange={ (event) => this.updateRows(event) }
                                            page={ currentPage }
                                            onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                                            style={ { borderBottom: 'none' } }
                                        />
                                    </TableRow>
                                </React.Fragment>
                                :
                                <TableRow className='app-table-row'>
                                    <TableCell className='app-table-cell'>
                                        No modifier lists matched your search
                                    </TableCell>
                                </TableRow> }
                        </TableBody>
                    </Table>
                </AdminCard>
                <ModifierListEdit
                    modifierList={ _.cloneDeep(this.state.selectedModifierList) }
                    onClose={ this.handleClose }
                    open={ this.state.openModListEdit }
                />
                <ModifierEdit
                    modifier={ this.state.selectedModifier }
                    onClose={ this.handleClose }
                    open={ this.state.openModEdit }
                />
            </div>
        );
    }
}

export default observer(ModifierListList);
