
const environments = {
  staging: {
      url: "https://staging.api.ordrslip.com/v4.1/",
      link: "https://staging.admin.ordrslip.com/",
      ws: "wss://ordrslip-api-staging.herokuapp.com/ws/",
      name: "Staging"
  },
  production: {
      url: "https://api.ordrslip.com/v4.1/",
      link: "https://admin.ordrslip.com/",
      ws: "wss://api.ordrslip.com/ws/",
      name: "Production"
  },
  local: {
      url: "http://localhost:8000/v4.1/",
      link: "http://localhost:8000/",
      ws: "ws://localhost:8000/ws/",
      name: "Local"
  },
  feature: {
    url: "https://ordrslip-api-pr-2993.herokuapp.com/v4.1/",
    link: "https://ordrslip-api-pr-2993.herokuapp.com/",
    name: "BOGO"
}
}

let env = 'production'; // update this to change environment

let config = {
  base_url: environments[env].url,
  link_url: environments[env].link,
  ws_url: environments[env].ws,
  environment: environments[env],
  version: '2.2.0'
};

export default config;