import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from '../store/store';
import AdminCard from '../components/elements/adminCard';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSearchInput from '../components/elements/adminSearchInput';
import { Button } from '../components/elements/buttons';
import CategoryEdit from '../components/catalog/categoryEdit';
import menuAPI from '../services/api/menuAPI';


class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: store.categories,
      currentPage: 0,
      inFocus: '',
      open: false,
      orderBy: 'name',
      order: 'desc',
      rowsPerPage: 10,
      searchedCategories: false,
      selectedCategory: {},
      typing: false,
      typingTimeout: 0
    }
  };

  handleClose = updated => {
    this.setState({ open: false });
    if (updated) this.setState({ categories: store.categories });
  }

  getCategories = () => {
    store.updateCategorySearch('');
    menuAPI.getRecursiveCategories({ page: 1 })
      .then(response => {
        this.setState({
          categories: response
        });
      });
  };

  getApiSearch = query => {
    store.updateLoading(true);
    if (!this.state.searchedCategories) {
      menuAPI.getRecursiveCategories({ page: 1 })
        .then(response => {
          this.setState({ categories: response, currentPage: 0 });
          store.updateLoading(false);
        });
    } else {
      menuAPI.searchCategories(query)
        .then(response => {
          this.setState({ categories: response.data, currentPage: 0 });
          store.updateLoading(false);
        });
    }
  };

  searchCategories = query => {
    store.updateCategorySearch(query);
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getApiSearch(query);
      }, 500),
      searchedCategories: query !== ''
    });
  };

  componentDidMount = () => {
    if (!store.categories.length) this.getCategories();
  };

  filterCategories = enabledType => {
    let categories = this.state.categories;
    switch (enabledType) {
      case 'All Categories':
        categories = store.categories;
        break;

      case 'Enabled':
        categories = _.filter(store.categories, ['is_enabled', true]);
        break;

      case 'Disabled':
        categories = _.filter(store.categories, ['is_enabled', false]);
        break;

      default:
        categories = store.categories;
        break;
    }
    this.setState({ categories, currentPage: 0 });
  };

  renderFilter = enabledType => {
    const categoryCount = _.countBy(store.categories, 'is_enabled');
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterCategories(enabledType) }>
          { enabledType }
        </Button>
        ({ enabledType === 'All Categories' ? store.categories.length : enabledType === 'Enabled' ? categoryCount.true || 0 : categoryCount.false || 0 })
      </React.Fragment>
    );
  };

  handleSort(value) {
    let categories = this.state.categories;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'name') categories = _.orderBy(categories, [category => category[value].toLowerCase()], [order]);
    else categories = _.orderBy(categories, [category => category[value]], [order]);
    this.setState({ categories, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size='1x' className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  editCategory = category => {
    this.setState({ selectedCategory: category });
    this.setState({ open: !this.state.open });
  };

  render() {
    const { order, orderBy, rowsPerPage, currentPage } = this.state;

    return (
        <div className='admin-page-wrapper'>
          <AdminPageHeader
            title='Categories'
            mainNav='Configuration'
            subNav='Item Library'
            description='Update your category settings.'
          />
          <AdminCard>
            <div className='columns is-mobile is-vcentered mb-0'>
              <div className='column pb-0 is-two-fifths'>
                <div className='medium-text'>
                  { this.renderFilter('All Categories') }<span className='pl-1 pr-1'> | </span>
                  { this.renderFilter('Enabled') }<span className='pl-1 pr-1'> | </span>
                  { this.renderFilter('Disabled') }<span className='pl-1 pr-1'> | </span>
                </div>
              </div>
              <div className='column pb-0 is-one-fifth is-offset-two-fifths'>
                <AdminSearchInput
                  value={ store.categorySearch }
                  onChange={ this.searchCategories }
                  style={ { paddingBottom: 12 } }
                />
              </div>
            </div>
              <div className='divider' />
                <Table className='app-table-wrapper'>
                  <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='app-table-cell'>
                        <TableSortLabel
                          active={ orderBy === 'name' }
                          direction={ orderBy === 'name' ? order : 'asc' }
                          onClick={ () => this.handleSort('name') }
                          IconComponent={ () => this.getSortIcon('name') }
                        >
                          Category Name
                          { orderBy === 'name' ? (
                            <span className='is-hidden'>
                              { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                            </span>
                          ) : null }
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className='app-table-cell'>
                        <TableSortLabel
                          active={ orderBy === 'is_enabled' }
                          direction={ orderBy === 'is_enabled' ? order : 'asc' }
                          onClick={ () => this.handleSort('is_enabled') }
                          IconComponent={ () => this.getSortIcon('is_enabled') }
                        >
                          Enabled
                          { orderBy === 'is_enabled' ? (
                            <span className='is-hidden'>
                              { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                            </span>
                          ) : null }
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className='app-table-cell' style={ { width: '20%' } }>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='app-table-body'>
                  { this.state.categories.length ?
                    <>
                      { (rowsPerPage > 0 ? this.state.categories.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : this.state.categories).map(category => (
                        <TableRow
                          key={ category.id }
                          className={ `app-table-row ${ this.state.inFocus === category.id ? 'highlighted-row ' : '' }` }
                          onMouseOver={ () => this.setState({ inFocus: category.id }) }
                        >
                          <TableCell className='app-table-cell'>
                            { category.image ?
                              <FontAwesomeIcon icon='fa-solid fa-image' className='pr-2 secondaryBlue' />
                              : <FontAwesomeIcon icon='fa-regular fa-image-slash' className='pr-2 disabledGrey'/>
                            }
                            { category.name }
                          </TableCell>
                          <TableCell className='app-table-cell'>
                            { category.is_enabled ? <span className='brightGreen semi-bold'>Enabled</span> : <span className='ordrslipRed semi-bold'>Disabled</span> }
                          </TableCell>
                          <TableCell className='app-table-cell has-text-right'>
                            { this.state.inFocus === category.id ?
                              <Button
                              edit
                              onClick={() => this.editCategory(category)}
                            >
                              Edit
                            </Button> : null}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={ [10, 25, 50] }
                          count={ this.state.categories && this.state.categories.length ? this.state.categories.length : 0 }
                          rowsPerPage={ this.state.rowsPerPage }
                          onRowsPerPageChange={ (event) => this.updateRows(event) }
                          page={ this.state.currentPage }
                          onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                          style={ { borderBottom: 'none' } }
                        />
                      </TableRow>
                    </>
                  :
                    <TableRow className='app-table-row'>
                      <TableCell className='app-table-cell'>
                        No categories matched your search.
                      </TableCell>
                    </TableRow> }
                </TableBody>
              </Table>
            </AdminCard>
          <CategoryEdit
            category={ this.state.selectedCategory }
            onClose={ this.handleClose }
            open={ this.state.open }
          />
        </div>
    );
  }
}

export default observer(CategoryList);
