import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';
import RadioGroup from "@material-ui/core/RadioGroup";

import menuAPI from '../../services/api/menuAPI';
import { errorMessage, fieldError } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import RadioLabel from "../elements/radioLabel";
import ImageCard from '../imageCard';
import { Text } from '../elements/text';
import ItemAvailability from "./itemAvailability";
import DraggableTable from '../elements/draggableTable';
import { toJS } from 'mobx';


class ItemEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            delete_image: false,
            file: null,
            fileUrl: '',
            reordered: false,
            item: this.props.item,
            updatedVariations: []
        };
    }

    updateImage = (fileUrl, file) => {
        this.setState({ fileUrl, file });
    };

    deleteImage = () => {
        this.setState({ fileUrl: '', file: null, delete_image: true });
    };

    toggleEnabled = () => {
        let { item } = this.state;
        item.is_available = !item.is_available;
        this.setState({ item });
    };

    toggleOosAtLocation = locationId => {
        let { item } = this.state;
        if (item.out_of_stock_locations.includes(locationId)) {
            let idx = item.out_of_stock_locations.indexOf(locationId);
            item.out_of_stock_locations.splice(idx, 1);
        } else {
            item.out_of_stock_locations.push(locationId);
        }
        this.setState({ item });
    };

    toggleHideAtLocation = locationId => {
        let { item } = this.state;
        if (item.hide_at_locations.includes(locationId)) {
            let idx = item.hide_at_locations.indexOf(locationId);
            item.hide_at_locations.splice(idx, 1);
        } else {
            item.hide_at_locations.push(locationId);
        }
        this.setState({ item });
    };

    toggleVariationOosLocations = (locationId, variation) => {
        let { updatedVariations } = this.state;
        let out_of_stock_locations = variation.out_of_stock_locations;
        if (out_of_stock_locations.includes(locationId)) {
            let idx = out_of_stock_locations.indexOf(locationId);
            out_of_stock_locations.splice(idx, 1);
        } else {
            out_of_stock_locations.push(locationId);
        }
        updatedVariations.push(variation);
        this.setState({ updatedVariations });
    };

    toggleVariationHideAtLocations = (locationId, variation) => {
        let { updatedVariations } = this.state;
        let hide_at_locations = variation.hide_at_locations;
        if (hide_at_locations.includes(locationId)) {
            let idx = hide_at_locations.indexOf(locationId);
            hide_at_locations.splice(idx, 1);
        } else {
            hide_at_locations.push(locationId);
        }
        updatedVariations.push(variation);
        this.setState({ updatedVariations });
    };

    componentDidMount = () => {
        this._isMounted = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.item !== this.props.item) {
            this.setState({ item: nextProps.item });
        }
    };

    handleChange(event) {
        let { item } = this.state;
        let value = event.target.value;
        const name = event.target.name;
        item[name] = value;
        this.setState({ item });
    };

    handleSubmit = () => {
        const { item, reordered, updatedVariations } = this.state;
        // Update Variations
        updatedVariations.forEach(variation => {
            let data = {
                out_of_stock_locations: variation.out_of_stock_locations,
                hide_at_locations: variation.hide_at_locations
            };
            menuAPI.updateVariation(variation.id, JSON.stringify(data))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        return;
                    }
                });
        });
        // Update Modifier Lists
        if (reordered) {
            let modListPayload = { 'objects': [] };
            item.modifier_lists.forEach((modList, index) => {
                modListPayload.objects.push({ 'id': modList.id, 'order': modList.order });
            });
            menuAPI.bulkUpdate('modifier-lists', JSON.stringify(modListPayload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        return;
                    } else {
                        this.setState({ apiErrors: {} });
                    }
                })
                .catch(err => console.error(err));
        }
        // Update Item
        let payload = {
            is_available: item.is_available,
            description: item.description,
            is_age_restricted: item.is_age_restricted.toString() === 'true' ? true : false,
            maximum_quantity: item.maximum_quantity ? parseInt(item.maximum_quantity) : null
        };

        if (item.is_available) {
            payload.out_of_stock_locations = item.out_of_stock_locations;
            payload.hide_at_locations = item.hide_at_locations;
        }
        if (this.state.delete_image) payload.delete_image = true;
        menuAPI.updateItem(item.id, JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                     if (this.state.fileUrl) {
                        let data = new FormData();
                        data.append('image', this.state.file);
                        menuAPI.updateItemImage(item.id, data)
                            .then(response => {
                                if (response.errors) {
                                    this.setState({ apiErrors: response.errors });
                                } else {
                                    this.handleClose(true);
                                }
                            });
                    } else {
                        this.handleClose(true);
                    }
                }
            })
            .catch(err => console.error(err));
    };

    handleClose = updated => {
        if (this._isMounted) {
            this.setState({
                delete_image: false,
                fileUrl: '',
                file: null,
                apiErrors: {},
                updatedVariations: [],
                reordered: false
            });
        }
        this.props.onClose(updated);
    };

    updateDragged = list => {
        let { item } = this.state;
        item.modifier_lists = list;
        this.setState({ item, reordered: true });
    };

    render() {
        const { apiErrors, item } = this.state;
        if (_.isEmpty(toJS(item))) return <div />;
        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ () => this.handleClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title={ `Edit ${ item.name }` }
                    onSubmit={ this.handleSubmit }
                    onClose={ () => this.handleClose(false) }
                    errorMessage={ errorMessage(this.state.apiErrors) }
                >
                    <form>
                        <AdminCard
                            style={{marginTop: 15}}
                            title='Item Details'
                            description='Upload your item image. If no image is selected, an image will not show on your app an/or online ordering.'
                        >
                            <div className='app-field-wrapper'>
                                <div className='app-field-label' style={ { marginTop: '15px' } }>Item Description</div>
                                <Text>Note: if you are using Square POS and the item description changes from your POS, the description will change back to match your POS the next time you sync your Admin Dashboard.</Text>
                                <textarea
                                    rows="4"
                                    style={ { width: '100%', padding: 10 } }
                                    className={ `app-input-field mt-0 ${ fieldError('description', apiErrors) ? 'red-border' : '' }` }
                                    name='description'
                                    placeholder='Item Description'
                                    value={ item.description }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                <div>{ fieldError('description', apiErrors) }</div>
                            </div>
                            <ImageCard
                                imgUrl={ !this.state.delete_image ? item.image : null }
                                file={ this.state.file }
                                fileUrl={ this.state.fileUrl }
                                altText={ item.name }
                                cardTitle=''
                                description=''
                                item={ item }
                                updateImage={ this.updateImage }
                                deleteImage={ this.deleteImage }
                                delete_image={ this.state.delete_image }
                            />
                            <div className='app-field-wrapper pt-5'>
                                <label className='app-field-label'>
                                    Age Restricted Item
                                </label>
                                <Text>Age Restricted Items require that the customer's ID will be verified upon pickup/delivery. Contactless delivery cannot be provided on Age Restricted Items. <span className='bold'>Note: All items will not be restricted by default and will need to be edited directly.</span></Text>
                                <RadioGroup
                                    name="is_age_restricted"
                                    value={ this.state.item.is_age_restricted.toString() }
                                    onChange={ (event) => this.handleChange(event) }
                                    className="radio-group"
                                >
                                    <RadioLabel
                                        value='false'
                                        label='No Restriction on Item'
                                    />
                                    <RadioLabel
                                        value='true'
                                        label='Item is Age-Restricted and needs proof valid ID upon pickup/delivery'
                                    />
                                </RadioGroup>
                            </div>
                            <div className='app-field-wrapper pt-5'>
                                <label className='app-field-label'>
                                    Maximum Items Ordered
                                </label>
                                <Text>Setting a maximum will limit users to the amount of this item that they can order. (Optional)</Text>
                                <input
                                    className={ `app-input-field mt-0 ${ fieldError('maximum_quantity', apiErrors) ? 'red-border' : '' }` }
                                    name='maximum_quantity'
                                    type='number'
                                    placeholder='Maximum'
                                    value={ item.maximum_quantity ? item.maximum_quantity : '' }
                                    onChange={ (event) => this.handleChange(event) }
                                />
                                <div>{ fieldError('maximum_quantity', apiErrors) }</div>
                            </div>
                        </AdminCard>
                        <ItemAvailability
                            item={ this.state.item }
                            toggleEnabled={ this.toggleEnabled }
                            toggleHideAtLocation={ this.toggleHideAtLocation }
                            toggleOosAtLocation={ this.toggleOosAtLocation }
                            toggleVariationOosLocations={ this.toggleVariationOosLocations }
                            toggleVariationHideAtLocations={ this.toggleVariationHideAtLocations }
                        />
                        <AdminCard
                            title='Organize Modifier Lists Within Item'
                            description='Organize your modifier lists the way they will display in your app and/or online ordering.'
                        >
                            { item.modifier_lists.length ?
                                <DraggableTable
                                    list={ item.modifier_lists }
                                    headerData={ ['Modifier List Name', 'Pricing', 'Enabled'] }
                                    fieldData={ ['name', 'pricerange', 'is_enabled'] }
                                    imageIcon={ true }
                                    updateParentState={ this.updateDragged }
                                />
                                : <Text>This item has no modifiers.</Text>
                            }
                        </AdminCard>
                    </form>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default ItemEdit;
