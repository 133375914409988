import React, { Component } from "react";
import FutureOrdersMaxEdit from "./futureOrdersMaxEdit";
import AdminCard from '../elements/adminCard';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class FutureOrdersMax extends Component {
  state = {
    open: false
  };

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { location } = this.props;

    return (
      <div>
        <AdminCard
          largeTitle
          title="Pending Orders Max Limit for Future Orders"
          description="Set a maximum amount of concurrent future pending orders that can be ordered during a 15 minute window. When the max limit has been reached the next available time will be suggested to the user to schedule their order."
          onHeaderActionClick={this.toggleDrawer}
        >
          <Table className="app-table-wrapper">
            <TableHead className="app-table-header">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell pl-0" style={{ width: 150 }}>
                    Type
                </TableCell>
                <TableCell className="app-table-cell">
                    Max Orders
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="app-table-body">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell pl-0">
                  Pickup
                </TableCell>
                <TableCell className="app-table-cell">
                  { location.max_concurrent_scheduled_pickup_orders ? `${location.max_concurrent_scheduled_pickup_orders} orders` : "No Limit" }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-table-cell pl-0">
                  Delivery
                </TableCell>
                <TableCell className="app-table-cell">
                  { location.max_concurrent_scheduled_delivery_orders ? `${ location.max_concurrent_scheduled_delivery_orders } orders` : "No Limit" }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AdminCard>
        <FutureOrdersMaxEdit
          open={this.state.open}
          onClose={this.toggleDrawer}
          location={ location }
          onUpdate={ this.props.onUpdate }
        />
      </div>
    );
  }
}

export default FutureOrdersMax;
