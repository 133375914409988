import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import { observer } from 'mobx-react';
import _ from 'lodash';

import store from '../../store/store';
import AdminCard from '../elements/adminCard';
import { Text } from '../elements/text';
import moment from "moment";
import accountsAPI from '../../services/api/accountsAPI';


class UserLoyalty extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          anchorEl: null,
          updated: false,
          user: props.user,
          userLoyalty: {},
        };
    };

    componentDidMount = () => {
        if (this.props.type === 'square loyalty' && this.props.user.phone_validated) {
            accountsAPI.getUserRewards(this.props.user.id, store.squareLoyalty.id)
                .then(response => { if (!response.errors) this.setState({ userLoyalty: response['data'] }); })
                .catch(err => console.error(err));
        } else if (this.props.type === 'punchcards') {
            accountsAPI.getPunchCard(this.props.user.id)
            .then(response => { if (!response.errors) this.setState({ userLoyalty: response['data'] }); })
            .catch(err => console.error(err));
        }
    };

    handleDialogOpen = () => {
        this.setState({ open: true });
    };

    handleDialogClose = () => {
        this.setState({ open: false });
    };

    handlePointsModalOpen = () => {
        this.setState({ openPointsModal: true, anchorEl: null });
    };

    handlePointsModalClose = (userLoyalty) => {
        if (userLoyalty && userLoyalty.id) {
            this.setState({ userLoyalty: userLoyalty, openPointsModal: false});
        } else {
            this.setState({ openPointsModal: false });
        }
    };

    handleClick = event => {
        this.setState({
          anchorEl: event.currentTarget
        });
    };

      handleClose = () => {
        this.setState({
          anchorEl: null
        });
    };


    showRewardIcon = (reward) => {
        if (this.state.userLoyalty.points >= reward.points_required) {
            return (
                <FontAwesomeIcon className='tertiaryOrange' icon={['fas', 'star']} />
            );
        } else {
            return (
                <FontAwesomeIcon className='tertiaryOrange' icon={['far', 'star']} />
            );
        }
    }

    getDescription = () => {
        if (this.props.type === 'square loyalty') {
            return 'Square Loyalty is an added feature within your Square POS. To see a more detailed and editable view of this feature, visit the Loyalty section in your Square Dashboard.'
        } else {
            return 'Punch cards are a way to offer repeat customers a reward for multiple purchases.'
        }
    }

    punchCardReward = () => {
        let reward = `${ store.punchCardSettings.reward_type === 'percent' ? store.punchCardSettings.percent_off + '%' : store.punchCardSettings.amount_off.formatted } off an order`;
        if (store.punchCardSettings.max_discount_amount) {
            reward = reward + ` up to $${ store.punchCardSettings.max_discount_amount }`
        }
        return reward;
    }

    render() {
        const { anchorEl, userLoyalty } = this.state;
        const isLoyalty = this.props.type === 'square loyalty';
        const open = Boolean(anchorEl);

        if (store.loading) return <div />;
        if (_.isEmpty(userLoyalty)) return <div />;
        return (
            <div>
                <AdminCard
                title={isLoyalty ? 'Square Loyalty' : 'Punch Cards'}
                description={this.getDescription()}
                >
                <div className='app-card-content-wrapper' style={{width: '100%', overflowX: 'auto'}}>
                    <div className='app-card-subheader'>
                        {isLoyalty ? 'Loyalty' : 'Punch Card'} Summary
                            <FontAwesomeIcon
                                className='cursor-pointer'
                                icon={ ['fa', 'ellipsis-h'] }
                                style={ { float: 'right', marginTop: '8px' } }
                                onClick={ this.handleClick }
                            />
                            <Popover
                                id='loyalty-popover'
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                    <div onClick={ () => this.props.openSecondaryDrawer('adjust points', isLoyalty) } className='loyalty-popover'>Adjust Points</div>
                            </Popover>
                    </div>
                    <div className='app-field-wrapper'>
                        <p className='app-field-label' style={ { marginTop: '15px' } }>
                            { isLoyalty ? 'Lifetime Points' : 'Total Punches' }
                        </p>
                        <Text>
                            { isLoyalty ? userLoyalty.lifetime_points : store.current_punch_card.points + '/' + store.punchCardSettings.points_required }
                        </Text>
                    </div>
                        { isLoyalty ?
                            <div className='app-field-wrapper'>
                                <p className='app-field-label'>Total Points</p>
                                <Text>{userLoyalty.points}</Text>
                            </div>
                        :null }
                    <div className='app-card-subheader'>
                        Reward Availability
                    </div>
                    <div className='user-info'><p>{isLoyalty ? 'Redeemable rewards and upcoming' : 'Available'} rewards for this customer.</p></div>
                    <div>
                    {isLoyalty && store.squareLoyalty.rewards && store.squareLoyalty.rewards.map(reward =>
                        <div key={reward.id}>
                            <div className='rewards-container'>
                                <div style={{ marginRight: 10 }}>
                                    {this.showRewardIcon(reward)}
                                </div>
                                <div>
                                    <p className='loyalty-reward-text'>{reward.name}</p>
                                    <p className='loyalty-reward-points'>{reward.points_required} {store.squareLoyalty.points_name}s</p>
                                </div>
                            </div>
                        </div>
                        )}
                    {!isLoyalty && store.availableRewards.length ? store.availableRewards.map(reward =>
                        <div key={reward.id}>
                            <div className='rewards-container'>
                                <div style={{ marginRight: 10 }}>
                                    <FontAwesomeIcon className='tertiaryOrange' icon={ ['fas', 'star'] } />
                                </div>
                                <div>
                                    <p className='loyalty-reward-text'>{ this.punchCardReward() }</p>
                                    <p className='loyalty-reward-points'>{ reward.points} Punches</p>
                                </div>
                            </div>
                        </div>
                            ) :
                        <div className='pl-5 mb-4'>
                            <Text>No available rewards.</Text>
                        </div>
                        }
                    {!isLoyalty && store.used_punch_cards && store.used_punch_cards.length ?
                        <>
                            <div className='app-card-subheader'>
                                Reward History
                            </div>
                            <div className='user-info'>
                                <p>Past rewards for this customer.</p>
                            </div>
                            {store.used_punch_cards.map(reward =>
                                <div key={reward.id}>
                                    <div className='rewards-container'>
                                        <div style={{ marginRight: 10 }}>
                                            <FontAwesomeIcon icon="fa-solid fa-trophy-star" className='brightGreen'/>
                                        </div>
                                        <div>
                                            <p className='loyalty-reward-text'>{ this.punchCardReward() }</p>
                                            <p className='loyalty-reward-points'>{ reward.points } Punches, { moment(reward.date_used).local().format('MMM D, YYYY') }</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <div className='pl-5 mb-4'>
                            <Text>No past rewards.</Text>
                        </div>
                        }
                    </div>
                </div>
                </AdminCard>
            </div>
        );
      }
    }

      export default observer(UserLoyalty);
