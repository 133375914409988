import React, { Component } from "react";
import WaitTimeEdit from "./waitTimeEdit";
import AdminCard from '../elements/adminCard';
import { Text } from "../elements/text";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class WaitTime extends Component {
  state = {
    open: false
  };

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { location } = this.props;
    return (
      <div>
        <AdminCard
          largeTitle
          title="Wait Time"
          description="Wait time is the estimated number of minutes a customer is told their order will take to prepare. This wait time equation will determine how a customer's estimated wait time is calculated."
          onHeaderActionClick={this.toggleDrawer}
          toolTip={ <><Text>Need more info on how the wait time is calculated? Visit our knowledge base article to see examples.</Text><br /><a href="http://info.ordrslip.com/support/wait-time-formula" target="_blank" rel="noopener noreferrer" className="ordrslipRed">info.ordrslip.com/support/wait-time-formula</a></>}
        >
          <Table className="app-table-wrapper">
            <TableHead className="app-table-header">
              <TableRow className="app-table-row">
                <TableCell className="app-table-cell pl-0" style={{width: 150}}>
                    Type
                </TableCell>
                <TableCell className="app-table-cell">
                    Wait Time Formula
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="app-table-body">
              <TableRow>
                <TableCell className='app-table-cell pl-0'>
                  Pickup
                </TableCell>
                <TableCell className="app-table-cell">
                  <span className='semi-bold'>{location.wait_time_base_pickup} min base time</span> + {"("}# orders ahead in queue <span className='semi-bold'>* {location.wait_time_per_order} min order wait time</span>{")"} + {"(("}# items in current order + # items ahead in queue{")"}<span className='semi-bold'> * {location.wait_time_per_item} min item wait time</span>{")"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-table-cell pl-0">
                  Delivery
                </TableCell>
                <TableCell className="app-table-cell">
                  <span className='semi-bold'>{location.wait_time_base_delivery} min base time</span> + {"("}# orders ahead in queue <span className='semi-bold'>* {location.wait_time_per_order} min order wait time</span>{")"} + {"(("}# items in current order + # items ahead in queue{")"}<span className='semi-bold'> * {location.wait_time_per_item} min item wait time</span>{")"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AdminCard>
        <WaitTimeEdit
          open={this.state.open}
          onClose={this.toggleDrawer}
          location={ location }
          onUpdate={ this.props.onUpdate }
        />
      </div>
    );
  }
}

export default WaitTime;
