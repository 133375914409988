import React, { Component } from "react";
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { colors } from "../../styles/global";

const StyledTooltip = withStyles(() => ({
    arrow: {
        color: colors.white,
    },
    tooltip: {
        backgroundColor: colors.white,
        color: colors.primaryBlack,
        boxShadow: "0px 0px 5px 0px rgba(51, 46, 46, 0.4);",
        fontSize: 14,
        padding: 20,
        borderRadius: 8
    }
}))(Tooltip);

class AdminToolTip extends Component {
    render() {
        return (
            <StyledTooltip title={ this.props.title } arrow>
                { this.props.icon ? this.props.icon :
                <IconButton className='help-icon' aria-label='help'>
                    <HelpIcon fontSize='small' />
                </IconButton>
                }
            </StyledTooltip>
        )
    }
}

export default AdminToolTip;