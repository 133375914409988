import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import LoadingSpinner from "./loadingSpinner";

class LoadingBox extends Component {

  render() {

    const { message, ...other } = this.props;

    return (
      <Dialog
        { ...other }
      >
        <div className="loading-box" style={{ marginTop: 0 }}>
          <div className="spinner-wrapper">
            <LoadingSpinner />
          </div>
          <div className="text-wrapper">
            { typeof message === 'string' ? message : 'Working on it...' }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default LoadingBox;
