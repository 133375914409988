import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Card } from "./cards";
import { AdminDataGrid } from "./tables";
import _ from "lodash";
import { TableRowButton } from "./buttons";

class AdminTable extends Component {

    addRowEditButtons = (columnData, pathBeforeId, routeHistory) => {
        let modifiedColumnData = _.cloneDeep(columnData);
        let editButtonColumn = {
            field: "id",
            flex: 1,
            headerName: " ",
            renderCell: params => (
                <TableRowButton
                    className="tableRowButton"
                    onClick={() => {
                        if (routeHistory) {
                            routeHistory.push(`${pathBeforeId}${params.value}`);
                        } else {
                            this.props.showRowEditButtonForModal(params.id);
                        }
                    }}
                >
                    Edit
                </TableRowButton>
            )
        };
        modifiedColumnData.push(editButtonColumn);
        return modifiedColumnData;
    }

    handleRowClick = (rowParams, showRowEditButtonsByParams) => {
        const isRowSelected = rowParams.element.className.includes('Mui-selected');
        if (showRowEditButtonsByParams && isRowSelected) {
            let { pathBeforeId, routeHistory } = showRowEditButtonsByParams;
            routeHistory.push(`${pathBeforeId}${rowParams.id}`);
        } else if (!showRowEditButtonsByParams && isRowSelected) {
            if (this.props.showRowEditButtonForModal) {
                this.props.showRowEditButtonForModal(rowParams.id);
            } else return;
        }
    }

    dataGrid = () => {
        let {
            columnData,
            rowData,
            noCardBorder, // Add for tables that already appear within cards.
            showRowEditButtonsByParams, // Add to show "Edit" buttons on rows that opens details screen
            showRowEditButtonForModal, // Add for Edit buton on rows that opens modal
            checkboxSelection, // Add to show checkboxes.
            onSelectionModelChange, // Add to manage checkbox changes.
        } = this.props;

        if (showRowEditButtonsByParams || showRowEditButtonForModal) {
            let { pathBeforeId, routeHistory } = showRowEditButtonsByParams || {};
            columnData = this.addRowEditButtons(columnData, pathBeforeId, routeHistory);
        }

        return (
            <AdminDataGrid
                autoHeight
                disableColumnMenu
                hideFooter
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={onSelectionModelChange ? (params) => onSelectionModelChange(params) : null}
                rows={rowData}
                onRowClick={rowParams => this.handleRowClick(rowParams, showRowEditButtonsByParams)}
                columns={columnData} />
        );
    }


    render() {
        let { noCardBorder  } = this.props; // Add for tables that already appear within cards.

        return (
            <React.Fragment>
                {noCardBorder ? (
                    this.dataGrid()
                ) : (
                    <Card>
                        {this.dataGrid()}
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

AdminTable.propTypes = {
    columnData: PropTypes.arrayOf(
        PropTypes.exact({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            renderCell: PropTypes.func,
          })
    ).isRequired,
    rowData: PropTypes.array.isRequired,
    noCardBorder: PropTypes.bool,
    showRowEditButtonsByParams: PropTypes.exact({
        pathBeforeId: PropTypes.string.isRequired,
        routeHistory: PropTypes.object.isRequired,
    }),
    checkboxSelection: PropTypes.bool,
    onSelectionModelChange: PropTypes.func,
}

export default AdminTable;