import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));


export default class messagesAPI {
    static getBroadcasts(params) {
        return new Promise((resolve, reject) => {
            let paramString = _.reduce(params, (result, value, key) => {
                return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
            }, '?');
            let url = `${ base_url }tenants/${ store.tenantId }/broadcast/messages/${ paramString }`;
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveBroadcasts(params, data, t0) {
        if (!t0) t0 = performance.now();  // Leaving this here to monitor performance on production
        let t1 = 0;
        store.updateLoading(true);
        let response = await this.getBroadcasts(params);
        let new_data = data;

        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveBroadcasts(params, new_data, t0);
        } else {
            t1 = performance.now();
            new_data = _.union(new_data, response['data']);
            if (params.status === "pending") {
                store.updateScheduledBroadcasts(new_data);
            } else if (params.status === "completed") {
                store.updateSentBroadcasts(new_data);
            }
        }
        if (t1) console.log(`Call to getRecursiveBroadcasts took ${ t1 - t0 } milliseconds.`);
        store.updateLoading(false);
        return new_data;
    }

    static updateBroadcastMessage(messageId, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(
                base_url +
                "tenants/" +
                store.tenantId +
                "/broadcast/messages/" +
                messageId +
                "/",
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + store.token
                    },
                    body: data
                }
            )
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.errors && response.errors.__all__) {
                        store.updateError(response.errors.__all__[0].message);
                    } else if (response.non_field_errors) {
                        store.updateError(response.non_field_errors[0]);
                    } else {
                        store.updateError(null);
                        store.updateBroadcastMessage(response.data);
                    }
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static createBroadcastMessage(payload) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            let url = `${ base_url }tenants/${ store.tenantId }/broadcast/messages/`;
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: payload
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    if (response.errors) {
                        let msg = response.errors[Object.keys(response.errors)[0]][0].message;
                        store.updateError(msg);
                        store.updateLoading(false);
                    } else {
                        store.updateError(null);
                        this.getRecursiveBroadcasts({ status: "pending", page: 1 }).then(() => store.updateLoading(false))
                    }
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getBroadcastById(message_id, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/broadcast/messages/" + message_id + "/?show_user_count", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static deleteBroadcastMessage(messageId) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(
                base_url +
                "tenants/" +
                store.tenantId +
                "/broadcast/messages/" +
                messageId +
                "/",
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + store.token
                    },
                    body: null
                }
            )
                .then(response => {
                    if (response.error) {
                        store.updateError(response.error);
                    } else {
                        store.updateError(null);
                        _.remove(store.scheduledBroadcasts, { 'id': messageId });
                    }
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getNotifications(params) {
        params.omit_orders = 1;
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveNotifications(params, data) {
        store.updateLoading(true);
        let response = await this.getNotifications(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveNotifications(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            if (params.status === "scheduled") {
                store.updateScheduledNotifications(response.data);
            } else if (params.status === "sent") {
                store.updateSentNotifications(response.data);
            }
        }
        store.updateLoading(false);
        return new_data;
    }

    static async createPushNotification(body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    this.getRecursiveNotifications({ 'page': 1, 'status': 'scheduled' }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async addPushNotificationImage(notificationId, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/${ notificationId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Authorization: "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async updatePushNotification(notificationId, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/${ notificationId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    this.getRecursiveNotifications({ 'page': 1, 'status': 'scheduled' }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    });
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async sendTestPushNotification(notificationId, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/${ notificationId }/test`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async deletePushNotification(notificationId) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/push-notifications/${ notificationId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: {}
            })
                .then(response => {
                    this.getRecursiveNotifications({ 'page': 1, 'status': 'scheduled' }).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

}