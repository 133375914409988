import React, { Component } from 'react';
import { observer } from 'mobx-react';

import store from '../../store/store';
import { formatDateTimeForView } from '../../services/utils';
import { Text } from '../elements/text';

class PushNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: store.activeTheme
        }
    }
    componentWillMount = () => {

    }
    render() {
        const { notification } = this.props;
        const { theme } = this.state;

        return (
            <div
                style={ { margin: 40 } }
                className={
                    notification.image
                        ? 'messageBorder'
                        : 'messageBorder border-padding'
                }
            >
                { notification.image ? (
                    <div>
                        <img src={ notification.image } alt='Name Here' width='100%' />
                    </div>
                ) : null }
                <div
                    className={
                        notification.image
                            ? 'inside-table-padding'
                            : ''
                    }
                >
                    <div
                        className='notification-title'
                        style={ { color: theme.primary_button_color } }
                    >
                        { notification.title }
                    </div>
                    <Text small weight='semi-bold' color={ theme.paragraph_text_color }>
                        { formatDateTimeForView(notification.notification_date) }
                    </Text>
                    <div style={ { paddingTop: 15 } }>{ notification.message }</div>
                    { notification.action_link &&
                        notification.action_link.url_text ? (
                        <div style={ { textAlign: 'center', paddingTop: 20 } }>
                            <a
                                href={ notification.action_link.url_link }
                                style={ { color: theme.secondary_button_color } }
                                target='_blank'
                                rel='noopener noreferrer'
                                className='semi-bold'
                            >
                                { notification.action_link.url_text }
                            </a>
                        </div>
                    ) : null }
                </div>
            </div>
        );
    }
}

export default observer(PushNotification);
