import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { observer } from "mobx-react";
import AdminCard from './elements/adminCard';
import { Button } from "./elements/buttons";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validatePassword } from '../services/utils';
import accountsAPI from '../services/api/accountsAPI';

class PasswordResetDialog extends Component {
  state = {
    activeStep: 1,
    email: '',
    reset_code: '',
    new_password: '',
    confirm_password: '',
    tenant_name: '',
    error: '',
    tenantRequired: false,
    number: false,
    lower: false,
    upper: false,
    passwordError: '',
    confirmPasswordError: ''
  };

  handleDialogClose = () => {
    this.props.onClose();
    this.clearStateValues();
    this.setState({ activeStep: 1 });
  };

  checkPasswordIsValid() {
    let error = '';
    if (!this.state.lower) {
      error = "Password requires a lowercase letter";
    } else if (!this.state.upper) {
      error = "Password requires a capital letter";
    } else if (!this.state.number) {
      error = "Password requires a number";
    }
    this.setState({ passwordError: error });

    if (this.state.confirmPasswordError) {
      error = "Passwords do not match.";
      this.setState({ confirmPasswordError: error });
      return false;
    } else if (!this.state.lower || !this.state.upper || !this.state.number) {
      error = "Check Password";
      this.setState({ confirmPasswordError: error });
      return false;
    } else {
      this.setState({ confirmPasswordError: '' });
      return true;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.activeStep === 1) {
      let payload = this.state.tenantRequired ? { email: this.state.email, tenant_name: this.state.tenant_name } : { email: this.state.email }
      accountsAPI.requestPasswordResetCode(payload)
        .then(response => {
          if (response.errors && response.errors.email) {
            this.setState({ error: response.errors.email[0] });
          } else if (response.errors && response.errors.__all__) {
            this.setState({error: response.errors.__all__[0]})
            if (response.errors.__all__[0] === "Please enter the name of your restaurant.") {
              this.setState({tenantRequired: true})
            }
          } else {
            this.setState({ error: '' });
            this.incrementStep();
          }
        })
    } else if (this.state.activeStep === 2) {
      this.incrementStep();
    } else if (this.state.activeStep === 3) {
      if (this.checkPasswordIsValid()) {
        let payload = {
          code: this.state.reset_code,
          email: this.state.email,
          password: this.state.new_password,
          tenant_name: this.state.tenant_name
        }
        accountsAPI.resetPassword(payload)
          .then((response) => {
            if (response.errors) {
              this.setState({ error: response.errors.__all__[0] })
              if (response.errors.__all__[0] === "Password reset code is not valid or has expired.") {
                this.decrementStep();
              }
            } else {
              this.incrementStep();
              this.clearStateValues();
            }
          })
      }
    }
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
    // Validate Password
    if (name === 'new_password') {
      let { passwordError, lower, upper, number } = validatePassword(value);
      this.setState({
        passwordError: passwordError,
        lower: lower,
        upper: upper,
        number: number,
      });
    } else if (name === 'confirm_password') {
      if (value === this.state.new_password) {
        this.setState({confirmPasswordError: ''})
      } else {
        this.setState({ confirmPasswordError: "Passwords do not match." })
      }
    }
  }

  incrementStep = () => {
    this.setState({ activeStep: this.state.activeStep + 1 })
  }

  decrementStep = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  }

  clearStateValues = () => {
    this.setState({ email: '', tenant_name: '', code: '', new_password: '', confirm_password: '', error: null, tenantRequired: false });
  }

  getDescription() {
    return this.state.activeStep === 1 ? "Please enter your email address so we can send you a reset code." : this.state.activeStep === 2 ? "A code was sent to your email. Enter the code here to reset your password." : this.state.activeStep === 3 ? "Enter a new password. (Must contain at least one uppercase letter, one lowercase letter, and one number.)" : null;
  }

  render() {
    const { ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleDialogClose}
        aria-labelledby="password-reset-dialog"
        {...other}
      >
        <AdminCard
          title="Reset Password"
          description={this.getDescription()}
          style={{ marginTop: 0, padding: 30, maxWidth: 380}}
        >
          {this.state.error && (
            <div className="error-message mt-0 mb-3">
              <ErrorOutline className="error-icon" />
              {this.state.error}
            </div>
          )}
          <form onSubmit={this.handleSubmit}>
            {this.state.activeStep === 1 ? 
              <>
                  <div className="app-field-wrapper">
                    <label htmlFor="email" className="app-field-label">
                      Email*
                    </label>
                    <input
                      className="app-input-field"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email*"
                      value={this.state.email}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                  {this.state.tenantRequired === true && (
                    <div className="app-field-wrapper">
                      <label htmlFor="tenant_name" className="app-field-label">
                        Restaurant Name*
                      </label>
                      <input
                        id="tenant_name"
                        name="tenant_name"
                        type="text"
                        placeholder="Restaurant Name*"
                        value={this.state.tenant_name}
                        className="app-input-field"
                        onChange={(event) => this.handleChange(event)}
                      />
                    </div>
                  )}
              </> :
            this.state.activeStep === 2 ? 
                <div className="app-field-wrapper">
                  <label htmlFor="reset_code" className="app-field-label">
                    Reset Code*
                  </label>
                  <input
                    className="app-input-field"
                    id="reset_code"
                    name="reset_code"
                    type="reset_code"
                    placeholder="Reset Code*"
                    value={this.state.reset_code}
                    onChange={(event) => this.handleChange(event)}
                  />
                </div> :
                this.state.activeStep === 3 ? 
                  <>
                    <div className="app-field-wrapper">
                      <label htmlFor="new_password" className="app-field-label">
                        New Password
                      </label>
                      <input
                        className="app-input-field"
                        id="new_password"
                        name="new_password"
                        type="password"
                        placeholder="New Password"
                        value={this.state.new_password}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <div className="password-check">
                        AAA
                        {this.state.upper ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={['fal', 'check']} /> : null}
                        | aaa
                        {this.state.lower ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={['fal', 'check']} /> : null}
                        | 123
                        {this.state.number ? <FontAwesomeIcon size="lg" className='check-password-icon' icon={['fal', 'check']} /> : null}
                      </div>
                      <div className="red-error-text smaller-left-spacing">
                        {this.state.passwordError}
                      </div>
                    </div>
                    <div className="app-field-wrapper">
                      <label htmlFor="confirm_password" className="app-field-label">
                        Confirm Password
                      </label>
                      <input
                        className="app-input-field"
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirm_password}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <div className="red-error-text smaller-left-spacing">
                        {this.state.confirmPasswordError}
                      </div>
                    </div>
                  </> :
                  this.state.activeStep === 4 ? 
                    <div className="mt-0 mb-3" style={{padding: "20px 0"}}>
                      <FontAwesomeIcon className='check-password-icon' icon={['fas', 'check-circle']} />
                      Your password was successfully reset!
                    </div>
              : null}
              </form>
          <div className='pt-3'>
            <Button
              type="button"
              onClick={this.handleDialogClose}
            >
              {this.state.activeStep === 4 ? 'Close' : 'Cancel'}
            </Button>
            {this.state.activeStep !== 4 ?
              <Button
              primary
              type="submit"
              onClick={this.handleSubmit}
            >
              {this.state.activeStep === 2 ? 'Next' : 'Submit'}
            </Button> : null}
          </div>
        </AdminCard>
      </Dialog>
    );
  }
}

export default observer(PasswordResetDialog);
