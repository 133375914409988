import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';

import menuAPI from '../../services/api/menuAPI';
import store from '../../store/store';
import { fieldError, errorMessage, weekdays } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import HoursEditCard from '../hoursEditCard';
import LocationSelection from '../locationSelection';


class MenuAddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: {},
            error: '',
            menu: {
                name: '',
                availability: [],
                locations: [],
                categories: []
            },
        };
    };

    componentWillReceiveProps = nextProps => {
        if (nextProps.menu && nextProps.menu.id !== this.state.menu.id) {
            this.setHours(nextProps.menu);
        }
    };

    setHours = menu => {
        let hours = [];
        weekdays.forEach(day => {
            let menuDay = _.find(toJS(menu.availability), { weekday: day });
            hours.push({
                weekday: day,
                start_time: menuDay ? menuDay.start_time : null,
                end_time: menuDay ? menuDay.end_time : null,
                closed: !Boolean(menuDay && menuDay.start_time)
            });
        });
        menu.availability = hours;
        this.setState({ menu });
    };

    componentDidMount = () => {
        this.setHours(this.state.menu);
    };

    handleClose = updated => {
        this.props.onClose(updated);
    };

    handleChange(event) {
        let { menu } = this.state;
        const value = event.target.value;
        const name = event.target.name;
        menu[name] = value;
        this.setState({ menu });
    };

    handleSubmit = () => {
        const { menu } = this.state;
        let payload = {
            name: menu.name,
            locations: menu.locations
        };
        let availability = _.filter(toJS(store.selectedHours), (item => { return item.start_time; })); // Filter out null hours for api
        availability.forEach(item => delete item.closed); // Remove 'closed' property for api
        payload.availability = availability;

        if (menu.id) {
            // Update Menu
            menuAPI.updateMenu(menu.id, JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        } else {
            // Create Menu
            menuAPI.postMenu(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                        if (response.errors.__all__) this.setState({ error: response.errors.__all__[0].message });
                    }
                    else {
                        this.handleClose(true);
                    }
                });
        }
    };

    updateLocations = locations => {
        let { menu } = this.state;
        menu.locations = locations;
        this.setState({ menu });
    };

    deleteMenu = () => {
        menuAPI.deleteMenu(this.state.menu.id)
            .then(() => {
                this.setState({ open: false });
                this.props.history.push('/catalog/menus');
            });
    };

    render() {
        const { open } = this.props;
        const { menu, apiErrors, error } = this.state;

        return (
            <Drawer
                anchor='right'
                open={ open }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
                onClose={ () => this.handleClose(false) }
            >
                <EditDrawer
                    title={ menu.id ? 'Edit Menu Details' : 'Create New Menu' }
                    onClose={ () => this.handleClose(false) }
                    onSubmit={ this.handleSubmit }
                    errorMessage={ errorMessage(this.state.apiErrors) }
                    deleteButton={ Boolean(menu.id) }
                    buttonAction={ this.deleteMenu }
                    buttonText="Delete Menu"
                >
                    <AdminCard
                        title='Menu Details'
                        style={ { marginTop: '15px' } }
                    >
                        <div className='app-field-wrapper'>
                            <label className='app-field-label' htmlFor='name' style={ { marginTop: '15px' } }>Menu Name</label>
                            <input
                                className={ `app-input-field mt-0 ${ fieldError('name', apiErrors) ? 'red-border' : '' }` }
                                id='name'
                                name='name'
                                type='text'
                                placeholder='Menu Name'
                                value={ menu.name }
                                onChange={ (event) => this.handleChange(event) }
                            />
                            { fieldError('name', apiErrors) }
                        </div>
                    </AdminCard>
                    <HoursEditCard
                        apiErrors={ apiErrors }
                        error={ error }
                        hours={ menu.availability }
                        hoursType={ 'Menu' }
                        payload={ menu.availability }
                    />
                    <LocationSelection
                        selectedLocations={ menu.locations }
                        itemName={ 'menu' }
                        onUpdateLocations={ this.updateLocations }
                        fieldError={ fieldError('locations', apiErrors) }
                    />
                </EditDrawer>
            </Drawer>
        );
    }
}

export default withRouter(observer(MenuAddEdit));
