import React, { Component } from "react";
import tenantAPI from '../../services/api/tenantAPI';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';

class ToggleOrdering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      is_enabled: this.props.location.is_enabled
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.location && nextProps.location !== this.state.location)
      this.setState({
        location: nextProps.location,
        is_enabled: nextProps.location.is_enabled
      });
  }

  toggleEnabled = () => {
    const location = this.state.location;
    let is_enabled = this.state.is_enabled === true ? false : true;
    const values = JSON.stringify({ is_enabled: is_enabled });
    tenantAPI.updateLocation(location.id, values)
      .then(response => this.props.onUpdate(response));
  };

  render() {

    return (
      <AdminCard
        largeTitle
        title="Enable/Disable Mobile Ordering"
        description="When enabled, users will be able to see this location on the mobile app and online ordering. To allow users to place an order from this location, you must at least have one fulfillment integration enabled."
      >
        <AdminToggle
          className="app-toggle"
          checked={this.state.is_enabled || false}
          onChange={ this.toggleEnabled }
        />
      </AdminCard>
    );
  }
}

export default ToggleOrdering;
