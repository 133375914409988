import React, { Component } from 'react';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import menuAPI from '../../services/api/menuAPI';
import store from '../../store/store';
import { errorMessage } from '../../services/utils';
import AdminCard from '../elements/adminCard';
import AdminToggle from '../elements/adminToggle';
import EditDrawer from '../elements/editDrawer';


class ModifierEdit extends Component {
  constructor(props) {
      super(props);
      this.state = {
        apiErrors: {},
        modifier: _.cloneDeep(this.props.modifier),
      };
  };

  componentWillReceiveProps(nextProps) {
      if (nextProps.modifier !== this.props.modifier) {
          this.setState({
              modifier: _.cloneDeep(nextProps.modifier),
          });
      }
  };

  toggleEnabled = () => {
      let { modifier } = this.state;
      modifier.is_enabled = !modifier.is_enabled;
      this.setState({ modifier });
  };

  handleSubmit = () => {
    const { modifier } = this.state;
    let payload = { is_enabled: modifier.is_enabled };
    if (modifier.is_enabled) {
      payload.out_of_stock_locations = modifier.out_of_stock_locations;
      payload.hide_at_locations = modifier.hide_at_locations;
    };
    menuAPI.updateModifier(modifier.id, JSON.stringify(payload))
        .then(response => {
            if (response.errors) {
                this.setState({ apiErrors: response.errors });
            } else {
                this.setState({ apiErrors: {} });
                this.handleClose(true);
            }
        });
  };

  handleClose = updated => {
    this.setState({ modifier: _.cloneDeep(this.props.modifier) });
    this.props.onClose(updated);
  };

  toggleOosAtLocation = locationId => {
    const { modifier } = this.state;
    if (modifier.out_of_stock_locations.includes(locationId)) {
      let idx = modifier.out_of_stock_locations.indexOf(locationId);
      modifier.out_of_stock_locations.splice(idx, 1);
    } else {
      modifier.out_of_stock_locations.push(locationId);
    }
    this.setState({ modifier });
  };

  toggleHideAtLocation = locationId => {
    const { modifier } = this.state;
    if (modifier.hide_at_locations.includes(locationId)) {
      let idx = modifier.hide_at_locations.indexOf(locationId);
      modifier.hide_at_locations.splice(idx, 1);
    } else {
      modifier.hide_at_locations.push(locationId);
    }
    this.setState({ modifier });
  };

  render() {
    const { modifier } = this.state;
    if (_.isEmpty(modifier)) return <div />;
    return (
      <Drawer
          anchor='right'
          open={ this.props.open }
          onClose={ () => this.handleClose(false) }
          classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
      >
        <EditDrawer
            title={`Edit ${modifier.name}`}
            onSubmit={ this.handleSubmit }
            onClose={ () => this.handleClose(false) }
            errorMessage={ errorMessage(this.state.apiErrors) }
        >
          <AdminCard
              title='Enable/Disable Modifier'
              style={ { marginTop: '15px' } }
              description='When enabled, this modifier will appear on your mobile app and/or online ordering.'
          >
            <AdminToggle
                className='app-toggle'
                checked={ modifier.is_enabled }
                onChange={ this.toggleEnabled }
            />
          </AdminCard>
          { modifier.is_enabled ?
            <AdminCard
              title='Modifier Availability'
              description='This modifier will show as available in the mobile app based on these settings.'
          >
            <div style={ { width: '100%', flex: 1, overflowX: 'auto' } }>
              <Table className='app-table-wrapper' size='small'>
                <TableHead className='app-table-header'>
                    <TableRow className='app-table-row'>
                      <TableCell className='app-table-cell pl-0 half-table-width'>
                          Location
                      </TableCell>
                      <TableCell className='app-table-cell pl-0 half-table-width'>
                          In Stock
                      </TableCell>
                      <TableCell className='app-table-cell'>
                          Displayed In App
                      </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  { store.locations &&
                    store.locations.length &&
                    store.locations.map(location => (
                      <React.Fragment key={ location.id }>
                        <TableRow
                          className='table-row'
                          onClick={ this.handleOnRowClick }
                        >
                          <TableCell className='app-table-cell pl-0'>
                            { location.name }
                          </TableCell>
                          <TableCell className='app-table-cell'>
                            <AdminToggle
                              className='app-toggle'
                              checked={ !modifier.out_of_stock_locations.includes(location.id) }
                              onChange={ () => this.toggleOosAtLocation(location.id) }
                              hideLabel
                            />
                          </TableCell>
                          <TableCell className='app-table-cell'>
                            <AdminToggle
                              className='app-toggle'
                              checked={ !modifier.hide_at_locations.includes(location.id) }
                              onChange={ () => this.toggleHideAtLocation(location.id) }
                              hideLabel
                            />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )) }
                  </TableBody>
                </Table>
              </div>
          </AdminCard> : null }
        </EditDrawer>
      </Drawer>
    );
  }
}

export default ModifierEdit;
