import React, { Component } from "react";
import PropTypes from 'prop-types';
import { StyledRadio } from "./radios";
import Lens from "@material-ui/icons/Lens";
import { FormControlLabel } from "@material-ui/core";

class RadioLabel extends Component {

    render() {
        const {
            value,
            label
        } = this.props;

        return(
            <FormControlLabel 
                value={value} 
                label={label} 
                control={<StyledRadio checkedIcon={<Lens />} />} 
            />
        )
    }
}

RadioLabel.propTypes = {
    value: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
}

export default RadioLabel;