import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { ErrorOutline } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from "../store/store";
import AdminPageHeader from "../components/elements/adminPageHeader";
import BroadcastsDrawer from '../components/messages/broadcastsDrawer';
import { Button } from '../components/elements/buttons';

import messagesAPI from '../services/api/messagesAPI';
import { formatDateTimeForView } from '../services/utils';
import AdminCard from '../components/elements/adminCard';


class Broadcasts extends Component {
  state = {
    sentMessages: store.sentBroadcasts,
    scheduledMessages: store.scheduledBroadcasts,
    locationError: '',
    inFocus: {},
    open: false,
    orderBy: 'broadcast_date',
    order: 'desc',
    currentPage: 0,
    rowsPerPage: 10,
    drawerType: ''
  };

  componentDidMount = () => {
      if (!store.scheduledBroadcasts.length) {
        messagesAPI.getRecursiveBroadcasts({'page': 1, 'status': 'pending'})
          .then(response => this.setState({ scheduledMessages: store.scheduledBroadcasts }));
      }
      if (!store.sentBroadcasts.length) {
        messagesAPI.getRecursiveBroadcasts({ 'page': 1, 'status': 'completed' })
          .then(response => this.setState({ sentMessages: store.sentBroadcasts }));
      }
  };

  addNew = () => {
    this.setState({ inFocus: {} });
    const locationOptions = this.getLocationOptions();
    if (locationOptions.length === 0) {
      this.setState({ 
        locationError: 'Cannot create an SMS message because all locations are currently disabled. Please enable a location to add a new SMS message.'
      });
    } else {
      this.toggleDrawer('edit');
    }
  }

  getLocationOptions = () => {
    let locationOptions = [];
    _.map(store.locations, location => {
      if (location.is_enabled)
        locationOptions.push({ value: location.id, label: location.name });
    });
    return locationOptions;
  };

  toggleDrawer = drawerType => {
    this.setState({ drawerType, open: !this.state.open });
  };

  viewBroadcast = broadcast_id => {
    messagesAPI.getBroadcastById(broadcast_id)
      .then(response => {
        this.setState({ inFocus: response, drawerType: 'view', open: !this.state.open });
      });
  };


  handleDialogClose = updated => {
    this.toggleDrawer('');
    store.updateError(null);
    if (updated) {
      setTimeout(() => {
        this.setState({ scheduledMessages: store.scheduledBroadcasts });
      }, 1500);
    }
  };

  getUserType = message => {
    const { user_type, user_days, location } = message;

    switch (true) {
      case Boolean(location):
        return 'By Location';

      case user_type === 'registered' && user_days === 'All':
        return 'All Signups';

      case user_type === 'registered' && user_days !== 'All':
        return 'Recent Signups';

      default:
        break;
    }
  };

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  handleSort(value) {
    let sentMessages = this.state.sentMessages;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'broadcast_date') {
      sentMessages = _.orderBy(sentMessages, [message => new Date(message.broadcast_date)], [order]);
    } else if (value === 'users') {
      sentMessages = _.orderBy(sentMessages, [message => this.getUserType(message)], [order]);
    } else {
      sentMessages = _.orderBy(sentMessages, [message => message[value].toLowerCase()], [order]);
    }
    this.setState({ sentMessages, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  filterMessages = (messageType) => {
    let sentMessages = this.state.sentMessages;
    if (messageType === 'All') {
      sentMessages = store.sentBroadcasts;
    } else {
      sentMessages = store.sentBroadcasts.filter(message => this.getUserType(message) === messageType);
    }
    this.setState({ sentMessages, currentPage: 0 });
  };

  renderMessagesFilter = messageType => {
    const messagesCount = _.countBy(store.sentBroadcasts, message => this.getUserType(message));
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterMessages(messageType) }>
          { messageType }{ messageType === 'All' ? ' Messages' : '' }
        </Button>
        ({ messageType === 'All' ? store.sentBroadcasts.length : messagesCount[messageType] || 0 })
      </React.Fragment>
    );
  };

  render() {
    const { scheduledMessages, sentMessages, inFocus, rowsPerPage, currentPage, order, orderBy, } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          title="SMS Messages"
          mainNav="Marketing"
          subNav="Messages"
          description="SMS messages are text message announcements you send to your customers. (Note that only one message can be sent per day.)"
          headerActionText="Add New"
          onHeaderActionClick={ this.addNew }
        />
        {this.state.locationError ?
        <div className="error-message not-bold">
          <ErrorOutline className="error-icon" />
          <span className="bold">ERROR: </span>{this.state.locationError}
        </div> : null}
        <div className="admin-page-content">
          <div>
            <AdminCard
              title="Scheduled SMS Messages"
              largeTitle
              description='SMS messages that have not been sent yet. (These are editable.)'
            >
              <Table className='app-table-wrapper'>
                <TableHead className='app-table-header'>
                  <TableRow className='app-table-row'>
                    <TableCell className='set-width thin app-table-cell'>Date</TableCell>
                    <TableCell className='set-width thin app-table-cell'>Users</TableCell>
                    <TableCell className='app-table-cell'>Message</TableCell>
                    <TableCell className='set-width app-table-cell' />
                  </TableRow>
                </TableHead>
                <TableBody className='app-table-body'>
                  { scheduledMessages.length ? scheduledMessages.map(message => (
                    <TableRow
                      className={ `app-table-row ${ this.state.inFocus.id === message.id ? "highlighted-row" : "" }` }
                      key={ message.id }
                      onMouseOver={ () => this.setState({ inFocus: message }) }
                    >
                      <TableCell className='set-width thin app-table-cell'>
                        { formatDateTimeForView(message.broadcast_date) }
                      </TableCell>
                      <TableCell className='set-width thin app-table-cell'>{ this.getUserType(message) }</TableCell>
                      <TableCell className='app-table-cell'>{ message.message }</TableCell>
                      <TableCell className='set-width app-table-cell'>
                        { inFocus.id === message.id ?
                          <Button edit onClick={ () => this.toggleDrawer('edit') }>
                            Edit
                          </Button>
                          : null }
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell colSpan={ 4 }>There are no scheduled messages.</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </AdminCard>
            <AdminCard
              title="Sent SMS Messages"
              largeTitle
              description='SMS messages that have already been sent. (These are not editable.)'
            >
              <div className='columns is-mobile is-vcentered mb-0'>
                <div className="column pb-0 is-full">
                  <div className='medium-text'>
                    { this.renderMessagesFilter('All') }<span className='pl-1 pr-1'> | </span>
                    { this.renderMessagesFilter('By Location') }<span className='pl-1 pr-1'> | </span>
                    { this.renderMessagesFilter('Recent Signups') }<span className='pl-1 pr-1'> | </span>
                    { this.renderMessagesFilter('All Signups') }
                  </div>
                </div>
              </div>
              <div className='divider' />
              <Table className='app-table-wrapper'>
                <TableHead className='app-table-header'>
                  <TableRow className='app-table-row'>
                    <TableCell className="app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "broadcast_date" }
                        direction={ orderBy === "broadcast_date" ? order : 'asc' }
                        onClick={ () => this.handleSort("broadcast_date") }
                        IconComponent={ () => this.getSortIcon("broadcast_date") }
                      >
                        Date
                        { orderBy === "broadcast_date" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "users" }
                        direction={ orderBy === "users" ? order : 'asc' }
                        onClick={ () => this.handleSort("users") }
                        IconComponent={ () => this.getSortIcon("users") }
                      >
                        Users
                        { orderBy === "users" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "message" }
                        direction={ orderBy === "message" ? order : 'asc' }
                        onClick={ () => this.handleSort("message") }
                        IconComponent={ () => this.getSortIcon("message") }
                      >
                        Message
                        { orderBy === "message" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className='set-width app-table-cell' />
                  </TableRow>
                </TableHead>
                <TableBody className='app-table-body'>
                  { sentMessages.length ?
                    <React.Fragment>
                      { (rowsPerPage > 0 ? sentMessages.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : sentMessages).map((message) => (
                      <TableRow
                        className={ `app-table-row ${ this.state.inFocus.id === message.id ? "highlighted-row" : "" }` }
                        key={ message.id }
                        onMouseOver={ () => this.setState({ inFocus: message }) }
                      >
                        <TableCell className='set-width thin app-table-cell'>
                          { formatDateTimeForView(message.broadcast_date) }
                        </TableCell>
                        <TableCell className='set-width thin app-table-cell'>{ this.getUserType(message) }</TableCell>
                        <TableCell className='app-table-cell'>{ message.message }</TableCell>
                        <TableCell className='set-width app-table-cell'>
                          { inFocus.id === message.id ?
                            <Button edit onClick={ () => this.viewBroadcast(message.id) }>
                              View
                            </Button>
                            : null }
                        </TableCell>
                      </TableRow>
                      )) }
                      <TableRow className="app-table-row">
                        <TablePagination
                          rowsPerPageOptions={ [10, 25, 50] }
                          count={ sentMessages && sentMessages.length ? sentMessages.length : 0 }
                          rowsPerPage={ this.state.rowsPerPage }
                          onRowsPerPageChange={ (event) => this.updateRows(event) }
                          page={ this.state.currentPage }
                          onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                          style={ { borderBottom: 'none' } }
                        />
                      </TableRow>
                    </React.Fragment>
                      :
                    <TableRow>
                      <TableCell colSpan={ 4 }>There are no sent messages.</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </AdminCard>
          </div>
        </div>
        <BroadcastsDrawer
          onClose={this.handleDialogClose}
          message={this.state.inFocus}
          drawerType={this.state.drawerType}
          open={this.state.open}
          aria-labelledby="broadcast-edit-dialog"
        />
      </div>
    );
  }
}

export default observer(Broadcasts);
