import React, { Component } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination, TableSortLabel } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from "../store/store";
import { formatDateTimeForView } from "../services/utils";
import AdminCard from "../components/elements/adminCard";
import AdminPageHeader from "../components/elements/adminPageHeader";
import { Button } from '../components/elements/buttons';
import { Text } from "../components/elements/text";
import PushNotificationDrawer from '../components/messages/pushNotificationDrawer';
import tenantAPI from '../services/api/tenantAPI';
import messagesAPI from '../services/api/messagesAPI';


class PushNotifications extends Component {
  state = {
    drawerType: '',
    inFocus: '',
    open: false,
    orderBy: 'notification_date',
    order: 'desc',
    currentPage: 0,
    rowsPerPage: 10,
    selectedNotification: {},
    sentNotifications: store.sentNotifications
  };

  componentDidMount = () => {
    if (!store.locations.length) {
      tenantAPI.getRecursiveLocations({ 'page': 1 })
        .then(() => {
          this.getNotifications();
          });
      } else {
      this.getNotifications();
    }
  };

  getNotifications = () => {
    tenantAPI.getActiveTheme();
    messagesAPI.getRecursiveNotifications({ 'page': 1, 'status': 'scheduled' });
    messagesAPI.getRecursiveNotifications({ 'page': 1, 'status': 'sent' })
      .then(() => this.setState({ sentNotifications: store.sentNotifications }));
  };

  handleClose = () => {
    this.setState({ open: false, selectedNotification: {}, drawerType: '' });
  };

  toggleDrawer = (notification, drawerType) => {
    this.setState({ selectedNotification: notification, drawerType, open: !this.state.open });
  };

  getUserType = notification => {
    const { user_type } = notification;

    switch (true) {
      case user_type === 'all':
        return 'By Location - All';

      case user_type === 'user':
        return 'By Location - Recent';

      case user_type === 'admin':
        return 'All Admin';

      case user_type === 'staff':
        return 'All Staff';

      default:
        break;
    }
  };

  handleSort(value) {
    let sentNotifications = this.state.sentNotifications;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'notification_date') {
      sentNotifications = _.orderBy(sentNotifications, [notification => new Date(notification.notification_date)], [order]);
    } else if (value === 'users') {
      sentNotifications = _.orderBy(sentNotifications, [notification => this.getUserType(notification)], [order]);
    } else {
      sentNotifications = _.orderBy(sentNotifications, [notification => notification[value].toLowerCase()], [order]);
    }
    this.setState({ sentNotifications, order, orderBy, currentPage: 0 });
  };

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  };

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  };

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  };

  filterNotifications = (notificationType) => {
    let sentNotifications = this.state.sentNotifications;
    if (notificationType === 'All') {
      sentNotifications = store.sentNotifications;
    } else {
      sentNotifications = store.sentNotifications.filter(notification => this.getUserType(notification) === notificationType);
    }
    this.setState({ sentNotifications, currentPage: 0 });
  };

  renderNotificationsFilter = notificationType => {
    const notificationsCount = _.countBy(store.sentNotifications, notification => this.getUserType(notification));
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterNotifications(notificationType) }>
          { notificationType }{ notificationType === 'All' ? ' Notifications' : '' }
        </Button>
        ({ notificationType === 'All' ? store.sentNotifications.length : notificationsCount[notificationType] || 0 })
      </React.Fragment>
    );
  };

  render() {
    const { sentNotifications, rowsPerPage, currentPage, order, orderBy } = this.state;

    return (
      <div className="admin-page-wrapper">
        <AdminPageHeader
          mainNav="Marketing"
          subNav="Messages"
          title="Push Notifications"
          description={
            <Text>
              <div>Push notifications will be displayed to your customers as an app notification and can be viewed when a</div>
              <div> customer is logged in to their account. <span className='bold ordrslipRed'>Note: Apps need to be on version 4.0 or higher to receive push notifications.</span></div>
            </Text>
          }
          headerActionText="Add New"
          onHeaderActionClick={() => this.toggleDrawer({}, 'edit')}
        />
        <div className="admin-page-content">
          <div>
            <AdminCard
              title="Scheduled Push Notifications"
              description="Push notifications that haven't been sent yet."
              largeTitle
            >
            {store.scheduledNotifications.length ? (
                <Table className="app-table-wrapper">
                  <TableHead className="app-table-header">
                    <TableRow className="app-table-row">
                      <TableCell className="set-width thin app-table-cell">Date</TableCell>
                      <TableCell className="set-width thin app-table-cell">Users</TableCell>
                      <TableCell className="app-table-cell">Title</TableCell>
                      <TableCell className="set-width app-table-cell" />
                    </TableRow>
                  </TableHead>
                  <TableBody className="app-table-body">
                    { store.scheduledNotifications && store.scheduledNotifications.map(notification => (
                      <TableRow
                        key={ notification.id }
                        className={ `app-table-row ${ this.state.inFocus === notification.id ? 'highlighted-row ' : '' }` }
                        onMouseOver={ () => this.setState({ inFocus: notification.id }) }
                      >
                        <TableCell className="set-width thin app-table-cell">{ formatDateTimeForView(notification.notification_date) }</TableCell>
                        <TableCell className="set-width thin app-table-cell">{ this.getUserType(notification) }</TableCell>
                        <TableCell className="app-table-cell">{ notification.title }</TableCell>
                        <TableCell className="set-width app-table-cell">
                          { this.state.inFocus === notification.id ?
                            <Button edit onClick={ () => this.toggleDrawer(notification, 'edit') }>
                            Edit
                            </Button>
                          : null }
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
            ) :
              <Text>There are no scheduled notifications.</Text>}
            </AdminCard>
            <AdminCard
              title="Sent Push Notifications"
              description="Push notifications that have been sent. (These are not editable.)"
              largeTitle
            >
              <div className='columns is-mobile is-vcentered mb-0'>
                <div className="column pb-0 is-full">
                  <div className='medium-text'>
                    { this.renderNotificationsFilter('All') }<span className='pl-1 pr-1'> | </span>
                    { this.renderNotificationsFilter('By Location - All') }<span className='pl-1 pr-1'> | </span>
                    { this.renderNotificationsFilter('By Location - Recent') }<span className='pl-1 pr-1'> | </span>
                    { this.renderNotificationsFilter('All Admin') }<span className='pl-1 pr-1'> | </span>
                    { this.renderNotificationsFilter('All Staff') }
                  </div>
                </div>
              </div>
              <div className='divider' />
                <Table className="app-table-wrapper">
                  <TableHead className="app-table-header">
                    <TableRow className="app-table-row">
                    <TableCell className="set-width thin app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "notification_date" }
                        direction={ orderBy === "notification_date" ? order : 'asc' }
                        onClick={ () => this.handleSort("notification_date") }
                        IconComponent={ () => this.getSortIcon("notification_date") }
                      >
                        Date
                        { orderBy === "notification_date" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                      </TableCell>
                    <TableCell className="set-width thin app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "users" }
                        direction={ orderBy === "users" ? order : 'asc' }
                        onClick={ () => this.handleSort("users") }
                        IconComponent={ () => this.getSortIcon("users") }
                      >
                        Users
                        { orderBy === "users" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                      </TableCell>
                    <TableCell className="app-table-cell">
                      <TableSortLabel
                        active={ orderBy === "title" }
                        direction={ orderBy === "title" ? order : 'asc' }
                        onClick={ () => this.handleSort("title") }
                        IconComponent={ () => this.getSortIcon("title") }
                      >
                        Title
                        { orderBy === "title" ? (
                          <span className='is-hidden'>
                            { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                          </span>
                        ) : null }
                      </TableSortLabel>
                      </TableCell>
                      <TableCell className="set-width app-table-cell" />
                    </TableRow>
                  </TableHead>
                  <TableBody className="app-table-body">
                  { sentNotifications.length ?
                    <React.Fragment>
                      { (rowsPerPage > 0 ? sentNotifications.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : sentNotifications).map((notification) => (
                        <TableRow
                          key={ notification.id }
                          className={ `app-table-row ${ this.state.inFocus === notification.id ? 'highlighted-row ' : '' }` }
                          onMouseOver={ () => this.setState({ inFocus: notification.id }) }
                        >
                          <TableCell className="set-width thin app-table-cell">{ formatDateTimeForView(notification.notification_date) }</TableCell>
                          <TableCell className="set-width thin app-table-cell">{ this.getUserType(notification) }</TableCell>
                          <TableCell className="app-table-cell">{ notification.title }</TableCell>
                          <TableCell className="set-width app-table-cell">
                            { this.state.inFocus === notification.id ?
                              <Button edit onClick={ () => this.toggleDrawer(notification, 'preview') }>
                                View
                              </Button>
                              : null }
                          </TableCell>
                        </TableRow>
                      )) }
                      <TableRow className="app-table-row">
                        <TablePagination
                          rowsPerPageOptions={ [10, 25, 50] }
                          count={ sentNotifications && sentNotifications.length ? sentNotifications.length : 0 }
                          rowsPerPage={ this.state.rowsPerPage }
                          onRowsPerPageChange={ (event) => this.updateRows(event) }
                          page={ this.state.currentPage }
                          onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                          style={ { borderBottom: 'none' } }
                        />
                      </TableRow>
                    </React.Fragment> :
                    <TableRow>
                      <TableCell colSpan={ 4 }>There are no sent notifications.</TableCell>
                    </TableRow>
                     }
                  </TableBody>
                </Table>
            </AdminCard>
          </div>
          <PushNotificationDrawer
            drawerType={this.state.drawerType}
            open={this.state.open}
            onClose={this.handleClose}
            toggleDrawer={this.toggleDrawer}
            notification={_.cloneDeep(this.state.selectedNotification)}
          />
        </div>
      </div>
    );
  }
}

export default observer(PushNotifications);
