import React, { Component } from "react";
import PropTypes from 'prop-types';
import { HeaderText, Text } from "./text";
import { Button } from "./buttons";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { colors } from '../../styles/global';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from 'react-router-dom';

class AdminPageHeader extends Component {
    navigateTertiary = () => {
        const { tertiaryLink, history } = this.props;
        if (tertiaryLink) {
            history.push(tertiaryLink);
        };
    };

    render() {
        const {
            title,
            description,
            headerActionText,
            onHeaderActionClick,
            mainNav,
            subNav,
            tertiaryNav,
            tertiaryLink
        } = this.props;

        return (
            <div>
                <div style={{ display: "flex", marginBottom: 5 }}>
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="medium" style={{ color: colors.secondaryGrey }} />}>
                        {mainNav ? <HeaderText large style={{ color: colors.secondaryGrey}}>{mainNav}</HeaderText>: null}
                        {subNav ? <HeaderText large style={{ color: colors.secondaryGrey }}>{subNav}</HeaderText> : null}
                        { tertiaryNav ?
                            <HeaderText
                                onClick={ this.navigateTertiary }
                                large
                                style={ { color: colors.secondaryGrey } }
                                className={tertiaryLink ? 'cursor-pointer' : ''}
                            >
                                { tertiaryNav }
                            </HeaderText> : null }
                        <HeaderText large style={{marginRight: 20}}>{title}</HeaderText>
                    </Breadcrumbs>
                </div>
                <Text large>
                    {description}
                </Text>
                {onHeaderActionClick && headerActionText && (
                <div className="pt-2">
                    <Button
                        primary
                        onClick={onHeaderActionClick}
                        className="app-button primary"
                    >
                        <FontAwesomeIcon size="lg" color="white" icon={['far', 'plus-circle']} />
                        <span className="pl-1">{headerActionText}</span>
                    </Button>
                </div>
                )}
            </div>
        );
    }
}

AdminPageHeader.propTypes = {
    title: PropTypes.string,
    mainNav: PropTypes.string,
    subNav: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headerActionText: PropTypes.string,
    onHeaderActionClick: PropTypes.func,
}

export default withRouter(AdminPageHeader);