import React, { Component } from 'react';
import placeholder from '../assets/img/placeholder.png';
import { Text } from '../components/elements/text';
import { displayImageName } from '../services/utils';

class ImageCard extends Component {
  chooseImage = event => {
    const file = event.target.files[0];
    event.target.value = null; // Clear input so user can reselect the same file
    URL.revokeObjectURL(this.props.fileUrl);
    this.props.updateImage(URL.createObjectURL(file), file);
  };

  deleteImage = () => {
    URL.revokeObjectURL(this.props.fileUrl);
    this.props.deleteImage();
  }

  render() {
    const {
      altText,
      delete_image,
      disable_delete,
      file,
      fileUrl,
      item,
      imgUrl,
      inputID
    } = this.props;

    return (
      <div>
        <div className='app-field-wrapper pt-3 mb-0'>
          <label className='app-field-label'>
            Image Preview
          </label>
        </div>
        <div
          style={ {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          } }
        >
          <input
            accept='image/*'
            style={ { display: 'none' } }
            id={ inputID ? inputID : 'contained-button-file' }
            multiple
            type='file'
            onChange={ this.chooseImage }
          />
          <label htmlFor={ inputID ? inputID : 'contained-button-file' }>
            <div
              type='button'
              style={ { paddingRight: 10, fontSize: 14 } }
              className='blueLinkText'
            >
              Choose a File
            </div>
          </label>
          { disable_delete ?
            null :
            <div
            type='button'
            className='redLinkText'
            onClick={this.deleteImage}
          >
            Delete Image
            </div>
          }
        </div>
        <Text style={ { marginTop: 2 } }>{ delete_image ? 'No File Chosen' : displayImageName(file, fileUrl, item) }</Text>
        <div className='app-image-wrapper'>
          <img src={ fileUrl ? fileUrl : imgUrl ? imgUrl : placeholder } alt={ altText } height='300' />
        </div>
      </div>
    );
  }
}

export default ImageCard;
