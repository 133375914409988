import React, { Component } from "react";
import withNav from "../components/withNav";
import _ from 'lodash';

import AdminCard from "../components/elements/adminCard";
import AdminPageHeader from "../components/elements/adminPageHeader";
import { BlueText, HeaderText, Text } from "../components/elements/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SupportEmailDrawer from "../components/supportEmailDrawer";
import tenantAPI from '../services/api/tenantAPI';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftArticle: {},
      rightArticle: {},
      open: false,
    }
  }

  componentDidMount = () => {
    tenantAPI.getArticles()
      .then(response => {
        this.setState({
          leftArticle: (_.find(response.data, { is_left: true })),
          rightArticle: (_.find(response.data, { is_right: true }))
        });
      })
  }

  render() {
    let { leftArticle, rightArticle } = this.state;
    return (
      <div>
        <div className='admin-page-wrapper'>
          <AdminPageHeader
            title='Ordrslip Admin Dashboard'
            description="Here's an overview of your business using Ordrslip. You'll also find helpful tips & tricks for managing your app."
          />
          <div>
            <div className="columns">
              <div className="column is-four-fifths">
                <AdminCard>
                  <HeaderText medium>Featured Knowledge Base Articles</HeaderText>
                  <div className="columns mt-3">
                    <div className="column is-5">
                      <div style={ { height: 300 } }>
                        <img style={ { borderRadius: 5, objectFit: 'cover', height: '100%', width: '100%' } } src={ leftArticle.image } alt={ leftArticle.title } />
                      </div>
                      <Text medium weight="bold" className="pt-2">
                        {leftArticle.title}
                      </Text>
                      <Text medium className="pt-2">
                        {leftArticle.description}
                      </Text>
                      <a href={leftArticle.url} target="_blank" rel="noopener noreferrer">
                        <BlueText medium weight="bold" underline className="pt-2">
                          View Article
                          <FontAwesomeIcon className='secondaryBlue pl-1' icon={ ['fas', 'angle-right'] } />
                        </BlueText>
                      </a>
                    </div>
                    <div className="column is-2"></div>
                    <div className="column is-5">

                      <div style={ { height: 300 } }>
                        <img style={ { borderRadius: 5, objectFit: 'cover', height: '100%', width: '100%' } } src={ rightArticle.image } alt={ "Free Support Kits to Help Drive App Usage!" } />
                      </div>
                      <Text medium weight="bold" className="pt-2">
                        {rightArticle.title}
                      </Text>
                      <Text medium className="pt-2">
                        {rightArticle.description}
                      </Text>
                      <a href={rightArticle.url} target="_blank" rel="noopener noreferrer">
                        <BlueText medium weight="bold" underline className="pt-2">
                          View Article
                          <FontAwesomeIcon className='secondaryBlue pl-1' icon={ ['fas', 'angle-right'] } />
                        </BlueText>
                      </a>
                    </div>
                  </div>
                  <div className="mt-4">
                    <HeaderText medium>Ordrslip Knowledge Base</HeaderText>
                    <Text medium>Still have questions? Want more tips and tricks? Visit our knowledge base or our Support Instagram for the best ways to utilize your app!</Text>
                    <div className="columns">
                      <div className="column is-one-fifths">
                        <a href="https://ordrslip.zohodesk.com/portal/en/kb/help-articles/" target="_blank" rel="noopener noreferrer">
                          <BlueText medium weight="bold" className="pt-2">
                            <FontAwesomeIcon className='secondaryBlue pr-1' icon={ ['fas', 'browser'] } />
                            Knowledge Base
                          </BlueText>
                        </a>
                      </div>
                      <div className="column is-two-fifths">
                        <a href="https://www.instagram.com/ordrslipsupport/" target="_blank" rel="noopener noreferrer">
                          <BlueText medium weight="bold" className="pt-2">
                            <FontAwesomeIcon className='secondaryBlue pr-1' icon={ ['fab', 'instagram'] } />
                            Ordrslip Support Instagram
                          </BlueText>
                        </a>
                      </div>
                      <div className="column is-one-fifth">
                        <BlueText medium weight="bold" className="pt-2 cursor-pointer" onClick={() => this.setState({ open: true })}>
                          Contact Support
                        </BlueText>
                      </div>
                    </div>
                  </div>
                </AdminCard>
              </div>
            </div>
          </div>
        </div>
        <SupportEmailDrawer open={this.state.open} onClose={() => this.setState({ open: false })} />
      </div>
    );
  }
}

export default withNav(Home);
