import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import { Text } from "../elements/text";
import moment from "moment";
import { getLocationNameById, getLocations } from "../../services/utils";
import store from "../../store/store";


class PromoCodeView extends Component {
    handleClose = () => {
        this.props.onClose();
    };

    getMaxRedemptions = (promoCode) => {
        switch (promoCode.max_redemptions_per_user) {
            case null:
                return "Unlimited Times"
            case 1:
                return "One Time"
            default:
                return `${promoCode.max_redemptions_per_user} Times`;
        }
    }

    getLocations(promoCode) {
        if (promoCode && promoCode.locations) {
            if (promoCode.locations.length) {
                return (
                    <div className='columns is-mobile is-vcentered is-multiline pl-0'>
                        {promoCode.locations.map((location, index) => (
                            <div
                                key={index}
                                style={{ marginLeft: 0 }}
                                className="small-text column is-half pb-0 pt-2"
                            >
                                <div className="columns is-mobile is-vcentered">
                                    <div style={{ paddingRight: 10 }} className="column is-one-fifth">
                                        <FontAwesomeIcon className="brightGreen" size="1x" icon={['fal', 'check']} />
                                    </div>
                                    <div
                                        className="column is-three-fifths"
                                        style={{ paddingLeft: 0, paddingRight: 10 }}>
                                        {getLocationNameById(location, store.locations)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            }
            return (<div className="small-text">
                        <Text>Valid at all locations.</Text>
                    </div>)
        }
        return null;
    }

    getDiscountAmount(promoCode) {
        if (promoCode.percent_off && promoCode.percent_off > 0) return `${promoCode.percent_off}% Off`;
        else if (promoCode.amount_off) return `${promoCode.amount_off.formatted} Off`;
        else return null;
    }

    render() {
        const { promoCode, duplicatePromo } = this.props;

        return (
                <EditDrawer
                title="View Promo Code"
                onClose={this.handleClose}
                noFooter
                topButton
                buttonAction={duplicatePromo}
                buttonText="Duplicate Promotion"
            >
                <AdminCard
                    title="Expired Promo Details"
                    style={{ marginTop: "15px" }}
                    >
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Promo Name</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.name}</Text>
                        </div>
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Promo Code</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.code}</Text>
                        </div>
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Promo Expiration</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                                <Text weight="semi-bold">{moment(promoCode.expiration).format("dddd MMM DD, YYYY")}</Text>
                        </div>
                    </div>
                    <div>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>Promo Can Be Redeemed By</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.max_redemptions ? promoCode.max_redemptions : "Unlimited"} Customers</Text>
                        </div>
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Number Of Times A Single Customer Can Redeem</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{this.getMaxRedemptions(promoCode)}</Text>
                        </div>
                    </div>
                </AdminCard>
                <AdminCard title="Expired Discount Options">
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Type/Amount</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.is_bogo ? 'Buy One Get One ' : null}{this.getDiscountAmount(promoCode)}</Text>
                        </div>
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Discount Applies To</div>
                        {promoCode.categories && promoCode.categories.length > 0 ? <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">Items in a certain category (or categories)</Text>
                        </div> : promoCode.items && promoCode.items.length > 0 ? <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">Certain item (or items)</Text>
                        </div> :
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">Any Item</Text>
                        </div>}
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Limit The Number Of Items The Discount Applies To</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.apply_items_amount ? promoCode.apply_items_amount : 'Unlimited'} {promoCode.apply_items_amount === 1 ? 'Item' : 'Items'}</Text>
                        </div>
                    </div>
                    <div>
                            <div className="app-field-label" style={{ marginTop: "15px" }}>Promo Can Be Redeemed By</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.max_redemptions ? promoCode.max_redemptions : "Unlimited"} Customers</Text>
                        </div>
                    </div>
                    <div>
                        <div className="app-field-label" style={{ marginTop: "15px" }}>Apply To Least/most Expensive Item In Order</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon icon="fa-light fa-star" size="lg" className='secondaryBlue pr-2 pb-1'/>
                            <Text weight="semi-bold">{promoCode.apply_to_minimum ? "Least" : "Most"} Expensive</Text>
                        </div>
                    </div>
                </AdminCard>
                <AdminCard title="Expired Location Selection" style={{marginBottom: 25}}>
                    <div className='pt-3 pb-3'>
                        {getLocations(promoCode)}
                    </div>
                </AdminCard>
            </EditDrawer>
        );
    }
}

export default (PromoCodeView);