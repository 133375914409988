import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "../store/store";
import ErrorDialog from "./errorDialog";
import SyncErrorDialog from "./syncErrorDialog";
import PosConnectionDialog from "./posConnectionDialog";

import config from "../config/config";

class ErrorDialogWrapper extends Component {

  handleDialogClose = () => {
    window.close();
    window.open(`${ config.base_url }tenants/${ store.tenant.id }/pos/${ store.tenant.pos_name }/authorize?redirect=${ config.link_url }?success`);
  }

  render() {
    return (
      <div>
        <ErrorDialog
          open={typeof store.showErrorDialog === 'boolean' ? store.showErrorDialog : false}
          />
        <SyncErrorDialog
          open={typeof store.showSyncErrorDialog === 'boolean' ? store.showSyncErrorDialog : false}
        />
        <PosConnectionDialog
          open={typeof store.posConnectionStatus === 'boolean' ? !store.posConnectionStatus : false}
          onClose={this.handleDialogClose} />

      </div>
    );
  }
}

export default observer(ErrorDialogWrapper);
