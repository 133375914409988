export const colors = {
    backgroundGrey: "#F0F0F0",
    brightGreen: "#55DA38",
    disabledGrey: "#AAAAAA",
    editHighlight: "#6B6C6C0F",
    errorRed: "#FF5252",
    navBarBlack: "#17181A",
    noticeYellow: "#FCDA00",
    primaryBlack: "#313131",
    ordrslipRed: "#F93822",
    secondaryBlue: "#0077CC",
    secondaryGrey: "#757575",
    tertiaryOrange: "#ED9A28",
    white: "#FFFFFF",
    darkGreen: "#3D9928",
    darkGray: "#35363A",
};
