import React, { Component } from "react";
import AdminCard from '../elements/adminCard';
import EditDrawer from '../elements/editDrawer';
import Drawer from '@material-ui/core/Drawer';
import { errorMessage, fieldError } from "../../services/utils";
import { HeaderText } from "../elements/text";
import tenantAPI from '../../services/api/tenantAPI';


class FutureOrdersMaxEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      max_concurrent_scheduled_pickup_orders: this.props.location.max_concurrent_scheduled_pickup_orders,
      max_concurrent_scheduled_delivery_orders: this.props.location.max_concurrent_scheduled_delivery_orders,
      apiErrors: {}
    };
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      max_concurrent_scheduled_pickup_orders: nextProps.location.max_concurrent_scheduled_pickup_orders,
      max_concurrent_scheduled_delivery_orders: nextProps.location.max_concurrent_scheduled_delivery_orders,
    });
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    let payload = {
      max_concurrent_scheduled_pickup_orders: this.state.max_concurrent_scheduled_pickup_orders || 0,
      max_concurrent_scheduled_delivery_orders: this.state.max_concurrent_scheduled_delivery_orders || 0,
    }
    tenantAPI.updateLocation(this.props.location.id, JSON.stringify(payload))
      .then(response => {
        if (response.errors) {
          this.setState({ apiErrors: response.errors });
        } else {
          this.setState({ apiErrors: {} });
          this.props.onUpdate(response);
          this.props.onClose();
        }
      })
      .catch(err => console.error(err));
  }

  render() {
    return (
      <Drawer anchor="right" open={this.props.open} onClose={this.props.onClose} classes={{ paper: "app-drawer", paperAnchorRight: "app-drawer" }}>
        <EditDrawer title="Edit Pending Orders Max Limit for Future Orders" fixedFooter onSubmit={this.handleSubmit} onClose={this.props.onClose}>
          <AdminCard
            title="Pending Orders Max Limit for Future Orders"
            style={{ marginTop: "15px" }}
            description="Set a maximum amount of concurrent future pending orders that can be ordered during a 15 minute window."
          >
            {errorMessage(this.state.apiErrors)}
            <form>
              <div className="app-field-wrapper">
                <label htmlFor="max_concurrent_scheduled_pickup_orders" className="app-field-label">
                  PICKUP MAX ORDER LIMIT
                </label>
                <HeaderText medium>Enter '0' for no limit.</HeaderText>
                <input
                  className={fieldError("max_concurrent_scheduled_pickup_orders", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="max_concurrent_scheduled_pickup_orders"
                  name="max_concurrent_scheduled_pickup_orders"
                  type="number"
                  placeholder="Max Order Limit"
                  value={this.state.max_concurrent_scheduled_pickup_orders}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('max_concurrent_scheduled_pickup_orders', this.state.apiErrors)}
              </div>
              <div className="app-field-wrapper">
                <label htmlFor="max_concurrent_scheduled_delivery_orders" className="app-field-label">
                  DELIVERY MAX ORDER LIMIT
                </label>
                <HeaderText medium>Enter '0' for no limit.</HeaderText>
                <input
                  className={fieldError("max_concurrent_scheduled_delivery_orders", this.state.apiErrors) ? "app-input-field red-border mt-0" : "app-input-field mt-0"}
                  id="max_concurrent_scheduled_delivery_orders"
                  name="max_concurrent_scheduled_delivery_orders"
                  type="number"
                  placeholder="Max Order Limit"
                  value={this.state.max_concurrent_scheduled_delivery_orders}
                  onChange={(event) => this.handleChange(event)}
                />
                {fieldError('max_concurrent_scheduled_delivery_orders', this.state.apiErrors)}
              </div>
            </form>
          </AdminCard>
        </EditDrawer>
      </Drawer>
    );
  }
}

export default FutureOrdersMaxEdit;
