import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';

import store from '../store/store';
import { errorMessage, fieldError } from '../services/utils';
import AdminCard from './elements/adminCard';
import EditDrawer from './elements/editDrawer';
import tenantAPI from '../services/api/tenantAPI';


class CustomTextMsgEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            apiErrors: {}
        };
    };

    handleChange(event) {
        let { content } = this.state;
        const value = event.target.value;
        const name = event.target.name;

        let customText = (_.find(store.customTextOptions, { property: name }));
        content.content[name] = _.clone(customText);
        content.content[name].value = value;
        this.setState({ content });
    };

    onSubmit = () => {
        this.setState({ apiErrors: {} }, () => {
            // Validate variables
            const variables = ['$ready_time', '$ready_date', '$contact'];
            _.map(this.state.content.content, item => {
                if (item.property.includes('order')) {
                    let text = item.value.split(' ');
                    text.forEach(val => {
                        val = val.replace('.', '');
                        if (val.startsWith('$') & !variables.includes(val)) {
                            let { apiErrors } = this.state;
                            apiErrors[item.property] = [{ message: 'Invalid variable name. Please check your message.' }];
                            this.setState({ apiErrors });
                        }
                    });
                }
            });
            if (!_.isEmpty(this.state.apiErrors)) {
                return;
            }
            store.updateContent(this.state.content);
            let payload = {
                'content': store.content.content
            };
            tenantAPI.postCustomTextFieldData(JSON.stringify(payload))
                .then(response => {
                    if (response.errors) {
                        this.setState({ apiErrors: response.errors });
                    } else {
                        tenantAPI.getCustomTextFieldData();
                        this.props.onClose(true);
                    }
                });
        });
    };

    handleReset = () => {
        let payload = {
            'content': { mobile_initial_modal_text: store.content.content.mobile_initial_modal_text, online_initial_modal_text: store.content.content.online_initial_modal_text }
        };

        tenantAPI.postCustomTextFieldData(JSON.stringify(payload))
            .then(response => {
                if (response.errors) {
                    this.setState({ apiErrors: response.errors });
                } else {
                    tenantAPI.getCustomTextFieldData();
                    this.props.onClose(true);
                }
            });
    };

    renderTextFields = () => {
        const content = this.state.content.content;
        const defaults = store.content.defaults;
        return (
            this.props.items.map(item => (
                <div key={item.property} className='app-card-section-wrapper pt-3 pb-2'>
                    <div>
                        <label className='inputLabel is-uppercase'>{ item.name }</label>
                        <textarea
                            rows="2"
                            style={ { width: '100%', padding: 10 } }
                            name={ item.property }
                            className={ `app-input-field mt-0 ${ fieldError(item.property, this.state.apiErrors) ? 'red-border' : ''}` }
                            value={ content && content[item.property] ? content[item.property].value : defaults[item.property].value }
                            onChange={ (event) => this.handleChange(event) }
                        />
                    </div>
                    { fieldError(item.property, this.state.apiErrors) }
                </div>
            ))
        );
    };

    handleClose = updated => {
        this.setState({ apiErrors: {} });
        this.props.onClose(updated);
    }

    render() {
        return (
            <Drawer
                anchor='right'
                open={ this.props.open }
                onClose={ () => this.handleClose(false) }
                classes={ { paper: 'app-drawer', paperAnchorRight: 'app-drawer' } }
            >
                <EditDrawer
                    title='Customize Order Status Text Messages'
                    onClose={ () => this.handleClose(false) }
                    onSubmit={ this.onSubmit }
                    topButton
                    buttonAction={ this.handleReset }
                    buttonText='Reset to Defaults'
                    errorMessage={ errorMessage(this.state.apiErrors) }
                >
                    <AdminCard
                        title='Edit Order Status Text Messages'
                        style={ { marginTop: '15px' } }
                        description={ this.props.description }
                    >
                        {this.renderTextFields()}
                    </AdminCard>
                </EditDrawer>
            </Drawer>
        );
    }
}

export default observer(CustomTextMsgEdit);
