import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { toJS } from 'mobx';

import integrationsAPI from '../services/api/integrationsAPI';
import tenantAPI from '../services/api/tenantAPI';
import store from '../store/store';
import { formatLocationHours } from '../services/utils';
import { Text } from '../components/elements/text';
import HoursCard from '../components/hoursCard';
import withNav from '../components/withNav';

import ToggleOrdering from '../components/location/toggleOrdering';
import PauseOrdering from '../components/location/pauseOrdering';
import ToggleSpecialInstructions from '../components/location/toggleSpecialInstructions';
import ToggleTipping from '../components/location/toggleTipping';
import ToggleGiftCards from '../components/location/toggleGiftCards';
import HolidayHours from '../components/location/holidayHours';
import WaitTime from '../components/location/waitTime';
import FutureOrdersMax from '../components/location/futureOrdersMax';
import SocialMedia from '../components/location/socialMedia';
import ToggleSquareInventory from '../components/location/toggleSquareInventory';
import AdminPageHeader from '../components/elements/adminPageHeader';
import LocationIntegrationCard from '../components/location/locationIntegrationCard';
import LocationSupportEmail from '../components/location/locationSupportEmail';


class LocationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {},
      fulfillments: []
    };
  };

  componentDidMount = () => {
    const { match } = this.props;
    this.interval = setInterval(() => {
      if (toJS(store.locations).length && toJS(store.fulfillments).length) {
        this.getLocation(match.params.locationId);
        clearInterval(this.interval);
      }
    }, 1000);
  };

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props) {
      const { match } = this.props;
      this.getLocation(match.params.locationId);
      document.getElementById('top').scrollIntoView({ behavior: 'smooth' });
    }
  };

  getLocation = locationId => {
    let location = store.locations.find(location => {
      store.updateCurrentLocation(locationId);
      return location.id === locationId;
    });
    let fulfillments = toJS(store.fulfillments).filter(f => {
      return f.locations.includes(location.id);
    });
    this.setState({ location, fulfillments });
  }

  getLocations = locationId => {
    tenantAPI.getRecursiveLocations({page: 1})
        .then(() => {
          let location = store.locations.find(location => {
            store.updateCurrentLocation(locationId);
            return location.id === locationId;
          });
          integrationsAPI.getRecursiveFulfillments({ page: 1 })
            .then(() => {
              let fulfillments = toJS(store.fulfillments).filter(f => {
                return f.locations.includes(location.id)
              })
              this.setState({ location, fulfillments });
            })
        });
  };

  handleUpdateHours = data => {
    tenantAPI.updateLocation(this.state.location.id, JSON.stringify(data.hours))
      .then(response => {
        let updatedLocation = formatLocationHours([response])[0];
        this.setState({ location: updatedLocation })
      })
      .catch(err => console.error(err));
  };

  handleUpdate = response => {
    if (response) {
      let location = formatLocationHours([response])[0];
      this.setState({ location });
    } else {
      this.getLocations(this.state.location.id);
    }
  };

  handleDeleteHoliday = (holidayId) => {
    tenantAPI.deleteHoliday(holidayId)
      .then(() => {
        this.getLocations(this.state.location.id);
      });
  };

  getFulfillment = (type) => {
    return this.state.fulfillments.find(f => {
      return f.integration === type;
    });
  };

  render() {
    if (!this.state.location) return <div />;
    const location = this.state.location;

    return (
      <div className='admin-page-wrapper' id='top'>
        <AdminPageHeader
          title={location.name}
          mainNav='Locations'
          description={<Text large>
            Changes made to these settings will apply only to the {location.name} location.
          </Text>}
        />
        <div className='admin-page-content'>
          <LocationSupportEmail location={location} onUpdate={this.handleUpdate} />
          <ToggleOrdering location={location} onUpdate={this.handleUpdate} />
          <PauseOrdering location={location} onUpdate={this.handleUpdate} />
          <HoursCard
            hours={location.store_hours}
            locationId={location.id}
            hoursType='Store'
            hoursPropName='store_hours'
            handleUpdate={this.handleUpdate}
          />
          <LocationIntegrationCard
            hours={ location.pickup_hours }
            fulfillment={ this.getFulfillment('basic_pickup') }
            fulfillmentType='basic_pickup'
            location={ location }
            hoursType='Pickup'
            hoursPropName='pickup_hours'
            handleUpdate={ this.handleUpdate }
          />
          <LocationIntegrationCard
            fulfillment={ this.getFulfillment('curbside_pickup') }
            fulfillmentType='curbside_pickup'
            location={ location }
            hoursType='Curbside'
            handleUpdate={ this.handleUpdate }
          />
          <LocationIntegrationCard
            hours={ location.delivery_hours }
            fulfillment={ this.getFulfillment('basic_delivery') }
            fulfillmentType='basic_delivery'
            location={ location }
            hoursType='Delivery'
            hoursPropName='delivery_hours'
            handleUpdate={ this.handleUpdate }
          />
          <HolidayHours
            location={location}
            onUpdate={this.handleUpdate}
            deleteHoliday={this.handleDeleteHoliday}
          />
          <WaitTime
            location={location}
            onUpdate={this.handleUpdate}
          />
          {store.tenant.pos_name === 'square' ?
            <ToggleSquareInventory location={location} onUpdate={this.handleUpdate} />
          : null}
          <ToggleTipping location={location} onUpdate={this.handleUpdate} />
          <ToggleGiftCards location={location} onUpdate={this.handleUpdate} />
          <ToggleSpecialInstructions location={location} onUpdate={this.handleUpdate} />
          <FutureOrdersMax
            location={location}
            onUpdate={this.handleUpdate}
          />
          <SocialMedia
            location={location}
            onUpdate={this.handleUpdate}
          />
        </div>
      </div>
    );
  }
}

export default withNav(withRouter(observer(LocationDetails)));
