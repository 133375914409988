import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination, TableSortLabel } from "@material-ui/core";

import store from '../store/store';
import { getNameById } from '../services/utils';
import AdminCard from '../components/elements/adminCard';
import AdminCheckbox from '../components/elements/adminCheckbox';
import AdminPageHeader from '../components/elements/adminPageHeader';
import AdminSearchInput from "../components/elements/adminSearchInput";
import AdminToolTip from '../components/elements/adminToolTip';
import { Button } from '../components/elements/buttons';
import { Text } from '../components/elements/text';
import ItemEdit from '../components/catalog/itemEdit';
import menuAPI from '../services/api/menuAPI';


class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      currentPage: 0,
      dialogOpen: false,
      inFocus: '',
      items: store.items,
      orderBy: 'full_name',
      order: 'desc',
      rowsPerPage: 10,
      selectedCategory: '',
      selectedItem: {},
      searchedItems: false,
      typing: false,
      typingTimeout: 0
    }
  }

  updateRows(event) {
    this.setState({ rowsPerPage: event.target.value, currentPage: 0 });
  }

  updatePage(event, newPage) {
    this.setState({ currentPage: newPage });
  }

  componentDidMount = () => {
    if (!store.items.length) this.getItems();
  };

  getItems = () => {
    store.updateItemSearch('');
    menuAPI.getRecursiveItems({ page: 1 })
      .then(() => {
        menuAPI.getRecursiveCategories({ page: 1 })
          .then(() => {
            this.setState({ items: store.items });
          })
      });
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  editItem = itemID => {
    menuAPI.getItemById(itemID)
      .then(response => {
        this.setState({ selectedItem: response, dialogOpen: !this.state.dialogOpen });
      });
  };

  handleDialogClose = updated => {
    this.setState({ dialogOpen: false });
    if (updated) this.setState({ items: store.items });
  };

  filterCategory(categoryId) {
    store.updateItemSearch('');
    let selectedCategory = '';
    if (categoryId === 'all') {
      this.getItems();
    } else {
      selectedCategory = categoryId;
      menuAPI.getItemsByCategory(categoryId)
        .then(response => {
          this.setState({ items: response.data });
        })
    }
    this.setState({ selectedCategory, currentPage: 0, anchorEl: null });
  };

  getApiSearch(query) {
    if (!this.state.searchedItems) {
      this.getItems();
    } else {
      menuAPI.searchItems(query)
        .then(response => {
          this.setState({ items: response.data, currentPage: 0 });
        });
    }
  };

  searchItems = query => {
    store.updateItemSearch(query);
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        self.getApiSearch(query);
      }, 500),
      searchedItems: query !== '',
    });
  }

  handleSort(value) {
    let items = this.state.items;
    let orderBy = value;
    let order = this.state.order === 'asc' && this.state.orderBy === value ? 'desc' : 'asc';
    if (value === 'name') items = _.orderBy(items, [item => item[value].toLowerCase()], [order]);
    else items = _.orderBy(items, [item => item[value]], [order]);
    this.setState({ items, order, orderBy, currentPage: 0 });
  }

  getSortIcon(orderBy) {
    let sortIcon = this.state.order === 'asc' && this.state.orderBy === orderBy ?
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-up'] } />
      :
      <FontAwesomeIcon size="1x" className='ordrslipRed ml-3' icon={ ['fas', 'caret-down'] } />;
    return sortIcon;
  }

  filterItems = enabledType => {
    let { items } = this.state;
    switch (enabledType) {
      case 'All Items':
        items = store.items;
        break;

      case 'Available':
        items = _.filter(store.items, ['is_available', true]);
        break;

      case 'Unavailable':
        items = _.filter(store.items, ['is_available', false]);
        break;

      default:
        items = store.items;
        break;
    }
    this.setState({ items, currentPage: 0 });
  };

  renderFilter = enabledType => {
    const itemCount = _.countBy(store.items, 'is_available');
    return (
      <React.Fragment>
        <Button edit onClick={ () => this.filterItems(enabledType) }>
          { enabledType }
        </Button>
        ({ enabledType === 'All Items' ? store.items.length : enabledType === 'Available' ? itemCount.true || 0 : itemCount.false || 0 })
      </React.Fragment>
    );
  }

  render() {
    const {
      anchorEl,
      currentPage,
      items,
      order,
      orderBy,
      rowsPerPage
    } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className='admin-page-wrapper'>
        <AdminPageHeader
          title='Items'
          mainNav='Configuration'
          subNav='Item Library'
          description={`Edit an item's settings, such as its description in the app or whether or not it's currently available for purchase.`}
        />
        <AdminCard>
          <div className='columns is-mobile is-vcentered mb-0'>
            <div className='column pb-0 is-two-fifths'>
              <div className='medium-text'>
                { this.renderFilter('All Items') }<span className='pl-1 pr-1'> | </span>
                { this.renderFilter('Available') }<span className='pl-1 pr-1'> | </span>
                { this.renderFilter('Unavailable') }<span className='pl-1 pr-1'> | </span>
              </div>
            </div>
            <div className='column pb-0 is-one-fifth is-offset-two-fifths'>
              <AdminSearchInput
                value={ store.itemSearch }
                onChange={ this.searchItems }
                style={ { paddingBottom: 12 } }
              />
            </div>
          </div>
          <div className='divider' />
          <Table className='app-table-wrapper mt-0'>
            <TableHead className='app-table-header'>
              <TableRow className='app-table-row'>
                <TableCell className='app-table-cell set-width-column-350'>
                  <TableSortLabel
                    active={ orderBy === "name" }
                    direction={ orderBy === "name" ? order : 'asc' }
                    onClick={ () => this.handleSort("name") }
                    IconComponent={ () => this.getSortIcon("name") }
                  >
                    Item Name
                    { orderBy === "name" ? (
                      <span className='is-hidden'>
                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell className='app-table-cell set-width thin'>
                  <TableSortLabel
                    onClick={ this.handleClick }
                    IconComponent={ () => this.getSortIcon("categories") }
                  >
                    Categories
                  </TableSortLabel>
                  <Popover
                    id='settings-popover'
                    open={ open }
                    anchorEl={ anchorEl }
                    onClose={ this.handleClose }
                    anchorOrigin={ {
                      vertical: 'bottom',
                      horizontal: 'center'
                    } }
                    transformOrigin={ {
                      vertical: 'top',
                      horizontal: 'center'
                    } }
                  >
                    <div className='popover-wrapper larger-padding'>
                      <div className='item-location-list'>
                        <FormControlLabel
                          control={
                            <AdminCheckbox
                              checked={ !this.state.selectedCategory }
                              onChange={ () => { this.filterCategory('all'); } }
                              name={ 'category' }
                            />
                          }
                          label="All Categories"
                          style={ { marginTop: "-5px" } }
                        />
                        { store.categories.length && store.categories.map((category) => (
                          <div key={category.id}>
                            <FormControlLabel
                              control={
                                <AdminCheckbox
                                  checked={ this.state.selectedCategory === category.id }
                                  onChange={ () => { this.filterCategory(category.id); } }
                                  name={ 'category' }
                                />
                              }
                              label={ category.name }
                              style={ { marginTop: "-5px" } }
                            />

                        </div>
                      )) }
                      </div>
                    </div>
                  </Popover>
                </TableCell>
                <TableCell className='app-table-cell set-width thin'>
                  <TableSortLabel
                    active={ orderBy === 'is_available' }
                    direction={ orderBy === 'is_available' ? order : 'asc' }
                    onClick={ () => this.handleSort('is_available') }
                    IconComponent={ () => this.getSortIcon('is_available') }
                  >
                    Available
                    { orderBy === 'is_available' ? (
                      <span className='is-hidden'>
                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                      </span>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell className='app-table-cell' >
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='app-table-body'>
              { items.length ?
                <>
                  { (rowsPerPage > 0 ? items.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : items).map(item => (
                  <TableRow
                    key={ item.id }
                    className={ `app-table-row ${ this.state.inFocus === item.id ? 'highlighted-row ' : '' }` }
                    onMouseOver={ () => this.setState({ inFocus: item.id }) }
                  >
                    <TableCell className='app-table-cell'>
                      { item.image ?
                        <FontAwesomeIcon icon='fa-solid fa-image' className='pr-2 secondaryBlue' />
                        : <FontAwesomeIcon icon='fa-regular fa-image-slash' className='pr-2 disabledGrey' />
                      }
                      { item.name }
                    </TableCell>
                    <TableCell className='app-table-cell'>
                      { item.categories.length ?
                        <div>
                            { getNameById(item.categories[0], 'categories') }
                            { item.categories.length - 1 > 0 ?
                            <span>
                                <AdminToolTip
                                  interactive
                                  arrow
                                  icon={ <span className="ordrslipRed"> +{ item.categories.length - 1 }</span> }
                                  title={
                                    <Text medium>
                                        { item.categories.length && item.categories.map((category, index) =>
                                          <div key={ category }>
                                            { getNameById(category, 'categories') }
                                          </div>
                                        ) }
                                    </Text>
                                  }
                                >
                                </AdminToolTip>
                            </span>
                            : null }
                        </div> : null }
                    </TableCell>
                    <TableCell className='app-table-cell'>
                        { item.is_available ? <span className='brightGreen semi-bold'>Available</span> : <span className='ordrslipRed semi-bold'>Unavailable</span> }
                    </TableCell>
                    <TableCell className='app-table-cell has-text-right'>
                      { this.state.inFocus === item.id ?
                        <Button
                          edit
                          onClick={() => this.editItem(item.id)}
                        >
                          Edit
                      </Button> : null}
                    </TableCell>
                  </TableRow>
                )) }
                  <TableRow className="app-table-row">
                    <TablePagination
                      rowsPerPageOptions={ [10, 25, 50] }
                      count={ items && items.length ? items.length : 0 }
                      rowsPerPage={ this.state.rowsPerPage }
                      onRowsPerPageChange={ (event) => this.updateRows(event) }
                      page={ this.state.currentPage }
                      onPageChange={ (event, newPage) => this.updatePage(event, newPage) }
                      style={ { borderBottom: 'none' } }
                    />
                  </TableRow>
                </>
              :
                <TableRow className='app-table-row'>
                  <TableCell className='app-table-cell'>
                    No Items matched your search
                  </TableCell>
                </TableRow> }
            </TableBody>
          </Table>
        </AdminCard>
        <ItemEdit
          item={ this.state.selectedItem }
          onClose={ this.handleDialogClose }
          open={ this.state.dialogOpen }
        />
      </div>
    );
  }
}

export default observer(ItemList);
